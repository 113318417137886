import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ROLE } from "../../base/members";
import { Select } from "../../base/ui";
const ChangeRoleSelect = React.memo(({ group_role, changeRole }) => {
    const { t } = useTranslation();
    const ROLE_OPTIONS = useMemo(() => [
        { uuid: ROLE.MANAGER, name: t(`role.${ROLE.MANAGER}`) },
        { uuid: ROLE.PARTICIPANT, name: t(`role.${ROLE.PARTICIPANT}`) },
        { uuid: ROLE.HOST, name: t(`role.${ROLE.HOST}`) }
    ], [t]);
    const [selectedOption, setSelectedOption] = useState();
    useEffect(() => {
        setSelectedOption(group_role);
    }, [group_role]);
    const onHandlerSet = (value) => {
        setSelectedOption(value);
        changeRole(value);
    };
    return (React.createElement(Select, { options: ROLE_OPTIONS, selectedOption: selectedOption, setSelectedOption: onHandlerSet }));
});
export default ChangeRoleSelect;
