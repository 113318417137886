/**
 *
 * @param handler
 */
export function getAttendance(handler) {
    APP.API.getAttendancesCheck()
        .then((response) => {
        if (response.complete) {
            handler.success(response.data);
        }
        else {
            handler.failed(response.data);
        }
    });
}
