import { ADD_CANVAS_LIST, DELETE_CANVAS_INDEX, DELETE_CANVAS_KEY, SET_CANVAS_LINE_DRAW, SET_CANVAS_OPTION, SET_CANVAS_TOOL, UPDATE_CANVAS_DRAW } from './actionTypes';
/**
 * 캔버스 리스트 삭제
 *
 * @param {String} uuid
 * @param {Number} index
 * @returns
 */
export function deleteCanvasIndex(uuid, index, isRemote = false) {
    return {
        type: DELETE_CANVAS_INDEX,
        uuid,
        index,
        isRemote
    };
}
/**
 * 캔버스 한줄 삭제
 *
 * @param uuid
 * @param index
 * @param key
 * @returns
 */
export function deleteCanvaskey(uuid, index, key, isRemote = false) {
    return {
        type: DELETE_CANVAS_KEY,
        uuid,
        index,
        key,
        isRemote
    };
}
/**
 * 한줄 라인 업데이트
 * @param data
 * @param isRmote
 * @returns
 */
export function setCanvasPoint(data, isRemote = false) {
    const { uuid, index, canvasTool, key, points } = data;
    return {
        type: SET_CANVAS_LINE_DRAW,
        uuid, index, key, property: { canvasTool, points },
        isRemote
    };
}
/**
 * 캔버스 리스트 데이터 업데이트
 * @param uuid
 * @param data
 * @returns
 */
export function updateCanvasDraw(uuid, data) {
    return {
        type: UPDATE_CANVAS_DRAW,
        uuid, data
    };
}
/**
 * 캔버스 도구 업데이트
 * @param tool
 * @returns
 */
export function setCanvasTool(tool) {
    return {
        type: SET_CANVAS_TOOL,
        tool
    };
}
/**
 * 캔버스 도구 옵션 업데이트
 * @param option
 * @returns
 */
export function setCanvasOption(option) {
    return {
        type: SET_CANVAS_OPTION,
        option
    };
}
/**
 * 초기값 설정(PDF 인덱스 리스트 )
 *
 * @param {Array} list
 * @returns
 */
export function addCanvasList(list) {
    return {
        type: ADD_CANVAS_LIST,
        list
    };
}
