import React from "react";
import { useTranslation } from "react-i18next";
import AddGroupButton from "./AddGroupButton";
import { PopupContainer } from "./common";
function GroupAddPopup() {
    const { t } = useTranslation();
    return (React.createElement(PopupContainer, { title: t("group.groupAdd"), guid: t("group.addGuid") },
        React.createElement(AddGroupButton, null)));
}
export default React.memo(GroupAddPopup);
