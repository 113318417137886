class EventManager {
    constructor() {
        this.subscribers = {};
    }
    subscribe(event, callback) {
        if (!this.subscribers[event]) {
            this.subscribers[event] = [];
        }
        this.subscribers[event].push(callback);
    }
    unsubscribe(event, callback) {
        const eventSubscribers = this.subscribers[event];
        if (eventSubscribers) {
            this.subscribers[event] = eventSubscribers.filter(cb => cb !== callback);
        }
    }
    publish(event, data) {
        const eventSubscribers = this.subscribers[event];
        if (eventSubscribers) {
            eventSubscribers.forEach(callback => {
                callback(data);
            });
        }
    }
}
export default EventManager;
