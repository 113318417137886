import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClickButton } from "../ui";
import ChoiceMemberStatus from "./ChoiceMemberStatus";
const ChoiceMember = React.memo(({ isChoice }) => {
    const { t } = useTranslation();
    const [isAll, setIsAll] = useState(false);
    const getAllMembers = (data) => {
        setIsAll(data?.members.size > 0 ? true : false);
    };
    useEffect(() => {
        ChoiceMemberStatus.subscribe('SET_CHOICE_MEMBER', getAllMembers);
    }, []);
    // useEffect(() => {
    //     if (isAll && isChoice) APP.eventManager.publish('SET_CHOICE_CHAT', { choice: false });
    // }, [isAll]);
    const handlerAllChoice = () => {
        APP.eventManager.publish('SET_CHOICE_CHAT', { choice: false });
        ChoiceMemberStatus.handlerMember({ type: 'init' });
    };
    const handlerChoice = () => {
        APP.eventManager.publish('SET_CHOICE_CHAT');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ClickButton, { className: `choice hr ${!isAll && 'isFocus'}`, label: t('chat.all'), onClick: handlerAllChoice }),
        React.createElement(ClickButton, { className: `choice ${isAll && 'isFocus'}`, label: t('chat.choice'), onClick: handlerChoice })));
});
export default ChoiceMember;
