/**
 * 캔버스 현재 사용하는 툴 수정
 *
 * {
 *      type: SET_CANVAS_TOOL,
 *      tool: String
 * }
 */
export const SET_CANVAS_TOOL = 'SET_CANVAS_TOOL';
/**
 * 캔버스 현재 사용하는 옵션 수정
 *
 * {
 *      type: SET_CANVAS_OPTION,
 *      option: {
 *          thickness?: Number,
 *          scale?: Number,
 *          opacity?: Number
 *          isWidthFit?: Boolean|undefined,
 *      }
 * }
 */
export const SET_CANVAS_OPTION = 'SET_CANVAS_OPTION';
/**
 * 초기 캔버스 리스트 세팅
 * @param {*} data
 * @returns
 */
export const SET_CANVAS_DRAW = 'SET_CANVAS_DRAW';
/**
 * 초기값 설정(PDF 인덱스 리스트 )
 *
 * @param {Array} list
 * @returns
 */
export const ADD_CANVAS_LIST = 'ADD_CANVAS_LIST';
/* ------------------------------------------------------------------- */
/**
 * 캔버스 리스트 삭제
 *
 * @param {String} uuid
 * @param {Number} index
 * @returns
 */
export const DELETE_CANVAS_INDEX = 'DELETE_CANVAS_INDEX';
/**
 * 한줄 라인 업데이트
 * @param data
 * @param isRmote
 * @returns
 */
export const SET_CANVAS_LINE_DRAW = 'SET_CANVAS_LINE_DRAW';
/**
 * 캔버스 리스트 데이터 업데이트
 * @param uuid
 * @param data
 * @returns
 */
export const UPDATE_CANVAS_DRAW = 'UPDATE_CANVAS_DRAW';
/**
 * 캔버스 한줄 삭제
 *
 * @param uuid
 * @param index
 * @param key
 * @returns
 */
export const DELETE_CANVAS_KEY = 'DELETE_CANVAS_KEY';
