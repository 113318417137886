import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClickButton } from "../../base/ui";
import { GROUP_CONTENTS, GROUP_CONTENT_TYPE } from "../constants";
import GroupListContainer from "./GroupListContainer";
import GroupMemberContainer from "./GroupMemberContainer";
import RemoteGroupButton from "./RemoteGroupButton";
import { GroupContainer } from "./prev";
import FocusGroupConatiner from "./FocusGroupContainer";
import GroupRandomPopup from "./GroupRandomPopup";
const PrevGroupContainer = React.memo(() => {
    const { t } = useTranslation();
    const [focusType, setFoucsType] = useState(GROUP_CONTENT_TYPE.MEMBER_LIST);
    const [focusItem, setFoucsItem] = useState();
    const updateFocus = (data) => {
        setFoucsItem(data?.uuid);
        setFoucsType(data?.uuid ? GROUP_CONTENT_TYPE.GROUP_FOUCS : GROUP_CONTENT_TYPE.GROUP_STATUS);
    };
    useEffect(() => {
        APP.eventManager.subscribe('UPDATE_FOCUS_GROUP_ITEM', updateFocus);
        return () => {
            APP.eventManager.subscribe('UPDATE_FOCUS_GROUP_ITEM', updateFocus);
        };
    }, []);
    useEffect(() => {
        if (focusType !== GROUP_CONTENT_TYPE.GROUP_FOUCS)
            setFoucsItem(undefined);
    }, [focusType]);
    const handlerFocusType = (type) => {
        if (type === GROUP_CONTENT_TYPE.RANDOM) {
            APP.eventManager.publish('UPDATE_OPEN_POPUP', { componet: GroupRandomPopup });
            return;
        }
        setFoucsType(type);
    };
    /**
     * 왼쪽 상단 메뉴 리스트 가져오기
     */
    const renderMenuList = useMemo(() => {
        return GROUP_CONTENTS.map(item => (React.createElement(ClickButton, { key: item, className: item === focusType && 'isFocus', isText: true, label: t(`group.${item}`), onClick: () => handlerFocusType(item) })));
    }, [focusType]);
    /**
     * 그룹 리스트 가져오기
     */
    const renderGroupList = useMemo(() => {
        return (React.createElement(React.Fragment, null,
            React.createElement(GroupListContainer, { isRow: false, isStart: false, focusItem: focusItem }),
            React.createElement(RemoteGroupButton, { isStart: true })));
    }, [focusItem]);
    /**
     * 컨텐트 가져오기
     */
    const renderContent = useMemo(() => {
        let member_list = [];
        switch (focusType) {
            case GROUP_CONTENT_TYPE.MEMBER_LIST:
                member_list = [
                    { name: 'status', sort: false },
                    { name: 'nickname', sort: true },
                    { name: 'group_role', sort: true },
                    { name: 'group_id', sort: false },
                ];
                return (React.createElement(GroupMemberContainer, { isSearch: true, member_list: member_list }));
            case GROUP_CONTENT_TYPE.GROUP_STATUS:
                return (React.createElement(GroupListContainer, { isRow: true, isStart: false, focusItem: focusItem }));
            case GROUP_CONTENT_TYPE.GROUP_FOUCS:
                member_list = [
                    { name: 'status', sort: false },
                    { name: 'nickname', sort: true },
                    { name: 'group_role', sort: true },
                ];
                if (!focusItem)
                    return null;
                return (React.createElement(FocusGroupConatiner, { focusItem: focusItem },
                    React.createElement(GroupMemberContainer, { isSearch: true, member_list: member_list, focusItem: focusItem })));
        }
    }, [focusType, focusItem]);
    return (React.createElement(GroupContainer, { renderMenuList: renderMenuList, renderGroupList: renderGroupList, renderContent: renderContent }));
});
export default PrevGroupContainer;
