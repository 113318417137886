import { getLocalID } from '../base/local';
import { MEDIA_TYPE, VIDEO_MUTISM_AUTHORITY, setAudioMuted, setVideoMuted } from '../base/media';
import { ADD_PINNED, ROLE, SET_MEMBER_ROLE, checkRoleLevel, getLocalMember } from '../base/members';
import { MiddlewareRegistry } from '../base/redux';
import { SETTINGS_UPDATED, UPDATE_ROOM_POLICY, getPropertyValue } from '../base/settings';

MiddlewareRegistry.register(store => next => async action => {
	let local = getLocalMember(store.getState);

	let role = local?.role;
	let pinned = local?.pinned;

	switch (action.type) {
		// case SETTINGS_UPDATED:
		// 	console.log(action);
		// 	if (action?.settings) {
		// 		const { settings } = action;
		// 		if (settings?.userSelectedCameraDeviceId) {
		// 			store.dispatch()
		// 		}
		// 		if (settings?.userSelectedMicDeviceId) {
		// 			store.dispatch(setAudioMuted(true, MEDIA_TYPE.AUDIO, false));
		// 		}
		// 	}
		// 	break;
		case UPDATE_ROOM_POLICY:
			if (!action.policy) return next(action);
			const policy = action.policy;
			const prevPolicy = getPropertyValue(store.getState(), 'policy');

			const prevPolicyCamera = prevPolicy?.initial_camera === 'following' ? 'on' : prevPolicy?.initial_camera;
			const prevPolicyMic = prevPolicy?.initial_mic === 'following' ? 'on' : prevPolicy?.initial_mic;

			const changePolicyCamera = policy.initial_camera === 'following' ? 'on' : policy.initial_camera;
			const changePolicyMic = policy.initial_mic === 'following' ? 'off' : policy.initial_mic;

			if (prevPolicyCamera !== changePolicyCamera || prevPolicyMic !== changePolicyMic) {
				const policy = action.policy || prevPolicy;

				if (!checkRoleLevel(role, ROLE.MANAGER) && !pinned) {
					store.dispatch(
						setVideoMuted(
							policy.initial_camera === 'off' ? true : false,
							MEDIA_TYPE.VIDEO,
							VIDEO_MUTISM_AUTHORITY.USER,
							false,
						),
					);
					store.dispatch(setAudioMuted(policy.initial_mic === 'off' ? true : false, MEDIA_TYPE.AUDIO, false));
				}

				if (policy.initial_camera === 'following' || policy.initial_mic === 'following') {
					const iCamera = policy.initial_camera === 'off' ? 'off' : 'on';
					const iMic = policy.initial_mic === 'off' ? 'off' : 'on';

					return next({
						...action,
						policy: {
							...policy,
							initial_camera: iCamera,
							initial_mic: iMic,
						},
					});
				}
			}
			break;
	}

	return next(action);
});
