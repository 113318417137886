import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactNewWindowStyles } from "react-new-window-styles";
import { useDispatch, useSelector } from "react-redux";
import ToggleSideBar from "../../base/header/components/ToggleSideBar";
import { getPropertyValue, updateSettings } from "../../base/settings";
import { SideBarContainer } from "../../base/sidebar";
import { ClickButton } from "../../base/ui";
import { toggleDualMonitor } from "../actions";
import { videoLayoutMode } from "../constants";
import { getCurrentMode } from "../functions";
import MembersLayout from "./MembersLayout";
import PageButton from "./PageButton";
import ScreenModeLayout from "./ScreenModeLayout";
import { DualStyled, LayoutContainerStyled } from "./layout/styles.web";
function DualLayoutConatiner() {
    const { t } = useTranslation();
    const newWindowNode = useRef();
    const layoutElement = useRef();
    const dispatch = useDispatch();
    const mode = useSelector((state) => getCurrentMode(state));
    const openSide = useSelector((state) => getPropertyValue(state, 'dualSideOpen'));
    const [windowOpen, setWindowOpen] = useState(false);
    const [outerStyle, setOuterStyle] = useState({ width: 960, height: 960 });
    const [prev, setPrev] = useState(false);
    const [next, setNext] = useState(false);
    const alertMessage = (data) => {
        console.log(data);
        if (newWindowNode.current) {
            const elementWindow = newWindowNode.current.state.externalWindow;
            if (elementWindow) {
                if (data?.message)
                    elementWindow.alert(t(data.message));
                if (data?.confirmMessage) {
                    const result = elementWindow.confirm(t(data.confirmMessage));
                    if (result)
                        data.confrimSuccess && data.confrimSuccess();
                }
            }
        }
    };
    useEffect(() => {
        APP.eventManager.subscribe('ALERT_DUAL_LAYOUT', alertMessage);
        return () => {
            APP.eventManager.unsubscribe('ALERT_DUAL_LAYOUT', alertMessage);
        };
    }, []);
    useEffect(() => {
        if (windowOpen && newWindowNode) {
            const element = newWindowNode.current.state.externalWindow;
            if (element && element.window) {
                element.window.addEventListener('resize', setContainerStyle);
            }
        }
    }, [windowOpen]);
    useEffect(() => {
        setContainerStyle();
    }, [openSide]);
    useEffect(() => {
        setWindowOpen(true);
        window.addEventListener("resize", setContainerStyle);
        return () => {
            setWindowOpen(false);
            window.removeEventListener("resize", setContainerStyle);
        };
    }, []);
    const unload = () => {
        dispatch(toggleDualMonitor());
        setWindowOpen(false);
    };
    const handlerSide = () => {
        dispatch(updateSettings({ dualSideOpen: !openSide }));
    };
    const setContainerStyle = () => {
        if (layoutElement && layoutElement.current) {
            const { width, height } = layoutElement.current.getBoundingClientRect();
            setOuterStyle({ width, height });
        }
    };
    const renderLayout = () => {
        const outerSize = {
            width: openSide ? outerStyle.width - 350 : outerStyle.width,
            height: outerStyle.height - 38
        };
        if (outerStyle.width >= 480) {
            return (React.createElement("div", { style: { display: "flex", width: "100%", height: "100%", flexWrap: "wrap", alignItems: "center" } },
                React.createElement(MembersLayout, { className: "gridFlex", type: "remote", noPaging: true, outerSize: outerStyle })));
        }
        return (React.createElement("div", { className: "vertical_grid" },
            React.createElement(MembersLayout, { className: "gridSlide", type: "remote", noPaging: true, outerSize: outerStyle })));
    };
    const renderButton = (type, onClick) => {
        return (React.createElement(ClickButton, { label: type, isText: true, icon: "prev", color: "#fff", onClick: onClick, size: 26 }));
    };
    return (React.createElement(ReactNewWindowStyles, { ref: newWindowNode, title: "Meemate", windowProps: { width: 960, height: 960 }, onClose: unload, copyStyles: true, autoClose: true },
        React.createElement(DualStyled, { isScreenMode: mode === videoLayoutMode.screen },
            openSide &&
                React.createElement("div", { className: "dual_sidebar" },
                    mode === videoLayoutMode.screen && React.createElement("div", { style: { height: "168px" } },
                        React.createElement(ScreenModeLayout, { isMobile: true })),
                    React.createElement("div", { className: "dual_side_header" },
                        React.createElement(ToggleSideBar, { className: 'side', mode: "chat", isText: true, size: 14, enableLable: true, label: t(`setting.chat`) }),
                        React.createElement(ToggleSideBar, { className: 'side', mode: "member", size: 14, isText: true, enableLable: true, label: t(`setting.member`) })),
                    React.createElement("div", { className: "dual_right_content" },
                        React.createElement(SideBarContainer, null))),
            React.createElement("div", { className: "dual_content", style: { width: `${openSide ? 'calc(100% - 350px)' : '100%'}` } },
                React.createElement("div", { className: "dual_side_header" },
                    React.createElement(ClickButton, { className: `downBtn ${openSide ? 'left' : 'right'}`, icon: "down", size: 20, onClick: handlerSide }),
                    React.createElement("div", { className: "page_btns" },
                        React.createElement(PageButton, { show: prev, type: 'prev', vertical: false, setPrev: setPrev, setNext: setNext, renderButton: renderButton }),
                        React.createElement(PageButton, { show: next, type: 'next', vertical: false, setPrev: setPrev, setNext: setNext, renderButton: renderButton }))),
                React.createElement(LayoutContainerStyled, { ref: layoutElement }, renderLayout())))));
}
export default React.memo(DualLayoutConatiner);
