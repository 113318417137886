import { toState } from "../redux";

/**
 * 사이드 바 (채팅 열렸는지에 대한 여부)
 * @param {Function} stateful 
 * @returns Boolean
 */
export function isChatOpend(stateful) {
    const state = toState(stateful);

    return state['features/base/sidebar'].chat;
}

/**
 * 사이드 바 (참석자 열렸는지에 대한 여부)
 * @param {Function} stateful 
 * @returns Boolean
 */
 export function isMemberOpend(stateful) {
    const state = toState(stateful);

    return state['features/base/sidebar'].member;
}

/**
 * 사이드바 열려있는지에 대한 여부
 * @param {Function} stateful 
 * @returns 
 */
export function isSideBarOpend(stateful) {
    const chat = isChatOpend(stateful);
    const member = isMemberOpend(stateful);
    return chat || member;
}