import React, { useMemo, useRef } from "react";
// @ts-ignore
import ClickButton from "./ClickButton";
// @ts-ignore
import Input from "./Input";
const SearchBar = React.memo(({ setSearch }) => {
    const searchRef = useRef(null);
    const onChangeSearch = () => {
        if (searchRef.current) {
            const value = searchRef.current.value;
            setSearch(value);
        }
    };
    const onKeyPress = e => {
        if (e.key === 'Space' || e.key === 'Enter' || e.key === 'NumpadEnter') {
            e.preventDefault();
            onChangeSearch();
        }
    };
    const searchIcon = useMemo(() => (React.createElement(ClickButton, { icon: "search", size: 24, color: "#29AAE2", onClick: onChangeSearch })), [onChangeSearch]);
    return (React.createElement(Input, { ref: searchRef, className: 'search_bar', type: 'text', placeholder: 'common.searchPlace', bottomLabel: searchIcon, onKeyPress: onKeyPress }));
});
export default SearchBar;
