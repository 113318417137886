import moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { ChatItem } from "./components";
import { getMessageByUid } from "./functions";
import { getPropertyValue } from "../settings";
const ChatItemContainer = React.memo(({ uuid }) => {
    const message = useSelector((state) => getMessageByUid(state, uuid), shallowEqual);
    if (!message)
        return;
    else
        return React.createElement(ChatRenderContainer, { message: message });
});
export default ChatItemContainer;
export const ChatRenderContainer = React.memo(({ message }) => {
    const { t } = useTranslation();
    const militaryTime = useSelector((state) => getPropertyValue(state, "militaryTime"));
    const time = useMemo(() => militaryTime ? moment(message.timestamp).format('HH:mm')
        : moment(message.timestamp).format('LT'), [militaryTime, message.timestamp]);
    const to = useMemo(() => {
        const privateRecipient = message.privateMessage;
        if (!privateRecipient)
            return null;
        if (message.type === 'system')
            return null;
        const recipientCount = privateRecipient.length;
        const recipients = privateRecipient && privateRecipient.map((r) => r.nickname);
        switch (recipientCount) {
            case 0:
                return t(message.isMe ? "chat.all" : "chat.toMe", {
                    sender: message.fromNickname,
                });
            case 1:
                return t(message.isMe ? "chat.youOne" : "chat.messageOne", {
                    sender: message.fromNickname,
                    recipient: recipients[0],
                });
            case 2:
                return t(message.isMe ? "chat.youTwo" : "chat.messageTwo", {
                    sender: message.fromNickname,
                    recipient1: recipients[0],
                    recipient2: recipients[1],
                });
            default:
                return t(message.isMe ? "chat.youMany" : "chat.messageMany", {
                    sender: message.fromNickname,
                    recipient: recipients[0],
                    count: recipientCount,
                });
        }
    }, [t, message.privateMessage, message.fromNickname]);
    return React.createElement(ChatItem, { to: to, type: message.type, message: message.message, isMe: message.isMe, uuid: message.uuid, time: time });
});
