export const config = {
    name: 'classMate',
    title: 'classMate',
    defaultLanguage: 'ko',
    lobby: {
        showLogin: true,
        showMyPage: true,
        logoUrl: '/libs/images/meetmate_logo.svg',
        logoWidth: {
            mobile: 72,
            desktop: 130,
        },
        prevBackgroundUrl: '/libs/images/main_bg.png',
        prevBackground: 'no-repeat center/cover fixed',
        backgroundUrl: '/libs/images/bg_lobby.png',
        background: 'no-repeat center/cover fixed',
        subText: null, // innovation 에서만 사용
        boxWidth: null, // innovation 에서만 사용
    },
    nickname: {
        backgroundUrl: '/libs/images/bg_nickname.png',
        background: 'no-repeat center/cover fixed',
    },
    ui: {
        layout: {
            GRID_MODE: true,
            PINNED_MODE: false,
            VAD_MODE: false,
            SEMINAR_MODE: false,
            whiteboard: true,
            document: true,
            common_note: false,
            screenShare: false,
            vod: true,
        },
        document: {
            direction: 'row',
            location: 'bottom',
        },
        film: {
            pin: false,
        },
        desktop: {
            header: {
                show: false,
                direction: 'row',
                left: null,
                middle: null,
                right: null,
            },
            footer: {
                show: true,
                direction: 'row',
                left: ['camera', 'mic'],
                middle: null,
                right: ['text-out'],
            },
            floating: {
                show: true,
                left: ['mode_change'],
                middle: null,
                right: null,
            },
        },
        mobile: {
            header: {
                show: false,
                direction: 'row',
                left: null,
                middle: null,
                right: null,
            },
            footer: {
                show: true,
                direction: 'row',
                left: ['camera', 'mic'],
                middle: null,
                right: ['text-out'],
            },
            floating: {
                show: true,
                left: ['mode_change'],
                middle: null,
                right: null,
            },
        },
    },
    optionList: [],
    // 기능에 대한 옵션
    option: {
        e2ee: true,
        mode_change: false,
        pin: true,
        grid: true,
        security: true,
        dualMonitor: true,
        selfRecord: true,
        serverRecord: true,
        devicePermission: true,
        settingDevice: true,
        fullscreen: false,
        group: true,
        workbook: true, // 통합 문서 (document + whiteboard)
        invite: true,
        chat: true,
        members: true,
        modeChange: false,
        pin: false,
        shareListPopup: true,
        enableLable: false,
        modeChange: true,
        gridCountSet: true,
        security: true,
        invite: true,
        notificationSet: true, // 알림 옵션 설정,
        attendance: true,
        chatFileLock: true,
        blur: true,
        vodFile: true,
        vodUrl: true,
        isDetailMode: true, //
    },
    style: {
        enableIconSize: 36,
        subAccountColor: '#000',
        main: '#fff',
        video_wrap: '#edf8f9',
        label: '#fff',
        playerColor: '#545454',
        focusColor: '#75b1ff',
        iconButton: {
            background: null,
            color: '#353B3D',
            iconBackColor: '#353B3D',
            iconStrokeColor: null,
        },
        iconHoverButton: {
            background: null,
            color: '#75b1ff',
            iconBackColor: '#75b1ff',
            iconStrokeColor: null,
        },
        textButton: {
            border: true,
            background: null,
            color: '#353B3D',
        },
        textHoverButton: {
            background: '#353B3D',
            color: '#fff',
        },
        canvasButton: {
            background: null,
            color: null,
            iconBackColor: '#101010',
            iconStrokeColor: null,
        },
        canvasHoverButton: {
            background: '#F3F4F8',
            color: null,
            iconBackColor: '#101010',
            iconStrokeColor: null,
        },
    },
};
