import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import loading from '../../../../../assets/images/loading.gif';
import { connect } from '../../../redux';
function Loading(props) {
    const data = props.isLoad ? props : useSelector((state) => state['features/base/loading']);
    const { load, option } = useMemo(() => data, [data]);
    return (React.createElement("div", { className: "load_cover center nonSelect", style: {
            background: `${option ? 'rgba(0,0,0, 0.8)' : 'rgba(0,0,0, 0.5)'}`,
            display: `${load ? 'block' : 'none'}`,
            zIndex: 9999,
            left: '50%',
            width: '100%',
            height: '100%',
        } },
        React.createElement("div", { className: "load center nonSelect" },
            React.createElement("dl", { style: {
                    background: `url(${loading}) no-repeat`,
                    width: '65px',
                    height: '65px',
                } }),
            React.createElement("span", { className: "text overText" }, option || 'LOADING'))));
}
export default connect()(Loading);
