import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
const RadioButtonStyled = styled.div `
    input[type='radio'] {
        width: ${props => props.size}px;
        height: ${props => props.size}px;
        min-width: ${props => props.size}px;
        min-height: ${props => props.size}px;
    }
`;
function RadioButton({ className, size = 25, guid, selectedOption, setSelectedOption, list, disabled }) {
    const { t } = useTranslation();
    const handleOptionChange = useCallback((event) => {
        setSelectedOption && setSelectedOption(event.target.value);
    }, [setSelectedOption]);
    return (React.createElement(RadioButtonStyled, { size: size, className: className },
        guid && React.createElement("div", { className: 'guid' },
            " ",
            t(guid),
            " "),
        list.map(item => (React.createElement("div", { key: item.id, className: 'radio' },
            React.createElement("input", { id: item.key, type: "radio", value: item.id, checked: selectedOption === item.id, onChange: handleOptionChange, disabled: disabled }),
            React.createElement("label", { className: `overText ${item.key}`, htmlFor: item.key, key: item.id }, item.label))))));
}
export default React.memo(RadioButton);
