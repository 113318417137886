import React, { useEffect, useState } from "react";
import RaiseHand from "./RaiseHand";
import { useSelector } from "react-redux";
import { ClickButton, Input } from "../base/ui";
import { useTranslation } from "react-i18next";
import { connect } from "../base/redux";
import { ROLE, checkRoleLevel, getLocalMemberRole } from "../base/members";

const KEEP = 'keep';
const FADE_OUT = 'fadeout';

const list = [
    {name: KEEP, icon: 'raise_hand'},
    {name: FADE_OUT, icon: 'raise_hand'},
]
function RaiseHandContainer({
    hands_up,
    hands_up_time, local_role,
    handlerUpdatePolicy, renderSubmitBtn
}) {
    const { t } = useTranslation();
    const guid = React.useMemo(() => t("dialog.raiseGuid"), [t]);

    const [ isRole, setIsRole ] = useState();
    const [handType, setHandType] = useState(hands_up);
    const [handTime, setHandTime] = useState(hands_up_time);

    useEffect(() => {
        setHandType(hands_up);
    }, [hands_up]);

    useEffect(() => {
        setHandTime(hands_up_time / 1000);
    }, [hands_up_time]);

    useEffect(() => {
        const isRole = checkRoleLevel(local_role, ROLE.MANAGER);
        setIsRole(isRole);
    }, [local_role]);

    const renderTimeout = () => {
        // 권한이 없는 경우 
        if (!isRole) {
            return (
                <ClickButton
                    className="no-hover disable"
                    isText={true}
                    label={t("dialog.timeSec", { sec: handTime })}
                />
            )
        } else {
            return (
                <Input className="hand_input" type="number" value={handTime} bottomLabel={t("dialog.sec")} onChange={setHandTime} />
            )
        }
    }

    const renderHands = React.useMemo(() => {
        return list.map(item => (
            <div className="hand_item" key={item.name}>
                <ClickButton
                    className={`option_item ${item.name === handType ? "isFocus" : ''}`}
                    icon={item.icon}
                    label={t(`dialog.${item.name}Handler`)} size={30}
                    onClick={isRole ? () => setHandType(item.name) : null}
                />

                { handType === FADE_OUT && item.name === FADE_OUT && renderTimeout() }
            </div>
        ));
    }, [handType, t, setHandType, handTime]);

    

    const onClick = () => {
        if (handTime > 50 || handTime <= 0) {
            APP.UI.alertMessage(t("dialog.notAllowTime"));
            return;
        }
        handlerUpdatePolicy({ hands_up: handType, hands_up_time: handTime * 1000 });
    }

    return (
        <>
            <RaiseHand guid={guid} renderHands={renderHands} />
            { isRole && renderSubmitBtn(onClick) }
        </>
    )
}

function _mapStateToProps(state) {
    const policy = state["features/base/settings"].policy;
    const local_role = getLocalMemberRole(state);

    return {
        hands_up: policy.hands_up,
        hands_up_time: policy.hands_up_time,
        local_role
    }
}

export default connect(_mapStateToProps)(RaiseHandContainer);