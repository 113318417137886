/**
 * 사용자 정보 저장
 * @param {ILocalState['token']} token
 * @param {ILocalState['user']} user
 * @returns
 */
export const SET_USER_INFO = 'SET_USER_INFO';
/**
 * 로그 아웃
 */
export const LOGOUT = 'LOGOUT';
/**
 * 클래스 메이트 로그인
 */
export const CLASS_MATE_LOGIN = 'CLASS_MATE_LOGIN';
