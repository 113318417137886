import { toState } from '../base/redux';
import { DOCUMENT_MODE, WORKBOOK_LIST } from './constants';
/**
 * (SHARE LAYOUT) 문서 reducer
 * @param stateful
 * @returns
 */
export function getShareState(stateful) {
    const state = toState(stateful);
    return state['features/share-layout'];
}
/**
 * uuid로 문서 아이템 가져오기
 * @param stateful
 * @param uuid
 * @returns
 */
export function getDocumentItemByUUID(stateful, uuid) {
    const shareState = getShareState(stateful);
    const visible_list = shareState.visibleList;
    const list = [...visible_list].find((item) => item.uuid === uuid);
    return list;
}
/**
 * uuid로 문서 아이템 가져오기
 * @param stateful
 * @param uuid
 * @returns
 */
export function getDocumentImgListByUID(stateful, uuid) {
    const shareState = getShareState(stateful);
    const visible_list = shareState.visibleList;
    const item = [...visible_list].find((item) => item.uuid === uuid);
    return item.list;
}
/**
 * 문서 파일 보내기 전 한글 파일인지 확인 후 한글 파일일 경우 변환 알림 문구 표출
 * @param {} ext
 * @returns
 */
export function preventHWP(ext) {
    if (ext.toUpperCase().indexOf('HWP') !== -1) {
        if (!APP.UI.confirmMessage('share.hwpError')) {
            return true;
        }
    }
    return false;
}
/**
 * 문서 리스트 가져오기
 *
 * @param {Function} getState
 * @param {String} mode
 * @returns
 */
export function getDocumentList(stateful) {
    const shareState = getShareState(stateful);
    const whiteboard = shareState.whiteboard;
    const document = shareState.document;
    return [...whiteboard, ...document];
}
/**
 * 문서 리스트 가져오기
 *
 * @param {Function} getState
 * @param {String} mode
 * @returns
 */
export function getDocumentListByMode(stateful, mode) {
    const shareState = getShareState(stateful);
    const isWorkBook = APP.config.option.workbook && WORKBOOK_LIST.includes(mode);
    if (isWorkBook)
        return getDocumentList(stateful);
    else
        return shareState[mode] ? [...shareState[mode]] : [];
}
/**
 * 문서 리스트 가져오기
 *
 * @param {Function} getState
 * @param {String} mode
 * @returns
 */
export function getVisibleDocumentList(stateful) {
    const shareState = getShareState(stateful);
    const visibleList = shareState.visibleList;
    return visibleList;
}
/**
 * 방 설정 권한 확인 (레이아웃에서만 사용)
 * @param {Function} stateful
 * @param {String} type
 * @returns Boolean
 */
export function checkSharePermission(stateful, type) {
    const state = toState(stateful);
    const permissions = state['features/room'].permissions;
    if (permissions.indexOf(type) !== -1)
        return true;
    return false;
}
/**
 * focus된 문서 가져오기
 */
export function getFocusItemByMode(stateful, mode) {
    const shareState = getShareState(stateful);
    const isWorkBook = APP.config?.option?.workbook && WORKBOOK_LIST.includes(mode);
    const changeMode = isWorkBook ? DOCUMENT_MODE : mode;
    return shareState.focus[changeMode];
}
/**
 * 캔버스 리스트 파싱
 * @param {Array} canvasList
 * @returns
 */
export function parseCanvaseLine(canvasList) {
    let list = new Map();
    canvasList &&
        Object.keys(canvasList).forEach(index => {
            const value = new Map();
            Object.keys(canvasList[index]).map(key => {
                value.set(key, canvasList[index][key]);
            });
            list.set(Number(index), value);
        });
    return list;
}
/**
 * 가능한 페이지 인가 확인
 */
export function isFocusAblePage(stateful, uuid, page) {
    const document = getDocumentItemByUUID(stateful, uuid);
    if (page < 0)
        return false;
    if (document.list && document.list.length < page + 1)
        return false;
    return true;
}
