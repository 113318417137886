import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dialog, hideDialog } from "../../dialog";
import { ClickButton } from "../../ui";
function InviteCode({ code }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const onCopy = () => {
        // dispatch(showNotification({titleKey: "invite.copyRoom"}, NOTIFICATION_TIMEOUT));
        dispatch(hideDialog());
    };
    return (React.createElement(Dialog, { titleKey: t("invite.title"), width: 400 },
        React.createElement("div", { className: 'invite_wrapper wrap_center' },
            React.createElement("p", null, t("invite.guid")),
            React.createElement(CopyToClipboard, { text: code, onCopy: onCopy },
                React.createElement(ClickButton, { isText: true, label: "invite.copyInviteCode" })))));
}
export default React.memo(InviteCode);
