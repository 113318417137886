/**
 * Create an action for when the settings are updated.
 *
 * {
 *     type: SETTINGS_UPDATED,
 *     settings: {
 *          route: stirng|boolean
 *     }
 * }
 */
export const SETTINGS_UPDATED = 'SETTINGS_UPDATED';
/**
 * 닉네임 저장
 *
 * {
 *     type: SET_DISPLAYNAME,
 *     displayName: String
 * }
 */
export const SET_DISPLAYNAME = 'SET_DISPLAYNAME';
/**
 * 전체 화면 보기
 * {
 *     type: SET_FULL_SCREEN,
 *     full: Boolean
 * }
 */
export const SET_FULL_SCREEN = 'SET_FULL_SCREEN';
/**
 * 룸 설정 값 업데이트
 * @param {Object} policy
 * @param {Boolean} isRemote
 */
export const UPDATE_ROOM_POLICY = 'UPDATE_ROOM_POLICY';
