import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { ROLE, getOccupiedMemberByGroupId } from "../../../base/members";
import { GroupTableStyled } from "../styles";
import { ClickButton } from "../../../base/ui";
export const GroupItem = React.memo(({ name, member_count, isFocus, setFocus, renderModifyBtn, renderDeleteBtn }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: `group_item ${isFocus ? 'isFocus' : ''}` },
        React.createElement("div", { className: "name_wrapper", onClick: setFocus },
            React.createElement("div", { className: "name overText" }, name),
            React.createElement("div", null,
                " (",
                t('group.person', { count: member_count }),
                ")")),
        renderModifyBtn,
        renderDeleteBtn));
});
export const GroupDetailItem = React.memo(({ uuid, name, member_count, setFocus }) => {
    const { t } = useTranslation();
    const enter_member = useSelector((state) => getOccupiedMemberByGroupId(state, uuid), shallowEqual);
    const host = useMemo(() => {
        const host = enter_member.find(item => item.group_role === ROLE.HOST);
        return host?.nickname || '-';
    }, [enter_member]);
    return (React.createElement("div", { className: "group_item" },
        React.createElement("div", { className: "title" },
            React.createElement("h2", { className: "overText" },
                name,
                " "),
            React.createElement(ClickButton, { className: "more", icon: 'plus', size: 12, label: t("group.more"), onClick: setFocus, color: "#545454" })),
        React.createElement(GroupTableStyled, { isFull: false },
            React.createElement("div", { className: "table_th" },
                React.createElement("div", null, t("group.memberCount")),
                React.createElement("div", null, t("group.belongCount")),
                React.createElement("div", null, t("group.hostName"))),
            React.createElement("div", { className: "table_body" },
                React.createElement("div", { className: "table_td" },
                    React.createElement("div", { className: "overText" }, t("group.person", { count: member_count })),
                    React.createElement("div", { className: "overText" }, t("group.person", { count: enter_member?.length })),
                    React.createElement("div", { className: "overText" }, host))))));
});
