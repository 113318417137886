import React, { Fragment, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ClickButton } from "../../base/ui";
import AddVodItem from "../../base/vod/AddVodItem";
import { convertVodList, registVod } from "../../base/vod/functions";
import { getRoomOption, isCheckPermission } from "../../room";
import ShareLayoutAction from "../ShareLayoutAction";
import { addShareItem, removeShareItem } from "../actions";
import { DOCUMENT_MODE, VOD_MODE, WHITE_MODE, WORKBOOK_LIST } from "../constants";
import { AddDocumentStyled } from "./styles.web";
import { PERMISSION } from "../../base/members";
const AddDocumentButton = React.memo(({ mode, isDialog = false, isIcon = false, }) => {
    const { t } = useTranslation();
    const permission = useSelector((state) => isCheckPermission(state, PERMISSION.SHARE));
    const meeting_uuid = useSelector((state) => getRoomOption(state).uuid);
    const isWorkBook = useMemo(() => APP.config.option.workbook && WORKBOOK_LIST.includes(mode), [mode]);
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    /**
     * (action) 파일 문서 변환 후 저장
     * @param {*} e
     */
    const handlerAddDocument = e => {
        const file = e.target.files[0];
        ShareLayoutAction.addDoumentFile(dispatch, file)
            .then(() => {
            e.target.value = "";
        })
            .catch(err => {
            const { message, uuid } = err;
            // 지원 하지 않는 파일 입니다. or 한글 파일 
            APP.UI.alertMessage(message);
            e.target.value = "";
            uuid && ShareLayoutAction.deleteShareItem(dispatch, uuid);
        });
    };
    /**
     * (action) 화이트 보드 (빈노트) 저장
     */
    const handlerAddWhite = () => {
        ShareLayoutAction.addDocoumetItem(dispatch, { config: WHITE_MODE });
    };
    /**
     * 문서 버튼 생성
     * if ) 공유 권한이 없는 경우 표출 X
     * @returns Comopnent
     */
    const _renderDocumentAddBtn = () => {
        return (React.createElement(Fragment, { key: "document" },
            React.createElement("input", { ref: inputRef, type: "file", id: "add_document", style: { display: "none" }, onChange: handlerAddDocument }),
            React.createElement("label", { htmlFor: "add_document" },
                React.createElement(ClickButton, { className: "add_btn", icon: 'document_add', color: "#fff", size: 26, isText: isDialog, label: isDialog ? t("document.addDocument") : "" }))));
    };
    /**
    * 화이트 보드 (빈 노트) 생성 버튼
    * if ) 공유 권한이 없는 경우 표출 X
    * @returns
    */
    const _renderWhiteAddBtn = () => {
        return (React.createElement(ClickButton, { key: "whiteboard", className: "add_btn", icon: 'whiteboard_add', color: "#fff", size: 26, isText: isDialog, label: isDialog ? t("document.addWhiteBoard") : "", onClick: handlerAddWhite }));
    };
    /**
     * (VOD) 문서 등록
     */
    const _renderVodAddBtn = () => {
        const handler = {
            success: async (data) => {
                // 앨범 조회 
                const vod_list = await registVod([data.items]);
                APP.API.changeVodList(vod_list, meeting_uuid, 'append')
                    .then(async (response) => {
                    if (response.complete) {
                        const chagne_list = [];
                        vod_list.map(i => chagne_list.push({
                            uuid: i.streaming_id,
                            name: i.name,
                            channel_id: '',
                            url: i.streaming_url
                        }));
                        const list = await convertVodList(chagne_list);
                        dispatch(addShareItem(list[0]));
                        dispatch(removeShareItem(data.uuid, true));
                    }
                    else {
                    }
                });
            },
            failed: (err) => {
                APP.UI.alertMessage(err);
            },
            setLoad: (load) => {
            }
        };
        return (React.createElement(AddVodItem, { key: "vod", isDialog: isDialog, isIcon: isIcon, isRoom: false, handler: handler }));
    };
    const buttons = useMemo(() => {
        if (APP.config.option.shareListPopup && !permission)
            return null;
        return isWorkBook ? [_renderWhiteAddBtn(), _renderDocumentAddBtn()].filter(Boolean)
            : mode === WHITE_MODE
                ? [_renderDocumentAddBtn()].filter(Boolean)
                : mode === DOCUMENT_MODE
                    ? [_renderDocumentAddBtn()].filter(Boolean)
                    : mode === VOD_MODE
                        ? [_renderVodAddBtn()].filter(Boolean)
                        : null;
    }, [isWorkBook, mode, permission]);
    return (React.createElement(AddDocumentStyled, { className: "add_btn_wrapper", name: APP.config.name }, buttons));
});
export default AddDocumentButton;
