/**
 * 로딩 페이지
 * {
 *     type: OPEN_LOAD,
 *     option: String|undefiend *
*  }
 */
export const OPEN_LOAD = 'OPEN_LOAD';
/**
 * 로딩 페이지 hide
 */
export const HIDE_LOAD = 'HIDE_LOAD';
