import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ClickButton } from "../../base/ui";
import { getGroupById } from "../functions";
import GroupDeletePopup from "./GroupDeletePopup";
import GroupModifyPopup from "./GroupModifyPopup";
import { GroupDetailItem, GroupItem } from "./prev";
import { GroupStartItem } from "./start";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { assignGroup, remoteGroup, toggleOpenGroups } from "../action";
import { getLocalID } from "../../base/local";
import { ROLE } from "../../base/members";
import { getActiveGroupId } from "../../room";
import { toggleLoad } from "../../base/loading";
import SendMessagePopup from "./SendMessagePopup";
const GroupItemContainer = React.memo(({ uuid, isRow, isStart, isFocus }) => {
    const list = useSelector((state) => getGroupById(state, uuid), shallowEqual);
    if (!list)
        return null;
    return React.createElement(GroupItemRender, { list: list, isRow: isRow, isStart: isStart, isFocus: isFocus });
});
const GroupItemRender = React.memo(({ list, isRow, isStart, isFocus }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const local_id = useSelector((state) => getLocalID(state));
    const active_group = useSelector((state) => getActiveGroupId(state));
    const { name, uuid, member_count, open_time, status } = useMemo(() => list, [list]);
    const handlerOpenModify = () => {
        APP.eventManager.publish('UPDATE_OPEN_POPUP', { componet: GroupModifyPopup, props: { uuid, name } });
    };
    const handlerOpenDelete = () => {
        APP.eventManager.publish('UPDATE_OPEN_POPUP', { componet: GroupDeletePopup, props: { uuid, name } });
    };
    const handleJoinLeaveAction = async () => {
        if (local_id) {
            dispatch(toggleOpenGroups());
            dispatch(toggleLoad(true));
            dispatch(assignGroup(uuid, [{ user_id: local_id, role_name: ROLE.ADMIN, type: '' }], 'reset', false));
            setTimeout(() => {
                APP.management.changeManagement(active_group, { group_id: uuid, group_role: ROLE.ADMIN });
                dispatch(toggleLoad(false));
            }, 3000);
        }
    };
    const handlerSendMessage = () => {
        APP.eventManager.publish('UPDATE_OPEN_POPUP', { componet: SendMessagePopup, props: { uuid, name } });
    };
    const handlerStartGroup = () => {
        const isStart = status === 'opened' ? false : true;
        dispatch(remoteGroup(isStart, uuid));
    };
    /**
     * 수정 버튼 component 랜더링
     */
    const renderModifyBtn = useMemo(() => (React.createElement(ClickButton, { icon: "modify", size: 16, onClick: handlerOpenModify })), [handlerOpenModify]);
    /**
     * 삭제 버튼 component 랜더링
     */
    const renderDeleteBtn = useMemo(() => (React.createElement(ClickButton, { icon: "delete", size: 16, onClick: handlerOpenDelete })), [handlerOpenDelete]);
    /**
     * 메시지 전달 버튼 component 랜더링
     */
    const renderEnterRoom = useMemo(() => (React.createElement(ClickButton, { label: t("group.enter"), isText: true, onClick: handleJoinLeaveAction })), []);
    /**
     * 메시지 전달 버튼 component 랜더링
     */
    const renderSendMessage = useMemo(() => (React.createElement(ClickButton, { label: t("group.sendMessage"), isText: true, onClick: handlerSendMessage })), []);
    /**
     * 개인 그룹 종료 버튼 component 랜더링
     */
    const renderEndRoom = useMemo(() => (React.createElement(ClickButton, { label: t(`group.${status === 'opened' ? "close" : "start"}`), isText: true, onClick: handlerStartGroup })), [status, handlerStartGroup]);
    const setFocus = () => APP.eventManager.publish('UPDATE_FOCUS_GROUP_ITEM', { uuid });
    if (!isRow) {
        return (React.createElement(GroupItem, { uuid: uuid, name: name, member_count: member_count, isFocus: isFocus, setFocus: setFocus, renderModifyBtn: renderModifyBtn, renderDeleteBtn: renderDeleteBtn }));
    }
    else {
        if (isStart) {
            return (React.createElement(GroupStartItem, { uuid: uuid, name: name, member_count: member_count, open_time: open_time, renderEnterRoom: renderEnterRoom, renderSendMessage: renderSendMessage, renderEndRoom: renderEndRoom }));
        }
        else {
            return (React.createElement(GroupDetailItem, { uuid: uuid, name: name, member_count: member_count, isFocus: isFocus, setFocus: setFocus }));
        }
    }
    return null;
});
export default GroupItemContainer;
// function GroupItemContainer({
//     uuid, name, count, start, open_time, handlerPopup 
// }) {
//     const { t } = useTranslation();
//     const dispatch = useDispatch();
//     const { local, active_group, enter_member } = useSelector(state => {
//         return {
//             local: getLocalMember(state),
//             active_group: getActiveGroupId(state),
//             enter_member: getOccupiedMemberByGroupId(state, uuid)?.length
//         }
//     }, shallowEqual);
//     /**
//      * (Action) 개별 그룹 시작
//      */
//     const handlerStartGroup = useCallback(() => {
//         dispatch(remoteGroup(!start, uuid));
//     }, [start, name]);    
//     /**
//      * (Component) 개별 그룹 종료/시작 버튼 
//      */
//     const renderEndRoom = useMemo(() => {
//         return (
//             <ClickButton label={t(`group.${!start ? "start" : "close"}`)} 
//                 isText={true} onClick={handlerStartGroup} />
//         )
//     }, [start, t]);
//     /**
//      * (Action) 메시지 보내기
//      */
//     const handlerSendMessage = useCallback(() => {
//         handlerPopup(SendMessagePopup, { uuid, name, handlerPopup })
//     }, [uuid, name]);
//     /**
//      * (Component) 메시지 보내기 버튼 
//      */
//     const renderSendMessage = useMemo(() => {
//         if (enter_member < 1 || !start) return null;
//         return (
//             <ClickButton label={t("group.sendMessage")} isText={true} 
//                 onClick={handlerSendMessage}
//             />
//         )
//     }, [start, enter_member]);
//     /**
//      * (Action) 개별 그룹 입장
//      */
//     const handleJoinLeaveAction = useCallback(async () => {
//         // 할당 
//         await dispatch(assignGroup(uuid, [{ user_id: local.user_uuid, role_name: ROLE.ADMIN, type: '' }], 'reset', false));
//         // 룸 이동 
//         APP.management.changeManagement(active_group, {group_id: uuid, group_role: ROLE.ADMIN});
//     }, [local, uuid, active_group]);
//     /**
//      * (Component) 개별 그룹 입장 버튼 
//      */
//     const renderEnterRoom = useMemo(() => {
//         if (!start) return null;
//         return (
//             <ClickButton label={t("group.enter")} isText={true} 
//                 onClick={handleJoinLeaveAction}
//             />
//         )
//     }, [start]);
//     return (
//         <GroupItem t={t} uuid={uuid} name={name} count={count}
//             open_time={open_time} enter_member={enter_member}
//             renderEnterRoom={renderEnterRoom}
//             renderEndRoom={renderEndRoom}
//             renderSendMessage={renderSendMessage} />
//     )
// }
// export default React.memo(GroupItemContainer);
