import { throttle } from "lodash";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { isMobileBrowser } from "../../base/environment/utils";
import { isSideBarOpend } from "../../base/sidebar";
import { HORIZONTAL_MODE, VIDEO_MODE_LIST } from "../constants";
import { getDualMonitor } from "../functions";
import { LayoutContainerStyled } from "./layout/styles.web";
import { getPropertyValue } from "../../base/settings";
export default React.memo(function LayoutWrapper({ mode, children }) {
    const layoutRef = useRef();
    const isDualMonitor = useSelector((state) => getDualMonitor(state));
    const sidebar = useSelector((state) => isSideBarOpend(state));
    const dualSideOpen = useSelector((state) => getPropertyValue(state, 'dualSideOpen'));
    /**
     * 레이아웃 사이즈 세팅
     */
    const setLayout = throttle(() => {
        const element = layoutRef?.current;
        if (element) {
            const { width, height } = element.getBoundingClientRect();
            APP.eventManager.publish('UPDATE_LAYOUT_SIZE', { width, height });
        }
    }, 500);
    /**
     * 초기 설정
     */
    useEffect(() => {
        setLayout();
        window.addEventListener("resize", setLayout);
        return () => {
            window.removeEventListener("resize", setLayout);
        };
    }, []);
    /**
     * 레이아웃에 변화가 생기는 경우
     * 사이드 바가 오픈된다거나, 듀얼 모니터가 사용되는 경우
     * -> 사이즈 다시 가져오기
     */
    useEffect(() => {
        setLayout();
    }, [sidebar, isDualMonitor, dualSideOpen, isMobileBrowser()]);
    return (React.createElement(LayoutContainerStyled, { name: APP.config.name, ref: layoutRef, isGrid: VIDEO_MODE_LIST.includes(mode), horizontal: HORIZONTAL_MODE.includes(mode), isHide: false }, children));
});
