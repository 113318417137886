import React, { useMemo } from "react";
import { ClickButton } from "../ui";
import { useSelector } from "react-redux";
import { getRoomOption } from "../../room";
import axios from "axios";
import moment from "moment";
const SaveChatButton = React.memo(() => {
    const meeting = useSelector((state) => getRoomOption(state));
    const { meeting_uuid, meeting_name } = useMemo(() => {
        return {
            meeting_uuid: meeting?.uuid,
            meeting_name: meeting?.name
        };
    }, [meeting]);
    const onClick = async () => {
        try {
            const fileUrl = `/api/meeting/v1/meetings/${meeting_uuid}/chat-logs-as-csv`;
            axios.get(fileUrl, { responseType: "blob" })
                .then((response) => {
                const now = moment().format("YYYY-MM-DD_HHmmss");
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.download = meeting_name + "_" + now + ".csv";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
                .catch((error) => {
                APP.UI.alertMessage("chat.failDownload");
            });
        }
        catch (err) {
            console.log(err);
        }
    };
    return (React.createElement(ClickButton, { className: 'chatSave', icon: 'chatSave', size: 18, color: "#000", onClick: onClick }));
});
export default SaveChatButton;
/**
 * 리액트 네이티브
 * // import RNFetchBlob from 'rn-fetch-blob';
 *  const response = await RNFetchBlob.config({
                fileCache: true,
                appendExt: 'csv', // 파일의 확장자를 지정합니다. 필요에 따라 변경 가능합니다.
                path: RNFetchBlob.fs.dirs.DownloadDir + '/' + 'test.csv',
            }).fetch('GET', process.env.MATE_HOST + `/api/meeting/v1/meetings/${meeting_uuid}/chat-logs-as-csv`);

 */ 
