import React from "react";
import { useSelector } from "react-redux";
import { getGroupByUid } from "../functions";
import { FocusGroup } from "./prev";
const FocusGroupConatiner = React.memo(({ focusItem, children }) => {
    const group = useSelector((state) => getGroupByUid(state, focusItem));
    if (!group)
        return null;
    return (React.createElement(FocusGroup, { name: group.name, count: group.member_count }, children));
});
export default FocusGroupConatiner;
