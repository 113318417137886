import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ClickButton } from "../../base/ui";
import { deleteGroup } from "../action";
import { PopupContainer } from "./common";
function GroupDeletePopup({ uuid, name }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const onClick = useCallback(() => {
        dispatch(deleteGroup(uuid, false));
        APP.eventManager.publish('UPDATE_OPEN_POPUP');
    }, [uuid]);
    const renderDeleteBtn = useMemo(() => {
        return React.createElement(ClickButton, { label: t("group.delete"), isText: true, onClick: onClick });
    }, [t, onClick]);
    return (React.createElement(PopupContainer, { title: t("group.delete"), guid: t("group.deleteGuid1", { name }) }, renderDeleteBtn));
}
export default GroupDeletePopup;
