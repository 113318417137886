import styled from 'styled-components';
// @ts-ignore
export const SecurityStyled = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    font-size: 18px;

    .title_wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
        justify-content: center;        
    }
    .password {
        display: grid;
        cursor: pointer;
        width: 180px; background-color: #4d4d4d;
        position: relative; border-radius: 16px;
        margin: 0;
        padding: 8px;
        color: #fff;
        width: 220px;
        box-sizing: border-box;

        &_true { background: #1facff; color: #fff; }

        .security {
            width: 100%;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            &_true { flex-direction: row-reverse; }
            svg { margin: 0; }
        }
    }

    input {
        text-align: center;
        border-radius: 10px;
        padding: 8px 12px;
        width: 220px;
        box-sizing: border-box;
    }
`;
