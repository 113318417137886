import React, { Fragment, useEffect, useMemo, useState } from "react";
import { SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
// @ts-ignore
import { useSelector } from "react-redux";
import { openDialog } from "../../../base/dialog";
import { ClickButton } from "../../../base/ui";
import AbstractRoomLeave from "../../../room/AbstractRoomLeave";
import { ALL_ERASER, CLOSE, COLOR, LINE_ERASER, OPACITY, PEN, THICKNESS, ZOOM, pen_list } from "../../constants";
import { checkCanvasPermission } from "../../functions";
import { CanvasIconStyled } from "./styles";
const ToolButton = React.memo(({ isMobile, item, checked, showDialog, focusTool, option, handlerUpdateCanvasTool, handlerChangeOption, setFocusTool }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sharePermission = useSelector((state) => checkCanvasPermission(state, 'share'));
    const [showChild, setShowChild] = useState();
    useEffect(() => {
        if (!showDialog)
            setShowChild(undefined);
    }, [showDialog]);
    const onClickHandler = () => {
        setShowChild(undefined);
        if (item.name === CLOSE.name) {
            dispatch(openDialog(AbstractRoomLeave));
            return;
        }
        if (item.children === false) {
            handlerUpdateCanvasTool && handlerUpdateCanvasTool(item.name);
            if (pen_list.includes(item.name)) {
                setFocusTool(PEN);
            }
            else {
                setFocusTool(item);
            }
        }
        else
            setFocusTool(item);
    };
    const renderDialog = useMemo(() => {
        if (!showDialog)
            return null;
        if (!focusTool)
            return null;
        if (typeof focusTool.children === 'boolean') {
            return (React.createElement(ExpandTool, { sharePermission: sharePermission, item: focusTool, focus: showChild?.name || '', isChild: false, isMobile: isMobile, option: option, handlerUpdateCanvasTool: handlerUpdateCanvasTool, handlerChangeOption: handlerChangeOption, setShowChild: setShowChild }));
        }
        else {
            return (React.createElement(React.Fragment, null,
                React.createElement(ExpandTool, { sharePermission: sharePermission, item: focusTool, focus: showChild ? showChild.name : focusTool.name, isChild: false, isMobile: isMobile, option: option, handlerUpdateCanvasTool: handlerUpdateCanvasTool, handlerChangeOption: handlerChangeOption, setShowChild: setShowChild }),
                showChild && React.createElement(ExpandTool, { sharePermission: sharePermission, item: showChild, focus: showChild.name, isChild: true, isMobile: isMobile, option: option, handlerUpdateCanvasTool: handlerUpdateCanvasTool, handlerChangeOption: handlerChangeOption })));
        }
    }, [sharePermission, showDialog, isMobile, focusTool, showChild, option]);
    if (!sharePermission && item.name === ALL_ERASER.name)
        return null;
    if (item.name === CLOSE.name) {
        return (React.createElement(ClickButton, { className: "canvas", size: 20, isText: true, label: t(`setting.out`), onClick: onClickHandler }));
    }
    return (React.createElement(CanvasIconStyled, null,
        React.createElement(ClickButton, { className: `canvas canvas-${checked ? 'on' : 'off'}`, icon: item.name, size: 20, color: checked ? "#8bacff" : "#545454", onClick: onClickHandler }),
        renderDialog));
});
export default ToolButton;
const ExpandTool = React.memo(({ sharePermission, isChild, isMobile, item, focus, option, handlerChangeOption, handlerUpdateCanvasTool, setShowChild }) => {
    if (!item)
        return null;
    const getComponent = useMemo(() => {
        switch (item.name) {
            case THICKNESS.name:
                return (React.createElement(ProgressBar, { value: option[THICKNESS.name], min: 1, max: 20, step: 1, name: THICKNESS.name, onChange: handlerChangeOption }));
            case OPACITY.name:
                return (React.createElement(ProgressBar, { value: option[OPACITY.name], min: 1, max: 10, step: 1, name: OPACITY.name, onChange: handlerChangeOption }));
            case COLOR.name:
                return (React.createElement(ColorBox, { value: option[COLOR.name], name: COLOR.name, onChange: handlerChangeOption }));
            case ZOOM.name:
                return (React.createElement(ZoomBox, { scale: option.scale, isWidthFit: option.isWidthFit, onChange: handlerChangeOption }));
            default:
                return null;
        }
    }, [item, option, handlerChangeOption]);
    const onClickChild = (data) => {
        if (isChild || data.children === false) {
            handlerUpdateCanvasTool && handlerUpdateCanvasTool(data.name);
        }
        else {
            setShowChild && setShowChild(data);
        }
    };
    if (typeof item.children === 'boolean' && !item.children)
        return null;
    return (React.createElement("div", { className: "option_box", style: {
            flexDirection: `${isMobile ? "column" : "row"}`,
            top: `${APP.config.name === 'class' ? '' : '0'}`,
            left: `${APP.config.name === 'class' ? 0 : isChild ? "112px" : "43px"}`,
            bottom: `${APP.config.name === 'class' ? "43px" : "0"}`,
        } }, typeof item.children === 'object' ? item.children.map(c => {
        if (!sharePermission && c.name === LINE_ERASER.name)
            return null;
        const checked = focus === c.name;
        return (React.createElement(ClickButton, { key: `d${c.name}`, className: `canvas canvas-${checked ? 'on' : 'off'}`, icon: c.name, size: 20, color: checked ? "#8bacff" : "#545454", onClick: () => onClickChild(c) }));
    }) : getComponent));
});
const ProgressBar = React.memo(({ className = 'field', value, min, max, step, name, onChange }) => {
    const handlerChange = (e) => {
        const data = e.target.value;
        onChange(name, Number(data));
    };
    return (React.createElement("div", { className: `zoom_box ${className}` },
        React.createElement("div", { className: "value" }, min),
        React.createElement("input", { type: "range", name: "range", min: min, max: max, step: step, value: value, onChange: handlerChange }),
        React.createElement("label", { htmlFor: "range" },
            " ",
            value,
            " "),
        React.createElement("div", { className: "value nonSelect" }, max)));
});
const ColorBox = React.memo(({ value, name, onChange }) => {
    const handlerChange = (e) => {
        const data = e.hex;
        onChange(name, data);
    };
    return (React.createElement(SketchPicker, { className: "color_box", color: value, onChange: handlerChange }));
});
const ZoomBox = React.memo(({ scale, isWidthFit, onChange }) => {
    const [value, setValue] = useState(scale);
    useEffect(() => {
        setValue(scale);
    }, [scale]);
    const handlerWidthChange = (value) => {
        onChange('isWidthFit', value);
    };
    const handlerScaleChange = (value) => {
        if (value <= 300 && value >= 50) {
            onChange('scale', Number(value));
            setValue(Number(value));
        }
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: "zoom_box field option_scale" },
            React.createElement(ClickButton, { color: "#545454", icon: "minus", size: 20, onClick: () => handlerScaleChange(value - 10) }),
            React.createElement("span", null,
                " ",
                value,
                " "),
            React.createElement(ClickButton, { color: "#545454", icon: "plus", size: 20, onClick: () => handlerScaleChange(value + 10) })),
        React.createElement("div", { style: { display: "flex", gap: "20px" } },
            React.createElement(ClickButton, { color: "#545454", className: `canvas canvas-${isWidthFit === undefined ? 'on' : 'off'}`, icon: "fitPage", size: 20, onClick: () => handlerWidthChange(undefined) }),
            React.createElement(ClickButton, { color: "#545454", className: `canvas canvas-${isWidthFit === true ? 'on' : 'off'}`, icon: "fitWidth", size: 20, onClick: () => handlerWidthChange(true) }),
            React.createElement(ClickButton, { color: "#545454", className: `canvas canvas-${isWidthFit === false ? 'on' : 'off'}`, icon: "fitHeight", size: 20, onClick: () => handlerWidthChange(false) }))));
});
