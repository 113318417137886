import { initLib } from '../lib-jitsi-meet/actions';
import { MiddlewareRegistry } from '../redux';
import { APP_WILL_MOUNT } from './actionTypes';
MiddlewareRegistry.register(store => next => action => {
    const { dispatch } = store;
    switch (action.type) {
        case APP_WILL_MOUNT:
            dispatch(initLib());
            break;
    }
    return next(action);
});
