import React, { useEffect, useMemo, useState } from "react";
import { getSurveyList } from "./function";
import SurveyItemContainer from "./SurveyItemContainer";
import { useSelector } from "react-redux";
import { getLocalIDMember } from "../members";
const SurveyListContainer = React.memo(({ isPollPersmission }) => {
    const localId = useSelector((state) => getLocalIDMember(state));
    const [list, setList] = useState();
    const handleUpdate = data => {
        setList(data);
    };
    const handlerError = error => {
        APP.UI.alertMessage(error);
    };
    const handlerRefresh = () => {
        getSurveyList(undefined, handleUpdate, handlerError);
    };
    useEffect(() => {
        getSurveyList(undefined, handleUpdate, handlerError);
        APP.eventManager.subscribe('REFRESH_LIST', handlerRefresh);
        return () => {
            APP.eventManager.unsubscribe('REFRESH_LIST', handlerRefresh);
        };
    }, []);
    const renderContent = useMemo(() => {
        if (!list)
            return React.createElement("div", null, " \uB4F1\uB85D\uB41C \uC124\uBB38\uC774 \uC5C6\uC2B5\uB2C8\uB2E4.");
        else {
            return list.map((item, index) => (React.createElement(SurveyItemContainer, { key: item.uuid, index: index, localId: localId, deadline: item.info.deadline, uuid: item.uuid, title: item.info.title, replied: item.info.replied, isPollPersmission: isPollPersmission })));
        }
    }, [list, localId]);
    return (renderContent);
});
export default SurveyListContainer;
