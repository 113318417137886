import React from "react";
import { ScreenShareStyled } from "./styles.web";
const ScreenMember = React.memo(({ isMobile, onlyScreen, position, renderScreenShare, renderScreenShareMember, renderPositionBtn }) => {
    return (React.createElement(ScreenShareStyled, { screenOnly: isMobile || onlyScreen, position: position },
        React.createElement("div", { className: "member_container" }, !isMobile && (onlyScreen ?
            React.createElement("div", { className: "btns_wrapper" },
                renderScreenShareMember,
                React.createElement("div", { className: "btns" }, renderPositionBtn))
            : renderScreenShareMember)),
        React.createElement("div", { className: "screen_layout" }, renderScreenShare)));
});
export default ScreenMember;
