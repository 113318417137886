import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ROLE, checkRoleLevel, getLocalMemberRole } from '../base/members';
import { ClickButton, DivElement, TextElement } from '../base/ui';
function DevicePermissionContainer({ initial_camera, initial_mic, handlerUpdatePolicy, }) {
    const local_role = useSelector((state) => getLocalMemberRole(state));
    const checkRole = useMemo(() => checkRoleLevel(local_role, ROLE.MANAGER), [local_role]);
    const mic = initial_mic !== 'off';
    const camera = initial_camera !== 'off';
    const toggleDevice = useCallback(async (type, currentState) => {
        if (!checkRole)
            return;
        // let response: any;
        // if (type === 'audio') response = await APP.management.setAllAudio(!currentState);
        // else response = await APP.management.setAllVideo(!currentState);
        // const setFunction = type === 'audio' ? APP.management.setAllAudio : APP.management.setAllVideo;
        // const response = await setFunction(!currentState);
        // if (response?.status === 200) {
        handlerUpdatePolicy({
            initial_camera: type === 'video' ? currentState : initial_camera,
            initial_mic: type === 'audio' ? currentState : initial_mic,
        });
        // }
    }, [checkRole, handlerUpdatePolicy, initial_camera, initial_mic]);
    const mediaButtons = useMemo(() => [
        {
            key: 'allAudioOn',
            label: 'devicePermissionSetting.allAudioOn',
            icon: 'allAudioOn',
            focusClass: mic ? 'isFocus' : '',
            onClick: () => toggleDevice('audio', 'on'),
        },
        {
            key: 'allAudioOff',
            label: 'devicePermissionSetting.allAudioOff',
            icon: 'allAudioOff',
            focusClass: !mic ? 'isFocus' : '',
            onClick: () => toggleDevice('audio', 'off'),
        },
        {
            key: 'allVideoOn',
            label: 'devicePermissionSetting.allVideoOn',
            icon: 'allVideoOn',
            focusClass: camera ? 'isFocus' : '',
            onClick: () => toggleDevice('video', 'on'),
        },
        {
            key: 'allVideoOff',
            label: 'devicePermissionSetting.allVideoOff',
            icon: 'allVideoOff',
            focusClass: !camera ? 'isFocus' : '',
            onClick: () => camera && toggleDevice('video', 'off'),
        },
    ], [mic, camera, toggleDevice]);
    return (React.createElement(React.Fragment, null,
        checkRole && React.createElement(TextElement, { className: "guid", text: "devicePermissionSetting.guid" }),
        React.createElement(DivElement, { className: "device-permission-btn" }, mediaButtons.map(item => (React.createElement(ClickButton, { key: item.key, className: `media-button ${!checkRole && 'no-hover'} ${item.focusClass}`, label: item.label, icon: item.icon, onClick: checkRole ? item.onClick : undefined, size: 30, numberOfLines: 2 }))))));
}
export default React.memo(DevicePermissionContainer);
