import React, { useMemo } from "react";
import ChatFileItem from "./ChatFileItem.web";
const ChatItem = React.memo(({ type, uuid, message, isMe, to, time }) => {
    const renderMessage = useMemo(() => {
        switch (type) {
            case 'text':
                return (React.createElement("div", { className: "message_wraaper" },
                    React.createElement("span", null,
                        " ",
                        time,
                        " "),
                    React.createElement("div", { className: "message" }, message)));
            case 'system':
                return (React.createElement("div", { className: "system_message" },
                    React.createElement("div", { className: "message" },
                        message,
                        " "),
                    React.createElement("span", null,
                        " ",
                        time,
                        " ")));
            case 'file':
                return React.createElement(ChatFileItem, { uuid: uuid, time: time, message: message });
        }
    }, [type, time, uuid, message]);
    return (React.createElement("div", { className: `chat_${type} ${isMe ? 'chat_right' : 'chat_left'}` },
        React.createElement("div", null,
            " ",
            to,
            " "),
        renderMessage));
});
export default ChatItem;
