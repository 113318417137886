import React, { useEffect, useState } from "react";
import { ClickButton } from "../../base/ui";
const MemberAudio = React.memo(({ audioTrack }) => {
    const [muted, setMuted] = useState(true);
    useEffect(() => {
        setMuted(!audioTrack || audioTrack.muted);
    }, [audioTrack]);
    return React.createElement(ClickButton, { icon: muted ? 'mic_off' : 'mic_on', size: 12, color: muted ? 'red' : APP.config.style.focusColor });
});
export default MemberAudio;
