import JitsiMeetJS from '../../../features/base/lib-jitsi-meet';
import { createLocalTracks } from '../../../features/base/lib-jitsi-meet/functions.any';
import { getPolicy } from '../../../features/base/settings';

class JitsiTracks {
    constructor() {
        this._untoggleScreenSharing = null;
    }

    // 로컬 트랙 생성 
    createTracks(options) {
        return JitsiMeetJS.createLocalTracks({
            ...options,
            constraints: {
                video: {
                    height: { ideal: 720 },
                    frameRate: { ideal: 30 }
                }
            }
        }).then(([track]) => {
            return Promise.resolve(track);
        }).catch(err => {
            return Promise.resolve();
        });
            
        // return (
        //     loadEffects(APP.store)
        //         .then(effectsArray => {
        //             const effects = effectsArray.filter(effect => Boolean(effect));
                
                    
        //         }
        //     ));    
    }

    createScreenShareTrack(options) {
        const frame = getPolicy(APP.store.getState, 'screen_option')?.frame || 15;
        
        const getDesktopStreamPromise = options.desktopStream
            ? Promise.resolve([ options.desktopStream ])
            : createLocalTracks(['desktop'], false, {
                desktopSharingSourceDevice: null,
                desktopSharingSources: null,
                resolution: 720,
                minFps: frame
            });

        return getDesktopStreamPromise.then(desktopStreams => {
            this._untoggleScreenSharing = options.unToggleScreenSharing.bind(this);

            desktopStreams && desktopStreams.map(stream => {
                stream.on(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () => {
                    this._untoggleScreenSharing && this._untoggleScreenSharing();
                });
            });

            return desktopStreams;
        }, (err) => {
            throw err;
        })
    }
}

export default JitsiTracks;