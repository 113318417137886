import React from "react";
import { useDispatch } from "react-redux";
import { translate } from "../../i18n";
import { responsePresentation } from "../../members";
import { ClickButton } from "../../ui";
import { PromptRaiseHand } from "./";

function AbstractPromptRaiseHand({
    t, user_uuid, displayName
}) {
    const dispatch = useDispatch();

    const renderAccept = () => {
        const onClick = () => {
            dispatch(responsePresentation(user_uuid, true, false));
        };

        return (
            <ClickButton className='text-button' 
                isText={true} label={t('notify.accept')}
                onClick={onClick}
            />
        )
    }

    const renderRefuse = () => {
        const onClick = () => {
            dispatch(responsePresentation(user_uuid, false, false));
        };

        return (
            <ClickButton className='text-button' 
                isText={true} label={t('notify.refuse')}
                onClick={onClick}
            />
        )
    }

    return (
        <PromptRaiseHand 
            t={t}
            displayName={displayName}
            renderAccept={renderAccept}
            renderRefuse={renderRefuse}
        />
    )
}

export default translate(AbstractPromptRaiseHand);