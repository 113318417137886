import React from "react";
import { HandlerStyled } from "./styles.web";

function RaiseHand({
    guid, renderHands
}) {
    return (
        <HandlerStyled style={{ marginBottom: "12px" }}>
            <div className="guid">{ guid }</div>
            <div className="handler_content">
                { renderHands }
            </div>
        </HandlerStyled>
    );
}

export default React.memo(RaiseHand);