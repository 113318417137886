import React from "react";
import { useDispatch } from "react-redux";
import { toggleDialog } from "../base/dialog";
import { ClickButton } from "../base/ui";
import DocumentListDialog from "./DocumentListDialog";
import { DocumentTool } from "./components";
const DocumentToolContainer = React.memo(({ isLeft, setIsLeft, children }) => {
    const dispatch = useDispatch();
    const onClick = () => {
        if (APP.config.option.shareListPopup) {
            dispatch(toggleDialog(DocumentListDialog));
            return;
        }
        setIsLeft(!isLeft);
    };
    const renderMenu = () => {
        return (React.createElement(ClickButton, { className: APP.config.name === 'class' ? "hide_menu" : "hide", icon: isLeft ? 'menu_open' : 'menu', size: APP.config.name === 'class' ? 24 : 20, color: APP.config.name === 'class' ? '#fff' : '#fff', onClick: onClick }));
    };
    return (React.createElement(DocumentTool, { renderMenu: renderMenu }, children));
});
export default DocumentToolContainer;
