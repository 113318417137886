class ChoiceMemberStatus {
    constructor() {
        this.subscribers = {};
        this.choiceMembers = new Map();
        this.getChoiceMembers = this.getChoiceMembers.bind(this);
        this.handlerMember = this.handlerMember.bind(this);
    }
    setChoiceMember(members) {
        this.publish('SET_CHOICE_MEMBER', { members });
        this.choiceMembers = members;
    }
    getChoiceMembers() {
        return this.choiceMembers;
    }
    isChecked(user_uuid) {
        return this.choiceMembers.get(user_uuid) ? true : false;
    }
    handlerMember(data) {
        switch (data?.type) {
            case 'init':
                this.setChoiceMember(new Map());
                break;
            case 'checked':
                if (data?.user_uuid && data?.nickname)
                    this.choiceMembers?.set(data?.user_uuid, data?.nickname);
                this.setChoiceMember(this.choiceMembers);
                break;
            case 'unChecked':
                if (data?.user_uuid)
                    this.choiceMembers?.delete(data?.user_uuid);
                this.setChoiceMember(this.choiceMembers);
                break;
        }
    }
    ;
    subscribe(event, callback) {
        if (!this.subscribers[event]) {
            this.subscribers[event] = [];
        }
        this.subscribers[event].push(callback);
    }
    unsubscribe(event, callback) {
        const eventSubscribers = this.subscribers[event];
        if (eventSubscribers) {
            this.subscribers[event] = eventSubscribers.filter(cb => cb !== callback);
        }
    }
    publish(event, data) {
        const eventSubscribers = this.subscribers[event];
        if (eventSubscribers) {
            eventSubscribers.forEach(callback => {
                callback(data);
            });
        }
    }
}
export default new ChoiceMemberStatus();
