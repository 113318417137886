import React from "react";
function GroupContainer({ renderMenuList, renderContent, renderAddButton, renderMemberButton }) {
    return (React.createElement("div", { className: "group" },
        React.createElement("div", { className: "left left_start" }, renderMenuList),
        React.createElement("div", { className: "body" },
            renderContent,
            React.createElement("div", { className: "btn" },
                renderAddButton,
                renderMemberButton))));
}
export default React.memo(GroupContainer);
