import React from "react";
import { useTranslation } from "react-i18next";
import { DocumentStyled } from "./styles.web";
const ShareDocument = React.memo(({ expand, isLeft, isMobile, mode, renderList, renderAddButton, children }) => {
    const { t } = useTranslation();
    return (React.createElement(DocumentStyled, { name: APP.config.name, ...APP.config.ui.document, expand: expand },
        isLeft && !APP.config.option.shareListPopup &&
            React.createElement("div", { className: `share_list ${!expand && !isMobile && 'non_expand'}` },
                React.createElement("div", { className: "share_header" },
                    React.createElement("p", null,
                        t(`share.share`, { name: t(`share.${mode}`) }),
                        " "),
                    renderAddButton),
                React.createElement("div", { className: 'share_items', style: { width: "100%", height: "calc(100% - 38px)", overflow: "auto" } }, renderList)),
        React.createElement("div", { className: `share_content ${isLeft && !APP.config.option.shareListPopup && 'expand'}` }, children)));
});
export default ShareDocument;
