import React from "react";
// @ts-ignore
import PopupContainer from "./PopupContainer";
import { ClickButton } from "../ui";
import { useTranslation } from "react-i18next";
import { EXT_STRING, FILE_MAX_SIZE } from "./constants";
import { useDispatch } from "react-redux";
import { addFileMessage } from "./action";
import { useSelector } from "react-redux";
import { getActiveGroupId } from "../../room";
const ChatFilePopup = React.memo(({ status, data }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const active_group = useSelector((state) => getActiveGroupId(state));
    const sendMessagee = () => {
        dispatch(addFileMessage(data, active_group));
        APP.eventManager.publish('UPDATE_OPEN_CHAT_POPUP');
    };
    return (React.createElement(PopupContainer, { title: t(`chat.${status}`), guid: t("chat.fileGuid", { volume: FILE_MAX_SIZE, file: EXT_STRING }) },
        React.createElement(ClickButton, { className: 'no-hover', label: data.name }),
        React.createElement(ClickButton, { label: t("chat.send"), isText: true, onClick: sendMessagee })));
});
export default ChatFilePopup;
