import React from "react";
function MemberItem({ local, nickname, styles, isMaxSize, vertical, renderVideo, renderAudioMuted, renderPin, renderConnection }) {
    return (React.createElement("div", { className: `item ${local ? 'mirror' : 'no_mirror'}`, style: isMaxSize
            ? vertical
                ? { width: `${styles.width}px`, maxHeight: `${styles.height}px`, height: '100%' }
                : { maxWidth: `${styles.width}px`, height: `${styles.height}px`, width: '100%' }
            : styles ? { width: `${styles.width}px`, height: `${styles.height}px` } : {} },
        React.createElement("div", { className: "videoWrap" },
            renderVideo,
            React.createElement("div", { className: "video_pin_icon" }, renderPin),
            React.createElement("div", { className: "video_label" },
                React.createElement("div", null, renderConnection),
                React.createElement("div", { className: "nickname" },
                    React.createElement("span", { className: "overText" },
                        " ",
                        nickname,
                        " ")),
                React.createElement("div", null, renderAudioMuted)))));
}
export default React.memo(MemberItem);
