import { HIDE_NOTIFICATION, SAVE_NOTIFICATION_MESSAGE, SET_NOTIFICATIONS_OPTION, SHOW_NOTIFICATION } from './actionTypes';
import { NOTIFICATION_TIMEOUT, NOTIFICATION_TIMEOUT_TYPE, NOTIFICATION_TYPE } from './constants';
import { getNotificationTime } from './functions';
/**
 * 알림 제한 시간 유형에 따라 알림 제한 시간 값을 반환하는 함수입니다.
 *
 * @param {string} type - Notification type.
 * @param {Object} notificationTimeouts - Config notification timeouts.
 * @returns {number}
 */
function getNotificationTimeout(type) {
    if (type === NOTIFICATION_TIMEOUT_TYPE.SHORT) {
        return NOTIFICATION_TIMEOUT.SHORT;
    }
    else if (type === NOTIFICATION_TIMEOUT_TYPE.MEDIUM) {
        return NOTIFICATION_TIMEOUT.MEDIUM;
    }
    else if (type === NOTIFICATION_TIMEOUT_TYPE.LONG) {
        return NOTIFICATION_TIMEOUT.LONG;
    }
    return null;
}
/**
 * 알림 문구
 *
 * @param {Object} props - The props needed to show the notification component.
 * @param {string} type - Timeout type.
 * @returns {Function}
 */
export function showNotification(props, type) {
    const now = Date.now();
    let timeout = getNotificationTime(APP.store.getState) || NOTIFICATION_TIMEOUT.MEDIUM;
    if (props.timeout) {
        if (props.titleKey === 'attendances.request')
            timeout = props.timeout;
    }
    return {
        type: SHOW_NOTIFICATION,
        props: {
            ...props,
            timestamp: now,
            appearance: props.appearance || NOTIFICATION_TYPE.INFO,
        },
        timeout,
        uid: props.uid || now.toString(),
    };
}
/**
 * 알림 지우기
 * @param {String} uid - Timeout type.
 * @returns
 */
export function hideNotification(uid) {
    return {
        type: HIDE_NOTIFICATION,
        uid,
    };
}
/**
 * 알림 문구 보이기 - 삭제 이벤트가 오지 않으면 계속 저장되는 이벤트
 * @param {Object} props - The props needed to show the notification component.
 * @returns
 */
export function persistentNotification(props) {
    const now = Date.now();
    return {
        type: SHOW_NOTIFICATION,
        props: {
            ...props,
            timestamp: now,
            appearance: props.appearance || NOTIFICATION_TYPE.ERROR,
        },
        uid: props.uid || now.toString(),
    };
}
/**
 * 알림 옵션 수정
 * @param {number} limit - The props needed to show the notification component.
 * @param {number} time - The props needed to show the notification component.
 * @returns
 */
export function updateNotificationOption(limit, time) {
    return {
        type: SET_NOTIFICATIONS_OPTION,
        limit,
        time,
    };
}
/**
 * 공지 사항 등록
 */
export function saveNotification(message, isRemote = false) {
    return {
        type: SAVE_NOTIFICATION_MESSAGE,
        message,
        isRemote,
    };
}
