import { addPendingDeviceRequest, areDeviceLabelsInitialized, getAudioOutputDeviceId, getAvailableDevices, getDeviceIdByLabel, groupDevicesByKind, setAudioInputDeviceAndUpdateSettings, setVideoInputDeviceAndUpdateSettings, } from '../base/devices';
import { isIosMobileBrowser } from '../base/environment/utils';
import JitsiMeetJS from '../base/lib-jitsi-meet';
import { toState } from '../base/redux';
export function getDeviceSelectionProps(stateful, isDevicePage) {
    const disablePreviews = isIosMobileBrowser();
    const state = toState(stateful);
    const settings = state['features/base/settings'];
    const { permissions, devices } = state['features/base/devices'];
    // const inputDeviceChangeSupported = JitsiMeetJS.mediaDevices.isDeviceChangeAvailable('input');
    // const speakerChangeSupported = JitsiMeetJS.mediaDevices.isDeviceChangeAvailable('output');
    // const userSelectedCamera = getUserSelectedCameraDeviceId(state);
    // const userSelectedMic = getUserSelectedMicDeviceId(state);
    const selectedAudioInputId = settings.userSelectedMicDeviceId;
    const selectedAudioOutputId = settings.userSelectedAudioOutputDeviceId; // getAudioOutputDeviceId();
    const selectedVideoInputId = settings.userSelectedCameraDeviceId;
    const selectedVideoMuted = settings.userSelectedCameraMuted;
    const selectedAudioMuted = settings.userSelectedAudioMuted;
    /**
     * 사용 또는 현재 설정(사용자 선택)에 있는 것과 함께 사용되지 않는 경우,
     * 장치 선택 대화 상자를 현재 사용 중인 장치로 채웁니다.
     */
    return {
        devices,
        availableDevices: state['features/base/devices'].availableDevices,
        hasAudioPermission: permissions.audio,
        hasVideoPermission: permissions.video,
        selectedAudioInputId,
        selectedAudioOutputId,
        selectedVideoInputId,
        isDevicePage,
        disablePreviews,
        initVolume: settings.speakerVolume,
        selectedVideoMuted,
        selectedAudioMuted,
    };
}
/**
 * 외부 응용 프로그램의 장치 요청을 처리합니다.
 *
 * @param {Dispatch} dispatch - The redux {@code dispatch} function.
 * @param {Function} getState - The redux function that gets/retrieves the redux
 * state.
 * @param {Object} request - The request to be processed.
 * @param {Function} responseCallback - The callback that will send the
 * response.
 * @returns {boolean} - True if the request has been processed and false otherwise.
 */
export function processExternalDeviceRequest(
// eslint-disable-line max-params
dispatch, getState, request, responseCallback) {
    if (request.type !== 'devices')
        return false;
    const state = getState();
    const settings = state['features/base/settings'];
    let result = true;
    switch (request.name) {
        case 'isDeviceListAvailable':
            responseCallback(JitsiMeetJS.mediaDevices.isDeviceListAvailable());
            break;
        case 'isDeviceChangeAvailable':
            responseCallback(JitsiMeetJS.mediaDevices.isDeviceChangeAvailable(request.deviceType));
            break;
        case 'isMultipleAudioInputSupported':
            responseCallback(JitsiMeetJS.isMultipleAudioInputSupported());
            break;
        case 'getCurrentDevices':
            dispatch(getAvailableDevices()).then((devices) => {
                if (areDeviceLabelsInitialized(state)) {
                    const deviceDescriptions = {
                        audioInput: undefined,
                        audioOutput: undefined,
                        videoInput: undefined,
                    };
                    // 현재 사용중인 장치
                    const currentlyUsedDeviceIds = new Set([
                        getAudioOutputDeviceId(),
                        settings.micDeviceId,
                        settings.cameraDeviceId,
                    ]);
                    devices.forEach(device => {
                        const { deviceId, kind } = device;
                        if (currentlyUsedDeviceIds.has(deviceId)) {
                            switch (kind) {
                                case 'audioinput':
                                    deviceDescriptions.audioInput = device;
                                    break;
                                case 'audiooutput':
                                    deviceDescriptions.audioOutput = device;
                                    break;
                                case 'videoinput':
                                    deviceDescriptions.videoInput = device;
                                    break;
                            }
                        }
                    });
                    responseCallback(deviceDescriptions);
                }
                else {
                    dispatch(addPendingDeviceRequest({
                        type: 'devices',
                        name: 'getCurrentDevices',
                        responseCallback,
                    }));
                }
            });
            break;
        case 'getAvailableDevices':
            dispatch(getAvailableDevices()).then((devices) => {
                if (areDeviceLabelsInitialized(state)) {
                    responseCallback(groupDevicesByKind(devices));
                }
                else {
                    // The labels are not available if the A/V permissions are
                    // not yet granted.
                    dispatch(addPendingDeviceRequest({
                        type: 'devices',
                        name: 'getAvailableDevices',
                        responseCallback,
                    }));
                }
            });
            break;
        case 'setDevice': {
            const { device } = request;
            if (!areDeviceLabelsInitialized(state)) {
                dispatch(addPendingDeviceRequest({
                    type: 'devices',
                    name: 'setDevice',
                    device,
                    responseCallback,
                }));
                return true;
            }
            const { label, id } = device;
            const deviceId = label ? getDeviceIdByLabel(state, device.label, device.kind) : id;
            if (deviceId) {
                switch (device.kind) {
                    case 'audioinput':
                        dispatch(setAudioInputDeviceAndUpdateSettings(deviceId));
                        break;
                    case 'audiooutput':
                        // dispatch(setAudioOutputDevice(deviceId));
                        break;
                    case 'videoinput':
                        dispatch(setVideoInputDeviceAndUpdateSettings(deviceId));
                        break;
                    default:
                        result = false;
                }
            }
            else {
                result = false;
            }
            responseCallback(result);
            break;
        }
        default:
            return false;
    }
    return true;
}
export function getVdoErrorMsg(error) {
    if (!error)
        return 'deviceSelection.cameraOk';
    let message = 'deviceSelection.cameraError';
    const { name } = error;
    switch (name) {
        case 'NotReadableError':
            message = 'deviceSelection.cameraError';
            break;
        case 'AbortError':
            message = 'deviceSelection.cameraAbort';
            break;
        case 'NotAllowedError':
            message = 'deviceError.cameraPermission';
            break;
        // case "NotFoundError":
        // case "OverconstrainedError":
        // case "SecurityError":
        // case "TypeError":
        //     message = "deviceError.cameraError";
        //     break;
    }
    return message;
}
