import React from "react";
import styled from "styled-components";
const LeaveStyled = styled.div `
    text-align: center;
    .buttons {
        margin-top: 20px;
    }
    .guid {
        white-space: pre-line;
        text-align: center;
        margin: 8px 0;
        word-break: keep-all;
    }
`;
function RoomLeave({ guid, renderButtons }) {
    return (React.createElement(LeaveStyled, null,
        React.createElement("div", { className: "guid" },
            " ",
            guid,
            " "),
        React.createElement("div", { className: "buttons" }, renderButtons)));
}
export default RoomLeave;
