import React, { useMemo } from "react";
import { FIGURE_LIST, LINE_ERASER, PEN, TEXT } from "../../constants";
import LineCanvas from "./LineCanvas";
import FigureCanvas from "./FigureCanvas";
import TextCanvas from "./TextCanvas";
const CanvasDrawItem = React.memo(({ drawCanvas, line, scale, selectedId, handlerUpdateLine }) => {
    const ratio = useMemo(() => {
        const r = (drawCanvas.width / line.canvasTool.width) * scale;
        return isNaN(r) || r === 0 ? 1 : r;
    }, [line.canvasTool.width, drawCanvas.width, scale]);
    const tool = useMemo(() => line.canvasTool.tool, [line.canvasTool.tool]);
    const selected = useMemo(() => line.canvasTool.key === selectedId, [line.canvasTool.key, selectedId]);
    if (tool === PEN.name || tool == LINE_ERASER.name) {
        return React.createElement(LineCanvas, { selected: selected, canvasTool: line.canvasTool, points: line.points, ratio: ratio, handlerUpdateLine: handlerUpdateLine });
    }
    else if (FIGURE_LIST.includes(tool)) {
        return React.createElement(FigureCanvas, { selected: selected, canvasTool: line.canvasTool, points: line.points, ratio: ratio, handlerUpdateLine: handlerUpdateLine });
    }
    else if (tool === TEXT.name) {
        return React.createElement(TextCanvas, { selected: selected, canvasTool: line.canvasTool, points: line.points, ratio: ratio, handlerUpdateLine: handlerUpdateLine });
    }
    return null;
});
export default CanvasDrawItem;
