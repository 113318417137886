import React from 'react';
import { connect } from 'react-redux';
import AbstractVideoTrack from '../AbstractVideoTrack';
import Video from './Video';
/**
 * Component that renders a video element for a passed in video track and
 * notifies the store when the video has started playing.
 *
 * @augments AbstractVideoTrack
 */
class VideoTrack extends AbstractVideoTrack {
    /**
     * Renders the video element.
     *
     * @override
     * @returns {ReactElement}
     */
    render() {
        const { _noAutoPlayVideo, className, id, muted, videoTrack, style, eventHandlers } = this.props;
        return (React.createElement(Video, { autoPlay: !_noAutoPlayVideo, className: className, eventHandlers: eventHandlers, id: id, muted: muted, onVideoPlaying: this._onVideoPlaying, style: style, videoTrack: videoTrack }));
    }
}
/**
 * Default values for {@code VideoTrack} component's properties.
 *
 * @static
 */
VideoTrack.defaultProps = {
    className: '',
    id: '',
};
export default connect()(VideoTrack);
