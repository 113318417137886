import { MiddlewareRegistry } from "../base/redux";
import { initGroup, modifyGroup, updateErrorInGroup } from "./action";
import { ASSIGN_GROUP, DELETE_GROUP, MODIFY_GROUP, REMOTE_ALL_GROUP, REMOTE_GROUP, SET_ALL_GROUP_LIST } from "./actionTypes";
MiddlewareRegistry.register(store => next => async (action) => {
    const { dispatch, getState } = store;
    switch (action.type) {
        case SET_ALL_GROUP_LIST:
            let member_list = new Map();
            let group_list = new Map();
            let start_group = false;
            Object.keys(action.list).map(group_id => {
                if (group_id === 'lobby')
                    return;
                const item = action.list[group_id];
                if (!start_group && item.status === 'opened')
                    start_group = true;
                const members = item.members && item.members.filter(i => i.type !== 'hidden');
                group_list.set(group_id, {
                    uuid: group_id,
                    name: item.name,
                    open_time: item.open_time,
                    status: item.status,
                    member_count: members && members.length || 0,
                    progress_duration: item.progress_duration
                });
                members && members.map(m => {
                    member_list.set(m.user_id, { ...m, group_name: item.name, group_id: group_id, group_status: item.status });
                });
            });
            dispatch(initGroup(group_list, member_list, start_group));
            break;
        case DELETE_GROUP:
            if (!action.uuid) {
                dispatch(updateErrorInGroup("group.noUid"));
                return;
            }
            if (!action.isRemote) {
                APP.API.deleteGroup(action.uuid)
                    .then(response => {
                    if (!response.complete) {
                        dispatch(updateErrorInGroup("group.delApiError"));
                        return;
                    }
                });
            }
            break;
        case MODIFY_GROUP:
            if (!action.uuid) {
                dispatch(updateErrorInGroup("group.noUid"));
                return;
            }
            if (!action.isRemote) {
                APP.API.modifyGroup(action.data, action.uuid)
                    .then(response => {
                    if (!response.complete) {
                        dispatch(updateErrorInGroup("group.modifyApiError"));
                        return;
                    }
                });
            }
            break;
        case ASSIGN_GROUP:
            if (!action.isRemote) {
                if (!action.uuid)
                    return;
                const response = await APP.API.assignGroup({
                    method: action.assgin_type,
                    members: action.data
                }, action.uuid);
                if (!response.complete) {
                    dispatch(updateErrorInGroup(response.data));
                    return;
                }
            }
            break;
        case REMOTE_ALL_GROUP:
            APP.API.updateAllGroup({ groups: action.data })
                .then(async (response) => {
                if (!response.complete) {
                    dispatch(updateErrorInGroup(response.data));
                    return;
                }
                else {
                    const response = await APP.management.updateGroupStatus(action.isStart);
                    if (response.status !== 200) {
                        console.log("error");
                        /**
                         * (Fix)
                         */
                        return;
                    }
                }
            });
            break;
        case REMOTE_GROUP:
            const switchType = action.isStart ? 'open' : 'close';
            if (action.groups) {
                let data = {};
                action.groups.map((g) => data = Object.assign(data, { [g.uuid]: { switch: switchType } }));
                // 전체 시작 
                APP.API.updateAllGroup({ groups: data })
                    .then(async (response) => {
                    if (!response.complete) {
                        dispatch(updateErrorInGroup(response.data));
                        return;
                    }
                    else {
                        const response = await APP.management.updateGroupStatus(action.isStart);
                        if (response.status !== 200) {
                            console.log("error");
                            /**
                             * (Fix)
                             */
                            return;
                        }
                    }
                });
            }
            else {
                dispatch(modifyGroup(action.group_id, { switch: switchType }, false));
            }
            break;
        // case UPDATE_ERROR_IN_GROUP:
        //     if (action.error) {
        //         setTimeout(() => {
        //             dispatch(updateErrorInGroup(null));
        //         }, 1000);
        //     }
        //     break;
    }
    return next(action);
});
