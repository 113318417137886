import { isRoomJoined } from "../../room";
import { getLocalSharingStatus, getScreenShareTrackId } from "../../screen-share";
import { MEMBER_STATUS, getMemberList } from "../members";
import { toState } from "../redux";
import { getScreenAudioTracksByParticipantId } from "../tracks";
/**
 * 현재 녹화 상태 가져오기
 * @param {IStore['getState']} stateful
 * @param {string} type
 */
export function getRecordStatus(stateful, type) {
    const state = toState(stateful);
    const recordState = state['features/base/record'];
    return recordState[type];
}
/**
 * 현재 방에 들어있는 사용자의 트랙
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getAudioTrackInRoom(stateful, excludeLocal) {
    const isJoined = isRoomJoined(stateful);
    if (!isJoined)
        return false;
    const mebmers = getMemberList(stateful, {
        filterGroup: true, status: [MEMBER_STATUS.OCCUPIDE]
    }, false);
    let audioTracks = new Map();
    const localSharing = getLocalSharingStatus(stateful);
    if (!localSharing) {
        const screen = getScreenShareTrackId(stateful);
        const screenTrack = getScreenAudioTracksByParticipantId(stateful, screen);
        if (screenTrack) {
            audioTracks.set(screen, screenTrack);
        }
    }
    mebmers.map(m => {
        const t = m.audioTrack;
        if (m.local && excludeLocal)
            return;
        if (t) {
            audioTracks.set(t.track.stream.id, t);
        }
    });
    return [...audioTracks.values()];
}
