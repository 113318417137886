import { SET_ROOM_LOCKED, SET_ROOM_PASSWORD, TOGGLE_E2EE } from './actionTypes';
/**
 * 방 비밀번호 설정
 * @param password  string
 * @returns
 */
export function setRoomPassword(password) {
    return {
        type: SET_ROOM_PASSWORD,
        password
    };
}
/**
 * 방 잠금 설정
 */
export function setRoomLocked(locked) {
    return {
        type: SET_ROOM_LOCKED,
        locked
    };
}
/**
 * E2EE 설정
 * @param enabled
 * @returns
 */
export function toggleE2EE(enabled) {
    return {
        type: TOGGLE_E2EE,
        enabled
    };
}
