import {
	setFailedJitsiConnection,
	setSuccessJitsiConnection,
	setWillJitsiConnection,
} from '../../../features/base/jisti';
import JitsiMeetJS from '../../../features/base/lib-jitsi-meet';
import { MEDIA_TYPE } from '../../../features/base/media';
import { ROLE, getLocalMember, getLocalMemberRole } from '../../../features/base/members';
import { getPropertyValue } from '../../../features/base/settings';
import {
	addLocalTrack,
	getTracksByTrackIdAndMediaType,
	replaceLocalTrack,
	trackAdded,
	trackRemoved,
} from '../../../features/base/tracks';
import { getRoomOption } from '../../../features/room';
import { getLocalSharingStatus, getScreenShareTrackId } from '../../../features/screen-share';
import { createTaskQueue } from '../../util/helpers';

const _replaceLocalVideoTrackQueue = createTaskQueue();
const _replaceLocalAudioTrackQueue = createTaskQueue();
class JitsiConference {
	constructor() {
		this.conference = null;
		this.jingleSession = null;
		this.lastConstrains = null;
		this.dualRoom = null;
	}

	setReceiverConstraints(data) {
		this.lastConstrains = data;

		if (this.conference && this.jingleSession && this.lastConstrains) {
			this.conference.setReceiverConstraints(data);
		}
	}

	// conferenceMutedVideoTrack(isMuted) {
	// 	if (this.dualRoom) {
	// 		this.dualRoom.conferenceMutedVideoTrack(isMuted);
	// 		return;
	// 	}
	// }

	// conferenceReplaceTrack(newTrackId) {
	// 	if (this.dualRoom) {
	// 		this.dualRoom.conferenceReplaceTrack(newTrackId);
	// 		return;
	// 	}
	// }

	join(connection, config, handler, isTemp, user_id) {
		// 회의실 uuid
		const meeting_uuid = getRoomOption(APP.store.getState).code;
		if (!meeting_uuid) return Promise.reject('no meeting');
		// 회의실 세팅
		const conference = connection.initJitsiConference(meeting_uuid, config);

		// conference.receiveVideoController._receiverVideoConstraints.updateReceiveResolution(720);

		const that = this;
		this.conference = conference;
		return new Promise(async (resolve, reject) => {
			// connection 실패가 발생하는 경우
			connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, _connectionFailedHandler);

			// 회의실 입장 성공
			function _handleConferenceJoined() {
				_unsubscribe();
				// 성공
				APP.store.dispatch(setSuccessJitsiConnection(conference));

				// 회의실 이벤트 등록
				function _conferenceEventListener() {
					// track 추가
					conference.on(JitsiMeetJS.events.conference.TRACK_ADDED, track => {
						if (!track || track.isLocal()) {
							return;
						}
						// if (isTemp && track.getType() === 'audio') track.mute();
						// track.muted();

						const screenTrackId = getScreenShareTrackId(APP.store.getState);
						if (getLocalSharingStatus(APP.store.getState) && track.ownerEndpointId === screenTrackId)
							return;

						APP.store.dispatch(trackAdded(track));
					});

					// track 삭제
					conference.on(JitsiMeetJS.events.conference.TRACK_REMOVED, track => {
						if (!track || track.isLocal()) {
							return;
						}

						APP.store.dispatch(trackRemoved(track));
					});

					// conference.on(JitsiMeetJS.events.conference._MEDIA_SESSION_STARTED, () => {
					// });

					conference.room.addListener('xmpp.session_accept', (jingleSession, ctx) => {
						that.jingleSession = jingleSession;

						that.setReceiverConstraints(that.lastConstrains);
					});
				}

				_conferenceEventListener();
			}

			// 회의실 입장 실패
			function _handleConferenceFailed(err) {
				_unsubscribe();
				!isTemp && APP.store.dispatch(setFailedJitsiConnection(conference));
				this.conference = null;

				reject(err);
			}

			function _unsubscribe() {
				conference.off(JitsiMeetJS.events.conference.CONFERENCE_JOINED, _handleConferenceJoined);
				conference.off(JitsiMeetJS.events.conference.CONFERENCE_FAILED, _handleConferenceFailed);
			}

			// 이벤트 등록
			conference.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, _handleConferenceJoined);
			conference.on(JitsiMeetJS.events.conference.CONFERENCE_FAILED, _handleConferenceFailed);

			await conference.join();
			this._conferenceWillJoin(handler, isTemp, user_id)
				.then(() => {
					resolve(conference);
				})
				.catch(err => {
					resolve(conference);
				});
		});
	}

	leave() {
		this.conference && this.conference.leave();

		this.conference = null;
	}

	async _conferenceWillJoin(handler, isTemp, user_id) {
		const conference = this.conference;

		if (isTemp) return Promise.resolve();

		try {
			APP.store.dispatch(setWillJitsiConnection(conference));
			const { audio: hasAudioPermission, video: hasVideoPermission } =
				APP.store.getState()['features/base/devices'].permissions;

			const { userSelectedMicDeviceId, userSelectedCameraDeviceId } =
				APP.store.getState()['features/base/settings'];

			const videoTracks = hasVideoPermission
				? await handler.handlerCreateTracks({
						devices: ['video'],
						cameraDeviceId: userSelectedCameraDeviceId,
					})
				: undefined;
			const audioTracks = hasAudioPermission
				? await handler.handlerCreateTracks({
						devices: ['audio'],
						micDeviceId: userSelectedMicDeviceId,
					})
				: undefined;

			const localTracks = [videoTracks, audioTracks];

			for (const track of localTracks) {
				if (!track) continue;

				if (track.type === 'video') await this.setVideoMuteStatus(track);
				else if (track.type === 'audio') await this.setAudioMuteStatus(track);

				await conference.addTrack(track);
				await APP.store.dispatch(addLocalTrack(track));
			}

			let bridge = {
				id: conference.myUserId(),
				type: 'video',
				video_track_id: localTracks[0]?.getId(),
				audio_track_id: localTracks[1]?.getId(),
				video_muted: localTracks[0]?.isMuted(),
				audio_muted: localTracks[1]?.isMuted(),
			};
			APP.management.setBridgeId(bridge);
			return Promise.resolve();
		} catch (err) {
			console.log(err);
			return Promise.reject();
		}
	}

	replaceTrack(oldTrack, newTrack) {
		try {
			return this.conference.replaceTrack(oldTrack, newTrack);
		} catch (err) {
			APP.UI.alertMessage('트랙 교체에 실패하였습니다.');
			return false;
		}
	}

	useVideoStream(newTrack) {
		const state = APP.store.getState();

		return new Promise((resolve, reject) => {
			const oldTrack = getTracksByTrackIdAndMediaType(state, 'local', MEDIA_TYPE.VIDEO)?.track;

			const trackAction = oldTrack ? replaceLocalTrack(oldTrack, newTrack) : addLocalTrack(newTrack);

			return APP.store
				.dispatch(trackAction)
				.then(() => {
					this.setVideoMuteStatus();
					resolve();
				})
				.catch(err => {
					console.log(err);
					APP.UI.alertMessage('비디오 트랙 교체에 실패하였습니다.', undefined, false, {
						option: 'error',
					});

					resolve();
				});
		});
	}

	useAudioStream(newTrack) {
		const state = APP.store.getState();

		return new Promise((resolve, reject) => {
			const oldTrack = getTracksByTrackIdAndMediaType(state, 'local', MEDIA_TYPE.AUDIO)?.track;

			if (oldTrack === newTrack) {
				resolve();

				return;
			}

			// Add the track to the conference if there is no existing track, replace it otherwise.
			const trackAction = oldTrack ? replaceLocalTrack(oldTrack, newTrack) : addLocalTrack(newTrack);

			return APP.store
				.dispatch(trackAction)
				.then(() => {
					this.setAudioMuteStatus();
					resolve();
				})
				.catch(error => {
					APP.UI.alertMessage('오디오 트랙 교체에 실패하였습니다.', undefined, false, {
						option: 'error',
					});
					console.error(`useAudioStream failed: ${error}`);
					resolve();
				});
		});
	}

	async setVideoMuteStatus(track) {
		if (track) {
			const { userSelectedCameraMuted, request_camera, policy } = APP.store.getState()['features/base/settings'];

			if (
				userSelectedCameraMuted ||
				!request_camera ||
				(policy.initial_camera === 'off' && getLocalMemberRole(APP.store.getState) !== ROLE.HOST)
			) {
				await track.mute();
			}
		}
	}

	async setAudioMuteStatus(track) {
		if (track) {
			const { userSelectedAudioMuted, request_mic, policy } = APP.store.getState()['features/base/settings'];

			if (
				userSelectedAudioMuted ||
				!request_mic ||
				(policy.initial_mic === 'off' && getLocalMemberRole(APP.store.getState) !== ROLE.HOST)
			)
				await track.mute();
		}
	}
}

function _connectionFailedHandler() {
	console.log(' 회의실 연결 실패 ');
}

export default JitsiConference;
