import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { PERMISSION } from "../base/members";
import { isCheckPermission } from "../room";
import DocumentListContainer from "./DocumentListContainer";
import { AddDocumentButton, ShareDocument } from "./components";
import { VOD_MODE } from "./constants";
import { getDocumentItemByUUID, getFocusItemByMode } from "./functions";
import CanvasLayoutContainter from "../canvas/CanvasLayoutContainter";
const ShareDocumentLayout = React.memo(({ mode, expand, isLeft, isMobile, renderShareLeft }) => {
    const permission = useSelector((state) => isCheckPermission(state, PERMISSION.SHARE));
    const { focusItem, documentItem } = useSelector((state) => {
        const focusItem = getFocusItemByMode(state, mode);
        return {
            focusItem,
            documentItem: getDocumentItemByUUID(state, focusItem.uuid)
        };
    }, shallowEqual);
    const renderAddButton = useMemo(() => {
        if (!permission)
            return null;
        return (React.createElement(AddDocumentButton, { mode: mode, isIcon: true }));
    }, [permission, mode]);
    const renderList = useMemo(() => {
        if (APP.config.option.shareListPopup)
            return null;
        return (React.createElement(DocumentListContainer, { permission: permission, mode: mode, focusItem: focusItem }));
    }, [permission, mode, focusItem]);
    return (React.createElement(ShareDocument, { mode: mode, expand: expand, isLeft: isLeft, isMobile: isMobile, renderAddButton: renderAddButton, renderList: renderList },
        React.createElement(CanvasLayoutContainter, { isMobile: isMobile, expand: expand, isLeft: isLeft, isVod: mode === VOD_MODE, documentItem: documentItem, focusIndex: focusItem.index, renderShareLeft: renderShareLeft })));
});
export default ShareDocumentLayout;
