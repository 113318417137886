import React from "react";
import { useTranslation } from "react-i18next";
const FocusGroup = React.memo(({ name, count, children }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: "focusGroup" },
        React.createElement("div", { className: "focusHead" },
            React.createElement("h2", null,
                " ",
                name,
                " "),
            React.createElement("span", null,
                " (",
                t('group.person', { count }),
                ")")),
        React.createElement("div", null, children)));
});
export default FocusGroup;
