import ReducerRegistry from "../base/redux/ReducerRegistry";
import { SET_ROOM_LOCKED, SET_ROOM_PASSWORD, TOGGLE_E2EE } from "./actionTypes";
const DEFAULT_STATE = {
    locked: false,
    password: null,
    e2eeEnable: false
};
;
ReducerRegistry.register('features/room-lock', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_ROOM_PASSWORD:
            return {
                ...state,
                locked: action.password ? true : false,
                password: action.password
            };
        case SET_ROOM_LOCKED:
            return {
                ...state,
                locked: action.locked
            };
        case TOGGLE_E2EE:
            return {
                ...state,
                e2eeEnable: action.enabled
            };
    }
    return state;
});
