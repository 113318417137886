import React, { useEffect, useRef, useState, useCallback } from 'react';
const Video = ({ autoPlay = true, className = '', eventHandlers, id = '', muted, onVideoPlaying, playsinline = true, style, videoTrack, ...props }) => {
    const videoRef = useRef(null);
    const [isMounted, setIsMounted] = useState(false);
    const attachTrack = useCallback(async (track) => {
        if (!track?.track || !videoRef.current) {
            return Promise.resolve();
        }
        return track.track.attach(videoRef.current);
    }, []);
    const detachTrack = useCallback((track) => {
        if (videoRef.current && track?.track) {
            track.track.detach(videoRef.current);
        }
    }, []);
    const handleVideoPlaying = useCallback(() => {
        if (onVideoPlaying) {
            onVideoPlaying();
        }
    }, [onVideoPlaying]);
    useEffect(() => {
        setIsMounted(true);
        if (videoRef.current) {
            videoRef.current.volume = 0;
            videoRef.current.onplaying = handleVideoPlaying;
        }
        attachTrack(videoTrack).then(() => {
            if (videoRef.current && autoPlay && isMounted) {
                videoRef.current.play().catch(error => { });
            }
        });
        return () => {
            setIsMounted(false);
            detachTrack(videoTrack);
        };
    }, []);
    useEffect(() => {
        detachTrack(videoTrack);
        attachTrack(videoTrack);
    }, [videoTrack]);
    useEffect(() => {
        if (muted === false && videoRef.current && isMounted) {
            videoRef.current.play().catch(error => { });
        }
    }, [muted, isMounted]);
    return (React.createElement("video", { autoPlay: autoPlay, className: className, id: id, muted: muted, playsInline: playsinline, ref: videoRef, style: style, ...eventHandlers }));
};
export default Video;
