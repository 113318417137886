import ReducerRegistry from "../redux/ReducerRegistry";
import { HIDE_LOAD, OPEN_LOAD } from "./actionTypes";
const DEFAULT_STATE = {
    load: false,
    option: undefined
};
ReducerRegistry.register('features/base/loading', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case OPEN_LOAD:
            return {
                load: true,
                option: action.option
            };
        case HIDE_LOAD:
            return {
                load: false,
                option: undefined
            };
    }
    return state;
});
