import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const CheckBox = React.memo(({ type, id, defaultValue, data, label }) => {
    const { t } = useTranslation();
    const [checked, setChecked] = useState(false);
    const onChange = () => setChecked(!checked);
    useEffect(() => {
        setChecked(defaultValue);
    }, [defaultValue]);
    useEffect(() => {
        APP.eventManager.publish('UPDATE_CHECK_LIST', { type, id, data, checked });
    }, [checked]);
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { id: id, type: 'checkbox', checked: checked, onChange: onChange }),
        React.createElement("label", { className: "overText", htmlFor: id }, t(label))));
});
export default CheckBox;
