import React, { useMemo, useState } from "react";
import { ChatNotification } from "./components";
import { useSelector } from "react-redux";
import { ClickButton } from "../ui";
const ChatNotificationContainer = React.memo(() => {
    const message = useSelector((state) => state['features/base/notification'].fixMessage);
    const [hide, setHide] = useState(true);
    const renderHideButton = useMemo(() => {
        return (React.createElement(ClickButton, { icon: hide ? "notification_close" : "notification_open", size: 20, onClick: () => setHide(!hide) }));
    }, [hide, setHide]);
    if (!message)
        return null;
    return (React.createElement(ChatNotification, { hide: hide, message: message, renderHideButton: renderHideButton }));
});
export default ChatNotificationContainer;
