import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClickButton } from "../../base/ui";
import { GROUP_CONTENT_TYPE } from "../constants";
import GroupAddPopup from "./GroupAddPopup";
import GroupListContainer from "./GroupListContainer";
import GroupMemberContainer from "./GroupMemberContainer";
import RemoteGroupButton from "./RemoteGroupButton";
import { GroupContainer } from "./start";
import GroupMonitoring from "./GroupMonitoring";
const StartGroupContainer = React.memo(() => {
    const { t } = useTranslation();
    const [focusType, setFoucsType] = useState(GROUP_CONTENT_TYPE.GROUP_STATUS);
    /**
     * 왼쪽 상단 메뉴 리스트 가져오기
     */
    const renderMenuList = useMemo(() => {
        return (React.createElement(React.Fragment, null,
            React.createElement(GroupMonitoring, null),
            React.createElement(RemoteGroupButton, { isStart: false })));
    }, []);
    const renderAddButton = useMemo(() => {
        const onClick = () => {
            APP.eventManager.publish('UPDATE_OPEN_POPUP', { componet: GroupAddPopup });
        };
        return (React.createElement(ClickButton, { label: t("group.groupAdd"), isText: true, onClick: onClick }));
    }, []);
    const renderMemberButton = useMemo(() => {
        const onClick = () => {
            setFoucsType(focusType === GROUP_CONTENT_TYPE.GROUP_STATUS ?
                GROUP_CONTENT_TYPE.MEMBER_LIST : GROUP_CONTENT_TYPE.GROUP_STATUS);
        };
        return (React.createElement(ClickButton, { label: focusType === GROUP_CONTENT_TYPE.GROUP_STATUS ?
                t("group.memberAdd") : t("group.preview"), isText: true, onClick: onClick }));
    }, [focusType]);
    const renderContent = useMemo(() => {
        let content = null;
        let member_list = [];
        switch (focusType) {
            case GROUP_CONTENT_TYPE.GROUP_STATUS:
                content = React.createElement(GroupListContainer, { isRow: true, isStart: true });
                break;
            case GROUP_CONTENT_TYPE.MEMBER_LIST:
                member_list = [
                    { name: 'status', sort: false },
                    { name: 'nickname', sort: true },
                    { name: 'group_role', sort: true },
                    { name: 'group_id', sort: false },
                ];
                content = React.createElement(GroupMemberContainer, { isSearch: true, member_list: member_list });
                break;
        }
        return content;
    }, [focusType]);
    return (React.createElement(GroupContainer, { renderMenuList: renderMenuList, renderContent: renderContent, renderAddButton: renderAddButton, renderMemberButton: renderMemberButton }));
});
export default StartGroupContainer;
