import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ClickButton, DivElement, Select, TextElement } from "../base/ui";
import { useDispatch } from "react-redux";
import { setCanvasOption } from "./actions";
import { changeFocusPage, updateShareItem } from "../share-layout/actions";
import { CanvasControlBar } from "./components/web/styles";
import { DOCUMENT_MODE } from "../share-layout/constants";
const selectedWidthOption = [
    { uuid: "width", name: "폭 맞춤" },
    { uuid: "height", name: "쪽 맞춤" },
    { uuid: "page", name: "페이지 맞춤" },
];
const ControlBarConatiner = React.memo(({ uuid, mode, scale, isWidthFit, page, maxPage }) => {
    const dispatch = useDispatch();
    const [pageFit, setPageFit] = useState("page");
    const [open, setOpen] = useState(true);
    const selectedPageOption = useMemo(() => {
        const data = [];
        for (let i = 1; i <= maxPage; i++) {
            data.push({ uuid: i, name: i });
        }
        return data;
    }, [maxPage]);
    useEffect(() => {
        let pageFit = "page";
        if (isWidthFit === true)
            pageFit = "width";
        else if (isWidthFit === false)
            pageFit = "height";
        setPageFit(pageFit);
    }, [isWidthFit]);
    const updateWidthOption = (value) => {
        let isWidthFit = undefined;
        switch (value) {
            case "width":
                isWidthFit = true;
                break;
            case "height":
                isWidthFit = false;
                break;
        }
        dispatch(setCanvasOption({ isWidthFit }));
    };
    // 페이지 업데이트 
    const updatePage = (value) => {
        dispatch(changeFocusPage(uuid, value - 1, mode));
    };
    // 페이지 아전 이동
    const handlerPageDown = useCallback(() => {
        if (page === 0)
            return;
        updatePage(page);
    }, [page]);
    // 페이지 다음 이동
    const handlerPageUp = useCallback(() => {
        if (page === maxPage)
            return;
        updatePage(page + 2);
    }, [page, maxPage]);
    // scale up
    const handlerScaleUp = useCallback(() => {
        if (scale + 10 > 300)
            return;
        dispatch(setCanvasOption({ scale: scale + 10 }));
    }, [scale]);
    // scale down
    const handlerScaleDown = useCallback(() => {
        if (scale - 10 < 50)
            return;
        dispatch(setCanvasOption({ scale: scale - 10 }));
    }, [scale]);
    // share documnet 
    const handlerShareDocument = useCallback(() => {
        dispatch(updateShareItem(uuid, { uuid, shared: true }));
    }, [uuid]);
    const renderShareBtn = useMemo(() => (React.createElement(ClickButton, { className: "option_item", icon: "shared", size: 18, color: "#545454", onClick: handlerShareDocument })), [uuid]);
    // 확대 버튼 ( + , - )
    const renderScaleBtn = useMemo(() => {
        return (React.createElement(DivElement, { className: 'controll_btn' },
            React.createElement(ClickButton, { className: "option_item", icon: "minus", size: 18, color: "#545454", onClick: handlerScaleDown }),
            scale,
            React.createElement(ClickButton, { className: "option_item", icon: "plus", size: 18, color: "#545454", onClick: handlerScaleUp })));
    }, [scale]);
    // 페이지 쪽 맞춤 drop down 
    const renderWidthFit = useMemo(() => (React.createElement(Select, { className: "select_box", selectedOption: pageFit, options: selectedWidthOption, setSelectedOption: updateWidthOption })), [pageFit]);
    // 페이지 이동 버튼 drop down 
    const renderPageInput = useMemo(() => (React.createElement(DivElement, { className: 'controll_btn' },
        React.createElement(Select, { className: "select_box", selectedOption: mode === DOCUMENT_MODE ? page + 1 : page, options: selectedPageOption, setSelectedOption: updatePage }),
        React.createElement(TextElement, { text: 'share.sliceMaxPage', textProps: { maxPage } }))), [page, maxPage, selectedPageOption]);
    // 페이지 이동 버튼 (< , >
    const renderPageBtn = useMemo(() => (React.createElement(DivElement, { className: 'controll_btn' },
        React.createElement(ClickButton, { className: "option_item paging", icon: "notification_close", size: 22, color: "#545454", onClick: handlerPageDown }),
        React.createElement(ClickButton, { className: "option_item paging", icon: "notification_open", size: 22, color: "#545454", onClick: handlerPageUp }))), [page, maxPage]);
    const renderContent = useMemo(() => {
        if (!open)
            return React.createElement(ClickButton, { className: "option_item canvas_toggle_bar", color: "#545454", icon: "notification_open", onClick: () => !open && setOpen(true) });
        else {
            return (React.createElement(React.Fragment, null,
                React.createElement(ClickButton, { className: "option_item", icon: "sideClose", color: "#545454", size: 16, onClick: () => setOpen(!open) }),
                renderShareBtn,
                renderScaleBtn,
                renderWidthFit,
                renderPageInput,
                renderPageBtn));
        }
    }, [open, renderShareBtn, renderScaleBtn, renderWidthFit, renderPageInput, renderPageBtn]);
    return (React.createElement(CanvasControlBar, { open: true, className: "canvas_control non_select" }, renderContent));
});
export default ControlBarConatiner;
