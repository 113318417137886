import React from "react";
import styled from "styled-components";

const RecordStyled = styled.div`
    width: 100%;
    text-align: center;

    .screen_table {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;

        &.top {
            border-top: 2px solid #d5d5d5;
        }

        .screen_item {
            display: flex;
            margin:  0;
            padding: 8px;
            box-sizing: border-box;
            justify-content: flex-start;
            align-items: center;

            &.header {
                background: #f2f2f2f2;
                margin-top: 8px;
            }

            .screen_title {
                font-weight: bold;
            }
            
            > * {
                width: 50%;
                justify-content: flex-start;
            }
        }
    }
`;

export const RecordMemberItem = React.memo(({
    t, nickname, role
}) => {
    return (
        <div className="screen_item">
            <div className="screen_frame">
                { nickname }
            </div>
            <div className="screen_frame">
                { t(`role.${role}`) }
            </div>
        </div>
    )
});

export const RecordMemberList = React.memo(({
    t, children
}) => {
    return (
        <RecordStyled>
            <div className="screen_table">
                <div className="screen_item header">
                    <div className="screen_title">
                        { t("dialog.nickname") }
                    </div>
                    <div className="screen_title">
                        { t("dialog.role") }
                    </div>
                </div>

                { children }
            </div>
        </RecordStyled>
    )
});