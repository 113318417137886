import React from "react";
import { ClickButton } from "../../ui";
const VodTempPlayer = React.memo(({ playerUrl }) => {
    const onClick = () => APP.eventManager.publish('UPDATE_ROOM_CONFIG_DIALOG', { show: false });
    return (React.createElement("div", { style: { paddingTop: '8px', textAlign: 'center' } },
        React.createElement("div", { className: "vod_temp_wrap" },
            React.createElement("iframe", { src: playerUrl, width: 464, height: 261 })),
        React.createElement(ClickButton, { className: 'btn vod_add_btn', isText: true, label: 'common.ok', onClick: onClick })));
});
export { VodTempPlayer };
