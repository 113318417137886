/**
 * 설문조사 리스트 가져오기
 */
export const getSurveyList = (survey_uuid, handlerUpdateList, handlerError) => {
    APP.API.getSurveyList(survey_uuid)
        .then((response) => {
        if (response.complete) {
            handlerUpdateList(response.data);
        }
        else {
            handlerError(response.data);
        }
    });
};
