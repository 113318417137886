import { toState } from '../redux/functions';
/**
 * Checks if any {@code Dialog} is currently open.
 *
 * @param {IStateful} stateful - The redux store, the redux
 * {@code getState} function, or the redux state itself.
 * @returns {boolean}
 */
export function isAnyDialogOpen(stateful) {
    return Boolean(toState(stateful)['features/base/dialog'].component);
}
/**
 * Checks if a {@code Dialog} with a specific {@code component} is currently
 * open.
 *
 * @param {IStateful} stateful - The redux store, the redux
 * {@code getState} function, or the redux state itself.
 * @param {React.Component} component - The {@code component} of a
 * {@code Dialog} to be checked.
 * @returns {boolean}
 */
export function isDialogOpen(stateful, component) {
    return toState(stateful)['features/base/dialog'].component === component;
}
export function isSheetOpen(stateful, component) {
    return toState(stateful)['features/base/dialog'].sheet === component;
}
