import React, { Fragment, useEffect, useState } from "react";
// @ts-ignore
import Chat from "./Chat";
const ChatContainer = React.memo(({ isMonitoring, groupId }) => {
    const [popup, setPopup] = useState();
    const [isChoice, setIsChoice] = useState(false);
    const handlerPopup = (data) => {
        const popupComponents = data?.componet ? React.createElement(data?.componet, data?.props) : null;
        setPopup(popupComponents);
    };
    const handlerChoiceChat = (data) => {
        const choice = data?.choice !== undefined ? false : !isChoice;
        setIsChoice(choice);
    };
    useEffect(() => {
        APP.eventManager.subscribe('UPDATE_OPEN_CHAT_POPUP', handlerPopup);
        APP.eventManager.subscribe('SET_CHOICE_CHAT', handlerChoiceChat);
        return () => {
            APP.eventManager.unsubscribe('UPDATE_OPEN_CHAT_POPUP', handlerPopup);
            APP.eventManager.unsubscribe('SET_CHOICE_CHAT', handlerChoiceChat);
        };
    }, []);
    return (React.createElement(Fragment, null,
        React.createElement(Chat, { isChoice: isChoice, isMonitoring: isMonitoring, groupId: groupId }),
        popup));
});
export default ChatContainer;
