import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import DocumentItemContainer from "./DocumentItemContainer";
import { VOD_MODE } from "./constants";
import { getDocumentListByMode } from "./functions";
const DocumentListContainer = React.memo(({ permission, mode, focusItem }) => {
    const [deep, setDeep] = useState();
    const list = useSelector((state) => getDocumentListByMode(state, mode), shallowEqual);
    useEffect(() => {
        if (mode === VOD_MODE)
            setDeep(undefined);
    }, [mode]);
    useEffect(() => {
        if (!list.includes(deep))
            setDeep(undefined);
    }, [list, deep]);
    return (list.map((uuid, index) => (React.createElement(DocumentItemContainer, { key: index, mode: mode, uuid: uuid, deep: deep, focusItem: focusItem, isFocus: uuid === deep, permission: permission, setFocus: setDeep }))));
});
export default DocumentListContainer;
