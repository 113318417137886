import React, { useState } from 'react';
import Recorder from '../../../../modules/server/recorder';
import { ClickButton } from '../../../base/ui';
import { MicTest } from './MicTest';
export default function AudioInputTest({ track, isIPhone }) {
    const [prevRecord, setPrevRecord] = useState(null);
    const [file, setFile] = useState();
    const [isRecord, setIsRecord] = useState(false);
    const onClick = () => {
        if (!track) {
            return;
        }
        if (prevRecord) {
            prevRecord.stop();
            return;
        }
        const testClass = new MicTest(setFile, setIsRecord, setPrevRecord);
        const recorder = new Recorder(testClass, true);
        if (!recorder.isSupportedBrowser()) {
            APP.UI.alertMessage('record.notAvaliable');
            return;
        }
        recorder.loadAudioStraem([{ track }]);
        // start record
        recorder.start(true);
        setPrevRecord(recorder);
        setTimeout(() => {
            recorder.stop();
        }, 4000);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { display: 'none' } },
            React.createElement("audio", { preload: "auto", autoPlay: true, "webkit-playsinline": "webkit-playsinline", playsInline: true, src: file })),
        React.createElement(ClickButton, { className: `audio_test_btn no-hover ${isRecord}`, label: "TEST", styles: { display: `${isIPhone ? 'none' : 'flex'} ` }, onClick: onClick })));
}
