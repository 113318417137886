import { isCheckPermission, LEAVE_ROOM, SET_ACTIVE_GROUP_ID } from '../../room';
import { hideNotification, showNotification } from '../notification/actions';
import { SHOW_NOTIFICATION } from '../notification/actionTypes';
import { getNotificationLimit, getNotificationList } from '../notification/functions';
import { MiddlewareRegistry } from '../redux';
import { addMember, removeMember, responsePresentation, setBridgeId, setMemberRole, updateMember } from './actions';
import { ADD_LOCAL_MEMBER, ADD_MEMBER, ADD_MEMBER_LIST, ADD_PINNED, ADD_RECORD, DELETE_MEMBER, DELETE_PINNED, DELETE_RECORD, REQUEST_PRESENTER, RESPONSE_PRESENTER, SET_MEMBER_ROLE, UPDATE_MEMBER_NICKNAME, } from './actionType';
import { MEMBER_STATUS, PERMISSION, ROLE } from './constans';
import { checkRoleLevel, getLocalIDMember, getLocalMember, getLocalMemberRole, getMemberByUserID } from './functions';
MiddlewareRegistry.register(store => next => async (action) => {
    const { getState, dispatch } = store;
    let member = undefined;
    let notification = true;
    switch (action.type) {
        case ADD_LOCAL_MEMBER:
        case ADD_MEMBER:
            member = action.member;
            if (!member)
                return;
            if (member.type === 'hidden')
                return null;
            if (member.jitsi_id) {
                dispatch(setBridgeId(member.user_uuid, member.jitsi_id));
            }
            break;
        case ADD_MEMBER_LIST:
            const response = await APP.API.getRemoteUserInformation({ status: MEMBER_STATUS.OCCUPIDE });
            const aMember = response.complete ? response.data : [];
            action.members.map(async (m) => {
                if (m.type === 'hidden')
                    return null;
                if (m.member_uuid === action.member_uuid)
                    return null;
                if (m.status === MEMBER_STATUS.BANNED)
                    return null;
                const group = aMember?.find(i => i.group_id !== '' && i.user_uuid === m.user_uuid);
                dispatch(addMember({ ...m, active_group: group ? group.group_id : '', local: false, existed: true }));
            });
            break;
        case ADD_PINNED:
        case DELETE_PINNED:
            if (!action.isRemote) {
                if (isCheckPermission(getState, PERMISSION.PIN)) {
                    const response = await APP.management.setMemberPinned(action.user_uuid, action.pinned);
                    if (response.status !== 200) {
                        console.log('error-setMemberPinned');
                        return;
                    }
                }
                else
                    return;
            }
            member = getMemberByUserID(getState, action.user_uuid);
            if (!member)
                return;
            dispatch(updateMember({
                ...member,
                pinned: action.pinned,
            }));
            break;
        case ADD_RECORD:
        case DELETE_RECORD:
            if (!action.isRemote) {
                if (isCheckPermission(getState, PERMISSION.PIN)) {
                    const response = await APP.management.setRecording(action.user_uuid, action.type === ADD_RECORD ? true : false);
                    if (response.status !== 200) {
                        console.log('error-setRecording');
                        return;
                    }
                }
                else
                    return;
            }
            member = getMemberByUserID(getState, action.user_uuid);
            if (!member)
                return;
            dispatch(updateMember({
                ...member,
                recording: action.record,
            }));
            break;
        case RESPONSE_PRESENTER:
            if (action.isRemote === false) {
                if (action.accepted) {
                    dispatch(setMemberRole(action.user_uuid, ROLE.PRESENTER, false));
                }
                const response = await APP.management.responsePresentation(action.user_uuid, action.accepted);
                if (response.status !== 200) {
                    /**
                     * (FIX Me)
                     */
                    return;
                }
            }
            else {
                const local_id = getLocalIDMember(getState);
                if (action.user_uuid === local_id) {
                    dispatch(showNotification({ titleKey: `notify.${action.accepted}ResponsePresenter` }));
                }
            }
            break;
        case SET_MEMBER_ROLE:
            member = getMemberByUserID(getState, action.user_uuid);
            if (!member)
                return;
            if (!action.isRemote) {
                if (isCheckPermission(getState, PERMISSION.MEMBER_ROLE)) {
                    const response = await APP.management.setMemberRole(action.user_uuid, action.role);
                    if (response.status !== 200) {
                        console.log('error');
                        return;
                    }
                }
                else
                    return;
            }
            if (action.isGroup) {
                member = Object.assign(member, { group_role: action.role });
            }
            else {
                member = Object.assign(member, { role: action.role });
            }
            dispatch(updateMember(member));
            break;
        case REQUEST_PRESENTER:
            const local_role = getLocalMemberRole(getState);
            if (action.isLocal) {
                const response = await APP.management.requestPresentation(action.member_uuid);
                if (response.status !== 200) {
                    /**
                     * (FIX Me)
                     */
                    return;
                }
            }
            else {
                if (checkRoleLevel(local_role, ROLE.HOST))
                    return;
            }
            break;
        case SHOW_NOTIFICATION:
            const limit = getNotificationLimit(getState);
            const show = getNotificationList(getState);
            if (limit <= 0)
                break;
            if (show.size + 1 > limit) {
                const last = show.values().next().value;
                if (last) {
                    if (last?.presenter)
                        dispatch(responsePresentation(last?.uid, false, false));
                    else
                        dispatch(hideNotification(last?.id));
                }
            }
            break;
        case DELETE_MEMBER:
            member = action.member;
            if (!member)
                return;
            store.dispatch(updateMember({
                ...member,
                status: MEMBER_STATUS.VACATED,
            }));
            break;
        case SET_ACTIVE_GROUP_ID:
            member = getLocalMember(getState, false);
            if (!member)
                return;
            store.dispatch(updateMember({
                ...member,
                active_group: action.group_id,
            }));
            break;
        case UPDATE_MEMBER_NICKNAME:
            member = getMemberByUserID(getState, action.user_uuid);
            if (!member)
                return;
            if (!action.isRemote) {
                const response = await APP.management.updateNickname(action.nickname);
                if (response.status !== 200) {
                    console.log('error');
                    return;
                }
            }
            store.dispatch(updateMember({
                ...member,
                nickname: action.nickname,
            }));
            break;
        case LEAVE_ROOM:
            const members = store.getState()['features/base/members'].members;
            members.forEach((member) => {
                store.dispatch(removeMember(member));
            });
            break;
    }
    return next(action);
});
