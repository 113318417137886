export const GROUP_CONTENT_TYPE = {
    MEMBER_LIST: "list",
    RANDOM: "random",
    GROUP_STATUS: "group_status",
    ADD: "add",
    MODIFY: "modify",
    DELETE: "delete",
    GROUP_FOUCS: "focus",
    GROUP_MEMBER_MODIFY: "member_modify"
};
export const GROUP_CONTENTS = [GROUP_CONTENT_TYPE.MEMBER_LIST, GROUP_CONTENT_TYPE.RANDOM, GROUP_CONTENT_TYPE.GROUP_STATUS];
