import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bytesToSize } from '../chat/functions';
// @ts-ignore
import { Dialog } from '../dialog';
import { CheckBox, ClickButton, DivElement, Icon, SearchBar, TextElement, } from '../ui';
import { default as useIntersectionObserver } from '../ui/components/BottomContainer.web';
import AddVodItem from './AddVodItem';
import { VodListStyled, VodTempPlayer } from './components';
import { VOD_PAGE_TYPE } from './constant';
import { deleteVod, getVodList, getVodOption } from './functions';
const SHOW_DEFAULT_COUNT = 20;
const SHOW_ICON_LIST = ['vodPlay', 'vodDown', 'delete_room'];
const VodListContainer = React.memo(({ page, select_vod_list, isRoomConfigDialog = false, isMobile = false, isDialog = false, }) => {
    const dispatch = useDispatch();
    const target = useRef(null);
    const [list, setList] = useState([]);
    const [search, setSearch] = useState();
    const [totalCount, setTotalCount] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const { vodList, showBottom } = useMemo(() => {
        return {
            vodList: list,
            showBottom: list.length > 0 && totalCount > list.length ? true : false,
        };
    }, [list, totalCount]);
    const { showTableHeader, showAddBtn, addBtnOption, showList } = useMemo(() => {
        let handler = {
            failed: (msg) => {
                APP.UI.alertMessage(msg);
                APP.eventManager.publish('UPDATE_ROOM_CONFIG_LOADING', {
                    isLoad: false,
                });
            },
            success: () => { },
            setLoad: () => { },
        };
        if (page === VOD_PAGE_TYPE.VOD_LIST) {
            // @ts-ignore
            handler.setLoad = (isLoad) => dispatch(setLoading(isLoad));
        }
        else if (page === VOD_PAGE_TYPE.VOD_ROOM_CONFIG) {
            handler.success = (data) => {
                APP.eventManager.publish('UPDATE_VOD_LIST', data);
            };
            handler.setLoad = (isLoad) => APP.eventManager.publish('UPDATE_ROOM_CONFIG_LOADING', {
                isLoad,
            });
        }
        return getVodOption(page, handler, isMobile);
    }, [page, isMobile]);
    const [observe, unobserve] = useIntersectionObserver(() => {
        setIsLoading(true);
        setPageIndex(page => page + 1);
    });
    useEffect(() => {
        if (pageIndex === 0)
            observe(target.current);
        const N = vodList.length;
        if (0 === N || totalCount <= N) {
            unobserve(target.current);
        }
    }, [vodList]);
    useEffect(() => {
        if (isLoading) {
            unobserve(target.current);
        }
        else {
            observe(target.current);
        }
    }, [isLoading]);
    /**
     * 초기 값 가져오기
     */
    const initList = () => {
        getVodList({
            name: search,
            owner_id: 'self',
            status: ['created'],
            offset: pageIndex * SHOW_DEFAULT_COUNT,
            limit: SHOW_DEFAULT_COUNT,
        }, handlerSuccess, handlerError);
    };
    useEffect(() => {
        initList();
    }, [search]);
    /**
     * VOD 초기 설정
     * @param data
     */
    const handlerSuccess = data => {
        const { total_count, items } = data;
        setTotalCount(total_count);
        setList(items || []);
    };
    /**
     * VOD 페이징 시 데이터 병합
     * @param data
     */
    const updateItems = data => {
        const { items } = data;
        if (!items)
            return;
        setList(prevList => {
            const idMap = new Map();
            prevList.map(prevItem => {
                idMap.set(prevItem.id, prevItem);
            });
            items &&
                items.map(newItem => {
                    if (idMap.has(newItem.id)) {
                        const objA = idMap.get(newItem.id);
                        idMap.set(newItem.id, { ...objA, ...newItem });
                    }
                    else {
                        idMap.set(newItem.id, newItem);
                    }
                });
            return (Array.from([...idMap.values()].sort((a, b) => new Date(b.creation_time).getTime() -
                new Date(a.creation_time).getTime())) || []);
        });
        setIsLoading(false);
    };
    /**
     * VOD 등록 시 하나의 vod 데이터 업데이트
     * @param data
     */
    const updateItem = (data) => {
        getVodList({
            name: search,
            id: data.album_id,
            owner_id: 'self',
            statrmfjgus: ['created', 'deleted'],
            offset: pageIndex * SHOW_DEFAULT_COUNT,
            limit: SHOW_DEFAULT_COUNT,
        }, updateItems, handlerError);
        APP.eventManager.publish('UPDATE_ROOM_CONFIG_LOADING', {
            isLoad: false,
        });
    };
    /**
     * 아이템 삭제
     * @param album_id
     */
    const deleteItem = (album_id) => {
        setTotalCount(prevTotal => prevTotal - 1);
        setList(prevList => {
            const updatedList = prevList.filter(item => item.id !== album_id);
            return updatedList;
        });
    };
    /**
     * VOD 등록 에러 표시
     * @param err
     * @returns
     */
    const handlerError = err => APP.UI.alertMessage(err);
    useEffect(() => {
        APP.eventManager.subscribe('UPDATE_VOD_LIST', updateItem);
        return () => {
            APP.eventManager.unsubscribe('UPDATE_VOD_LIST', updateItem);
        };
    }, []);
    useEffect(() => {
        pageIndex !== 0 &&
            getVodList({
                name: search,
                owner_id: 'self',
                status: ['created'],
                offset: pageIndex * SHOW_DEFAULT_COUNT,
                limit: SHOW_DEFAULT_COUNT,
            }, updateItems, handlerError);
    }, [pageIndex]);
    // function: 테이블 아이템 가져와서 뿌려주기 - (use 테이블 아이템 컴포넌트)
    const getTableItem = (value, item) => {
        let itemValue = item[value];
        if (value === 'no')
            return null;
        if (value === 'name' && isMobile) {
            return (React.createElement(DivElement, { key: value, className: `table_text ${value}` },
                React.createElement(TextElement, { className: "overText", text: itemValue }),
                React.createElement(DivElement, { className: "sub_text" },
                    React.createElement(TextElement, { className: "overText", text: bytesToSize(item.media.size) }))));
        }
        if (value === 'check') {
            const label = item.name + ' - ' + bytesToSize(item.media.size);
            const defaultValue = select_vod_list &&
                select_vod_list.find(i => i.streaming_id === item.id);
            return (React.createElement(CheckBox, { key: item.id, type: "VOD", id: item.id, defaultValue: defaultValue ? true : false, data: {
                    album_id: item.id,
                    name: item.name,
                }, label: label }));
        }
        if (value === 'etc') {
            const getHandlerAction = async (name) => {
                switch (name) {
                    case 'vodPlay':
                        APP.eventManager.publish('UPDATE_ROOM_CONFIG_DIALOG', {
                            show: true,
                            props: {
                                component: (React.createElement(VodTempPlayer, { playerUrl: item.media.url })),
                                title: 'vod.player',
                                isMobile,
                            },
                        });
                        break;
                    case 'vodDown':
                        if (navigator.product === 'ReactNative') {
                        }
                        else {
                            fetch(item.media.url)
                                .then(response => response.blob())
                                .then(blob => {
                                const link = document.createElement('a');
                                link.href = URL.createObjectURL(blob);
                                link.download = item.name;
                                link.click();
                            })
                                .catch(console.error);
                        }
                        return;
                    case 'delete_room':
                        if (APP.UI.confirmMessage('vod.deleteConfirm') ===
                            false)
                            return;
                        const handler = {
                            success: () => {
                                deleteItem(item.id);
                            },
                            failed: err => {
                                APP.UI.alertMessage(err);
                            },
                        };
                        deleteVod(item.id, undefined, handler);
                        break;
                }
            };
            return (React.createElement(DivElement, { key: value, className: `table_text ${value}` }, SHOW_ICON_LIST.map(icon => (React.createElement(ClickButton, { key: icon, icon: icon, size: isMobile ? 26 : 32, color: "#C6CBD2", onClick: () => getHandlerAction(icon) })))));
        }
        if (value === 'size')
            itemValue = bytesToSize(item.media[value]);
        return (React.createElement(DivElement, { key: value, className: `table_text ${value}` }, itemValue));
    };
    // (Component) - 테이블 아이템
    const renderTableItem = useMemo(() => {
        if (vodList && vodList.length > 0) {
            return vodList.map((item, index) => (React.createElement(DivElement, { key: item.id, className: "table_item" },
                showList.includes('no') && (React.createElement(TextElement, { className: "table_text no", text: index + 1 })),
                showList.map(name => getTableItem(name, item)))));
        }
        return (React.createElement(DivElement, { className: "no_list" },
            React.createElement(Icon, { icon: "no_list", size: 132, color: "#F8F8F8" }),
            React.createElement(TextElement, { text: "vod.noList" })));
    }, [vodList, showList, pageIndex, select_vod_list]);
    // (Component) - 테이블 head
    const renderTableHead = useMemo(() => showList.map(name => (React.createElement(TextElement, { key: name, className: `table_text ${name}`, text: `vod.${name}` }))), [showList]);
    const renderShowBottom = useMemo(() => {
        return (showBottom && (React.createElement(DivElement, { ref: target, style: { width: '100%', height: 30 } }, "Load")));
    }, [showBottom, pageIndex]);
    // (Component) - VOD 리스트 불러오기
    const renderVodList = useMemo(() => {
        if (isMobile) {
            return (React.createElement(DivElement, { className: "table_wrapper" },
                React.createElement(DivElement, { className: "table" },
                    React.createElement(DivElement, { className: `table_body vod_table ${isRoomConfigDialog && 'line'}` },
                        renderTableItem,
                        renderShowBottom))));
        }
        else {
            return (React.createElement(DivElement, { className: `table_wrapper ${isRoomConfigDialog && 'table_dialog'}` },
                React.createElement(DivElement, { className: "table" },
                    showTableHeader && (React.createElement(DivElement, { className: "table_head" }, renderTableHead)),
                    React.createElement(DivElement, { className: `table_body vod_table ${isRoomConfigDialog && 'line'}` },
                        renderTableItem,
                        renderShowBottom))));
        }
    }, [
        isRoomConfigDialog,
        renderTableHead,
        renderTableItem,
        renderShowBottom,
    ]);
    // (Component) - VOD 저장 버튼
    const renderAddBtn = useMemo(() => showAddBtn && (React.createElement(AddVodItem, { isMobile: isMobile, ...addBtnOption })), [isMobile, showAddBtn, addBtnOption]);
    const renderSubmit = useMemo(() => select_vod_list && (React.createElement(ClickButton, { className: "btn ok vod_add_btn", isText: true, label: "common.ok", size: isMobile ? 14 : 18, onClick: () => APP.eventManager.publish('PREVIEW_SUBMIT_VOD_LIST') })), [select_vod_list, isMobile]);
    if (isMobile) {
        return (React.createElement(VodListStyled, { isMobile: true, className: "room_config_wrapper" },
            React.createElement(SearchBar, { setSearch: setSearch }),
            React.createElement(DivElement, { className: "wrap_list" }, renderVodList),
            renderAddBtn));
    }
    if (isDialog) {
        return (React.createElement(Dialog, { className: "vod_dialog" },
            React.createElement(SearchBar, { setSearch: setSearch }),
            renderVodList,
            renderSubmit));
    }
    else {
        return (React.createElement(VodListStyled, { isMobile: false, className: "room_config_wrapper" },
            React.createElement(DivElement, { className: `head_wrap ${page === VOD_PAGE_TYPE.VOD_ROOM_RESERVATION && 'room_dialog'}` },
                renderAddBtn,
                React.createElement(SearchBar, { setSearch: setSearch })),
            React.createElement(DivElement, { className: "wrap_list" }, renderVodList),
            renderSubmit));
    }
});
export default VodListContainer;
