export const config = {
	name: 'mate',
	title: 'Meetmate',
	defaultLanguage: 'ko',
	lobby: {
		showLogin: true,
		showMyPage: true,
		logoUrl: '/libs/images/meetmate_logo.svg',
		logoWidth: {
			mobile: 72,
			desktop: 130,
		},
		prevBackgroundUrl: '/libs/images/main_bg.png',
		prevBackground: 'no-repeat center/cover fixed',
		backgroundUrl: '/libs/images/bg_lobby.png',
		background: 'no-repeat center/cover fixed',
		subText: null, // innovation 에서만 사용
		boxWidth: null, // innovation 에서만 사용
	},
	nickname: {
		backgroundUrl: '/libs/images/bg_nickname.png',
		background: 'no-repeat center/cover fixed',
	},
	ui: {
		layout: {
			GRID_MODE: true,
			PINNED_MODE: true,
			VAD_MODE: true,
			SEMINAR_MODE: true,
			whiteboard: true,
			document: true,
			common_note: true,
			screenShare: true,
			vod: true,
		},
		document: {
			direction: 'column',
			location: 'left',
		},
		film: {
			pin: true,
		},
		desktop: {
			header: {
				show: true,
				direction: 'row',
				left: ['room_name', 'room_count', 'room_time', 'connection'],
				middle: ['mode_change'],
				right: ['recordList', 'chat', 'member', 'option'],
			},
			footer: {
				show: true,
				direction: 'column',
				left: ['one_depth_mode_change'],
				right: ['setting', 'camera', 'mic', 'out'],
			},
			floating: {
				show: false,
				left: null,
			},
		},
		mobile: {
			header: {
				show: true,
				direction: 'row',
				left: ['connection', 'mode_change', 'room_name', 'room_count', 'room_time'],
				middle: null,
				right: ['setting'],
			},
			footer: {
				show: true,
				direction: 'row',
				left: null,
				middle: ['camera', 'mic', 'chat', 'member', 'option', 'out'],
				right: null,
			},
			floating: {
				show: false,
				left: null,
			},
		},
		close: {
			shutdown: true, // 강제 퇴장
			close: true, // 방 나가기
		},
	},
	maxMember: '무제한',
	optionList: [
		'grid',
		'e2ee',
		'blur',
		'survey',
		'security',
		'selfRecord',
		'dualMonitor',
		'serverRecord',
		'attendance',
		'notification',
		'settingDevice',
		'group',
	],
	// 기능에 대한 옵션
	option: {
		e2ee: true,
		pin: true,
		grid: true,
		security: true,
		dualMonitor: true,
		selfRecord: false,
		serverRecord: true,
		devicePermission: true,
		setHandlerMode: true,
		changeLabel: true,
		changeScreenMode: true,
		settingDevice: true,
		fullscreen: false,
		group: true,
		workbook: true, // 통합 문서 (document + whiteboard)
		invite: true,
		file: true,
		invite: true,
		modeChange: true,
		gridCountSet: true,
		security: true,
		invite: true,
		notificationSet: true, // 알림 옵션 설정,
		shareListPopup: false,
		attendance: true,
		chatFileLock: true,
		blur: true,
		vodFile: true,
		vodUrl: true,
		survey: true,
		isDetailMode: false,
		notification: true, // 공지 사항
		controlDevice: false,
		hideForceEndRoom: false,
	},
	style: {
		enableIconSize: 24,
		subAccountColor: '#000',
		main: '#123',
		video_wrap: '#000',
		label: '#000',
		labelText: '#fff',
		playerColor: '#fff',
		focusColor: '#8bacff',
		iconButton: {
			background: null,
			color: '#fff',
			iconBackColor: '#fff',
			iconStrokeColor: null,
		},
		iconHoverButton: {
			background: null,
			color: '#8bacff',
			iconBackColor: '#8bacff',
			iconStrokeColor: null,
		},
		optionButton: {
			background: null,
			color: '#000',
			iconBackColor: '#000',
			iconStrokeColor: null,
		},
		optionHoverButton: {
			background: null,
			color: '#8bacff',
			iconBackColor: '#8bacff',
			iconStrokeColor: null,
		},
		textButton: {
			background: '#494949',
			color: '#fff',
		},
		textHoverButton: {
			background: '#8bacff',
			color: '#fff',
		},
		canvasButton: {
			background: null,
			color: null,
			iconBackColor: '#545454',
			iconStrokeColor: '#545454',
		},
		canvasHoverButton: {
			background: '#b5b5b5',
			color: '#545454',
			iconBackColor: '#545454',
			iconStrokeColor: '#545454',
		},
	},
};
