import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ClickButton, Input } from "../../base/ui";
import { modifyGroup } from "../action";
import { isHaveGroupByName } from "../functions";
import { PopupContainer } from "./common";
const GroupModifyPopup = React.memo(({ uuid, name }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [changeName, setChangeName] = useState(name);
    /**
     * (action) 수정
     */
    const onClick = useCallback(async () => {
        const isHave = isHaveGroupByName(changeName);
        if (isHave)
            APP.eventManage.publish('ALRET_GROUP_MESSAGE', { message: t("group.alreadyGroup") });
        else {
            await dispatch(modifyGroup(uuid, { name: changeName }, false));
            APP.eventManager.publish('UPDATE_OPEN_POPUP');
        }
    }, [changeName, uuid, dispatch, isHaveGroupByName]);
    /**
     * 이름 입력 input component에서 Enter클릭시 수정 onClick
     * @returns
     */
    const onKeyPress = useCallback(e => {
        if (e.key === 'Space' || e.key === 'Enter' || e.key === 'NumpadEnter') {
            e.preventDefault();
            onClick();
        }
    }, [onClick]);
    /**
     * 이름 입력 input component 랜더링 하는 함수
     * @returns
     */
    const renderNameInput = useMemo(() => (React.createElement(Input, { value: changeName, onKeyPress: onKeyPress, onChange: setChangeName })), [changeName, onKeyPress]);
    /**
     * 수정 버튼 component 랜더링 하는 함수
     * @returns
     */
    const renderModifyBtn = useMemo(() => (React.createElement(ClickButton, { label: t("group.modify"), isText: true, onClick: onClick })), [t, onClick]);
    return (React.createElement(PopupContainer, { title: t("group.modify"), guid: t("group.modifyGuid", { name }) },
        React.createElement(React.Fragment, null,
            renderNameInput,
            renderModifyBtn)));
});
export default GroupModifyPopup;
