import i18next from 'i18next';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { getLocalID } from "../base/local";
import { getVodList } from '../base/vod/functions';
import { addCanvasList } from '../canvas/actions';
import { ADD_SHARE_ITEM, ADD_SHARE_LIST, DELETE_SHARE_ITEM, OPEN_DOCUMENT_LIST, SET_FOCUS_SHARE_ITEM, UPDATE_SHARE_ITEM } from './actionTypes';
import { DOCUMENT_MODE, VOD_MODE, WHITE_MODE, WORKBOOK_LIST } from './constants';
import { checkSharePermission, isFocusAblePage, parseCanvaseLine } from './functions';
export function openDocumentList() {
    return {
        type: OPEN_DOCUMENT_LIST,
    };
}
/**
 * (DOCUMENT - FOCUS_DOCUMENT) 문서 포커스
 * @param uuid
 * @param index
 * @param mode
 * @param isRemote
 * @returns
 */
export function setFocusShareItem(uuid, index, mode, isRemote = false) {
    let cMode = mode;
    cMode = (APP.config.option.workbook && WORKBOOK_LIST.includes(mode)) ? 'document' : mode;
    return {
        type: SET_FOCUS_SHARE_ITEM,
        uuid, index, mode: cMode, isRemote
    };
}
/**
 * (DOCUMENT - REMOVE DOCUMENT) 문서 삭제
 * @param uuid
 * @param isRemote
 */
export function removeShareItem(uuid, isRemote = false) {
    return {
        type: DELETE_SHARE_ITEM,
        uuid, isRemote
    };
}
/**
 * (VOD) 파일 저장
 */
export function addVodFile(sendData, name, owner_id = 'self', handler) {
    return async (dispatch, getState) => {
        const uuid = uuidv4();
        const data = {
            uuid,
            file: { name },
            isWhiteBoard: false,
            shared: false,
            page: undefined,
            list: undefined,
            count: 0,
            complete: false,
            user_uuid: '',
            config: VOD_MODE
        };
        // 임시 파일 저장 , 
        dispatch(addShareItem(data, true));
        return APP.API.registVod(sendData, name, owner_id)
            .then((response) => {
            if (response.complete) {
                const data = response.data;
                const updateItems = (test) => {
                    if (test.items && test.items.length > 0) {
                        const result = test.items[0];
                        if (result.media.is_playable_on_chrome) {
                            handler.success({ items: { album_id: data.id, name }, uuid });
                        }
                        else {
                            dispatch(removeShareItem(uuid, true));
                            handler.failed('인코딩이 불가능합니다.');
                        }
                    }
                };
                getVodList({ id: data.id, owner_id: 'self', status: 'created' }, updateItems, handler.failed);
            }
            else {
                dispatch(removeShareItem(uuid, true));
                handler.failed(response.data, uuid);
            }
        });
    };
}
/**
 * (DOCUMENT) 파일 저장
 * @param {File} file
 * @returns
 */
export function addDocumentFile(file) {
    return async (dispatch, getState) => {
        const uuid = uuidv4();
        const user_uuid = getLocalID(getState);
        if (!user_uuid)
            return;
        const data = {
            uuid,
            file: { name: file.name },
            isWhiteBoard: false,
            shared: false,
            page: undefined,
            list: undefined,
            count: 0,
            complete: false,
            user_uuid,
            config: DOCUMENT_MODE
        };
        // 임시 파일 저장 , 
        dispatch(addShareItem(data, true));
        const response = await APP.API.convertShareDocument(file, uuid);
        if (response.complete) {
            dispatch(removeShareItem(uuid, true));
            dispatch(addShareItem({ ...data, uuid: response.data.uuid, isWhiteBoard: false }, true));
            return true;
        }
        else {
            dispatch(removeShareItem(uuid, true));
            return response.data;
        }
    };
}
/**
 * (DOCUMENT - UPDATE) 문서 업데이트
 * @param uuid
 * @param data
 * @param isRemote
 */
export function updateShareItem(uuid, data, isRemote = false) {
    return {
        type: UPDATE_SHARE_ITEM,
        uuid, data, isRemote
    };
}
/**
 * (ACTION - ADD SHARE ITEM) 문서 저장
 * @param data
 * @param isRemote
 * @returns
 */
export function addShareItem(data, isRemote = false) {
    return {
        type: ADD_SHARE_ITEM,
        data, isRemote
    };
}
/**
 * 문서 저장 (document 모드 제외하고 모든 모드에서 사용)
 * @param {object} option
 * @returns
 */
export function addDocumentItem(option) {
    return async (dispatch, getState) => {
        const uuid = uuidv4();
        const user_uuid = getLocalID(getState);
        const date = moment().format("HH:DD:SS");
        if (!user_uuid)
            return;
        const data = {
            uuid,
            file: { name: `${i18next.t("share.whiteboard")}_${date}` },
            isWhiteBoard: true,
            shared: false,
            page: undefined,
            list: undefined,
            count: 0,
            complete: false,
            user_uuid,
            config: WHITE_MODE,
            ...option
        };
        dispatch(addShareItem(data));
    };
}
/**
 * 아직 저장되지 않은 문서 포커스
 * @param {*} uuid
 * @param {*} index
 * @param {*} mode
 * @param {*} isRemote
 * @returns
 */
export function tempFocusShareItem(uuid, index, mode, isRemote = false) {
    return async (dispatch, getState) => {
        let cMode = mode;
        if (!isRemote) {
            if (checkSharePermission(getState, 'share')) {
                cMode = (APP.config.option.workbook && WORKBOOK_LIST.includes(mode)) ? 'document' : mode;
                const response = await APP.management.focusShareItem(uuid, index, cMode);
                if (response !== 200) {
                    /**
                     * (fix) 여기서의 에러 처리 필요
                     */
                    console.log(" 아직 저장되지 않은 문서 포커스 오류 - " + mode);
                    return;
                }
            }
            else
                return;
        }
        dispatch({
            type: SET_FOCUS_SHARE_ITEM,
            uuid, index, mode: cMode
        });
    };
}
/**
 * (DOCUMENT - INIT) 문서 초기 설정
 * @param list
 * @param member_uuid
 * @returns
 */
export function addDocumentList(list, member_uuid, isSharedPermission = false) {
    return async (dispatch, getState) => {
        const whiteboard_list = new Set();
        const document_list = new Set();
        const vod_list = new Set();
        const visible_list = new Set();
        const canvas_list = new Map();
        const user_uuid = getLocalID(getState);
        list.map(item => {
            const document = {
                uuid: item.uuid,
                file: item.file,
                isWhiteBoard: item.isWhiteBoard,
                shared: item.shared,
                page: item.page,
                list: item.list,
                count: item.count,
                complete: item.complete,
                config: item.config || WHITE_MODE,
                user_uuid: item.config === VOD_MODE ? '' : (item.user_uuid || user_uuid)
            };
            if (member_uuid === item.member_uuid || item.shared || (item.config === VOD_MODE && isSharedPermission)) {
                if (item.config === VOD_MODE) {
                    vod_list.add(document.uuid);
                }
                else if (item.isWhiteBoard) {
                    whiteboard_list.add(document.uuid);
                }
                else {
                    document_list.add(document.uuid);
                }
                visible_list.add(document);
            }
            if (item?.canvasList) {
                const canvasList = parseCanvaseLine(item.canvasList);
                canvas_list.set(item.uuid, canvasList);
            }
        });
        dispatch(addCanvasList(canvas_list));
        dispatch({
            type: ADD_SHARE_LIST,
            whiteboard_list, document_list, vod_list, visible_list
        });
    };
}
/**
 * 현재 페이지 +1 ,-1
 * @param {String} uuid
 * @param {String} page
 * @param {String} mode
 * @returns
 */
export function changeFocusPage(uuid, page, mode) {
    return async (dispatch, getState) => {
        const isAble = isFocusAblePage(getState, uuid, page);
        if (isAble) {
            dispatch(setFocusShareItem(uuid, page, mode));
            return true;
        }
        else {
            return false;
        }
    };
}
