import React, { useState } from "react";
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { useTranslation } from "react-i18next";
import { ClickButton } from "../../base/ui";
import { MIDDLE_ATTENDANCES } from "../constants";
import { CheckStyled } from "./styles.web";
const ChoiceTimeSet = React.memo(({ handlerClick, onSave }) => {
    const { t } = useTranslation();
    const [selectTime, setSelectedTime] = useState();
    const handleTimeChange = (time) => {
        let newTime;
        if (typeof time === 'string') {
            // 문자열로 전달된 경우 Date 객체로 변환
            const currentDate = new Date();
            const timeParts = time.split(':');
            currentDate.setHours(Number(timeParts[0]), Number(timeParts[1]));
            newTime = currentDate;
        }
        else {
            newTime = time.toDate();
        }
        setSelectedTime(newTime);
    };
    const timeConstraints = {
        minutes: { min: 0, max: 59, step: 1 },
    };
    return (React.createElement(CheckStyled, null,
        React.createElement("div", { className: "guid" }, t("attendances.middleAttachmentsGuid")),
        React.createElement(Datetime, { input: false, value: selectTime, onChange: handleTimeChange, dateFormat: false, initialViewMode: "time", timeFormat: "hh:mm A" // 시간 형식 지정 (24시간 형식)
            , timeConstraints: timeConstraints }),
        React.createElement("div", { className: "btn_bundle" },
            React.createElement(ClickButton, { onClick: () => handlerClick('init'), label: t("attendances.previous"), isText: true }),
            React.createElement(ClickButton, { onClick: () => onSave(MIDDLE_ATTENDANCES, selectTime), label: t("attendances.okay"), isText: true }))));
});
export default ChoiceTimeSet;
