import i18next from "i18next";
import { toggleLoad } from "../../features/base/loading";

export default {
    alertMessage : (message, props) => {
        message && window.alert( i18next.t(message, props) );
    }, 
    confirmMessage: (message) => {
        if (message) {
            return confirm( i18next.t(message) );
        } 
        
        return false;
    }
}