//@ts-ignore
import { LEAVE_ROOM, SET_ACTIVE_GROUP_ID, SET_ROOM_JOINED, SET_ROOM_OPTION, SET_ROOM_PERMISSIONS, } from './actionTypes';
/**
 * 방 정보 세팅 ( 옵션, 비밀번호, 미디어서버, 회의실 오픈 시간 )
 * @param {RoomData} data
 * @returns
 */
export function setRoomOption(data) {
    typeof APP === 'object' &&
        APP.API.setMeetingUuid(data.option?.uuid || null);
    return {
        type: SET_ROOM_OPTION,
        option: data.option,
        password_checking: data?.password_checking,
        media_server_info: data?.media_server_info,
        start_time: data?.start_time,
    };
}
/**
 * 회의실 입장 여부
 * @param {boolean} joined
 * @returns
 */
export function setRoomJoined(joined) {
    return {
        type: SET_ROOM_JOINED,
        joined,
    };
}
/**
 * 회의실 퇴장
 * @returns
 */
export function leaveRoom() {
    return {
        type: LEAVE_ROOM,
    };
}
/**
 * 현재 입장되어있는 그룹 아이디
 * @param {string} group
 * @returns
 */
export function setActiveGroupId(group_id) {
    return {
        type: SET_ACTIVE_GROUP_ID,
        group_id,
    };
}
/**
 * 현재 룸에 대한 권한,
 * @param {string[]} permissions
 * @returns
 */
export function setRoomPermissions(permissions) {
    return {
        type: SET_ROOM_PERMISSIONS,
        permissions,
    };
}
