import React, { useEffect, useMemo, useState } from "react";
// @ts-ignore
import { Dialog } from "../dialog";
import { PERMISSION } from "../members";
import { ClickButton } from "../ui";
import SurveyIFrame from "./SurveyIFrame";
import SurveyListContainer from "./SurveyListContainer";
import { SURVEY_MENU_TYPE } from "./constant";
// @ts-ignore
import Survey from "./Survey";
const SurveyContainer = React.memo(({ permissions, survey_id }) => {
    const [surveyType, setSurveyType] = useState(SURVEY_MENU_TYPE.LIST);
    const [surveyItem, setSurveyItem] = useState();
    const isPollPersmission = useMemo(() => permissions.indexOf(PERMISSION.POLL) !== -1 ? true : false, [permissions]);
    const handlerUpdateMenu = (data) => {
        if (data?.uuid)
            setSurveyItem(data.uuid);
        if (data.type)
            setSurveyType(data.type);
    };
    useEffect(() => {
        if (survey_id && !isPollPersmission) {
            setSurveyItem(survey_id);
            setSurveyType(SURVEY_MENU_TYPE.VOTE);
        }
        APP.eventManager.subscribe('UPDATE_SURVER_MENU', handlerUpdateMenu);
        return () => {
            APP.eventManager.unsubscribe('UPDATE_SURVER_MENU', handlerUpdateMenu);
        };
    }, []);
    const renderHead = useMemo(() => {
        switch (surveyType) {
            case SURVEY_MENU_TYPE.LIST:
                return isPollPersmission && React.createElement(ClickButton, { isText: true, label: '설문 등록', onClick: () => setSurveyType(SURVEY_MENU_TYPE.ADD) });
            case SURVEY_MENU_TYPE.VOTE:
            case SURVEY_MENU_TYPE.EDIT:
            case SURVEY_MENU_TYPE.ADD:
                return React.createElement(ClickButton, { isText: true, label: '이전', onClick: () => setSurveyType(SURVEY_MENU_TYPE.LIST) });
        }
    }, [surveyType]);
    const renderContent = useMemo(() => {
        switch (surveyType) {
            case SURVEY_MENU_TYPE.LIST:
                return React.createElement(SurveyListContainer, { isPollPersmission: isPollPersmission });
            case SURVEY_MENU_TYPE.VOTE:
            case SURVEY_MENU_TYPE.EDIT:
            case SURVEY_MENU_TYPE.ADD:
                return React.createElement(SurveyIFrame, { type: surveyType, surveyItem: surveyItem, setSurveyType: setSurveyType });
        }
    }, [surveyType, surveyItem]);
    return (React.createElement(Dialog, { titleKey: "\uC124\uBB38 \uC870\uC0AC", width: 800, style: { height: "80%", display: "flex", flexDirection: "column" } },
        React.createElement(Survey, { renderHead: renderHead, renderContent: renderContent })));
});
export default SurveyContainer;
