import React, { useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { isSideBarOpend } from "../base/sidebar";
import { VOD_MODE, WHITE_MODE } from "../share-layout/constants";
import NoDocument from "./NoDocument";
import CanvasDrawLayout from "./components/web/CanvasDrawLayout";
import CanvasTool from "./components/web/CanvasTool";
import { CanvasLayoutStyled } from "./components/web/styles";
import { PEN } from "./constants";
import { getFitSize } from "./functions";
import { getPropertyValue } from "../base/settings";
import ControlBarConatiner from "./ControlBarContainer";
const CanvasLayoutContainter = React.memo(({ isMobile = false, expand, isLeft, focusIndex, documentItem, isVod, renderShareLeft }) => {
    const fullCanvasRef = useRef();
    const option = useSelector((state) => state['features/canvas'].option, shallowEqual);
    const tool = useSelector((state) => state['features/canvas'].tool, shallowEqual);
    const sidebar = useSelector((state) => isSideBarOpend(state), shallowEqual);
    const openSide = useSelector((state) => getPropertyValue(state, 'dualSideOpen'));
    const isWidthFit = useMemo(() => option.isWidthFit, [option.isWidthFit]);
    const scale = useMemo(() => option.scale, [option.scale]);
    // 사용자가 현재 사용하는 도구 
    const [focusTool, setFocusTool] = useState(PEN);
    const [drawCanvas, setDrawCanvas] = useState({ width: 0, height: 0 });
    const [fullCanvas, setFullCanvas] = useState({ width: 0, height: 0 });
    const isDocumentVod = useMemo(() => documentItem?.config === VOD_MODE ? true : false, [documentItem?.config]);
    const updateCanvasStyle = () => {
        const element = fullCanvasRef?.current;
        if (element) {
            const { width, height } = element.getBoundingClientRect();
            setFullCanvas({ width, height: isVod ? height - 55 : height });
        }
    };
    useEffect(() => {
        updateCanvasStyle();
    }, []);
    useEffect(() => {
        window.addEventListener("resize", updateCanvasStyle);
        return () => {
            window.removeEventListener("resize", updateCanvasStyle);
        };
    }, [isVod]);
    useEffect(() => {
        updateCanvasStyle();
    }, [isVod, expand, isLeft, openSide, sidebar]);
    useEffect(() => {
        if (!documentItem)
            return;
        // 화이트 보드일 경우 캔버스 스타일 16:9 비율 고정
        if (documentItem?.config === WHITE_MODE) {
            const canvasStyle = getFitSize(fullCanvas, { width: 16, height: 9 }, isWidthFit);
            setDrawCanvas(canvasStyle);
        }
    }, [isVod, documentItem?.config, fullCanvas, isWidthFit]);
    const renderTool = useMemo(() => {
        return React.createElement(CanvasTool, { isMobile: isMobile || fullCanvas.height < 664, tool: tool, option: option, isVod: isVod, uuid: documentItem?.uuid, index: focusIndex, focusTool: focusTool, setFocusTool: setFocusTool });
    }, [isVod, isDocumentVod, option, tool, focusTool, isMobile, fullCanvas.height, documentItem?.uuid, focusIndex]);
    return (React.createElement(React.Fragment, null,
        renderShareLeft(renderTool),
        React.createElement(CanvasLayoutStyled, { ref: fullCanvasRef, isVod: isDocumentVod },
            documentItem && documentItem.config !== VOD_MODE && React.createElement(ControlBarConatiner, { uuid: documentItem.uuid, mode: documentItem.config, scale: scale, isWidthFit: isWidthFit, page: focusIndex, maxPage: documentItem?.list?.length || 1 }),
            !documentItem && React.createElement(NoDocument, { isVod: isVod, focusIndex: focusIndex }),
            documentItem && React.createElement(CanvasDrawLayout, { isVod: isVod, option: option, tool: tool, focusIndex: focusIndex, documentItem: documentItem, fullCanvas: fullCanvas, drawCanvas: drawCanvas, setDrawCanvas: setDrawCanvas, setFocusTool: setFocusTool }))));
});
export default CanvasLayoutContainter;
