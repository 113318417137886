import { getLocalIDMember } from "../base/members";
import { MiddlewareRegistry } from "../base/redux";
import { SET_FOCUS_SHARE_ITEM } from "../share-layout/actionTypes";
import { DELETE_CANVAS_INDEX, DELETE_CANVAS_KEY, SET_CANVAS_LINE_DRAW } from "./actionTypes";
import { setCanvasOption, updateCanvasDraw } from "./actions";
import { checkCanvasPermission, getPDFDrawByIDAndIndex } from "./functions";
MiddlewareRegistry.register(store => next => action => {
    const { dispatch, getState } = store;
    switch (action.type) {
        case DELETE_CANVAS_INDEX:
            if (!action.isRemote) {
                if (checkCanvasPermission(getState, 'share')) {
                    APP.management.deleteShareCanvasIndex(action.uuid, action.index);
                }
                else
                    return;
            }
            break;
        case SET_CANVAS_LINE_DRAW:
            if (!action.isRemote) {
                // if (checkCanvasPermission(getState, 'share') ) {      
                APP.management.sendSharePoints(action.uuid, action.index, action.key, action.property);
                // } else return;
            }
            const { line, item } = getPDFDrawByIDAndIndex(getState, action.uuid, action.index);
            line.set(action.index, item.set(action.key, action.property));
            dispatch(updateCanvasDraw(action.uuid, line));
            break;
        case DELETE_CANVAS_KEY:
            if (!action.isRemote) {
                const local_uuid = getLocalIDMember(getState);
                if (checkCanvasPermission(getState, 'share') || action.key.includes(local_uuid)) {
                    APP.management.deleteShareLine(action.uuid, action.index, action.key, false);
                }
                else
                    return;
            }
            break;
        case SET_FOCUS_SHARE_ITEM:
            dispatch(setCanvasOption({ color: '#000', scale: 100, isWidthFit: undefined, thickness: 2, opacity: 10 }));
            break;
    }
    return next(action);
});
