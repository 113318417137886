import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setPage } from "../actions";
import { getPage } from "../functions";
import { LayoutPage } from "./layout";
function PageButton({ type, show, renderButton, setPrev, setNext, vertical, maxStyle }) {
    const dispatch = useDispatch();
    const { page, maxPage } = useSelector((state) => getPage(state), shallowEqual);
    useEffect(() => {
        if (page > 1)
            setPrev(true);
        else
            setPrev(false);
        if (maxPage > page)
            setNext(true);
        else
            setNext(false);
    }, [page, maxPage]);
    const prevPage = () => {
        dispatch(setPage(page - 1));
    };
    const nextPage = () => {
        dispatch(setPage(page + 1));
    };
    const onClick = type === 'next' ? nextPage : prevPage;
    if (!show)
        return null;
    return (renderButton ? renderButton(type, onClick) :
        React.createElement(LayoutPage, { vertical: vertical, maxStyle: maxStyle, buttonType: type, onClick: onClick }));
}
export default React.memo(PageButton);
