import { TaskQueue } from './TaskQueue';

/**
 * 지연된 개체를 만듭니다.
 */
export function createDeferred() {
    const deferred = {};

    deferred.promise = new Promise((resolve, reject) => {
        deferred.resolve = resolve;
        deferred.reject = reject;
    });

    return deferred;
}

export function createTaskQueue() {
    return new TaskQueue();
}
