import styled, { css } from "styled-components";
// @ts-ignore
export const AddVodStyled = styled.div `
    display: flex;
    gap: 8px;
    
    > * {
        margin: 0;
    }
`;
// @ts-ignore
export const VodUrlStyled = styled.div `
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .vod_input {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
        gap: 8px;

        .label {
            margin: 0 1.2rem;
            color: #161C1F;

            font-family: Pretendard;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: -0.4px;
        }
        input {
            border: none;
            box-sizing: border-box;
            flex: 2;
            border-radius: 8px;
            background: #F7F8FC;
        }
    }

    ${(props) => props.isMobile ? css `
        margin: 0;
        padding: 0 30px;
        box-sizing: border-box;
        .vod_input {
            flex-direction: column;
            align-items: flex-start;

            input { width: 100%; padding: 12px; }
            .label { margin: 0!important; font-size: 16px!important; }

            > * {
                margin: 0;
            }
        }
    ` : css `
        margin: 8px 0;

        .vod_input {
            input { padding: 16px; }
        }

    `}
`;
// @ts-ignore
export const ControllerStyled = styled.div `
    width: 100%;
    height: 55px;
    display: flex;
    flex-direction: column;

    video {
        background: #000;
    }

    /* Play/Pause Button */
    .hover_wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .hover_wrap .overText {
        color: white;
        font-size: 20px;
        font-weight: bold;
    }

    .hover_wrap.play::before {
        content: "\f144";
        font-family: "Font Awesome 5 Free";
    }

    .hover_wrap.pause::before {
        content: "\f28b";
        font-family: "Font Awesome 5 Free";
    }

    .control_box {
        flex: 1;
        position: relative;
        display: flex;
        gap: 12px;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding: 0 10px;

        > * {
            margin: 0;
        }
    }

    /* Progress Bar Container */
    .progress_bar {
        width: 100%;
        position: relative;
        height: 5px;
        background-color: #ccc;
        border-radius: 2.5px;
        overflow: hidden;

        /* Progress Bar */
        .progress {
            height: 100%;
            background-color: ${props => APP.config.style.focusColor};
            border-radius: 2.5px;
            margin: 0;
        }

        /* Seek Bar */
        input[type="range"] {
            width: 100%;
            height: inherit;
            appearance: none;
            background-color: transparent;
            outline: none;        
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 1;
            margin-top: -2.5px;
        }
    
        input[type="range"]::-webkit-slider-thumb {
            appearance: none;
            width: 10px;
            height: 10px;
            background-color: ${props => APP.config.style.focusColor};
            border-radius: 50%;
            cursor: pointer;
        }
    
        input[type="range"]::-moz-range-thumb {
            width: 10px;
            height: 10px;
            background-color: ${props => APP.config.style.focusColor};
            border-radius: 50%;
            cursor: pointer;
        }
    }

   

    /* Parent Container */
    .volume {
    position: relative;
    }

    /* Volume Slider Container */
    .volume_wrap {
        border-radius: 4px;
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        .volume_slider {
            display: none;
            transform: translateY(-100%);
            transition: transform 0.2s ease;
        }

        &:hover .volume_slider {
            display: block;
            transform: translateY(0);
        }
    }


    /* Volume Slider Container */
    .volume_slider {
        position: relative;
        width: 100px;

        /* Seek Bar */
        input[type="range"] {
            width: 100%;
            height: inherit;
            appearance: none;
            background-color: #d5d5d5;
            outline: none;        
            cursor: pointer;
            border-radius: 30px;
            z-index: 1;
            margin-top: -2.5px;
        }
    
        input[type="range"]::-webkit-slider-thumb {
            appearance: none;
            width: 10px;
            height: 10px;
            background-color: ${props => APP.config.style.focusColor};
            border-radius: 50%;
            cursor: pointer;
        }
    
        input[type="range"]::-moz-range-thumb {
            width: 10px;
            height: 10px;
            background-color: ${props => APP.config.style.focusColor};
            border-radius: 50%;
            cursor: pointer;
        }
    }    
`;
// @ts-ignore
export const VodListStyled = styled.div `
    position: relative;
    z-index: 1;
    .head_wrap {
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        &.room_dialog {
            justify-content: flex-end!important;
            
            .search_bar {
                width: 100%!important;
                max-width: 100%!important;
            } 
        }

        > * { margin: 0; }

        .test { 
            display: flex;
            gap: 18px;
        }

        .add_room_btn {
            font-size: 18px; 
            padding: 12px 20px;
            &:hover {
                background: #29AAE2!important;
                color: #FFF!important;
                svg {
                    color: #FFF!important;
                    fill: #FFF!important;
                    background: #29AAE2!important;
                }
            }
        }
    }

    .wrap_list {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }   

    .btn {
        > * { margin: 0; }
    }

    ${(props) => props.isMobile ? `
        
        .test {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-content: center;
            margin: 0;
            gap: 10px;
            width: 100%;

            > *, .btn {
                width: 100%;
            }
        }
    ` : `
        .test > * {
            margin: 0;
        }
    `}

`;
