import { hideDialog } from "../base/dialog";
import { MiddlewareRegistry } from "../base/redux";
import { SET_ROOM_JOINED } from "./actionTypes";
import { isRoomJoined } from "./functions";
MiddlewareRegistry.register(store => next => async (action) => {
    switch (action.type) {
        case SET_ROOM_JOINED:
            if (action.joined === false) {
                const prevJoined = isRoomJoined(store.getState);
                if (prevJoined) {
                    store.dispatch(hideDialog());
                }
            }
            break;
    }
    return next(action);
});
