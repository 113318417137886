import JitsiMeetJS from '../lib-jitsi-meet';
import { getUserSelectedOutputDeviceId, updateSettings } from '../settings';
import { ADD_PENDING_DEVICE_REQUEST, DEVICE_PERMISSIONS_CHANGED, SET_AUDIO_INPUT_DEVICE, SET_VIDEO_INPUT_DEVICE, UPDATE_DEVICE_LIST, } from './actionTypes';
import { areDeviceLabelsInitialized, getDeviceIdByLabel, getDeviceLabelById } from './functions';
// 초기
const DEVICE_TYPE_TO_SETTINGS_KEYS = {
    audioInput: {
        currentDeviceId: 'micDeviceId',
        userSelectedDeviceId: 'userSelectedMicDeviceId',
        userSelectedDeviceLabel: 'userSelectedMicDeviceLabel',
    },
    audioOutput: {
        currentDeviceId: 'audioOutputDeviceId',
        userSelectedDeviceId: 'userSelectedAudioOutputDeviceId',
        userSelectedDeviceLabel: 'userSelectedAudioOutputDeviceLabel',
    },
    videoInput: {
        currentDeviceId: 'audioOutputDeviceId',
        userSelectedDeviceId: 'userSelectedCameraDeviceId',
        userSelectedDeviceLabel: 'userSelectedCameraDeviceLabel',
    },
};
/**
 * Signals that the device permissions have changed.
 *
 * @param {Object} permissions - Object with the permissions.
 * @returns {{
 *      type: DEVICE_PERMISSIONS_CHANGED,
 *      permissions: Object
 * }}
 */
export function devicePermissionsChanged(permissions) {
    return {
        type: DEVICE_PERMISSIONS_CHANGED,
        permissions,
    };
}
/**
 * 보류 중인 장치 요청을 추가합니다.
 *
 * @param {Object} request - The request to be added.
 * @returns {{
 *      type: ADD_PENDING_DEVICE_REQUEST,
 *      request: Object
 * }}
 */
export function addPendingDeviceRequest(request) {
    return {
        type: ADD_PENDING_DEVICE_REQUEST,
        request,
    };
}
/**
 * 회의가 시작되기 전에 초기 A/V 장치를 구성합니다.
 * @returns {Function}
 */
export function configureInitialDevices() {
    return (dispatch, getState) => {
        const deviceLabels = {};
        let updateSettingsPromise;
        if (deviceLabels) {
            updateSettingsPromise = dispatch(getAvailableDevices()).then(() => {
                if (!areDeviceLabelsInitialized(getState)) {
                    Object.keys(deviceLabels).forEach(key => {
                        dispatch(addPendingDeviceRequest({
                            type: 'devices',
                            name: 'setDevice',
                            device: {
                                kind: key.toLowerCase(),
                                label: deviceLabels[key],
                            },
                            // eslint-disable-next-line no-empty-function
                            responseCallback() { },
                        }));
                    });
                }
                else {
                    const newSettings = {};
                    Object.keys(deviceLabels).forEach(key => {
                        const label = deviceLabels[key];
                        const deviceId = getDeviceIdByLabel(getState, label, key);
                        if (deviceId) {
                            const settingsTranslationMap = DEVICE_TYPE_TO_SETTINGS_KEYS[key];
                            newSettings[settingsTranslationMap.currentDeviceId] = deviceId;
                            newSettings[settingsTranslationMap.userSelectedDeviceId] = deviceId;
                            newSettings[settingsTranslationMap.userSelectedDeviceLabel] = label;
                        }
                    });
                    dispatch(updateSettings(newSettings));
                }
            });
        }
        else {
            updateSettingsPromise = Promise.resolve();
        }
        updateSettingsPromise.then(() => {
            const userSelectedAudioOutputDeviceId = getUserSelectedOutputDeviceId(getState);
            // return setAudioOutputDeviceId(userSelectedAudioOutputDeviceId, dispatch)
            //     .catch(ex => console.warn(`Failed to set audio output device. Default audio output device will be used instead ${ex}`));
        });
    };
}
/**
 * 연결된 A/V 입력 및 출력 장치를 쿼리하고 알려진 장치의 redux 상태를 업데이트합니다.
 *
 * @returns {Function}
 */
export function getAvailableDevices() {
    return async (dispatch) => new Promise(resolve => {
        const { mediaDevices } = JitsiMeetJS;
        if (mediaDevices.isDeviceListAvailable() && mediaDevices.isDeviceChangeAvailable()) {
            mediaDevices.enumerateDevices(devices => {
                dispatch(updateDeviceList(devices));
                resolve(devices);
            });
        }
        else {
            resolve([]);
        }
    });
}
/**
 * 알려진 오디오 및 비디오 장치 목록을 업데이트하라는 신호입니다.
 *
 * @param {Array<MediaDeviceInfo>} devices - All known available audio input,
 * audio output, and video input devices.
 * @returns {{
 *      type: UPDATE_DEVICE_LIST,
 *      devices: Array<MediaDeviceInfo>
 * }}
 */
export function updateDeviceList(devices) {
    return {
        type: UPDATE_DEVICE_LIST,
        devices,
    };
}
/**
 *현재 사용 중인 비디오 입력 장치를 업데이트하라는 신호입니다.
 *
 * @param {string} deviceId - The id of the new video input device.
 * @returns {{
 *      type: SET_VIDEO_INPUT_DEVICE,
 *      deviceId: string
 * }}
 */
export function setVideoInputDevice(deviceId) {
    return {
        type: SET_VIDEO_INPUT_DEVICE,
        deviceId,
    };
}
/**
 * 현재 사용 중인 오디오 입력 장치를 업데이트하라는 신호입니다.
 * @param {string} deviceId - The id of the new audio input device.
 * @returns {{
 *      type: SET_AUDIO_INPUT_DEVICE,
 *      deviceId: string
 * }}
 */
export function setAudioInputDevice(deviceId) {
    return {
        type: SET_AUDIO_INPUT_DEVICE,
        deviceId,
    };
}
/**
 * 오디오 입력 장치 ID를 설정하고 세션 간에 유지되도록 설정을 업데이트합니다.
 *
 * @param {string} deviceId - The id of the new audio input device.
 * @returns {Function}
 */
export function setAudioInputDeviceAndUpdateSettings(deviceId) {
    return function (dispatch, getState) {
        const deviceLabel = getDeviceLabelById(getState, deviceId, 'audioInput');
        dispatch(setAudioInputDevice(deviceId));
        dispatch(updateSettings({
            userSelectedMicDeviceId: deviceId,
            userSelectedMicDeviceLabel: deviceLabel,
        }));
    };
}
/**
 * 비디오 입력 장치 ID를 설정하고 세션 간에 유지되도록 설정을 업데이트합니다.
 *
 * @param {string} deviceId - The id of the new video input device.
 * @returns {Function}
 */
export function setVideoInputDeviceAndUpdateSettings(deviceId) {
    return function (dispatch, getState) {
        const deviceLabel = getDeviceLabelById(getState, deviceId, 'videoInput');
        dispatch(setVideoInputDevice(deviceId));
        dispatch(updateSettings({
            userSelectedCameraDeviceId: deviceId,
            userSelectedCameraDeviceLabel: deviceLabel,
        }));
    };
}
