import { ROLE, checkRoleLevel, getLocalMemberRole } from '../base/members';
import { stopTempServerRecord } from '../base/record/actions';
import { MiddlewareRegistry } from '../base/redux';
import { UPDATE_ROOM_POLICY } from '../base/settings/actionTypes';
import { getCurrentMode, setLayoutMode, videoLayoutMode } from '../video-layout';
import { SET_LAYOUT_MODE } from '../video-layout/actionTypes';
import { setShareScreenOption, stopScreen } from './action';
import { SET_SHARE_SCREEN_ID } from './actionTypes';
import { getLocalSharingStatus } from './functions';

MiddlewareRegistry.register(store => next => action => {
	switch (action.type) {
		case SET_LAYOUT_MODE:
			const mode = action.mode;

			if (!action.isRemote) {
				const screen = getLocalSharingStatus(store.getState);
				if (
					getCurrentMode(store.getState) === videoLayoutMode.screen &&
					action.mode !== videoLayoutMode.screen
				) {
					store.dispatch(stopScreen(false));
				}
			}

			break;

		case SET_SHARE_SCREEN_ID:
			if (action.isRemote === false) {
				if (action.jitsi_id) {
					store.dispatch(setLayoutMode(videoLayoutMode.screen, action.isRemote));
				} else {
					store.dispatch(setLayoutMode(videoLayoutMode.grid, action.isRemote, true));
				}
			}

			break;

		case UPDATE_ROOM_POLICY:
			const policy = action.policy;
			if (policy.screen_option) {
				if (policy.screen_option.permission) {
					// 동기화 부여된 경우
					// policy로 업데이트
					store.dispatch(setShareScreenOption(policy.screen_option.type));
				} else {
					// 동기화 부여되지 않은 경우
					// 업데이트 없음.
					store.dispatch(setShareScreenOption(null));
				}
			}
	}

	return next(action);
});
