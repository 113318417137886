import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalID } from "../local";
import { ClickButton, Input } from "../ui";
import { setMemberNick } from "./actions";
const ChangeNickname = React.memo(({ nickname }) => {
    const dispatch = useDispatch();
    const loca_id = useSelector((state) => getLocalID(state));
    const [name, setName] = useState();
    const onSaveNick = async () => {
        if (!name)
            return;
        loca_id && dispatch(setMemberNick(loca_id, name, false));
        APP.eventManager.publish('CHANGE_NICKNAME');
    };
    const renderChangeBtn = useMemo(() => React.createElement(ClickButton, { icon: "nickCompletion", size: 16, color: "#545454", onClick: onSaveNick }), [onSaveNick]);
    return (React.createElement(Input, { className: 'change_nick option_item', defaultValue: nickname, onChange: setName, bottomLabel: renderChangeBtn }));
});
export default ChangeNickname;
