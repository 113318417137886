import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClickButton, OnOffSwitch, Input } from "../ui";
import LayoutCount from "./LayoutCount";
import { useSelector } from "react-redux";
import { ROLE, checkRoleLevel, getLocalMemberRole } from "../members";

function LayoutCountContainer({
    grid_sync, show_grid_count,
    renderSubmitBtn, updateGridClick
}) {
    const { t } = useTranslation();
    const local_role = useSelector(state => getLocalMemberRole(state));

    const [ isShow, setIsShow ] = useState(false);
    const [ checkRole, setCheckRole ] = useState(false);
    const [ count, setCount ] = useState(show_grid_count);

    useEffect(() => {
        setIsShow(grid_sync === 'none' ? false : true);
    }, [grid_sync]);

    useEffect(() => {
        setCount(show_grid_count);
    }, [show_grid_count]);

    useEffect(() => {
        setCheckRole(checkRoleLevel(local_role, ROLE.MANAGER));
    }, [local_role]);

    /**
     * 그리드 카운트 동기화 설정
     * @returns 
     */
    const renderSyncType = () => {
        const label = isShow ? 'ON' : 'OFF';
        if (!checkRole) {
            return (
                <ClickButton
                    className="isFocus disable"
                    isText={true}
                    label={label}
                />
            )
        }
        return (
            <OnOffSwitch
                label={label}
                isShow={isShow}
                handlerClick={setIsShow}
            />
        );
    };

    /**
     * 그리드 사용자 수 설정
     * @returns 
     */
    const renderGridCount = () => {
        if (isShow && !checkRole) {
            return (
                <ClickButton
                    className="isFocus disable"
                    isText={true}
                    label={count}
                />
            )
        }

        return (
            <Input className="grid_count_input" type="number" value={count} onChange={setCount} />
        )
    }

    const onClick = () => {
        updateGridClick(isShow ? 'sync' : 'none', count);
    }

    return (
        <>
            <LayoutCount renderSyncType={renderSyncType} 
                renderGridCount={renderGridCount}
            />
            { renderSubmitBtn(onClick) }
        </>
    )
}

export default React.memo(LayoutCountContainer);