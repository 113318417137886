import { isCheckPermission } from '../../room/functions';
import { getDisplayName } from '../settings/functions';
import { ADD_LOCAL_MEMBER, ADD_MEMBER, ADD_MEMBER_LIST, ADD_PINNED, ADD_RECORD, DELETE_MEMBER, DELETE_PINNED, DELETE_RECORD, LEFT_MEMBER, REQUEST_PRESENTER, RESPONSE_PRESENTER, SET_BRIDGE_ID, SET_GROUP_MEMBERS, SET_MEMBER_ROLE, SET_MEMBER_VOICE, UPDATE_MEMBER, UPDATE_MEMBER_NICKNAME, } from './actionType';
import { MEMBER_STATUS, PERMISSION } from './constans';
import { getMemberByUserID, getProcessedMember, getUserIDByMemberId } from './functions';
/**
 * 로컬 사용자 저장
 * @param {object} member
 * @returns
 */
export function addLocalMember(member) {
    return async (dispatch, getState) => {
        const nickname = getDisplayName(getState) || '';
        const localMember = {
            ...member,
            recording: false,
            nickname,
            video_track_id: '',
            audio_track_id: '',
            jitsi_id: '',
            bridge_sessions: null,
            type: '',
            hands_up_time: '',
            local: true,
            existed: true,
            status: MEMBER_STATUS.OCCUPIDE,
            hands_up: false,
        };
        const processedMember = await getProcessedMember(localMember);
        dispatch(addMember(processedMember));
    };
}
/**
 * 로컬 사용자 퇴장
 * @param {stirng} member_uuid
 * @returns
 */
export function deleteMember(member_uuid) {
    return (dispatch, getState) => {
        const user_uuid = getUserIDByMemberId(getState, member_uuid);
        if (!user_uuid)
            return;
        const member = getMemberByUserID(getState, user_uuid);
        if (!member)
            return;
        dispatch({
            type: DELETE_MEMBER,
            member,
        });
    };
}
/**
 * 사용자만 VACATED로 변경
 * @param {} member
 * @returns
 */
export function removeMember(member) {
    return {
        type: LEFT_MEMBER,
        member,
    };
}
/**
 * 한번에 모든 사용자 저장
 * @param members
 * @param member_uuid
 * @returns
 */
export function addMembers(members, member_uuid) {
    return {
        type: ADD_MEMBER_LIST,
        members,
        member_uuid,
    };
}
/**
 * (MEMBER) 사용자 손들기
 * @param user_uuid
 * @param hands_up
 * @param isRemote
 * @returns
 */
export function setHandlerMember(user_uuid, handler, isRemote = false) {
    return async (dispatch, getState) => {
        const member = getMemberByUserID(getState, user_uuid);
        if (!member)
            return;
        const cHandler = handler === null ? !member.hands_up : handler;
        if (!isRemote) {
            const response = await APP.management.raiseHand(user_uuid, cHandler);
            if (response.status !== 200) {
                console.log('error');
                return;
            }
        }
        const { hands_up: hands_up_type, hands_up_time } = getState()['features/base/settings'].policy;
        if (hands_up_type === 'fadeout') {
            // 일시적인 손들기
            if (!isRemote && member.local && cHandler === true) {
                setTimeout(() => {
                    dispatch(setHandlerMember(user_uuid, false));
                }, hands_up_time || 1000);
            }
        }
        dispatch({
            type: UPDATE_MEMBER,
            member: {
                ...member,
                hands_up: cHandler,
            },
            change_hands: true,
        });
    };
}
/**
 * 사용자 저장
 * @param member
 * @returns
 */
export function addMember(member) {
    return {
        type: member.local ? ADD_LOCAL_MEMBER : ADD_MEMBER,
        member,
    };
}
/**
 * 사용자 pin 설정 추가
 * @param {string} user_uuid
 * @param {boolean} pinned
 * @returns
 */
export function setMemberPinned(user_uuid, pinned, isRemote = false) {
    return {
        type: pinned ? ADD_PINNED : DELETE_PINNED,
        user_uuid,
        pinned,
        isRemote,
    };
}
/**
 * 권한 변경 요청
 * @param {stirng} user_uuid
 * @param {stirng} role
 * @param {boolean} isRemote
 * @param {boolean} isGroup
 * @returns
 */
export function setMemberRole(user_uuid, role, isRemote = false, isGroup = false) {
    return {
        type: SET_MEMBER_ROLE,
        user_uuid,
        role,
        isRemote,
        isGroup,
    };
}
/**
 * 사용자 닉네임 수정
 * @param {string} user_uuid
 * @param {boolean} record
 * @param {boolean} isRemote
 * @returns
 */
export function setMemberNick(user_uuid, nickname, isRemote = false) {
    return {
        type: UPDATE_MEMBER_NICKNAME,
        user_uuid,
        nickname,
        isRemote,
    };
}
/**
 * 녹화 사용자
 * @param {string} user_uuid
 * @param {boolean} record
 * @param {boolean} isRemote
 * @returns
 */
export function setMemberRecord(user_uuid, record, isRemote = false) {
    return {
        type: record ? ADD_RECORD : DELETE_RECORD,
        user_uuid,
        isRemote,
    };
}
/**
 * 사용자 강제 퇴장
 * @param {string} user_uuid
 * @param {boolean} isRemote
 * @returns
 */
export function kickMemeber(user_uuid, isRemote = false) {
    return async (dispatch, getState) => {
        if (!isRemote) {
            if (isCheckPermission(getState, PERMISSION.KICK)) {
                const response = await APP.mateManagement.kickMemeber(user_uuid);
                if (response.status !== 200) {
                    console.log('error');
                    return;
                }
            }
            else
                return;
        }
        const member = getMemberByUserID(getState, user_uuid);
        if (member) {
            dispatch({
                type: UPDATE_MEMBER,
                member: {
                    ...member,
                    status: MEMBER_STATUS.VACATED,
                },
            });
        }
    };
}
/**
 * 사용자 정보 업데이트
 * @param {object} member
 * @returns
 */
export function updateMember(member) {
    return {
        type: UPDATE_MEMBER,
        member,
    };
}
/**
 * 사용자 브릿지 아이디
 * @param {string} user_uuid
 * @param {string} bridge_session_id
 * @returns
 */
export function setBridgeId(user_uuid, bridge_sessions) {
    return {
        type: SET_BRIDGE_ID,
        user_uuid,
        bridge_sessions,
    };
}
/**
 * 사용자 그룹 할당 or 할당 해제
 * @param {string} group_id
 * @param {GroupMember[] | null} members
 * @param {string} assign_type { insert, delete, all }
 * @returns
 */
export function setGroupMember(group_id, members, assign_type) {
    return {
        type: SET_GROUP_MEMBERS,
        group_id,
        members,
        assign_type,
    };
}
/**
 * 보이스 사용자 업데이트
 * @param {string} jitsi_id
 * @returns
 */
export function setMemberVoice(jitsi_id) {
    return (dispatch, getState) => {
        const bridgeList = getState()['features/base/members'].bridgeList;
        const focusVoice = [...bridgeList.entries()].find(([user_uuid, bridge_data]) => bridge_data?.id === jitsi_id);
        if (focusVoice) {
            dispatch({
                type: SET_MEMBER_VOICE,
                user_uuid: focusVoice[0],
            });
        }
        else {
            dispatch({
                type: SET_MEMBER_VOICE,
                user_uuid: '',
            });
        }
    };
}
/**
 * 발표자 요청
 * @param {boolean} isLocal
 * @param {string} role
 * @param {member_uuid} member_uuid
 * @returns
 */
export function requestPresentation(isLocal, member_uuid) {
    return {
        type: REQUEST_PRESENTER,
        isLocal,
        member_uuid,
    };
}
/**
 * 발표자 응답
 * @param {string} user_uuid
 * @param {boolean} accepted
 * @param {boolean} isRemote
 * @returns
 */
export function responsePresentation(user_uuid, accepted = false, isRemote = true) {
    return {
        type: RESPONSE_PRESENTER,
        user_uuid,
        accepted,
        isRemote,
    };
}
