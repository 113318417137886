import { throttle } from "lodash";
import { ASSIGN_GROUP, DELETE_GROUP, INIT_GROUP, MODIFY_GROUP, REMOTE_ALL_GROUP, REMOTE_GROUP, SET_ALL_GROUP_LIST, SET_GROUP_ITEM, TOGGLE_OPEN_GROUP, UPDATE_ERROR_IN_GROUP, UPDATE_GROUP_STATUS } from "./actionTypes";
/**
 * 그룹 전체 가져오기
 * @returns
 */
export function handlerGroupUpdate() {
    return (dispatch) => {
        dispatch(setGroupAllListThrottled);
    };
}
const setGroupAllListThrottled = throttle(setGroupAllList(), 1000, { leading: false, trailing: true });
export function setGroupAllList() {
    return async (dispatch) => {
        const response = await APP.API.getGroupList();
        if (!response.complete) {
            /**
             * Fix me 에러 처리 필요
             */
            return;
        }
        dispatch({
            type: SET_ALL_GROUP_LIST,
            list: response.data
        });
    };
}
/**
 * 현재 채팅 관리자 열기, 닫기
 * @param {boolean} isOpen
 * @returns
 */
export function toggleOpenGroups(isOpen) {
    return {
        type: TOGGLE_OPEN_GROUP,
        isOpen
    };
}
/**
 * 그룹 세팅
 * @param {GroupList} group
 * @returns
 */
export function setGroup(group) {
    return {
        type: SET_GROUP_ITEM,
        group,
        uuid: group.uuid
    };
}
/**
 * 그룹 삭제
 * @param {string} uuid
 * @param {boolean} isRemote
 * @returns
 */
export function deleteGroup(uuid, isRemote = true) {
    return {
        type: DELETE_GROUP,
        uuid,
        isRemote
    };
}
/**
 * 그룹 수정
 * @param {string} uuid: 그룹 아이디
 * @param {object} data: 수정할 이름
 * @param {boolean} isRemote: 자의 or 타의 (자의에 의한 액션일 경우 API업데이트 / 타의에 의한 액션일 경우 그냥 업데이트 )
 * @returns
 */
export function modifyGroup(uuid, data, isRemote = true) {
    return {
        type: MODIFY_GROUP,
        uuid,
        data,
        isRemote
    };
}
/**
 * 그룹 할당
 * @param {string} uuid: 그룹 아이디
 * @param {AssignGroup[]} data: 할당된 사용자 정보
 * @param {string} assgin_type: 할당 유형 (unset: 할당 해제, reset: 할당)
 */
export function assignGroup(uuid, data, assgin_type, isRemote = true) {
    return {
        type: ASSIGN_GROUP,
        uuid,
        data,
        assgin_type,
        isRemote
    };
}
/**
 * 그룹 상태 원격 조정
 * @param {boolean} isStart: 시작 여부
 * @param {string} group_id: 그룹 아이디
 * @returns
 */
export function remoteGroup(isStart, group_id, groups) {
    return {
        type: REMOTE_GROUP,
        isStart,
        group_id,
        groups
    };
}
/**
 * 전체 그룹 업데이트
 * @param {boolean} isStart: 시작 여부
 * @returns
 */
export function updateGroupStaus(isStart) {
    return {
        type: UPDATE_GROUP_STATUS,
        isStart
    };
}
/**
 * 그룹에 알림메시지 전달
 * @param {string} message: 전달할 메시지
 * @param {string} group_id: 그룹 아이디
 */
export function notifyGroupMessage(message, group_id) {
    return async (dispatch) => {
        const response = await APP.management.notifyGroupMessage(message, group_id);
        if (response.status !== 200) {
            dispatch(updateErrorInGroup(response.data));
            return;
        }
    };
}
/**
 * 에러 메시지
 * @param {string|null} error
 * @returns
 */
export function updateErrorInGroup(error) {
    return {
        type: UPDATE_ERROR_IN_GROUP,
        error
    };
}
/**
 * (GROUP) 그룹 초기화
 */
export function initGroup(group_list, member_list, start_group) {
    return {
        type: INIT_GROUP,
        group_list, member_list,
        start_group
    };
}
/**
 * (GROUP) 전체 시작 및 종료
 */
export function remoteAllGroup(data, isStart) {
    return {
        type: REMOTE_ALL_GROUP,
        data,
        isStart
    };
}
