import React from "react";
import { useSelector } from "react-redux";
import { Dialog } from "../dialog";
import { translate } from "../i18n";
import { connect } from "../redux";
import { RecordMemberItem, RecordMemberList } from "./components";
import { getMemberByUserID, getMemberRecordList } from "./functions";

function RecordMemberListContainer({
    t, members
}) {    
    if (!members) return null;
    return (
        <Dialog titleKey={t("record.list")} width="450">
            <RecordMemberList t={t}>
                { members.map(user_uuid => <RecordMemberItemContainer t={t} key={user_uuid} user_uuid={user_uuid} /> ) }
            </RecordMemberList>
        </Dialog>
    );
}

function _mapStateToProps(state) {
    const members = getMemberRecordList(state);
    return {
        members
    }
}
export default translate(connect(_mapStateToProps)(RecordMemberListContainer));


const RecordMemberItemContainer = React.memo(({
    t, user_uuid
}) => {
    const member = useSelector(state => getMemberByUserID(state, user_uuid))

    return (
        <RecordMemberItem t={t} nickname={member.nickname} role={member.role} />
    )
});