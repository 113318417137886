/**
 * 채팅 오픈  
 * {
 *     type: OPEN_CHAT,
 *     option: String|Boolean * 
*  }
 */
export const OPEN_CHAT = 'OPEN_CHAT';

/**
 * 채팅 hide
 * {
 *     type: HIDE_CHAT,
 *     option: String|Boolean * 
 *  }
 */
export const HIDE_CHAT = 'HIDE_CHAT';


/**
 * 참석자 오픈  
 * {
 *     type: OPEN_MEMBER,
 *     option: Boolean 
*  }
 */
export const OPEN_MEMBER = 'OPEN_MEMBER';

/**
 * 참석자 hide
 * {
 *     type: HIDE_MEMBER,
 *     option: Boolean
 *  }
 */
export const HIDE_MEMBER = 'HIDE_MEMBER';