import React from 'react';

function SettingNotification({ t, guid, renderLimitCount, renderTime }) {
	return (
		<div className="screen_table">
			<div className="guid">{guid}</div>
			<div className="screen_item">
				<div className="screen_title">{t('dialog.maxCountNotification')}</div>
				<div className="screen_frame">{renderLimitCount()}</div>
			</div>
			<div className="screen_item">
				<div className="screen_title">{t('dialog.maxTimeNotification')}</div>
				<div className="screen_frame">{renderTime()}</div>
			</div>
		</div>
	);
}

export default SettingNotification;
