import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { CommonNoteLayout } from "../../base/common-note";
import { DocumentToolContainer, ShareDocumentLayout } from "../../share-layout";
import { videoLayoutMode } from "../constants";
import { getExpand } from "../functions";
const ShareModeLayout = React.memo(({ mode, isMobile, isLeft, setIsLeft }) => {
    const expand = useSelector((state) => getExpand(state), shallowEqual);
    const renderShareLeft = (children) => {
        return (React.createElement(DocumentToolContainer, { isLeft: isLeft, setIsLeft: setIsLeft }, children));
    };
    const renderShareModeScreen = useMemo(() => {
        switch (mode) {
            case videoLayoutMode.document:
            case videoLayoutMode.white:
                return (React.createElement(ShareDocumentLayout, { mode: mode, isMobile: isMobile, expand: expand, isLeft: isLeft, renderShareLeft: renderShareLeft }));
            case videoLayoutMode.note:
                return (React.createElement(CommonNoteLayout, { style: { flex: 1, width: "100%", height: "100%", border: "none" }, mode: mode, isMobile: isMobile, expand: expand, isLeft: isLeft, renderShareLeft: renderShareLeft }));
            case videoLayoutMode.vod:
                return (React.createElement(ShareDocumentLayout, { mode: mode, isMobile: isMobile, expand: expand, isLeft: isLeft, renderShareLeft: renderShareLeft }));
        }
    }, [mode, isMobile, expand, isLeft]);
    return (renderShareModeScreen);
});
export default ShareModeLayout;
