import { Colors } from '../Colors';
export const DropDownOption = {
    titleIcon: {
        show: false,
        name: 'default',
        size: 30,
        color: Colors.grey,
    },
    itemIcon: {
        show: false,
        name: 'default',
        size: 30,
        color: Colors.w_grey,
        focus: Colors.grey,
    },
    downIcon: {
        show: true,
        name: 'arrow',
        size: 20,
        color: Colors.grey,
    },
    titleText: {
        show: true,
    },
};
