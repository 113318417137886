import i18next from 'i18next';
import React, { Suspense, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { compose, createStore } from 'redux';
import Thunk from 'redux-thunk';
import { DialogContainer } from '../base/dialog';
//@ts-ignore
import { QueryClient, QueryClientProvider } from 'react-query';
import { BaseContainer } from '../base/app';
import { Loading } from '../base/loading';
import { MiddlewareRegistry, ReducerRegistry, StateListenerRegistry } from '../base/redux';
import { GlobalStateProvider } from './GlobalStateProvider';
function _createStore() {
    const reducer = ReducerRegistry.combineReducers({});
    const middleware = MiddlewareRegistry.applyMiddleware(Thunk);
    const composeEnhancers = compose;
    const store = createStore(reducer, composeEnhancers(middleware));
    StateListenerRegistry.subscribe(store);
    if (typeof APP !== 'undefined') {
        APP.store = store;
    }
    return store;
}
let store = null;
// const test = async () => {
//     const { complete, data } = await APP.API.getPublicSetting();
//     if (complete) {
//         const items = data.items;
//         if (items && items.length > 0) {
//             APP.config = items[0].ui;
//         }
//     }
//     document.title = APP.config.title || 'Meetmate';
// };
const AppContainer = React.memo(() => {
    const queryClient = new QueryClient();
    store = _createStore();
    useEffect(() => {
        document.title = APP.config.title || 'Meetmate';
        const meta = document.createElement('meta');
        meta.name = 'google';
        meta.content = 'notranslate';
        document.getElementsByTagName('head')[0].appendChild(meta);
    }, []);
    return (React.createElement(I18nextProvider, { i18n: i18next },
        React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(Provider, { store: store },
                React.createElement(Suspense, { fallback: React.createElement(Loading, { isLoad: true }) },
                    React.createElement(GlobalStateProvider, null,
                        React.createElement(BaseContainer, null),
                        React.createElement(DialogContainer, null),
                        React.createElement(Loading, null)))))));
});
export default AppContainer;
