import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
const ChatNotificationStyled = styled.div `
    padding: 4px 8px; 
    box-sizing: border-box;
    background: #123;
    width: 100%;
    display: flex;
    align-items: center;
    height: ${props => props.hide ? "30px" : "auto"};
    color: #fff;

    .chat_notification {
        flex: 1;
        overflow: hidden;

        ${props => props.hide ? `
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis
        ` : `
            white-space: break-spaces;
            word-break: break-all;
        `}
    }

    .click_button {
        margin: 0 0 auto;
    }
`;
const ChatNotification = React.memo(({ hide, message, renderHideButton }) => {
    const { t } = useTranslation();
    return (React.createElement(ChatNotificationStyled, { hide: hide },
        React.createElement("div", { className: "chat_notification" }, t("dialog.notiMessage", { message })),
        renderHideButton));
});
export default ChatNotification;
