/**
 * 카메라의 마주보는 모드 집합입니다.
 * @enum {string}
 */
export const CAMERA_FACING_MODE = {
    ENVIRONMENT: 'environment',
    USER: 'user'
};
/**
 * 미디어 유형 집합입니다.
 *
 * @enum {string}
 */
export const MEDIA_TYPE = {
    AUDIO: 'audio',
    PRESENTER: 'presenter',
    SCREENSHARE: 'screenshare',
    VIDEO: 'video'
};
/* eslint-disable no-bitwise */
/**
 * 로컬 화면 공유를 음소거/음소거 해제할 수 있는 권한의 유형입니다.
 *
 * @enum {number}
 */
export const SCREENSHARE_MUTISM_AUTHORITY = {
    AUDIO_ONLY: 1 << 0,
    USER: 1 << 2
};
/**
 * 로컬 비디오를 음소거/음소거 해제할 수 있는 권한의 유형입니다.
 *
 * @enum {number}
 */
export const VIDEO_MUTISM_AUTHORITY = {
    AUDIO_ONLY: 1 << 0,
    BACKGROUND: 1 << 1,
    USER: 1 << 2,
    CAR_MODE: 1 << 3,
    SCREEN_SHARE: 1 << 4
};
/* eslint-enable no-bitwise */
/**
 * 비디오 트랙의 유형.
 *
 * @enum {string}
 */
export const VIDEO_TYPE = {
    CAMERA: 'camera',
    DESKTOP: 'desktop'
};
