import styled from 'styled-components';
// @ts-ignore
export const SurveyContainerStyled = styled.div `
    padding: 8px 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;

    > * { margin: 0; }

    .content {
        height: 100%;
        width: 100%;
        overflow: auto;
        
        .survey_item {
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #f2f2f2;
            padding: 4px 0;
            box-sizing: border-box;
            
            .index {
                flex: 1;
                text-align: center;
            }

            .title {
                flex: 2;
                margin: 0; 
            }
            .click_button {
                margin: 0 0 0 auto;
                flex: 0.5;
            }
        }
    }
`;
