import { SHOW_START_ATTENDANCE } from "./actionTypes";
/**
 * (ATTENDANCES) 출석 확인
 * @param param0
 * @returns
 */
export function showStartAttendance({ name, uuid, check_duration }) {
    return {
        type: SHOW_START_ATTENDANCE,
        name,
        uuid,
        check_duration
    };
}
