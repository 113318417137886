import React from "react";
import { useTranslation } from "react-i18next";
import { GridLayoutCountStyled } from "./styles.web";

function LayoutCount({
    renderSyncType, renderGridCount
}) {
    const { t } = useTranslation();

    return (
        <GridLayoutCountStyled className="count_container">
            <div className="guid"> { t("dialog.setLayoutGuid") } </div>
            <div className="screen_table">
                <div className="screen_item">
                    <div className="screen_title">{t("dialog.gridCountSync")}</div>
                    <div className="screen_frame">
                        { renderSyncType() }
                    </div>
                </div>
                <div className="screen_item">
                    <div className="screen_title">{t("dialog.gridCount")}</div>
                    <div className="screen_frame">
                        { renderGridCount() }
                    </div>
                </div>
            </div>
        </GridLayoutCountStyled>
    )
}

export default React.memo(LayoutCount);