import _ from 'lodash';
import { ReducerRegistry } from '../redux';
import { ADD_FILE, ADD_MESSAGE, ADD_MESSAGE_LIST, SET_CHOICE_MEMBER, SET_IS_CHAT_READ } from './actionTypes';
import { LEAVE_ROOM } from '../../room';
const DEFAULT_STATE = {
    messages: new Map(),
    fileList: new Map(),
    choiceMembers: new Map(),
    isRead: true,
};
ReducerRegistry.register('features/base/chat', (state = DEFAULT_STATE, action) => {
    const copyState = _.cloneDeep(state);
    switch (action.type) {
        case ADD_MESSAGE_LIST:
            return {
                ...state,
                messages: action.data,
            };
        case ADD_FILE:
            copyState.fileList.set(action.data.name, action.data);
            return copyState;
        case ADD_MESSAGE:
            copyState.messages.set(action.data.uuid, action.data);
            return copyState;
        case SET_CHOICE_MEMBER:
            return {
                ...state,
                choiceMembers: action.members,
            };
        case SET_IS_CHAT_READ:
            return {
                ...state,
                isRead: action.isRead,
            };
        case LEAVE_ROOM:
            return {
                ...DEFAULT_STATE,
            };
    }
    return state;
});
