import React, { useMemo } from 'react';
import { ButtonOption } from '../option';
// @ts-ignore
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../ui';
export const Button = React.memo(({ className, text, option, tablIndex, styles, onClick }) => {
    const { t } = useTranslation();
    const buttonOption = useMemo(() => _.merge({}, ButtonOption, option), [option]);
    return (React.createElement("button", { className: `button ${className} ${onClick ? 'cursor-pointer' : 'cursor-default'}`, onClick: onClick, tabIndex: tablIndex, style: styles?.buttonWrap },
        buttonOption.buttonBeforeIcon.show && (React.createElement(Icon, { className: buttonOption.buttonBeforeIcon.className, icon: buttonOption.buttonBeforeIcon.name, size: buttonOption.buttonBeforeIcon.size, width: buttonOption.buttonBeforeIcon.width, height: buttonOption.buttonBeforeIcon.height, fill: buttonOption.buttonBeforeIcon.color, stroke: buttonOption.buttonBeforeIcon.stroke, color: buttonOption.buttonBeforeIcon.color })),
        text && React.createElement("p", { className: "overText" }, t(text)),
        buttonOption.buttonAfterIcon.show && (React.createElement(Icon, { className: buttonOption.buttonAfterIcon.className, icon: buttonOption.buttonAfterIcon.name, size: buttonOption.buttonAfterIcon.size, width: buttonOption.buttonBeforeIcon.width, height: buttonOption.buttonBeforeIcon.height, fill: buttonOption.buttonAfterIcon.color, stroke: buttonOption.buttonBeforeIcon.stroke, color: buttonOption.buttonBeforeIcon.color }))));
});
