import React, { forwardRef } from 'react';
export const FileInput = forwardRef((props, ref) => {
    const { id, children, onChange, ...rest } = props;
    const handleChange = (event) => {
        if (onChange) {
            onChange(event);
        }
    };
    return (React.createElement("label", { htmlFor: id, style: { cursor: 'pointer' } },
        React.createElement("input", { id: id, type: "file", style: { display: 'none' }, ref: ref, onChange: handleChange, ...rest }),
        children));
});
