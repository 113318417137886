import React from 'react';

export default function PromptRaiseHand({ t, displayName, renderAccept, renderRefuse }) {
	return (
		<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 12 }}>
			{t('notify.requestPresenterInfo', { displayName })}

			<div style={{ display: 'flex', gap: '12px' }}>
				{renderAccept()}
				{renderRefuse()}
			</div>
		</div>
	);
}
