/**
 * 배경 효과가 활성화되었는지 여부를 나타내는 발송된 redux 액션의 유형입니다.
 *
 * @returns {{
*     type: BACKGROUND_ENABLED,
*     backgroundEffectEnabled: boolean
* }}
*/
export const BACKGROUND_ENABLED = 'BACKGROUND_ENABLED';
/**
 * 가상 배경을 활성화 또는 비활성화하는 작업 유형
 *
 * @returns {{
*     type: SET_VIRTUAL_BACKGROUND,
*     virtualSource: string,
*     blurValue: number,
*     backgroundType: string,
*     selectedThumbnail: string
* }}
*/
export const SET_VIRTUAL_BACKGROUND = 'SET_VIRTUAL_BACKGROUND';
/**
 *
 */
export const SAVE_VIRTUAL_IMG = 'SAVE_VIRTUAL_IMG';
