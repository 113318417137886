import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toggleDialog } from '../dialog';
import { Input } from '../ui';
// @ts-ignore
import { SettingNotification } from './components';
import { useSelector } from 'react-redux';
import { ROLE, checkRoleLevel, getLocalMemberRole } from '../members';
const SettingNotificationContainer = React.memo(({ notification_option, renderSubmitBtn, handlerUpdatePolicy, }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const modifyPermission = useSelector((state) => checkRoleLevel(getLocalMemberRole(state), ROLE.MANAGER));
    const { count: limit, duration: time } = useMemo(() => notification_option, [notification_option]);
    const [maxCount, setMaxCount] = useState(limit);
    const [durationTime, setDurationTime] = useState(time);
    useEffect(() => {
        setMaxCount(limit);
    }, [limit]);
    useEffect(() => {
        setDurationTime(time / 1000);
    }, [time]);
    const handlerClick = () => {
        if (maxCount < 0) {
            APP.UI.alertMessage('dialog.notAllowCount');
            return;
        }
        if (durationTime < 0) {
            APP.UI.alertMessage('dialog.notAllowTime');
            return;
        }
        handlerUpdatePolicy({
            notification_option: {
                ...notification_option,
                count: maxCount * 1,
                duration: durationTime * 1000,
            },
        });
        // @ts-ignore
        dispatch(toggleDialog());
    };
    /**
     * 알림 메시지 수
     * @returns
     */
    const renderLimitCount = () => {
        return (React.createElement(Input, { className: "grid_count_input", type: "number", bottomLabel: t('dialog.per'), value: maxCount, disabled: !modifyPermission, onChange: setMaxCount }));
    };
    /**
     * 알림 메시지 유지 시간
     * @returns
     */
    const renderTime = () => {
        return (React.createElement(Input, { className: "grid_count_input", type: "number", bottomLabel: t('dialog.sec'), disabled: !modifyPermission, value: durationTime, onChange: setDurationTime }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SettingNotification, { t: t, guid: t('dialog.notificationGuid'), renderLimitCount: renderLimitCount, renderTime: renderTime }),
        modifyPermission && renderSubmitBtn(handlerClick)));
});
export default SettingNotificationContainer;
