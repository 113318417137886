import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { AppContainer } from './features/app';
import './features/app/middlewares.web';
import './features/app/reducers.web';
import { API } from './modules/API';
import { default as UI } from './modules/UI/UIHelper.web';
import EventManager from './modules/server/EventManager';
import { default as Management } from './modules/server/index.js';
import './css/all.scss';
import { getConfig } from './config/test';
// import { config } from '/libs/MateConfig';
const config = getConfig();
const management = new Management();
const eventManager = new EventManager();
const window = global;
window.APP = {
    API,
    config: config,
    UI,
    management,
    canvasList: new Map(),
    vodList: [],
    eventManager,
};
const container = document.getElementById('root');
if (container) {
    const root = ReactDOMClient.createRoot(container);
    root.render(React.createElement(AppContainer, null));
}
