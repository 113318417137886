import React, { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ClickButton, DivElement, TextElement } from '../ui';
import { hideDialog } from './actions';
import { DialogStyled } from './components';
const Dialog = React.memo(({ className, titleKey, width, height, isFit, style, hideCancelButton, onCancel, children, }) => {
    const dispatch = useDispatch();
    const containerRef = useRef(null);
    const dialogRef = useRef(null);
    const dialogStyle = useMemo(() => {
        return { width: `${width ? `${width}px` : 'auto'}`, height: `${isFit ? '100%' : `${height}px`}`, ...style };
    }, [width, height, style]);
    const handleHideDialog = () => {
        if (onCancel)
            onCancel();
        else
            dispatch(hideDialog());
    };
    const renderHead = useMemo(() => (React.createElement(DivElement, { className: "dialog_header" },
        React.createElement(TextElement, { className: "title overText", text: titleKey }),
        !hideCancelButton && (React.createElement(ClickButton, { className: "close", icon: "common_del", size: 18, color: "#C7C7C7", onClick: handleHideDialog })))), [titleKey, hideCancelButton, handleHideDialog]);
    return (React.createElement(DialogStyled, { ref: containerRef },
        React.createElement(DivElement, { ref: dialogRef, className: `dialog_wrapper ${className}` },
            React.createElement(DivElement, { className: "dialog_content", style: dialogStyle },
                renderHead,
                React.createElement(DivElement, { className: "dialog_body" }, children)))));
});
export default Dialog;
