import { toState } from "../base/redux";
/**
 * 그룹 상태 가져오기
 * @param {*} stateful
 * @returns
 */
const getGroupState = (stateful) => {
    const state = toState(stateful);
    return state['features/groups'];
};
/**
 * 현재 그룹 오픈 여부
 * @param {} stateful
 * @returns
 */
export function getEnabledGroup(stateful) {
    return getGroupState(stateful).isOpen;
}
/**
 * 그룹 시작 여부
 * @param {*} stateful
 * @returns
 */
export function getStartGroup(stateful) {
    return getGroupState(stateful).isStart;
}
/**
 * 현재 그룹이 속해있는 이름인지 확인
 * @param {string} name
 * @returns
 */
export function isHaveGroupByName(name) {
    const list = getGroupList(APP.store.getState);
    return list.find(item => item.name === name);
}
/**
 * 그룹 내 에러 이벤트 가져오기
 * @param stateful
 * @returns
 */
export function getGroupError(stateful) {
    return getGroupState(stateful).error;
}
/**
 * 그룹 아이디로 그룹 조회
 * @param stateful
 * @param uuid
 * @returns
 */
export function getGroupByUid(stateful, uuid) {
    const list = getGroupState(stateful).list;
    if (uuid) {
        return list.get(uuid);
    }
    return null;
}
/**
 * 시작 그룹 조회
 * @param stateful
 * @param uuid
 * @returns
 */
export function getFilterGroupList(stateful, isStart) {
    const list = getGroupList(stateful);
    if (isStart === undefined)
        return null;
    return list.filter(item => item.start === isStart).map(item => item.uuid);
}
/**
 * (GROUP) 그룹 사용자 조회
 */
export function getGroupMemberByUserId(stateful, user_id) {
    const member_list = getGroupState(stateful).member_list;
    return member_list && member_list.get(user_id);
}
/**
 * (GROUP) 그룹 아이디 조회
 */
export function getGroupIds(stateful) {
    return getGroupState(stateful)?.group_ids;
}
/**
 * (GROUP) 그룹 ID로 조회
 */
export function getGroupById(stateful, uuid) {
    return getGroupState(stateful).list.get(uuid);
}
/**
 * (GROUP) 그룹 전체조회
 */
export function getGroupList(stateful) {
    return [...getGroupState(stateful).list.values()];
}
