import ReducerRegistry from "../redux/ReducerRegistry";
import { SET_RECORDING_ENABLED } from "./actionTypes";
const DEFAULT_STATE = {
    server: {
        enabled: false,
        option: {
            recording_id: "",
            resource_id: "",
            secret_key: "",
        }
    },
    local: {
        enabled: false,
        option: {
            recording_id: "",
            resource_id: "",
            secret_key: "",
        }
    }
};
;
ReducerRegistry.register('features/base/record', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SET_RECORDING_ENABLED:
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    enabled: action.enabled,
                    option: action.option
                }
            };
    }
    return state;
});
