import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getActiveGroupId } from "../../room";
import { MEMBER_STATUS, getMemberIdAndNicknameList } from "../members";
import { ClickButton } from "../ui";
import { useTranslation } from "react-i18next";
import ChoiceMemberStatus from "./ChoiceMemberStatus";
const ChoiceChatMember = React.memo(() => {
    const { t } = useTranslation();
    const [choiceMember, setChoiceMembers] = useState(new Map());
    const active_group = useSelector((state) => getActiveGroupId(state)) || '';
    const filterData = useMemo(() => { return { excludeLocal: true, activeGroupIds: [active_group], status: [MEMBER_STATUS.OCCUPIDE] }; }, [active_group]);
    const members = useSelector((state) => getMemberIdAndNicknameList(state, filterData, true), (prevMembers, nextMembers) => {
        return _.isEqual(prevMembers, nextMembers);
    });
    const handlerChoiceMember = (data) => {
        setChoiceMembers(new Map([...data?.members]));
    };
    useEffect(() => {
        setChoiceMembers(ChoiceMemberStatus.getChoiceMembers());
        ChoiceMemberStatus.subscribe('SET_CHOICE_MEMBER', handlerChoiceMember);
        return () => {
            ChoiceMemberStatus.unsubscribe('SET_CHOICE_MEMBER', handlerChoiceMember);
        };
    }, []);
    const renderCloseBtn = useMemo(() => (React.createElement(ClickButton, { className: "choice_close", label: t("dialog.close"), onClick: () => APP.eventManager.publish('SET_CHOICE_CHAT', { choice: false }) })), [t]);
    return (React.createElement(React.Fragment, null,
        renderCloseBtn,
        members.map(m => m && React.createElement(ChoiceChatItem, { key: m.user_uuid, member: m, isChecked: (choiceMember && choiceMember.get(m.user_uuid)) ? true : false }))));
});
export default ChoiceChatMember;
const ChoiceChatItem = React.memo(({ member, isChecked }) => {
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        setChecked(isChecked);
    }, [isChecked]);
    const handlerSetChecked = () => {
        setChecked(!checked);
        ChoiceMemberStatus.handlerMember({ ...member, type: checked ? 'unChecked' : 'checked' });
    };
    return (React.createElement(ClickButton, { className: 'choice_member_item', icon: checked ? "checked" : "circle", label: member.nickname || '-', size: 16, color: "#545454", onClick: handlerSetChecked }));
});
