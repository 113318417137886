import React, { useEffect, useState } from "react";
import ChoiceMemberStatus from "./ChoiceMemberStatus";
import { ClickButton } from "../ui";
const ChatChoiceMember = React.memo(() => {
    const [choiceMember, setChoiceMember] = useState();
    const handlermembers = (data) => {
        if (data?.members.size <= 0)
            setChoiceMember(undefined);
        const arrayData = Array.from(data?.members, ([uuid, nickname]) => ({ uuid, nickname }));
        setChoiceMember(arrayData);
    };
    useEffect(() => {
        ChoiceMemberStatus.subscribe('SET_CHOICE_MEMBER', handlermembers);
    }, []);
    const onClick = (user_uuid) => {
        ChoiceMemberStatus.handlerMember({ user_uuid, type: 'unChecked' });
    };
    return (choiceMember && choiceMember.map(item => (React.createElement(ClickButton, { key: item.uuid, className: 'choice_item', label: item.nickname, icon: 'sideClose', color: '#545454', onClick: () => onClick(item.uuid) }))));
});
export default ChatChoiceMember;
