import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// @ts-ignore
import { Dialog, toggleDialog } from "../base/dialog";
import ChoiceTimeSetContainer from "./ChoiceTimeSetContainer";
import { Attendance } from "./components";
import { ATTENDANCES_LIST, ENTRANCE_ATTENDANCES, LEAVING_ATTENDANCES, MIDDLE_ATTENDANCES } from "./constants";
import { getAttendance } from "./functions";
import NextPageContainer from "./NextPageContainer";
const AttendanceContainer = React.memo(() => {
    const dispatch = useDispatch();
    const [showType, setShowType] = useState('init');
    const [showBtn, setShowBtn] = useState(ATTENDANCES_LIST);
    const handler = {
        success: (data) => {
            let list = [ENTRANCE_ATTENDANCES];
            if (data.found_count > 0) {
                const isEntranceExist = data.items.find(i => i.name === ENTRANCE_ATTENDANCES);
                const isLeavingExist = data.items.find(i => i.name === LEAVING_ATTENDANCES);
                if (isEntranceExist && isLeavingExist) {
                    list = [MIDDLE_ATTENDANCES];
                }
                else if (isEntranceExist) {
                    list = [MIDDLE_ATTENDANCES, LEAVING_ATTENDANCES];
                }
            }
            setShowBtn(list);
        }, failed: (err) => {
            console.log(err);
        }
    };
    useEffect(() => {
        getAttendance(handler);
    }, []);
    const handlerClick = (type) => {
        setShowType(type);
    };
    const onSave = (type, dateTime) => {
        let times;
        if (type === MIDDLE_ATTENDANCES) {
            if (!dateTime || new Date() > dateTime && type === MIDDLE_ATTENDANCES) {
                APP.UI.alertMessage("attendances.errorGuid");
                return;
            }
            times = dateTime;
        }
        else {
            times = new Date();
        }
        const time = times.toJSON();
        const check_duration = 300000; // 10분 
        APP.API.createAttendancesCheck({
            name: type,
            check_start_time: time,
            check_duration
        }).then(({ complete, message }) => {
            if (complete) {
                // @ts-ignore
                dispatch(toggleDialog());
            }
            else {
                APP.UI.alertMessage(message);
            }
        });
    };
    return (React.createElement(Dialog, { titleKey: 'attendances.create', width: 600 }, showType === 'init' ? React.createElement(Attendance, { showBtn: showBtn, handlerClick: handlerClick })
        : showType === MIDDLE_ATTENDANCES ? React.createElement(ChoiceTimeSetContainer, { handlerClick: handlerClick, onSave: onSave })
            : React.createElement(NextPageContainer, { onSave: onSave, handlerClick: handlerClick, showType: showType })));
});
export default AttendanceContainer;
