import { SET_ACTIVE_GROUP_ID, SET_ROOM_JOINED } from '../../room';
import ReducerRegistry from '../redux/ReducerRegistry';
import { ADD_LOCAL_MEMBER, ADD_MEMBER, ADD_PINNED, ADD_RECORD, DELETE_MEMBER, DELETE_PINNED, DELETE_RECORD, LEFT_MEMBER, SET_BRIDGE_ID, SET_MEMBER_VOICE, UPDATE_MEMBER, } from './actionType';
import { MEMBER_STATUS } from './constans';
const DEFAULT_STATE = {
    local: '',
    remote: new Set(),
    pinIds: new Set(),
    recordIds: new Set(),
    voice: '',
    members: new Map(),
    memberIds: new Map(),
    bridgeList: new Map(),
};
ReducerRegistry.register('features/base/members', (state = DEFAULT_STATE, action) => {
    let member;
    let memberState = state;
    switch (action.type) {
        case ADD_LOCAL_MEMBER:
            member = action.member;
            memberState.local = member.user_uuid;
            memberState.members.set(member.user_uuid, member);
            memberState.memberIds.set(member.member_uuid, member.user_uuid);
            member.pinned && memberState.pinIds.add(member.user_uuid);
            // if (member.role === ROLE.HOST) state.hostList.add(member.user_uuid);
            return {
                ...memberState,
            };
        case ADD_MEMBER:
            member = action.member;
            member.pinned && memberState.pinIds.add(member.user_uuid);
            memberState.remote.add(member.user_uuid);
            memberState.members.set(member.user_uuid, member);
            memberState.memberIds.set(member.member_uuid, member.user_uuid);
            // console.log(member);
            member.bridge_sessions &&
                member.bridge_sessions.length > 0 &&
                memberState.bridgeList.set(member.user_uuid, member.bridge_sessions[0]);
            // if (member.role === ROLE.HOST) state.hostList.add(member.user_uuid);
            return {
                ...memberState,
            };
        case LEFT_MEMBER:
            member = action.member;
            if (member.local)
                memberState.local = '';
            memberState.members.set(member.user_uuid, { ...member, status: MEMBER_STATUS.VACATED });
            memberState.bridgeList.delete(member.user_uuid);
            memberState.memberIds.delete(member.member_uuid);
            memberState.pinIds.delete(member.user_uuid);
            if (memberState.voice === member.user_uuid)
                memberState.voice = '';
            return {
                ...memberState,
            };
        case DELETE_MEMBER:
            member = action.member;
            if (member.local)
                memberState.local = '';
            memberState.memberIds.delete(member.member_uuid);
            // memberState.hostList.delete(member.user_uuid);
            memberState.pinIds.delete(member.user_uuid);
            if (memberState.voice === member.user_uuid)
                memberState.voice = '';
            return {
                ...memberState,
            };
        case ADD_PINNED:
            memberState.pinIds.add(action.user_uuid);
            return {
                ...memberState,
            };
        case DELETE_PINNED:
            memberState.pinIds.delete(action.user_uuid);
            return {
                ...memberState,
            };
        case ADD_RECORD:
            memberState.recordIds.add(action.user_uuid);
            return {
                ...memberState,
            };
        case DELETE_RECORD:
            memberState.recordIds.delete(action.user_uuid);
            return {
                ...memberState,
            };
        case SET_ACTIVE_GROUP_ID:
            memberState.pinIds = new Set();
            memberState.recordIds = new Set();
            return {
                ...memberState,
            };
        case UPDATE_MEMBER:
            member = action.member;
            if (!member.user_uuid)
                break;
            // if (member.role === ROLE.HOST) memberState.hostList.add(member.user_uuid);
            memberState.members.set(member.user_uuid, member);
            return {
                ...memberState,
            };
        case SET_BRIDGE_ID:
            memberState.bridgeList.set(action.user_uuid, action.bridge_sessions);
            return {
                ...memberState,
            };
        case SET_MEMBER_VOICE:
            if (action.user_uuid)
                memberState.voice = action.user_uuid;
            return {
                ...memberState,
            };
        case SET_ROOM_JOINED:
            if (!action.joined) {
                return {
                    local: '',
                    remote: new Set(),
                    pinIds: new Set(),
                    recordIds: new Set(),
                    voice: '',
                    members: new Map(),
                    memberIds: new Map(),
                    bridgeList: new Map(),
                };
            }
            break;
    }
    return state;
});
// declare var APP: any;
// StateListenerRegistry.register((state: any) => getLocalGroup(state),
//     throttle((result, store) => {
//         const isJoined = isRoomJoined(store.getState);
//         if (!isJoined) return;
//         if (result.group_id && result.start === undefined) return;
//         const active_group = getActiveGroupId(store.getState);
//         const { group_id, group_role, start, role } = result;
//         if (role === ROLE.HOST) return;
//         if (start) {
//             if (group_id ) {
//                 if (active_group === group_id) store.dispatch(toggleLoad(false));
//                 else  APP.management.changeManagement(active_group, {group_id, group_role})
//             }
//         } else {
//             if (active_group !== '') {
//                 APP.management.changeManagement(group_id, {group_id: ''});
//             }
//         }
//     }, 1000, { leading: false, trailing: true }), {
//         deepEquals: true
//     }
// )
