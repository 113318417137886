import { MiddlewareRegistry } from "../base/redux";
import { TOGGLE_E2EE } from "./actionTypes";
MiddlewareRegistry.register(store => next => async (action) => {
    switch (action.type) {
        case TOGGLE_E2EE:
            APP.management.toggleE2EE(action.enabled);
            break;
    }
    return next(action);
});
