import _ from 'lodash';
import { ReducerRegistry } from '../redux';
import { HIDE_NOTIFICATION, SAVE_NOTIFICATION_MESSAGE, SET_NOTIFICATIONS_OPTION, SHOW_NOTIFICATION, } from './actionTypes';
import { SET_ACTIVE_GROUP_ID, SET_ROOM_JOINED } from '../../room';
const DEFAULT_STATE = {
    enabled: true,
    notifications: [],
    show: new Set(),
    limit: 5,
    time: 3000,
    fixMessage: undefined,
};
ReducerRegistry.register('features/base/notification', (state = DEFAULT_STATE, action) => {
    const data = _.cloneDeep(state);
    switch (action.type) {
        case SET_ACTIVE_GROUP_ID:
            return DEFAULT_STATE;
        case SHOW_NOTIFICATION:
            if (action.props.save) {
                data.notifications.push({
                    ...action.props,
                    uid: action.uid,
                });
            }
            data.show.add({
                ...action.props,
                uid: action.uid,
            });
            return {
                ...data,
            };
        case HIDE_NOTIFICATION:
            [...data.show.values()].map((item) => {
                if (item.uid === action.uid)
                    data.show.delete(item);
            });
            return {
                ...data,
            };
        case SET_NOTIFICATIONS_OPTION:
            return {
                ...data,
                limit: action.limit,
                time: action.time,
            };
        case SAVE_NOTIFICATION_MESSAGE:
            return {
                ...data,
                fixMessage: action.message,
            };
        case SET_ROOM_JOINED:
            if (!action.joined) {
                return DEFAULT_STATE;
            }
            break;
    }
    return state;
});
