import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getGroupIds } from "../functions";
import GroupItemContainer from "./GroupItemContainer";
import { GroupList } from "./prev";
import { ClickButton } from "../../base/ui";
import { useTranslation } from "react-i18next";
function GroupListContainer({ isRow, isStart, focusItem }) {
    const { t } = useTranslation();
    const list = useSelector((state) => getGroupIds(state), shallowEqual);
    const renderList = useMemo(() => {
        if (list && list.length <= 0) {
            return (React.createElement(ClickButton, { className: 'no-hover no_group', label: t('group.createGroup'), icon: "group", color: "#545454", size: 32 }));
        }
        return (React.createElement(GroupList, { isRow: isRow, isStart: isStart }, list.map(uuid => React.createElement(GroupItemContainer, { key: uuid, isRow: isRow, isStart: isStart, isFocus: focusItem === uuid, uuid: uuid }))));
    }, [list, focusItem, isRow, isStart]);
    return renderList;
}
export default GroupListContainer;
