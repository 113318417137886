import React, { useEffect, useState } from "react";
import Icon from "../../base/ui/components/Icon";
import statsEmitter from "../statsEmitter";
import { ClickButton } from "../../base/ui";

function ConnectionIndicatorContainer({
    icon, jitsi_id
}: {
    icon?: string;
    jitsi_id: string
}) {
    const [stats, setStats] = useState();
    const [color, setColor] = useState("#00e700");

    useEffect(() => {
        jitsi_id && statsEmitter.subscribeToClientStats(jitsi_id, _onStatsUpdated);
    }, [jitsi_id]);

    /**
     * 전달된 사용자 ID와 관련된 새로운 연결 통계가 사용 가능한 경우 호출되는 콜백입니다. 
     * 현재 통계의 컴포넌트 표시를 업데이트합니다
     *
     * @param {Object} stats - Connection stats from the library.
     * @private
     * @returns {void}
     */
    const _onStatsUpdated = (nStats = {}) => {
        const { connectionQuality } = nStats;
        const newPercentageState = typeof connectionQuality === 'undefined'
            ? {} : { percent: connectionQuality };

        const newStats = Object.assign(
            {},
            stats,
            nStats,
            newPercentageState);
        
        setStats(newStats);
    }

    useEffect(() => {
        const percent = stats?.percent || 100;
    
        let color = "#D00000";
        if (percent === 100)  color="#00e700";
        else if (percent >= 0  && percent < 66) color="orange";

        setColor(color);        
    }, [stats?.percent]);


    if (icon) {
        return (
            <Icon icon={icon} size={10} color={color} />
        )
    } else {
        return (<ClickButton className="connection_mobile" />)
    }
}

export default React.memo(ConnectionIndicatorContainer);