import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getMemberList } from "../../base/members";
import { ClickButton, Input } from "../../base/ui";
import MemberItemContainter from "./MemberItemContainter";
import { MemberList } from "./prev";
import _ from "lodash";
const GroupMemberContainer = React.memo(({ isSearch, focusItem, member_list }) => {
    const { t } = useTranslation();
    const [sortType, setSortType] = useState('nickname');
    const [descType, setDescType] = useState(false);
    const [search, setSearch] = useState();
    const filterData = useMemo(() => (focusItem
        ? { excludeHost: true, excludeLocal: true, groupIds: [focusItem], filterGroup: true, nickname: search }
        : { excludeHost: true, excludeLocal: true, nickname: search }), [focusItem, search]);
    const members = useSelector((state) => getMemberList(state, filterData, false), (prevMembers, nextMembers) => {
        return _.isEqual(prevMembers, nextMembers);
    });
    const sortMembers = useMemo(() => {
        const sorted = [...members].sort((a, b) => a[sortType]?.localeCompare(b[sortType]));
        return descType ? sorted.reverse() : sorted;
    }, [members, sortType, descType]);
    const handleClick = useCallback((name, sort) => {
        if (sort) {
            if (sortType === name)
                setDescType(!descType);
            else {
                setSortType(name);
                setDescType(true);
            }
        }
    }, [sortType, descType]);
    const renderHeader = useMemo(() => {
        return (member_list.map(item => React.createElement(ClickButton, { key: item.name, className: "nonSelect group_member_header", icon: item.sort ? sortType === item.name && descType ? 'down' : 'up' : null, size: 12, label: t(`group.${item.name}`), onClick: () => handleClick(item.name, item.sort) })));
    }, [member_list, sortType, descType]);
    const renderSearch = useMemo(() => {
        if (!isSearch)
            return null;
        return (React.createElement(Input, { className: "search", bottomLabel: React.createElement(ClickButton, { icon: "search", size: 16, color: "#fff" }), onChange: setSearch }));
    }, [isSearch]);
    const renderSortMembers = useMemo(() => {
        if (!sortMembers || sortMembers.length <= 0) {
            return (React.createElement(ClickButton, { className: 'no_group no-hover', color: '#545454', label: t('group.noShowMembers'), icon: 'member', size: 32 }));
        }
        else {
            return (sortMembers.map(member => React.createElement(MemberItemContainter, { key: member.user_uuid, member: member, member_list: member_list })));
        }
    }, [sortMembers]);
    return (React.createElement(MemberList, { renderHeader: renderHeader, renderSearch: renderSearch }, renderSortMembers));
});
export default GroupMemberContainer;
