/**
 * 알림 문구
 *
 * @param {Object} props - The props needed to show the notification component.
 * @param {string} type - Timeout type.
 * @returns {Function}
 */
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
/**
 * 알림 지우기
 * @param {String} uid - Timeout type.
 * @returns
 */
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const SET_NOTIFICATIONS_OPTION = 'SET_NOTIFICATIONS_OPTION';
export const SAVE_NOTIFICATION_MESSAGE = 'SAVE_NOTIFICATION_MESSAGE';
