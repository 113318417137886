import React from "react";
import { useTranslation } from "react-i18next";
import { ClickButton } from "../../base/ui";
import { ATTENDANCES_LIST, ENTRANCE_ATTENDANCES, LEAVING_ATTENDANCES, MIDDLE_ATTENDANCES } from "../constants";
import { CheckStyled } from "./styles.web";
const Attendance = React.memo(({ showBtn, handlerClick }) => {
    const { t } = useTranslation();
    return (React.createElement(CheckStyled, null,
        React.createElement("div", { className: "guid" },
            t("attendances.createGuid"),
            React.createElement("br", null),
            React.createElement("p", null,
                " ",
                t("attendances.attachments", { name: t(`attendances.${ENTRANCE_ATTENDANCES}`) }),
                " "),
            React.createElement("p", null,
                " ",
                t("attendances.attachments", { name: t(`attendances.${LEAVING_ATTENDANCES}`) }),
                " "),
            React.createElement("p", null,
                " ",
                t("attendances.middleAttachments", { name: t(`attendances.${MIDDLE_ATTENDANCES}`) }),
                " ")),
        React.createElement("div", { className: "btn_bundle" }, ATTENDANCES_LIST.map(btn => {
            const isDisable = !showBtn.includes(btn);
            return (React.createElement(ClickButton, { key: btn, className: isDisable ? 'disable' : '', label: t(`attendances.${btn}`), isText: true, onClick: () => !isDisable && handlerClick(btn) }));
        }))));
});
export default Attendance;
