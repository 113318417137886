import _ from 'lodash';
import { getGroupByUid, getGroupMemberByUserId } from '../../groups/functions';
import { getActiveGroupId } from '../../room/functions';
import { getLocalID } from '../local';
import { MEDIA_TYPE } from '../media/constants';
import { toState } from '../redux';
import { getTracksByTrackIdAndMediaType } from '../tracks/functions';
import { MEMBER_STATUS, MEMBER_STATUS_LEVEL, ROLE, ROLE_LEVEL } from './constans';
export function isLocalParticipant(stateful) {
    const localRole = getLocalMemberRole(stateful) || ROLE.PARTICIPANT;
    return ![ROLE.ADMIN, ROLE.HOST, ROLE.MANAGER].includes(localRole);
}
/**
 *
 */
export function getRoleMenu(local_role, remote_role) {
    switch (local_role) {
        case ROLE.ADMIN:
        case ROLE.HOST:
        case ROLE.MANAGER:
            if (checkRoleLevel(local_role, remote_role)) {
                return ['presenter', 'handler', 'mic', 'voice', 'pin', 'etc'];
            }
    }
}
/**
 * 모든 사용자 가져오기 + filter
 * @param {Function} stateful
 * @param {Array} status
 * @returns
 */
export function getMemberList(stateful, data, isSort = true) {
    const state = toState(stateful);
    const { local, remote } = state['features/base/members'];
    const list = [local, ...remote];
    const status = data?.status || null;
    const excludePin = data?.excludePin || false;
    const excludeLocal = data?.excludeLocal || false;
    const excludeVoice = data?.excludeVoice || false;
    const excludeIds = data?.excludeIds || null;
    const nickname = data?.nickname || null;
    const active_group = getActiveGroupId(stateful);
    const groupIds = data?.groupIds ? data.groupIds : null;
    const activeGroupIds = data?.filterGroup ? [active_group] : data?.activeGroupIds ? data.activeGroupIds : null;
    const excludeHost = data?.excludeHost || null;
    const voice = getVoiceMember(state);
    const members = [];
    for (let i = 0; i < list.length; i++) {
        const uuid = list[i];
        if (!uuid)
            continue;
        const user = getMemberTrackByUserID(state, uuid, true);
        if (!user)
            continue;
        const { status: userStatus, local: userLocal, user_uuid: userUUID, pinned: userPinned, role: userRole, group_id: userGroupID, group_role: userGroupRole, active_group: userAGroupID, nickname: userNickname, } = user;
        const role_name = active_group === '' ? userRole : userGroupRole;
        if ((status ? status.includes(userStatus) : true) &&
            (excludeLocal ? !userLocal : true) &&
            (excludeVoice ? voice !== userUUID : true) &&
            (excludePin ? !userPinned : true) &&
            (excludeHost ? role_name !== ROLE.HOST : true) &&
            (excludeIds ? !excludeIds.includes(userUUID) : true) &&
            (nickname && userNickname ? userNickname.includes(nickname) : true) &&
            (groupIds && !userLocal ? groupIds.includes(userGroupID) : true) &&
            (activeGroupIds && !userLocal ? activeGroupIds.includes(userAGroupID) : true)) {
            members.push(user);
        }
    }
    if (isSort) {
        members.sort((a, b) => {
            const nicknameComparison = (a.nickname && b.nickname && a.nickname.localeCompare(b.nickname)) || 0;
            const localComparison = +a.local - +b.local;
            const pinnedComparison = +b.pinned - +a.pinned;
            const voiceComparison = voice === a.user_uuid ? -1 : 0;
            const aRole = a.active_group === '' ? a.role : a.group_role;
            const bRole = a.active_group === '' ? b.role : b.group_role;
            const roleComparison = ROLE_LEVEL.indexOf(aRole) - ROLE_LEVEL.indexOf(bRole);
            const statusComparison = MEMBER_STATUS_LEVEL.indexOf(a.status) - MEMBER_STATUS_LEVEL.indexOf(b.status);
            if (statusComparison !== 0) {
                return statusComparison;
            }
            if (roleComparison !== 0) {
                return roleComparison;
            }
            if (nicknameComparison !== 0) {
                return nicknameComparison;
            }
            if (localComparison !== 0) {
                return localComparison;
            }
            if (pinnedComparison !== 0) {
                return pinnedComparison;
            }
            if (voiceComparison !== 0) {
                return voiceComparison;
            }
        });
    }
    return members;
}
export function getMemberIdAndNicknameList(stateful, data, isSort = true) {
    const members = getMemberList(stateful, data, isSort);
    return members.map(t => {
        return { user_uuid: t.user_uuid, nickname: t.nickname };
    });
}
export function getMemberIdsList(stateful, data, isSort = true) {
    const members = getMemberList(stateful, data, isSort);
    return members.map(t => t.user_uuid);
}
/**
 * 현재 입장 중인 사용자 수 가져오기
 * @param {*} stateful
 * @returns
 */
export function getMemberCount(stateful) {
    const members = getMemberList(stateful, {
        status: [MEMBER_STATUS.OCCUPIDE],
        filterGroup: true,
    }, false);
    return members.length;
}
/**
 * 음성 사용자 가져오기
 * @param {Function} stateful
 * @returns
 */
export function getVoiceMember(stateful) {
    const state = toState(stateful);
    return state['features/base/members'].voice;
}
/**
 * 로컬 사용자 정보 가져오기
 * @param {*} stateful
 * @returns
 */
export function getLocalMember(stateful, hasGroup = false) {
    const state = toState(stateful);
    const local_uuid = state['features/base/members'].local;
    const local = getMemberByUserID(stateful, local_uuid);
    if (!local)
        return null;
    return local;
}
/**
 * 로컬 사용자의 권한 정보 가져오기
 * @param {*} stateful
 * @returns
 */
export function getLocalMemberRole(stateful) {
    const localID = getLocalID(stateful);
    if (!localID)
        return null;
    const local = getMemberTrackByUserID(stateful, localID, false);
    if (local) {
        return local.active_group === '' ? local.role : local.group_role;
    }
    return null;
}
/**
 * 모든 사용자 ID 조회(방에 없는 사용자 까지)
 * @param {Function} stateful
 * @param {string} user_uuid
 * @returns
 */
export function getMemberByUserID(stateful, user_uuid) {
    const state = toState(stateful);
    const members = state['features/base/members'].members;
    return members.get(user_uuid);
}
/**
 * 현재 권한 확인
 * @param {Function} stateful
 * @param {string} user_uuid
 */
export function getRoleByUserID(stateful, user_uuid) {
    const members = getMemberByUserID(stateful, user_uuid);
    return members && members.role;
}
/**
 * 사용자 member ID로 user ID 가져오기
 */
export function getUserIDByMemberId(stateful, member_uuid) {
    const state = toState(stateful);
    const memberIDs = state['features/base/members']?.memberIds;
    if (!member_uuid)
        return null;
    return memberIDs && memberIDs.get(member_uuid);
}
/**
 * 사용자 아이디로 사용자 트랙 아이디 가져오기
 * @param {Function} stateful
 * @param {string} user_uuid
 * @returns
 */
export function getBridgeIDByUserID(stateful, user_uuid) {
    const state = toState(stateful);
    const bridgeList = state['features/base/members'].bridgeList;
    return bridgeList.get(user_uuid)?.id;
}
export function getLoaclMemberTrack(stateful) {
    const localID = getLocalID(stateful);
    if (!localID)
        return undefined;
    const member = getMemberTrackByUserID(stateful, localID);
    return member;
}
/**
 * 사용자 아이디로 트랙 정보 가져오기
 * @param {Function} stateful
 * @param {string} user_uuid
 */
export function getMemberTrackByUserID(stateful, user_uuid, isTrack = true) {
    const state = toState(stateful);
    const member = getMemberByUserID(state, user_uuid);
    if (!member)
        return null;
    const jitsi_id = member.jitsi_id || getBridgeIDByUserID(state, user_uuid) || '';
    const videoTrack = isTrack && getTracksByTrackIdAndMediaType(state, member.local ? 'local' : jitsi_id, MEDIA_TYPE.VIDEO);
    const audioTrack = isTrack && getTracksByTrackIdAndMediaType(state, member.local ? 'local' : jitsi_id, MEDIA_TYPE.AUDIO);
    const group_item = getGroupMemberByUserId(state, user_uuid);
    return {
        ...member,
        jitsi_id,
        videoTrack,
        audioTrack,
        nickname: member.nickname || group_item?.nickname,
        group_id: group_item ? group_item.group_id : '',
        group_name: group_item ? group_item.group_name : '',
        group_role: group_item ? group_item.role_name : '',
        group_nick: group_item ? group_item.nickname : '',
        group_status: group_item ? group_item.group_status : '',
    };
}
/**
 * local 사용자 아이디 가져오기
 * @param {Function} stateful
 * @returns
 */
export function getLocalIDMember(stateful) {
    const state = toState(stateful);
    return state['features/base/members'].local;
}
/**
 * 권한 레벨 확인
 * @param {string} localRole
 * @param {string} memberRole
 * @returns
 */
export function checkRoleLevel(localRole, memberRole) {
    if (!localRole || !memberRole)
        return false;
    if (ROLE_LEVEL.indexOf(localRole) >= ROLE_LEVEL.indexOf(memberRole))
        return false;
    return true;
}
/**
 * 사용자 핀 가져오기
 * @param {IStore['getState']} stateful
 * @param {String|null} excludeId
 * @returns
 */
export function getPinnedMember(stateful, excludeId, excludeLocal = false) {
    const state = toState(stateful);
    const pinList = state['features/base/members'].pinIds;
    const copyPin = _.cloneDeep(pinList);
    excludeId && copyPin.delete(excludeId);
    excludeLocal && copyPin.delete(getLocalIDMember(stateful));
    return copyPin;
}
/**
 * 사용자 핀 개수 가져오기
 * @param {IStore['getState']} stateful
 * @param {String|null} excludeId
 * @returns
 */
export function getPinnedMemberCount(stateful, excludeId) {
    const pin = getPinnedMember(stateful, excludeId);
    return pin.size;
}
/**
 * 핀 사용자 가져오기
 * @param {IStore['getState']} stateful
 * @param {Boolean} isSeminar
 * @returns {string[]}
 */
export function getPinnedMembersBySeminar(stateful, isSeminar) {
    const state = toState(stateful);
    if (isSeminar) {
        let member = getMemberList(state, { status: [MEMBER_STATUS.OCCUPIDE], filterGroup: true }, false);
        if (member && member.length > 0) {
            member = member.sort((a, b) => (a.pinned === b.pinned ? 0 : a.pinned ? -1 : 1));
            const seminar = member[0];
            if (seminar) {
                if (seminar?.user_uuid)
                    return [seminar.user_uuid];
            }
        }
    }
    else {
        const voiceMember = getVoiceMember(stateful);
        const pin = getPinnedMember(state, voiceMember);
        if (pin && pin.size > 0)
            return [...pin];
    }
    return null;
}
/**
 * 그룹 아이디로 사용자 검색
 * @param {*} stateful
 * @param {string} group_id
 * @returns
 */
export function getGroupMemberByGroupId(stateful, group_id) {
    const state = toState(stateful);
    const members = state['features/base/members'].members;
    return group_id ? [...members.values()].filter(member => member.group_id === group_id) : [members.values()];
}
/**
 * 입장된 사용자 그룹 아이디로 사용자 검색
 * @param {*} stateful
 * @param {string} group_id
 * @returns
 */
export function getOccupiedMemberByGroupId(stateful, group_id) {
    const state = toState(stateful);
    const members = state['features/base/members'].members;
    return [...members.values()].filter(member => member.group_id === group_id && member.status === MEMBER_STATUS.OCCUPIDE && member.group_type === '');
}
/**
 * 녹화 사용자 가져오기
 * @param {*} stateful
 * @returns
 */
export function getMemberRecordList(stateful) {
    const state = toState(stateful);
    const list = state['features/base/members'].recordIds;
    return list && list.size > 0 ? [...list] : [];
}
/**
 * 현재 공유 중인 사용자 아이디 가져오기
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getScreenUserID(stateful) {
    const state = toState(stateful);
    const share_member = state['features/screen-share'].share_member;
    if (share_member) {
        const member = getMemberByUserID(stateful, share_member);
        if (member)
            return share_member;
        else {
            const user_uuid = getUserIDByMemberId(stateful, share_member);
            return user_uuid;
        }
    }
    return null;
}
/**
 * 로컬 그룹 사용자 가져오기
 *
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getLocalGroup(stateful) {
    const state = toState(stateful);
    const local = getLocalMember(state);
    const group = getGroupByUid(state, local?.group_id);
    return {
        role: local?.role,
        group_id: local?.group_id,
        group_role: local?.group_role,
        start: group?.start,
        group_name: group?.name,
    };
}
export async function getProcessedMember(member) {
    // 닉네임 없는 경우 무조건 닉네임 조회
    let nickname = member.nickname || '';
    let group_id = member.group_id || '';
    let group_role = member.group_role || '';
    let active_group = '';
    let group_type = '';
    const response = await APP.API.getRemoteUserInformation({ user_id: member.user_uuid });
    if (response.complete) {
        if (!nickname || nickname === '') {
            const item = response?.data?.find((item) => item.nickname && item.nickname !== '');
            if (item)
                nickname = item.nickname;
        }
        // 그룹 조회
        // 새로 들어온 사용자
        const item = response?.data?.find((item) => item.group_id !== '' && item.status !== MEMBER_STATUS.BANNED);
        if (item) {
            group_id = item.group_id;
            group_role = item.role.name;
            group_type = item.type;
            active_group = item.status === MEMBER_STATUS.OCCUPIDE ? item.group_id : '';
        }
    }
    return { ...member, nickname, group_role, group_id, group_type, active_group };
}
