/**
 * (ACTION - ADD SHARE ITEM) 문서 저장
 * @param data
 * @param isRemote
 * @returns
 */
export const ADD_SHARE_ITEM = 'ADD_SHARE_ITEM';
/**
 * (DOCUMENT - FOCUS_DOCUMENT) 문서 포커스
 * @param uuid
 * @param index
 * @param mode
 * @param isRemote
 * @returns
 */
export const SET_FOCUS_SHARE_ITEM = 'SET_FOCUS_SHARE_ITEM';
/**
 * (DOCUMENT - UPDATE) 문서 업데이트
 * @param uuid
 * @param data
 * @param isRemote
 */
export const UPDATE_SHARE_ITEM = 'UPDATE_SHARE_ITEM';
/**
 * (DOCUMENT - REMOVE DOCUMENT) 문서 삭제
 * @param uuid
 * @param isRemote
 */
export const DELETE_SHARE_ITEM = 'DELETE_SHARE_ITEM';
/**
 * (DOCUMENT - INIT) 문서 초기 설정
 * @param list
 * @param member_uuid
 * @returns
 */
export const ADD_SHARE_LIST = 'ADD_SHARE_LIST';
export const OPEN_DOCUMENT_LIST = 'OPEN_DOCUMENT_LIST';
