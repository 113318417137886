import { IStore } from '../app/types';
import { toState } from '../base/redux';

/**
 * 현재 공유 중인가?/
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getStartScreenShare(stateful) {
	const state = toState(stateful);

	return state['features/screen-share'].start_share;
}

/**
 * 현재 공유 중인 사용자가 나인가.
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getLocalSharingStatus(stateful) {
	const state = toState(stateful);

	return state['features/screen-share'].isSharingVideo;
}

/**
 * 현재 공유 중인 트랙 아이디 가져오기.
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getScreenShareTrackId(stateful) {
	const state = toState(stateful);

	return state['features/screen-share'].share;
}

/**
 * 현재 화면 공유 레이아웃 옵션
 * @param {IStore['getState']} stateful
 * @returns
 */
export function getScreenLayoutType(stateful) {
	const state = toState(stateful);

	return state['features/screen-share'].screen_type;
}
