import { combineReducers } from 'redux';
import { SET_ROOM_JOINED } from '../../room';
import ReducerRegistry from '../redux/ReducerRegistry';
import { TRACK_REMOVED } from '../tracks';
import { SET_AUDIO_AVAILABLE, SET_AUDIO_MUTED, SET_AUDIO_UNMUTE_PERMISSIONS, SET_SCREENSHARE_MUTED, SET_VIDEO_AVAILABLE, SET_VIDEO_MUTED, SET_VIDEO_UNMUTE_PERMISSIONS, STORE_VIDEO_TRANSFORM, TOGGLE_CAMERA_FACING_MODE } from './actionTypes';
import { CAMERA_FACING_MODE, SCREENSHARE_MUTISM_AUTHORITY } from './constants';
/**
 * 로컬 오디오의 초기 상태입니다.
 *
 * @type {AudioMediaState}
 */
export const _AUDIO_INITIAL_MEDIA_STATE = {
    available: true,
    unmuteBlocked: false,
    muted: false
};
/**
 * Reducer for audio media state.
 *
 * @param {AudioMediaState} state - Media state of local audio.
 * @param {Object} action - Action object.
 * @param {string} action.type - Type of action.
 * @private
 * @returns {AudioMediaState}
 */
function _audio(state = _AUDIO_INITIAL_MEDIA_STATE, action) {
    switch (action.type) {
        case SET_AUDIO_AVAILABLE:
            return {
                ...state,
                available: action.available
            };
        case SET_AUDIO_MUTED:
            return {
                ...state,
                muted: action.muted
            };
        case SET_AUDIO_UNMUTE_PERMISSIONS:
            return {
                ...state,
                unmuteBlocked: action.blocked
            };
        default:
            return state;
    }
}
/**
 * Media state object for local screenshare.
 *
 * @typedef {Object} ScreenshareMediaState
 * @property {boolean} available=true - Screenshare available state.
 * @property {boolean} muted=true - Screenshare muted state.
 * @property {boolean} unmuteBlocked=false - Screenshare unmute blocked state.
 */
/**
 * Initial state for video.
 *
 * @type {ScreenshareMediaState}
 */
export const _SCREENSHARE_INITIAL_MEDIA_STATE = {
    available: true,
    muted: SCREENSHARE_MUTISM_AUTHORITY.USER,
    unmuteBlocked: false
};
/**
 * 화면 공유 미디어 상태를 위한 감속기.
 *
 * @param {VideoMediaState} state - Media state of local screenshare.
 * @param {Object} action - Action object.
 * @param {string} action.type - Type of action.
 * @private
 * @returns {ScreenshareMediaState}
 */
function _screenshare(state = _SCREENSHARE_INITIAL_MEDIA_STATE, action) {
    switch (action.type) {
        case SET_SCREENSHARE_MUTED:
            return {
                ...state,
                muted: action.muted
            };
        case SET_VIDEO_UNMUTE_PERMISSIONS:
            return {
                ...state,
                unmuteBlocked: action.blocked
            };
        default:
            return state;
    }
}
/**
 * 로컬 비디오에 대한 미디어 상태 개체입니다
 *
 * @typedef {Object} VideoMediaState
 * @property {CAMERA_FACING_MODE} facingMode='user' - Camera facing mode.
 * @property {boolean} muted=false - Video muted state.
 */
// FIXME 기술적으로 _VIDEO_INITIAL_MEDIA_STATE는 기능 기반/미디어 내부의 상수이며 여러 파일에서 사용되므로 constants.js에 있어야 합니다.
// 하지만 실제로 VideoMediaState는 여러 파일에서도 사용되므로 어디로 이동해야 하는지 모르겠습니다.
/**
 * Initial state for video.
 *
 * @type {VideoMediaState}
 */
export const _VIDEO_INITIAL_MEDIA_STATE = {
    available: true,
    unmuteBlocked: false,
    facingMode: CAMERA_FACING_MODE.USER,
    muted: 0,
    /**
     * {@code id}에 의해 {@code MediaStream}에 적용된 동영상 {@link Transform}(예: "확대하려면 핀치")
     */
    transforms: {}
};
/**
 * Reducer for camera media state.
 *
 * @param {VideoMediaState} state - Media state of local video.
 * @param {Object} action - Action object.
 * @param {string} action.type - Type of action.
 * @private
 * @returns {VideoMediaState}
 */
function _video(state = _VIDEO_INITIAL_MEDIA_STATE, action) {
    switch (action.type) {
        // case CONFERENCE_FAILED:
        // case CONFERENCE_LEFT:
        case SET_ROOM_JOINED:
            if (!action.joined)
                return _clearAllVideoTransforms(state);
        case SET_VIDEO_AVAILABLE:
            return {
                ...state,
                available: action.available
            };
        case SET_VIDEO_MUTED:
            return {
                ...state,
                muted: action.muted
            };
        case SET_VIDEO_UNMUTE_PERMISSIONS:
            return {
                ...state,
                unmuteBlocked: action.blocked
            };
        case STORE_VIDEO_TRANSFORM:
            return _storeVideoTransform(state, action);
        case TOGGLE_CAMERA_FACING_MODE: {
            let cameraFacingMode = state.facingMode;
            cameraFacingMode = cameraFacingMode === CAMERA_FACING_MODE.USER
                ? CAMERA_FACING_MODE.ENVIRONMENT
                : CAMERA_FACING_MODE.USER;
            return {
                ...state,
                facingMode: cameraFacingMode
            };
        }
        case TRACK_REMOVED:
            return _trackRemoved(state, action);
        default:
            return state;
    }
}
/**
 * Listen for various actions related to media devices.
 *
 * @param {Object} state - State of media devices.
 * @param {Object} action - Action object.
 * @param {string} action.type - Type of action.
 * @param {Object} action.media - Information about media devices to be
 * modified.
 * @returns {Object}
 */
ReducerRegistry.register('features/base/media', combineReducers({
    audio: _audio,
    screenshare: _screenshare,
    video: _video
}));
/**
 * 저장된 모든 동영상 {@link Transform}을 제거합니다.
 *
 * @param {Object} state - The {@code video} state of the feature base/media.
 * @private
 * @returns {Object}
 */
function _clearAllVideoTransforms(state) {
    return {
        ...state,
        transforms: _VIDEO_INITIAL_MEDIA_STATE.transforms
    };
}
/**
 * Stores the last applied transform to a stream.
 *
 * @param {Object} state - The {@code video} state of the feature base/media.
 * @param {Object} action - The redux action {@link STORE_VIDEO_TRANSFORM}.
 * @private
 * @returns {Object}
 */
function _storeVideoTransform(state, { streamId, transform }) {
    return {
        ...state,
        transforms: {
            ...state.transforms,
            [streamId]: transform
        }
    };
}
/**
 * Removes the stored video {@link Transform} associated with a
 * {@code MediaStream} when its respective track is removed.
 *
 * @param {Object} state - The {@code video} state of the feature base/media.
 * @param {Object} action - The redux action {@link TRACK_REMOVED}.
 * @private
 * @returns {Object}
 */
function _trackRemoved(state, { track: { track } }) {
    if (track) {
        const streamId = track.getStreamId();
        if (streamId && streamId in state.transforms) {
            const nextTransforms = {
                ...state.transforms
            };
            delete nextTransforms[streamId];
            return {
                ...state,
                transforms: nextTransforms
            };
        }
    }
    return state;
}
