import React from "react";
import { NotificationStyled } from "./styles";
import Icon from "../../../ui/components/Icon";
import { useTranslation } from "react-i18next";

function Notification({
    list
}) {
    const { t } = useTranslation();

    return (
        <NotificationStyled>
            { list.map((item, index) => (
                
                <div key={index} className={`n_item ${item.appearance}`}>
                    <div className="title">
                        <Icon icon={item.appearance} size={16} color={item.appearance === 'error' ? "#fff" : "#545454"} />
                        <span>{t(item?.titleKey, item?.titleArguments)}</span>
                    </div>
                    
                    { item?.component 
                        ? React.createElement(item?.component, item?.componentProps)
                        : <div className="description">
                            { t(item?.description, item?.descriptionArguments) }
                        </div> }
                </div>
            )) }
        </NotificationStyled>
    )
}

export default React.memo(Notification);