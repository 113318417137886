import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getActiveGroupId } from "../../room";
import ChatSide from "./ChatSide";
import MemberSide from "./MemberSide";
import { SideBar } from "./components";
import { isChatOpend, isMemberOpend } from "./functions";
const SideBarContainer = React.memo(({ isMonitoring = false, initChat, initMember }) => {
    const active_group = useSelector((state) => getActiveGroupId(state));
    const isChat = useSelector((state) => isMonitoring ? initChat : isChatOpend(state));
    const isMember = useSelector((state) => isMonitoring ? initMember : isMemberOpend(state));
    const [hideChat, setHideChat] = useState(false);
    const [hideMember, setHideMember] = useState(false);
    const renderChat = useMemo(() => {
        if (!isChat)
            return null;
        return (React.createElement(ChatSide, { isMonitoring: isMonitoring, groupId: active_group, hideChat: hideChat, setHideChat: setHideChat }));
    }, [isChat, active_group, hideChat, isMonitoring]);
    const renderMember = useMemo(() => {
        if (!isMember)
            return null;
        return (React.createElement(MemberSide, { isMonitoring: isMonitoring, groupId: active_group, hideMember: hideMember, setHideMember: setHideMember }));
    }, [isMember, active_group, hideMember, isMonitoring]);
    if (!isChat && !isMember)
        return null;
    return (React.createElement(SideBar, { isChat: isChat, isMember: isMember, hideMember: hideMember, hideChat: hideChat, renderChat: renderChat, renderMember: renderMember }));
});
export default SideBarContainer;
