import React from 'react';
const OnOffSwitch = ({ className, isShow, label, handlerClick, guid }) => {
    const handleInputChange = (event) => {
        const newIsShow = event.target.checked;
        handlerClick && handlerClick(newIsShow);
    };
    return (React.createElement("div", { className: className },
        guid && React.createElement("div", { className: 'guid' }, guid),
        React.createElement("label", { className: "switch" },
            React.createElement("input", { type: "checkbox", checked: isShow, onChange: handleInputChange }),
            React.createElement("div", { className: "slider round" },
                React.createElement("div", { className: "text" }, label)))));
};
export default React.memo(OnOffSwitch);
