import React from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import RoomTimer from "../../../base/header/components/RoomTimer";
import { MEMBER_STATUS, getMemberList } from "../../../base/members";
import { GroupTableStyled } from "../styles";
export const GroupStartItem = React.memo(({ uuid, name, member_count, open_time, renderEnterRoom, renderEndRoom, renderSendMessage }) => {
    const { t } = useTranslation();
    const enter_member = useSelector((state) => {
        const member = getMemberList(state, { activeGroupIds: [uuid], status: [MEMBER_STATUS.OCCUPIDE] }).filter(i => !i.local);
        return member.length || 0;
    }, shallowEqual);
    return (React.createElement("div", { className: "group_item" },
        React.createElement("div", { className: "title" },
            React.createElement("h2", { className: "overText" },
                name,
                " ")),
        React.createElement(GroupTableStyled, { isFull: false },
            React.createElement("div", { className: "table_body" },
                React.createElement("div", { className: "table_td start_group underline" },
                    React.createElement("div", { className: "th" }, t("group.running_time")),
                    React.createElement("div", { className: "overText" }, open_time ? React.createElement(RoomTimer, { start_time: new Date(open_time) }) : '-')),
                React.createElement("div", { className: "table_td start_group underline" },
                    React.createElement("div", { className: "th" }, t("group.allMember")),
                    React.createElement("div", { className: "overText" }, t("group.person", { count: member_count }))),
                React.createElement("div", { className: "table_td start_group" },
                    React.createElement("div", { className: "th" }, t("group.belongMember")),
                    React.createElement("div", { className: "overText" }, t("group.person", { count: enter_member }))))),
        React.createElement("div", { className: "btn_wrapper" },
            React.createElement("div", { className: "btn" },
                renderEnterRoom,
                enter_member > 0 && renderSendMessage),
            renderEndRoom)));
});
