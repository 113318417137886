import styled from "styled-components";
export const ChatStyled = styled.div `
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .EmojiPickerReact {
        > * {
            margin: 0;
        }
    }

    .chat_content {
        flex: 1;
        margin: 0;
        overflow: auto;
    }

    .chat_input_wrapper {
        margin: 0;
        overflow: hidden;

        .chat_head {
            padding: 8px;
            box-sizing: border-box;
            background: #f2f2f2;
            display: flex;
            align-items: center;

            .title {
                font-size: 14px;
                font-weight: bold;
                margin: 0; 
            }

            .btn_wrapper {
                display: flex;
                padding: 0 4px;
                border-radius: 8px;
                border: 1px solid #545454;

                .click_button {
                    padding: 2px 12px;
                    box-sizing: border-box;
                }

                .choice {
                    &.isFocus {
                        font-weight: bold;
                    }
                }

                .hr {
                    border-right: 1px solid #545454;
                }
            }

            .btn_left_wrapper {
                margin: 0 0 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
            }
        }

        .chat_input { margin: 0;     
            padding: 4px;
            box-sizing: border-box;
        }

        textarea {
            resize: none;
            width: 100%;
            box-sizing: border-box;
            padding: 8px;
        }
    }

    .choice_list {
        width: 100%;
        box-sizing: border-box;
        overflow: auto;
        display: flex;
        flex-wrap: nowrap;
        gap: 4px;

        .choice_item {
            display: flex;
            padding: 4px;
            box-sizing: border-box;
            margin: 0;
            border: 1px solid;
            max-width: 100px;
            align-items: center;
            gap: 4px;

            svg {
                min-width: 12px;
            }
        }
    }

    .choice_member {
        width: 100%;
        max-height: 165px;
        overflow: auto;

        .choice_close {
            padding: 8px;
            box-sizing: border-box;
            text-align: center;
            background: #f2f2f2;
        }       

        &_item {
            cursor: pointer;
            display: flex;
            gap: 12px;
            padding: 4px 12px;
            align-items: center;
            border-bottom: 1px solid;

            > * {
                margin: 0;
            }
        }
    }

    .popup {
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0,0,0,0.2);
        width: 100%;
        height: 100%;
    }    

    .popup_content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .header {
            display: flex;
            border-bottom: 2px solid;
            padding: 5px 0;
            width: 100%;

            .title {
                margin: 0 auto 0 0;
                font-size: 15px;
                font-weight: bold;
            }

            .close {
                margin: 0 0 0 auto;
                cursor: pointer;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 12px;
            background: #fff;
            padding: 12px;
            border-radius: 6px;
        }

        input {
            padding: 8px;
            box-sizing: border-box;
            border: none;
            border-bottom: 1px solid;
        }
    }

    .system_message {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 8px 12px 4px;
        color: purple;
        gap: 4px;

        > * {
            margin: 0;
        }
    }

`;
export const PopupStyled = styled.div `
   .popup {
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0,0,0,0.2);
        width: 100%;
        height: 100%;
    }        

    .guid {
        white-space: break-spaces;
        text-align: center;
    }

    .popup_content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 95%;

        .header {
            display: flex;
            border-bottom: 2px solid;
            padding: 5px 0;
            width: 100%;

            .title {
                margin: 0 auto 0 0;
                font-size: 15px;
                font-weight: bold;
            }

            .close {
                margin: 0 0 0 auto;
                cursor: pointer;
            }
        }

        .guid {
            white-space: break-spaces;
            text-align: center;
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 12px;
            background: #fff;
            padding: 12px;
            border-radius: 6px;
            width: 85%;
        }

        input {
            padding: 8px;
            box-sizing: border-box;
            border: none;
            border-bottom: 1px solid;
        }
    }
`;
