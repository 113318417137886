import React, { useEffect, useMemo, useState } from 'react';
import VideoTrack from '../../../base/media/components/web/VideoTrack';
const MemberVideo = React.memo(({ videoTrack, nickname }) => {
    const [muted, setMuted] = useState(true);
    useEffect(() => {
        setMuted(!videoTrack || videoTrack.muted);
    }, [videoTrack]);
    const renderMutedVideo = useMemo(() => {
        return (React.createElement("div", { className: "default" },
            React.createElement("div", { className: "circleText" },
                React.createElement("div", { className: " overText" }, nickname))));
    }, [nickname]);
    return muted ? renderMutedVideo : React.createElement(VideoTrack, { className: "member_video", videoTrack: videoTrack, muted: false });
});
export default MemberVideo;
