/**
 * 임시 서버 녹화 시작
 */
export const TEMP_SERVER_RECORD_START = 'TEMP_SERVER_RECORD_START';
/**
 * 임시 서버 녹화 중지
 */
export const TEMP_SERVER_RECORD_STOP = 'TEMP_SERVER_RECORD_STOP';
/**
 * 녹화 시작을 알림.
 */
export const SET_RECORDING_ENABLED = "SET_RECORDING_ENABLED";
