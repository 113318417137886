import { APP_WILL_MOUNT, APP_WILL_UNMOUNT } from '../app';
import JitsiMeetJS, { JitsiMediaDevicesEvents } from '../lib-jitsi-meet';
import { MEDIA_TYPE } from '../media';
import { MiddlewareRegistry } from '../redux';
import { SET_AUDIO_INPUT_DEVICE, SET_VIDEO_INPUT_DEVICE } from './actionTypes';
import { configureInitialDevices, devicePermissionsChanged, updateDeviceList } from './actions';

// 장치에 대한 권한 확인 + 권한 업데이트 이벤트 추가
const _permissionsListener = permissions => {
	APP.store.dispatch(devicePermissionsChanged(permissions));
	APP.store.dispatch(configureInitialDevices());
};

const deviceChangeListener = async data => {
	const device = await JitsiMeetJS.mediaDevices.enumerateDevices(data => {
		APP.store.dispatch(updateDeviceList(data));
	});
};
/**
 * A listener for device permissions changed reported from lib-jitsi-meet.
 */
let permissionsListener;

MiddlewareRegistry.register(store => next => action => {
	switch (action.type) {
		case APP_WILL_MOUNT:
			if (store.getState()['features/base/app'].app === true) return;

			const { mediaDevices } = JitsiMeetJS;

			permissionsListener = _permissionsListener;
			mediaDevices.addEventListener(JitsiMediaDevicesEvents.PERMISSIONS_CHANGED, permissionsListener);
			mediaDevices.addEventListener(JitsiMediaDevicesEvents.DEVICE_LIST_CHANGED, deviceChangeListener);

			Promise.all([
				mediaDevices.isDevicePermissionGranted('audio'),
				mediaDevices.isDevicePermissionGranted('video'),
			])
				.then(results => {
					_permissionsListener({
						audio: results[0],
						video: results[1],
					});
				})
				.catch(error => {
					console.error('Error checking device permissions:', error);
				});

			break;

		case APP_WILL_UNMOUNT:
			// 장치 권한 이벤트 삭제
			JitsiMeetJS.mediaDevices.removeEventListener(
				JitsiMediaDevicesEvents.PERMISSIONS_CHANGED,
				permissionsListener,
			);

			JitsiMeetJS.mediaDevices.removeEventListener(
				JitsiMediaDevicesEvents.DEVICE_LIST_CHANGED,
				deviceChangeListener,
			);
			break;

		case SET_AUDIO_INPUT_DEVICE:
			APP.management.updateTrack(action.deviceId, MEDIA_TYPE.AUDIO);
			// store.dispatch(replaceAudioTrackById(action.deviceId));

			break;

		case SET_VIDEO_INPUT_DEVICE:
			APP.management.updateTrack(action.deviceId, MEDIA_TYPE.VIDEO);

			break;
	}

	return next(action);
});
