import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toggleLoad } from '../base/loading';
import { ClickButton, DivElement, Input, TextElement } from '../base/ui';
import { setRoomJoined } from '../room/actions';
import { setRoomPassword } from './action';
import { Dialog, hideDialog } from '../base/dialog';
import { SecurityStyled } from './components';
const RoomLockDialog = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(toggleLoad(false));
    }, []);
    const [password, setPassword] = useState('');
    const onEnterRoom = async () => {
        dispatch(toggleLoad(true));
        dispatch(setRoomPassword(password));
        APP.management.join(password)
            .then(() => {
            dispatch(setRoomJoined(true));
            dispatch(toggleLoad(false));
            dispatch(hideDialog());
        }).catch(err => {
            // 에러 메세지 표출 - mate 오류 
            APP.UI.alertMessage(err);
            dispatch(toggleLoad(false));
        });
    };
    return (React.createElement(Dialog, { titleKey: "dialog.security" },
        React.createElement(SecurityStyled, null,
            React.createElement(DivElement, { className: 'title_wrapper' },
                React.createElement(TextElement, { text: 'roomLock.lockGuid' }),
                React.createElement(Input, { className: "lock_wrapper", type: "password", placeholder: "roomLock.inputPassword", onChange: setPassword })),
            React.createElement(ClickButton, { className: 'text-button', isText: true, label: "deviceSelection.enter", onClick: onEnterRoom }))));
};
export default RoomLockDialog;
