import { LEAVE_ROOM } from '../../room';
import { CAMERA_FACING_MODE, MEDIA_TYPE, SET_AUDIO_MUTED, SET_VIDEO_MUTED, TOGGLE_CAMERA_FACING_MODE } from '../media';
import { MiddlewareRegistry } from '../redux';
import { destroyLocalTracks } from './actions';
import { TRACK_UPDATED } from './actionTypes';
import {
	getLocalTrack,
	getLocalTracks,
	getTracksByTrackIdAndMediaType,
	getTrackState,
	setTrackMuted,
} from './functions';

MiddlewareRegistry.register(store => next => action => {
	switch (action.type) {
		case SET_VIDEO_MUTED:
			_setMuted(store, action.muted, MEDIA_TYPE.VIDEO);
			break;

		case SET_AUDIO_MUTED:
			_setMuted(store, action.muted, MEDIA_TYPE.AUDIO);
			break;

		case TOGGLE_CAMERA_FACING_MODE: {
			const localTrack = _getLocalTrack(store.getState, MEDIA_TYPE.VIDEO);
			let track;

			if (localTrack && (track = localTrack.track)) {
				// XXX MediaStreamTrack._switchCamera is a custom function
				// implemented in react-native-webrtc for video which switches
				// between the cameras via a native WebRTC library implementation
				// without making any changes to the track.
				track._switchCamera();

				// Don't mirror the video of the back/environment-facing camera.
				const mirror = track.getCameraFacingMode() === CAMERA_FACING_MODE.USER;

				store.dispatch({
					type: TRACK_UPDATED,
					track: {
						track,
						mirror,
					},
				});
			}
			break;
		}

		case LEAVE_ROOM:
			if (action?.joined) break;

			const local_tracks = getLocalTracks(getTrackState(store.getState));
			store.dispatch(destroyLocalTracks(local_tracks));
			break;
	}

	return next(action);
});

/**
 * Gets the local track associated with a specific {@code MEDIA_TYPE} in a
 * specific redux store.
 *
 * @param {Store} store - The redux store from which the local track associated
 * with the specified {@code mediaType} is to be retrieved.
 * @param {MEDIA_TYPE} mediaType - The {@code MEDIA_TYPE} of the local track to
 * be retrieved from the specified {@code store}.
 * @param {boolean} [includePending] - Indicates whether a local track is to be
 * returned if it is still pending. A local track is pending if
 * {@code getUserMedia} is still executing to create it and, consequently, its
 * {@code jitsiTrack} property is {@code undefined}. By default a pending local
 * track is not returned.
 * @private
 * @returns {Track} The local {@code Track} associated with the specified
 * {@code mediaType} in the specified {@code store}.
 */
function _getLocalTrack(getState, mediaType, includePending = false) {
	return getLocalTrack(getState()['features/base/tracks'], mediaType, includePending);
}

/**
 * 특정 미디어 유형의 로컬 트랙을 음소거 또는 음소거 해제합니다.
 */
function _setMuted(store, muted, mediaType) {
	const localTrack = getTracksByTrackIdAndMediaType(store, 'local', mediaType);
	if (localTrack && localTrack.track) {
		setTrackMuted(localTrack.track, muted);
	}
}
