import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileBrowser } from '../base/environment/utils';
import { setLayoutContentStyle, setLayoutType } from './actions';
import { LayoutScreen, LayoutWrapper } from './components';
import { LAYOUT_TYPE } from './constants';
import { getCurrentMode } from './functions';
const LayoutContainer = React.memo(() => {
    const dispatch = useDispatch();
    const mode = useSelector((state) => getCurrentMode(state));
    const [layoutStyle, setLayoutStyle] = useState({ width: 0, height: 0 });
    const [showLayoutType, setShowLayoutType] = useState(LAYOUT_TYPE.desktop);
    const updateLayoutSize = (size) => {
        setLayoutStyle(size);
        if (isMobileBrowser() || APP.config.name === 'class')
            setShowLayoutType(LAYOUT_TYPE.mobile);
        else if (size.width <= 720)
            setShowLayoutType(LAYOUT_TYPE.tablet);
        else
            setShowLayoutType(LAYOUT_TYPE.desktop);
    };
    useEffect(() => {
        APP.eventManager.subscribe('UPDATE_LAYOUT_SIZE', updateLayoutSize);
        return () => {
            APP.eventManager.unsubscribe('UPDATE_LAYOUT_SIZE', updateLayoutSize);
        };
    }, []);
    useEffect(() => {
        dispatch(setLayoutType(showLayoutType));
    }, [showLayoutType]);
    useEffect(() => {
        dispatch(setLayoutContentStyle(layoutStyle.width, layoutStyle.height));
    }, [layoutStyle]);
    return (React.createElement(LayoutWrapper, { mode: mode, layoutStyle: layoutStyle },
        React.createElement(LayoutScreen, { mode: mode, showLayoutType: showLayoutType, layoutStyle: layoutStyle })));
});
export default LayoutContainer;
