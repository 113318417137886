import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ClickButton, Input } from "../../base/ui";
import { notifyGroupMessage } from "../action";
import { PopupContainer } from "./common";
function SendMessagePopup({ uuid, name }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [message, setMessage] = useState();
    /**
     * (action) 수정
     */
    const onClick = useCallback(async () => {
        dispatch(notifyGroupMessage(message, uuid));
        APP.eventManager.publish('UPDATE_OPEN_POPUP');
    }, [message, uuid, dispatch]);
    /**
     * 이름 입력 input component에서 Enter클릭시 수정 onClick
     * @returns
     */
    const onKeyPress = useCallback(e => {
        if (e.key === 'Space' || e.key === 'Enter' || e.key === 'NumpadEnter') {
            e.preventDefault();
            onClick();
        }
    }, [onClick]);
    /**
     * 메시지 입력 input component 랜더링 하는 함수
     * @returns
     */
    const renderMessageInput = useMemo(() => {
        return React.createElement(Input, { className: "message_input", textarea: true, minRows: 6, value: message, onKeyPress: onKeyPress, onChange: setMessage });
    }, [message, setMessage, onKeyPress]);
    /**
     * 수정 버튼 component 랜더링 하는 함수
     * @returns
     */
    const renderModifyBtn = useMemo(() => {
        return React.createElement(ClickButton, { label: t("group.send"), isText: true, onClick: onClick });
    }, [t, onClick]);
    return (React.createElement(PopupContainer, { title: t("group.sendMessage"), guid: t("group.sendMessageGuid", { name }) },
        React.createElement(React.Fragment, null,
            renderMessageInput,
            renderModifyBtn)));
}
export default React.memo(SendMessagePopup);
