import { getActiveGroupId } from '../../room/functions';
import { getLocalMember } from '../members/functions';
import { ADD_FILE, ADD_MESSAGE, ADD_MESSAGE_LIST, SET_CHOICE_MEMBER, SET_IS_CHAT_READ } from './actionTypes';
import { checkChatPermission, getChoiceList } from './functions';
/**
 * 메시지 전송
 * @param {String} message
 */
export function addMessage(message) {
    return async (dispatch, getState) => {
        const timestamp = new Date().getTime();
        const user = getLocalMember(getState);
        const uuid = `${timestamp}_${user?.user_uuid}`;
        const group_id = getActiveGroupId(getState) || '';
        const private_message = getChoiceList(getState);
        if (!user) {
            /**
             * (Fix me) 에러 처리 필요
             */
            return;
        }
        const data = {
            uuid,
            isMe: true,
            type: 'text',
            from: user.user_uuid,
            fromNickname: user.nickname,
            message,
            privateMessage: private_message, //[...choiceMembers.values()], // 닉네임
            timestamp,
            group_id,
        };
        dispatch(addShareMessage(data, false));
    };
}
/**
 * 파일 전송
 * @param file
 * @param group_id
 * @returns
 */
export function addFileMessage(file, group_id = '') {
    return async (dispatch, getState) => {
        const timestamp = new Date().getTime();
        const from = getLocalMember(getState);
        if (!from) {
            // alert message '
            return;
        }
        const uuid = `${timestamp}_${from?.user_uuid}`;
        const private_message = getChoiceList(getState);
        const data = {
            uuid,
            isMe: true,
            type: 'file',
            from: from?.user_uuid,
            fromRole: from?.role,
            fromNickname: from?.nickname,
            message: file.name,
            privateMessage: private_message,
            // privateMessage: members, // 닉네임
            timestamp,
            group_id: group_id || '',
        };
        dispatch(addShareMessage(data, true));
        const response = await APP.API.convertChatFile(file);
        if (response.complete) {
            const fileData = {
                name: file.name,
                size: file.size,
                link: response.data.download_link,
            };
            const fileMessage = {
                uuid,
                isMe: true,
                type: 'file',
                from: from?.user_uuid,
                fromRole: from?.role,
                fromNickname: from?.nickname,
                message: JSON.stringify(fileData),
                privateMessage: private_message,
                timestamp,
                group_id: group_id || '',
            };
            // 알림.
            // await dispatch(addShareFile(fileData, false));
            dispatch(addShareMessage(fileMessage, false));
        }
    };
}
/**
 * 파일 저장
 * @param data
 * @param isRemote
 * @returns
 */
export function addShareFile(data, isRemote = true) {
    return async (dispatch, getState) => {
        if (!isRemote) {
            if (checkChatPermission(getState, 'chat')) {
                const response = await APP.management.sendFile(data);
                if (response.status !== 200) {
                    /**
                     * (fix me)
                     */
                    console.log('addShareFile - error');
                    return;
                }
            }
            else
                return;
        }
        dispatch({
            type: ADD_FILE,
            data,
        });
    };
}
/**
 * 메시지 저장
 * @param data
 * @param isRemote
 * @returns
 */
export function addShareMessage(data, isRemote = true) {
    return {
        type: ADD_MESSAGE,
        data,
        isRemote,
    };
}
/**
 * 채팅 전송할 사용자 설정
 * @param members
 * @returns
 */
export function setChoiceMember(members) {
    return {
        type: SET_CHOICE_MEMBER,
        members,
    };
}
/**
 * 채팅 read
 */
export function setIsChatRead(isRead) {
    return {
        type: SET_IS_CHAT_READ,
        isRead,
    };
}
export function setChatMessageList(data) {
    return {
        type: ADD_MESSAGE_LIST,
        data,
    };
}
