import React, { Fragment, useMemo } from "react";
import { MEMBER_STATUS } from "../../../base/members";
import ChangeGroupSelect from "../ChangeGroupSelect";
import ChangeRoleSelect from "../ChangeRoleSelect";
const MemberItem = React.memo(({ member, member_list, changeRole, changeGroup }) => {
    const { nickname, group_id, group_role, status } = useMemo(() => member, [member]);
    const renderNickname = useMemo(() => React.createElement("div", null,
        " ",
        nickname || '-',
        " "), [nickname]);
    const renderStatus = useMemo(() => React.createElement("div", null,
        " ",
        status === MEMBER_STATUS.OCCUPIDE ? 'O' : 'X',
        " "), [status]);
    const renderContent = (item) => {
        switch (item) {
            case 'nickname':
                return renderNickname;
            case 'status':
                return renderStatus;
            case 'group_role':
                if (group_id === '') {
                    return React.createElement("div", null, " - ");
                }
                return React.createElement(ChangeRoleSelect, { group_role: group_role, changeRole: changeRole });
            case 'group_id':
                return React.createElement(ChangeGroupSelect, { group_id: group_id, changeGroup: changeGroup });
        }
    };
    const renderMembers = useMemo(() => {
        return (member_list.map(item => (React.createElement(Fragment, { key: item.name }, renderContent(item.name)))));
    }, [member, member_list]);
    return (React.createElement("div", { className: "table_td" }, renderMembers));
});
export default MemberItem;
