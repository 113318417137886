import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClickButton } from "../../base/ui";
import { setExpand } from "../actions";
import { LAYOUT_TYPE, SHARE_MODE_LIST, VIDEO_MODE_LIST, videoLayoutMode } from "../constants";
import { getDualMonitor, getFilmStyle, isShowByType } from "../functions";
import MembersLayout from "./MembersLayout";
import ShareModeLayout from "./ShareModeLayout";
import { MemberContainer } from "./layout";
import ScreenModeLayout from "./ScreenModeLayout";
const ShareMemberLayout = React.memo(({ expand }) => {
    if (expand) {
        return (React.createElement(React.Fragment, null,
            React.createElement(MembersLayout, { className: "horizontal_item", type: "pin", noShowMember: true, noPaging: true, maxStyle: { width: 172, height: 98 } }),
            React.createElement(MembersLayout, { className: "horizontal_item", type: "remote", noShowMember: true, maxStyle: { width: 172, height: 98 } })));
    }
    else {
        return (React.createElement(MembersLayout, { className: "horizontal_item", type: "seminar", noShowMember: true, maxStyle: { width: 172, height: 98 } }));
    }
});
const LayoutScreen = React.memo(({ mode, showLayoutType, layoutStyle }) => {
    const expand = useSelector((state) => state['features/video-layout'].expand);
    const showRemote = useSelector((state) => isShowByType(state, 'remote'));
    const isDualMonitor = useSelector((state) => getDualMonitor(state));
    const dispatch = useDispatch();
    const [isLeft, setIsLeft] = useState(true);
    // isDual mde  = isMobile : true
    const isMobile = useMemo(() => showLayoutType !== LAYOUT_TYPE.desktop ? true : false, [showLayoutType]);
    const toggleExapnd = () => dispatch(setExpand(!expand));
    /**
     * 화면 공유
     */
    const renderScreenLayout = useMemo(() => (React.createElement(ScreenModeLayout, { isMobile: isMobile || isDualMonitor })), [isMobile, isDualMonitor]);
    /**
     * 문서 공유
     */
    const renderDocumentLayout = useMemo(() => {
        return (React.createElement(React.Fragment, null,
            isMobile || ((!isLeft && !expand) || APP.config.option.shareListPopup || isDualMonitor) ? null :
                React.createElement(MemberContainer, { className: `horizontal ${!expand && 'non_expand'}` },
                    React.createElement(ShareMemberLayout, { expand: expand }),
                    React.createElement(ClickButton, { className: `expand_button ${!expand && 'non'}`, icon: 'previous', size: 16, color: "#545454", onClick: toggleExapnd })),
            React.createElement(ShareModeLayout, { mode: mode, isMobile: isMobile || isDualMonitor, isLeft: isLeft, setIsLeft: setIsLeft })));
    }, [mode, expand, isMobile, isLeft, isDualMonitor]);
    /**
     * 핀 모드
     */
    const renderPinLayout = useMemo(() => {
        const outerSize = !isDualMonitor
            ? { width: layoutStyle.width, height: layoutStyle.height - (showRemote ? 98 : 0) }
            : layoutStyle;
        return (React.createElement(React.Fragment, null,
            !isDualMonitor && React.createElement("div", { className: "horizontal layout_center" },
                React.createElement(MembersLayout, { className: "horizontal layout_center", type: 'remote', noShowMember: true, maxStyle: { width: 172, height: 98 } })),
            React.createElement(MembersLayout, { className: "mainFlex", type: "pin", noPaging: true, outerSize: outerSize })));
    }, [isDualMonitor, layoutStyle.width, layoutStyle.height, showRemote]);
    /**
     * 그리드 모드
     */
    const renderGridLayout = useMemo(() => (!isDualMonitor ?
        React.createElement(MembersLayout, { className: "gridFlex", noShowMember: true, type: 'remote', outerSize: layoutStyle })
        : React.createElement(MembersLayout, { className: "gridFlex", noShowMember: true, type: 'seminar', outerSize: layoutStyle })), [layoutStyle]);
    /**
     * 세미나 모드
     */
    const renderSeminarLayout = useMemo(() => (!isDualMonitor ?
        React.createElement(MembersLayout, { className: "mainFlex", type: "seminar", noPaging: true, outerSize: layoutStyle })
        : React.createElement(MembersLayout, { className: "gridFlex", noShowMember: true, type: 'seminar', outerSize: layoutStyle })), [isDualMonitor, layoutStyle]);
    /**
     * 보이스 모드
     */
    const renderVoiceLayout = useMemo(() => {
        const outerSize = !isDualMonitor
            ? getFilmStyle(APP.store.getState, 2, { width: layoutStyle.width, height: layoutStyle.height - (showRemote ? 98 : 0) })
            : getFilmStyle(APP.store.getState, 2, layoutStyle);
        return (React.createElement(React.Fragment, null,
            !isDualMonitor && React.createElement("div", { className: "horizontal layout_center" },
                React.createElement(MembersLayout, { className: "horizontal layout_center", type: 'remote', noShowMember: true, maxStyle: { width: 172, height: 98 } })),
            React.createElement("div", { className: "voice" },
                React.createElement("div", { className: "mainFlex", style: { width: outerSize.width + 'px', height: outerSize.height + 'px' } },
                    React.createElement(MembersLayout, { className: "mainFlex", noPaging: true, type: "pin", outerSize: outerSize })),
                React.createElement("div", { className: "mainFlex", style: { width: outerSize.width + 'px', height: outerSize.height + 'px' } },
                    React.createElement(MembersLayout, { className: "mainFlex", type: "voice", outerSize: outerSize, noPaging: true })))));
    }, [layoutStyle, isDualMonitor, showRemote]);
    const renderMobileLayout = useMemo(() => (React.createElement("div", { className: "vertical_grid" },
        React.createElement(MembersLayout, { className: "gridSlide", noShowMember: true, vertical: true, type: videoLayoutMode.seminar === mode ? 'seminar' : 'remote' }))), [mode]);
    const renderLayout = useMemo(() => {
        if (isMobile && VIDEO_MODE_LIST.includes(mode)) {
            return renderMobileLayout;
        }
        switch (mode) {
            case videoLayoutMode.screen:
                return renderScreenLayout;
            case videoLayoutMode.pin:
                return renderPinLayout;
            case videoLayoutMode.voice:
                return renderVoiceLayout;
            case videoLayoutMode.seminar:
                return renderSeminarLayout;
            default:
                if (SHARE_MODE_LIST.includes(mode)) {
                    return renderDocumentLayout;
                }
                return renderGridLayout;
        }
    }, [isMobile, mode, renderPinLayout, renderGridLayout, renderDocumentLayout,
        renderDocumentLayout, renderVoiceLayout,
        renderSeminarLayout, renderMobileLayout]);
    return renderLayout;
});
export default React.memo(LayoutScreen);
