import React from 'react';
import InputElement from './Input';
import { Colors } from '../Colors';
import { Icon } from '../../ui';
const SearchBar = React.memo(({ className = 'md', filterData = 'name', handlerFilterList }) => {
    const [isFocused, setIsFocused] = React.useState(false);
    const [textValue, setTextValue] = React.useState('');
    const onSubmit = () => {
        handlerFilterList({ [filterData]: textValue });
    };
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);
    const getSize = () => {
        if (className.includes('sm')) {
            return 20;
        }
        else if (className.includes('lg')) {
            return 28;
        }
        return 24;
    };
    return (React.createElement("div", { className: `flex row search-bar ${className} ${isFocused && 'focus'}` },
        React.createElement(InputElement, { type: "text", placeholder: "\uAC80\uC0C9\uC5B4\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694", isEnterPrevent: true, onChange: setTextValue, onSubmit: onSubmit, onFocus: handleFocus, onBlur: handleBlur }),
        React.createElement("div", { className: "flex row icon", onClick: onSubmit },
            React.createElement(Icon, { icon: "search", size: getSize(), fill: Colors.tur }))));
});
export default SearchBar;
