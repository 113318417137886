import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ChatContainer } from "../../base/chat";
import { addShareFile, addShareMessage } from "../../base/chat/action";
import { MEMBER_STATUS, ROLE, getLoaclMemberTrack } from "../../base/members";
import { MemberItemRender } from "../../base/members/MemberItemContainer";
import SideBarHeader from "../../base/sidebar/components/SideBarHeader.web";
import Sidebar from "../../base/sidebar/components/web/Sidebar";
import { ClickButton } from "../../base/ui";
import { assignGroup } from "../action";
import ChangeGroupSelect from "./ChangeGroupSelect";
import { GroupMonitoringStyled } from "./styles";
import { toggleChat, toggleMember } from "../../base/sidebar/actions";
class EventMangement {
    constructor(handler) {
        this.handler = handler;
        this.members = new Map();
        this.setMembers = this.setMembers.bind(this);
        this.addMembers = this.addMembers.bind(this);
        this.updateMember = this.updateMember.bind(this);
        this.getUserByMemberId = this.getUserByMemberId.bind(this);
        this.deleteMember = this.deleteMember.bind(this);
        this.handsupMember = this.handsupMember.bind(this);
        this.handleMessage = this.handleMessage.bind(this);
    }
    setMembers(newMembers) {
        this.members = newMembers;
        this.handler.updateMembers(newMembers);
    }
    addMembers(cMembers) {
        let copyMember = _.cloneDeep(this.members);
        cMembers?.forEach((m) => {
            if (m.type === "hidden" || m.status === MEMBER_STATUS.BANNED)
                return;
            copyMember.set(m.user_uuid, { ...m, nickname: m.nickname || "-", status: MEMBER_STATUS.OCCUPIDE });
        });
        this.setMembers(copyMember);
    }
    updateMember(user_uuid, data) {
        let copyMember = _.cloneDeep(this.members);
        const cMember = copyMember.get(user_uuid);
        if (cMember && cMember.user_uuid) {
            copyMember.delete(user_uuid);
            copyMember.set(user_uuid, { ...cMember, ...data });
            this.setMembers(copyMember);
        }
    }
    getUserByMemberId(member_uuid) {
        let dMember;
        this.members.forEach((m) => {
            if (m.member_uuid === member_uuid)
                dMember = m;
        });
        return dMember;
    }
    deleteMember(member_uuid) {
        let copyMember = _.cloneDeep(this.members);
        let dMember = this.getUserByMemberId(member_uuid);
        if (dMember && dMember.user_uuid) {
            copyMember.set(dMember.user_uuid, {
                ...dMember,
                local: false,
                status: MEMBER_STATUS.VACATED,
            });
            this.setMembers(copyMember);
        }
    }
    handsupMember(member_uuid, data) {
        let dMember = this.getUserByMemberId(member_uuid);
        dMember && this.updateMember(dMember.user_uuid, data);
    }
    handleMessage(event) {
        const data = event.payload;
        if (!data)
            return;
        switch (event?.class) {
            case "Event.Room.Chat.Text":
                const content = JSON.parse(data.message);
                APP.store.dispatch(addShareMessage(content, true));
                break;
            case "Event.Room.Chat.File":
                APP.store.dispatch(addShareFile(data.message, true));
                break;
            case "Room.Member.Join":
                this.addMembers(data.members);
                break;
            case "Event.Room.Member.Joined":
                this.addMembers([data.message]);
                break;
            case "Event.Room.Member.Left":
                this.deleteMember(data.from);
                break;
            case "Event.Room.Member.Role.Set":
                const { target, role } = data.message;
                this.updateMember(target, { role });
                break;
            case "Event.Room.Member.Pin.Added":
                this.updateMember(data.message.target, { pinned: true });
                break;
            case "Event.Room.Member.Pin.Deleted":
                this.updateMember(data.message.target, { pinned: false });
                break;
            case "Event.Room.Member.HandsUp":
                this.handsupMember(data.to, { hands_up: true });
                break;
            case "Event.Room.Member.HandsDown":
                this.handsupMember(data.to, { hands_up: false });
                break;
        }
    }
}
let groupMate;
const GroupMonitoring = React.memo(() => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isChat, setIsChat] = useState(true);
    const [isMember, setIsMember] = useState(true);
    const [groupId, setGroupId] = useState('');
    const [event, setEvent] = useState(null);
    const [members, setMembers] = useState(new Map());
    const local = useSelector((state) => getLoaclMemberTrack(state), shallowEqual);
    const changeGroup = (change_group) => {
        setGroupId(change_group);
    };
    const handlerLeaveGroup = async () => {
        if (groupMate && groupMate.disconnect)
            await groupMate.disconnect();
    };
    useEffect(() => {
        const test = async () => {
            if (!groupId || groupId === '') {
                if (local.group_id) {
                    await dispatch(assignGroup(local.group_id, [{ user_id: local.user_uuid, role_name: ROLE.ADMIN, type: 'hidden' }], 'unset', false));
                }
            }
            else {
                await dispatch(assignGroup(groupId, [{ user_id: local.user_uuid, role_name: ROLE.ADMIN, type: 'hidden' }], 'reset', false));
            }
            handlerLeaveGroup();
            if (groupId) {
                const eventManager = new EventMangement({
                    updateMembers: (members) => setMembers(members)
                });
                APP.management.groupMonitoringJoin(groupId, eventManager)
                    .then((response) => {
                    groupMate = response;
                    setEvent(eventManager);
                });
            }
        };
        test();
        return () => {
            setEvent(null);
        };
    }, [groupId]);
    const headerContent = (name, showBody) => {
        const hideContent = () => {
            switch (name) {
                case 'chat':
                    setIsChat(!showBody);
                    break;
                case 'member':
                    setIsMember(!showBody);
                    break;
            }
        };
        const handlerClose = () => {
            switch (name) {
                case 'chat':
                    dispatch(toggleChat(false));
                    break;
                case 'member':
                    dispatch(toggleMember(false));
                    break;
            }
        };
        return (React.createElement(SideBarHeader, { name: name, isMonitoring: true, showBody: showBody, hideContent: hideContent, handlerClose: handlerClose }));
    };
    const renderMember = useMemo(() => {
        const content = headerContent('member', isMember);
        if (!isMember)
            return content;
        return (React.createElement(React.Fragment, null,
            content,
            React.createElement("div", { style: { flex: 1, overflow: "auto" } }, [...members.entries()].map(([user_uuid, member]) => {
                return React.createElement(MemberItemRender, { key: user_uuid, member: {
                        ...member, active_group: groupId,
                        group_id: member.group_id,
                        group_name: member.nickname,
                        group_role: member.role
                    }, local_group_id: groupId, localRole: ROLE.ADMIN, isMonitoring: true });
            }))));
    }, [isMember, members]);
    const renderChat = useMemo(() => {
        const content = headerContent('chat', isChat);
        if (!isChat)
            return content;
        return (React.createElement(React.Fragment, null,
            content,
            React.createElement(ChatContainer, { isMonitoring: true, groupId: groupId })));
    }, [isChat, groupId]);
    return (React.createElement(GroupMonitoringStyled, null,
        React.createElement(ChangeGroupSelect, { className: 'group_select', group_id: groupId, show_start: true, changeGroup: changeGroup }),
        !groupId || groupId === ''
            ? React.createElement(ClickButton, { label: t("group.noSelectGuid") })
            : React.createElement(Sidebar, { chat: isChat, member: isMember, isChat: true, isMember: true, renderMember: renderMember, renderChat: renderChat })));
});
// let groupMate;
// function GroupMonitoring({
//     group_list
// }) {
//     const { t } = useTranslation();
//     const dispatch = useDispatch();
//     const local = useSelector(state => getLocalMember(state));
//     const permissions = useSelector(state => getPermissions(state));
//     const [groupId, setGroupId] = useState();
//     const [event, setEvent] = useState(null);
//     const [ members, setMembers] = useState(new Map());
//     const [ isChat, setIsChat ] = useState(true); 
//     const [ isMember, setIsMember ] = useState(true);
//     const handlerLeaveGroup = async () => {
//         if (groupMate && groupMate.disconnect) await groupMate.disconnect();
//     }
//     useEffect(() => {
//         const newMembers = event?.getMembers() || new Map();
//         setMembers(newMembers);
//     }, [event?.getMembers()]);
//     useEffect(() => {
//         if (!groupId || groupId === '') {
//             if (local.group_id) {
//                 dispatch(assignGroup(local.group_id, [{ user_id: local.user_uuid, role_name: ROLE.ADMIN, type: 'hidden' }], 'unset', false));
//             }            
//         } else {
//             dispatch(assignGroup(groupId, [{ user_id: local.user_uuid, role_name: ROLE.ADMIN, type: 'hidden' }], 'reset', false));
//         } 
//     }, [groupId]);
//     useEffect(() => {
//         handlerLeaveGroup();
//         if (local.group_id && local.group_id === groupId) {
//             const eventManager = new EventMangement();
//             APP.management.groupMonitoringJoin(groupId, eventManager)
//                 .then((response) => {
//                     groupMate = response;
//                     setEvent(eventManager);
//                 });            
//         }
//         return () => {
//             setEvent(null);
//         }
//     }, [local.group_id]);    
//     const headerContent = (name, showBody) => {
//         const hideContent = () => {
//             switch (name) {
//                 case 'chat':
//                     setIsChat(!showBody);
//                     break;
//                 case 'member':
//                     setIsMember(!showBody);
//                     break;
//             }
//         }
//         return (
//             <SideBarHeader name={name}
//                 isMonitoring={true}
//                 showBody={showBody} 
//                 hideContent={hideContent}
//             />
//         )
//     };
//     const renderMember = useMemo(() => {       
//         const content = headerContent('member', isMember);
//         if (!isMember) return content;
//         return (
//             <>
//                 { content }
//                 <div style={{ flex: 1, overflow: "auto" }}>
//                     { [...members.entries()].map(([user_uuid, member]) => {
//                         return <MemberItemContainer key={user_uuid} groupId={groupId} nMember={member}
//                             local={local} isMonitoring={true} active_group={groupId}
//                             isLocal={false} user_uuid={user_uuid} permissions={permissions} 
//                         />
//                     }) }
//                 </div>
//             </>
//         )
//     });
//     const renderChat = useMemo(() => {
//         const content = headerContent('chat', isChat);
//         if (!isChat) return content;
//         return (
//             <>
//                 { content }
//                 <ChatContainer isMonitoring={true} groupId={groupId} />
//             </>
//         )
//     })
//     return (
//         <GroupMonitoringStyled>
//             <ChangeGroupSelect className='group_select' 
//                 isMonitoring={true} groupId={groupId} handlerGroupUpdate={setGroupId}
//                 handlerLeaveGroup={handlerLeaveGroup} />
//             { !groupId || groupId === '' 
//                 ? <ClickButton label={t("group.noSelectGuid")}></ClickButton>
//                 : <Sidebar chat={isChat} member={isMember} isChat={true} isMember={true} renderMember={renderMember} renderChat={renderChat} /> } 
//         </GroupMonitoringStyled>
//     )
// }
export default React.memo(GroupMonitoring);
