import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRoomOption } from "../../room";
import { toggleDialog } from "../dialog";
import { MemberListContainer } from "../members";
import { ClickButton } from "../ui";
import { toggleMember } from "./actions";
import { InviteCode, SideBarHeader } from "./components";
const MemberSide = React.memo(({ isMonitoring, groupId, hideMember, setHideMember }) => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState();
    const [onSwitchSearch, setOnSwitchSearch] = useState(false);
    const roomCode = useSelector((state) => getRoomOption(state)?.code);
    const hideContent = () => setHideMember(!hideMember);
    const handlerClose = () => dispatch(toggleMember(false));
    const toggleOpenInviteCode = () => dispatch(toggleDialog(InviteCode, { code: roomCode }));
    const renderInviteBtn = (styles) => {
        if (!APP.config.option.invite)
            return null;
        return (React.createElement(ClickButton, { icon: "invite", size: 18, color: "#000", styles: styles, onClick: toggleOpenInviteCode }));
    };
    const renderHead = useMemo(() => (React.createElement(SideBarHeader, { name: "member", onSwitchSearch: onSwitchSearch, setOnSwitchSearch: setOnSwitchSearch, handlerSearchText: setSearch, isMonitoring: isMonitoring, groupId: groupId, showBody: !hideMember, renderInviteBtn: renderInviteBtn, hideContent: hideContent, handlerClose: handlerClose })), [groupId, isMonitoring, hideMember, renderInviteBtn]);
    const renderBody = useMemo(() => {
        if (hideMember)
            return null;
        return (React.createElement(MemberListContainer, { search: search, isMonitoring: isMonitoring, groupId: groupId }));
    }, [hideMember, isMonitoring, groupId, search]);
    return (React.createElement(React.Fragment, null,
        renderHead,
        renderBody));
});
export default MemberSide;
