import ReducerRegistry from "../base/redux/ReducerRegistry";
import { ADD_CANVAS_LIST, DELETE_CANVAS_INDEX, DELETE_CANVAS_KEY, SET_CANVAS_DRAW, SET_CANVAS_OPTION, SET_CANVAS_TOOL, UPDATE_CANVAS_DRAW } from "./actionTypes";
import { ALL_ERASER } from "./constants";
const DEFAULT_STATE = {
    tool: 'pen',
    option: {
        color: '#000',
        scale: 100,
        isWidthFit: undefined,
        thickness: 2,
        opacity: 10
    },
    draw: new Map()
};
ReducerRegistry.register('features/canvas', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        // 캔버스 한줄  삭제
        case DELETE_CANVAS_KEY:
            const test = state.draw.get(action.uuid);
            if (test) {
                const item = test.get(action.index);
                if (item)
                    item.delete(action.key);
            }
            return {
                ...state
            };
        // 캔버스 리스트 삭제
        case DELETE_CANVAS_INDEX:
            const prevDraw = state.draw.get(action.uuid);
            if (prevDraw)
                prevDraw.delete(action.index);
            return {
                ...state
            };
        // 캔버스 리스트 데이터 업데이트
        case UPDATE_CANVAS_DRAW:
            state.draw.set(action.uuid, action.data);
            return {
                ...state
            };
        case ADD_CANVAS_LIST:
            state.draw = action.list;
            return {
                ...state
            };
        case SET_CANVAS_DRAW:
            state.draw.set(action.uuid, action.data);
            return {
                ...state
            };
        case SET_CANVAS_TOOL:
            if (action.tool === ALL_ERASER.name) {
                return { ...state };
            }
            return {
                ...state,
                tool: action.tool
            };
        case SET_CANVAS_OPTION:
            const copyState = _.cloneDeep(state);
            copyState.option = { ...copyState.option, ...action.option };
            return {
                ...copyState
            };
    }
    return state;
});
