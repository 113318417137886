import { HIDE_LOAD, OPEN_LOAD } from './actionTypes';
/**
 * (LOADING) 로딩 숨기기
 * @returns
 */
export function hideLoad(option) {
    return {
        type: HIDE_LOAD
    };
}
/**
 * (LOADING) 로딩 보이기
 * @param {string} option
 * @returns
 */
export function openLoad(option) {
    return {
        type: OPEN_LOAD,
        option
    };
}
export function toggleLoad(load, option) {
    return {
        type: load ? OPEN_LOAD : HIDE_LOAD,
        option
    };
}
