const MIME_TYPE = 'video/webm;codecs=H264';
const START_TIME = 10000;

export default class Recorder {
	constructor(recordClass, isOnlyAudio) {
		this.recorder;
		this.recordClass = recordClass;
		this.stream = new Map();
		this.audioSources;
		this.audioDestination;
		this.gainNode;
		this.isOnlyAudio = isOnlyAudio;

		var audioContext = window.AudioContext;
		if (typeof audioContext === undefined) {
			if (typeof window.webkitAudioContext !== undefined) {
				/*global AudioContext:true */
				audioContext = window.webkitAudioContext;
			}

			if (typeof window.mozAudioContext !== undefined) {
				/*global AudioContext:true */
				audioContext = window.mozAudioContext;
			}
		}

		this.audioContext = audioContext;

		this.storage = {
			AudioContext: null,
			AudioContextConstructor: null,
		};
		if (typeof audioContext !== undefined) {
			this.storage.AudioContext = audioContext;
		} else if (typeof window.webkitAudioContext !== undefined) {
			this.storage.AudioContext = window.webkitAudioContext;
		}
	}

	/**
	 * 기기 지원 여부
	 */
	isSupportedBrowser() {
		if (this.audioContext === undefined) return false;
		if (
			!this.isOnlyAudio &&
			(navigator.mediaDevices === undefined || navigator.mediaDevices.getDisplayMedia === undefined)
		)
			return false;
		if (typeof MediaRecorder === undefined) return false;

		return true;
	}

	/**
	 * 초기 오디오 스트림 세팅
	 */
	loadAudioStraem(audioTracks) {
		if (!this.storage.AudioContextConstructor) {
			this.storage.AudioContextConstructor = new this.storage.AudioContext();
		}

		this.audioContext = this.storage.AudioContextConstructor;
		this.audioSources = [];

		this.gainNode = this.audioContext.createGain();
		this.gainNode.connect(this.audioContext.destination);
		this.gainNode.gain.value = 0;

		this.audioDestination = this.audioContext.createMediaStreamDestination();

		// 오디오 트랙 소스 저장
		audioTracks.forEach(track => {
			if (!track) return;
			const stream = track.track.stream;

			var audioSource = this.audioContext.createMediaStreamSource(stream);
			audioSource.connect(this.gainNode);

			this.audioSources.push(audioSource);
		});
	}

	/**
	 * 오디오 스트림 mixed
	 */
	getMixedAudioStream() {
		this.audioSources.forEach(audioSource => {
			audioSource.connect(this.audioDestination);
		});

		return this.audioDestination.stream;
	}

	/**
	 * 추가 오디오 발생
	 */
	connectAudio(track) {
		const audioSource = this.audioContext.createMediaStreamSource(track.stream);
		audioSource.connect(this.gainNode);

		this.audioSources.push(audioSource);
		audioSource.connect(this.audioDestination);
	}

	disconnectAudio(track) {
		const idx = this.audioSources.findIndex((source, id) => id == track.getStreamId());
		if (idx > -1) {
			this.audioSources[idx].disconnect();
			this.audioSources.splice(idx, 1);
		}
	}

	/**
	 * 녹화 시작
	 */
	async start(isOnlyAudio = false) {
		let gdmStream, resultStream;

		try {
			if (!isOnlyAudio) {
				try {
					gdmStream = await navigator.mediaDevices.getDisplayMedia({
						video: {
							displaySurface: 'browser',
							frameRate: 15,
						},
						audio: false,
						preferCurrentTab: true,
					});
				} catch (err) {
					console.log(err);
					this.recordClass.error('record.recordingCancel');

					return;
				}
				let mixedAudioStream = this.getMixedAudioStream();
				let mixTracks = mixedAudioStream.getTracks().concat(gdmStream?.getVideoTracks());
				resultStream = new MediaStream(mixTracks);
			} else {
				let mixedAudioStream = this.getMixedAudioStream();

				resultStream = new MediaStream(mixedAudioStream);
			}

			this.recordClass?.init && this.recordClass.init();

			this.recorder = new MediaRecorder(resultStream, { mimeType: MIME_TYPE });
			// 레코딩 시작
			this.recorder.onstart = () => {
				this.recordClass?.start && this.recordClass.start();
			};

			// 레코딩 데이터
			this.recorder.ondataavailable = e => {
				if (e.data && e.data.size > 0) {
					this.recordClass.save(e.data);
				} else {
					this.recordClass.error('record.noData');
				}
			};

			// 레코딩 중단
			this.recorder.onstop = () => {
				if (gdmStream) {
					gdmStream.getTracks().forEach(track => track.stop());
					gdmStream = null;
				}

				this.recordClass.stop();
			};

			// (action) 레코딩 시작
			this.recorder.start(START_TIME);

			// 스트림이 중단된 경우
			gdmStream && gdmStream.addEventListener('inactive', this.stop.bind(this));
		} catch (err) {
			if (!isOnlyAudio) {
				gdmStream && gdmStream.getTracks().forEach(track => track.stop());
				gdmStream = null;
			}

			this.recordClass.error('record.createError');
		}
	}

	stop() {
		// 녹화 중인 경우
		if (this.recorder && this.recorder.state === 'recording') {
			// (action) 녹화 중단
			this.recorder.stop();
			this.recordClass.stop();
		}
	}
}
