import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, toggleDialog } from '../base/dialog';
import { translate } from '../base/i18n';
import { ROLE, checkRoleLevel, getLocalMember } from '../base/members';
import { ClickButton } from '../base/ui';
import { getActiveGroupId } from './functions';
// @ts-ignore
import RoomLeave from './components/RoomLeave';
import { assignGroup } from '../groups/action';
import { toggleLoad } from '../base/loading';
const FORCE_END = 'forceEnd';
const GROUP_OUT = 'group_out';
const TERMINATE = 'terminate';
function AbstractRoomLeave({ t }) {
    const dispatch = useDispatch();
    const local = useSelector((state) => getLocalMember(state));
    const active_group = useSelector((state) => getActiveGroupId(state));
    const [list, setList] = useState([TERMINATE]);
    const handlerTerminate = useCallback(() => {
        // TERMINATE 버튼 클릭 시 실행되는 코드
        APP.management.leave();
    }, []);
    const handlerGroupout = useCallback(async () => {
        // 재 입장
        if (active_group && active_group !== '' && local) {
            dispatch(toggleLoad(true));
            dispatch(assignGroup(active_group, [{ user_id: local.user_uuid, role_name: ROLE.ADMIN }], 'reset', false));
            setTimeout(() => {
                APP.management.changeManagement(active_group, { group_id: '' });
                dispatch(toggleDialog());
                dispatch(toggleLoad(false));
            }, 3000);
        }
    }, [active_group, local]);
    useEffect(() => {
        const newButtonList = checkRoleLevel(local?.role, ROLE.MANAGER)
            ? active_group === ''
                ? [FORCE_END, TERMINATE]
                : [GROUP_OUT, TERMINATE]
            : [TERMINATE];
        setList(newButtonList);
    }, [local?.role, active_group]);
    const handlerForceEnd = useCallback(async () => {
        // FORCE_END 버튼 클릭 시 실행되는 코드 : 강제 종료
        try {
            const response = await APP.API.forceEndRoom();
            if (response.complete) {
                dispatch(toggleDialog());
            }
        }
        catch (error) {
            console.error(error);
        }
    }, [handlerTerminate]);
    const handlerClick = useCallback(type => {
        switch (type) {
            case GROUP_OUT:
                handlerGroupout();
                break;
            case FORCE_END:
                handlerForceEnd();
                break;
            case TERMINATE:
                handlerTerminate();
                break;
        }
    }, [handlerForceEnd, handlerTerminate]);
    const renderButtons = useMemo(() => list.map(name => name === FORCE_END && APP.config.option.hideForceEndRoom ? null : (React.createElement(ClickButton, { key: name, isText: true, label: t(`dialog.${name}`), onClick: () => handlerClick(name) }))), [list, t, handlerClick]);
    return (React.createElement(Dialog, { titleKey: t('dialog.rommLeave'), width: "450" },
        React.createElement(RoomLeave, { guid: list.includes(FORCE_END) ? t('dialog.forceEndGuid') : t('dialog.terminateGuid'), renderButtons: renderButtons })));
}
export default React.memo(translate(AbstractRoomLeave));
