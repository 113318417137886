import dashjs from "dashjs";
import React, { useEffect, useRef, useState } from "react";
const SeekInput = React.memo(({ player, hasPermission, handlerSeekTime }) => {
    const inputRef = useRef(null);
    const [played, setPlayed] = useState(0);
    const [isDown, setIsDown] = useState(false);
    const handleSeekMouseDown = (e) => {
        setIsDown(true);
    };
    const handleSeekChange = (e) => {
        setPlayed(parseFloat(e.target.value));
    };
    const handleSeekMouseUp = (e) => {
        if (!hasPermission)
            return;
        handlerSeekTime(parseFloat(e.target.value));
        setIsDown(false);
    };
    const handlerTimeUpdate = (ev) => {
        const currentTimeToEnd = ev.timeToEnd;
        const currentTime = ev.time;
        const ratio = currentTime / (currentTime + currentTimeToEnd);
        setPlayed(ratio);
    };
    useEffect(() => {
        if (isDown)
            return;
        player.on(dashjs.MediaPlayer.events.PLAYBACK_TIME_UPDATED, handlerTimeUpdate);
        return () => {
            player.off(dashjs.MediaPlayer.events.PLAYBACK_TIME_UPDATED, handlerTimeUpdate);
        };
    }, [player, isDown]);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = played.toString();
        }
    }, [played]);
    return (React.createElement("div", { className: "progress_bar" },
        React.createElement("div", { className: "progress", style: { width: `calc(100% * ${played})` } }),
        React.createElement("input", { ref: inputRef, disabled: !hasPermission, type: "range", min: 0, max: 0.999999, step: "any", value: played, onChange: handleSeekChange, onMouseDown: handleSeekMouseDown, onMouseUp: handleSeekMouseUp })));
});
export default SeekInput;
