import i18next from "i18next";
import _ from "lodash";
import { toggleLoad } from "../base/loading";
import { ROLE, getLoaclMemberTrack } from "../base/members";
import { ReducerRegistry, StateListenerRegistry } from "../base/redux";
import { LEAVE_ROOM, getActiveGroupId, isRoomJoined } from "../room";
import { INIT_GROUP, TOGGLE_OPEN_GROUP } from "./actionTypes";
const DEFAULT_STATE = {
    isOpen: false,
    isStart: false,
    group_ids: [],
    list: new Map(new Map()),
    error: null,
    member_list: new Map(new Map()),
};
ReducerRegistry.register('features/groups', (state = DEFAULT_STATE, action) => {
    const copyState = _.cloneDeep(state);
    switch (action.type) {
        case LEAVE_ROOM:
            return { ...DEFAULT_STATE };
        case INIT_GROUP:
            const group_ids = [...action.group_list.keys()];
            return {
                ...state,
                group_ids,
                list: action.group_list,
                member_list: action.member_list,
                isStart: action.start_group
            };
        case TOGGLE_OPEN_GROUP:
            return {
                ...state,
                isOpen: action.isOpen === undefined ? !copyState.isOpen : action.isOpen
            };
    }
    return state;
});
let setTimeEvent;
StateListenerRegistry.register((state) => getLoaclMemberTrack(state), async (member, store) => {
    if (!member || !isRoomJoined(store.getState) || (member.role === ROLE.HOST || member.role === ROLE.ADMIN)) {
        return;
    }
    if (setTimeEvent) {
        store.dispatch(toggleLoad(false));
        clearTimeout(setTimeEvent);
    }
    const active_group = getActiveGroupId(store.getState);
    if (member) {
        if (member.group_status === 'opened') {
            if (member.group_id !== active_group) {
                store.dispatch(toggleLoad(true, i18next.t('group.assginGroup', { name: member.group_name })));
                setTimeEvent = setTimeout(async () => {
                    await APP.management.changeManagement(active_group, { group_id: member.group_id, group_role: member.group_role });
                    store.dispatch(toggleLoad(false));
                }, 3000);
                return;
            }
        }
        else {
            if (active_group && active_group !== '') {
                store.dispatch(toggleLoad(true, i18next.t('group.unsetGroup', { name: member.group_name })));
                setTimeEvent = setTimeout(() => {
                    APP.management.changeManagement(active_group, { group_id: '' });
                    store.dispatch(toggleLoad(false));
                }, 3000);
                return;
            }
        }
    }
    else {
        if (active_group && active_group !== '') {
            store.dispatch(toggleLoad(true, i18next.t('group.lobbyGroup')));
            setTimeEvent = setTimeout(() => {
                APP.management.changeManagement(active_group, { group_id: '' });
                store.dispatch(toggleLoad(false));
            }, 3000);
            return;
        }
    }
}, {
    deepEquals: true
});
