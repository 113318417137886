import React from "react";
import { useDispatch } from "react-redux";
import { ROLE } from "../../base/members";
import { assignGroup } from "../action";
import { MemberItem } from "./prev";
const MemberItemContainter = React.memo(({ member, member_list }) => {
    const dispatch = useDispatch();
    const updateGroupMember = (group_id, group_role, assgin_type) => {
        dispatch(assignGroup(group_id, [{ user_id: member.user_uuid, role_name: group_role, type: '' }], assgin_type, false));
    };
    const changeGroup = (change_group) => {
        const group_id = change_group === '' ? member.group_id : change_group;
        const assgin_type = change_group === '' ? 'unset' : 'reset';
        const role = member.group_role === '' ? ROLE.PARTICIPANT : member.group_role;
        updateGroupMember(group_id, role, assgin_type);
    };
    const changeRole = (change_role) => {
        updateGroupMember(member.group_id, change_role, 'reset');
    };
    return (React.createElement(MemberItem, { member: member, member_list: member_list, changeGroup: changeGroup, changeRole: changeRole }));
});
export default MemberItemContainter;
