import styled from 'styled-components';
// @ts-ignore
export const PreviewInputConatainerStyled = styled.div `
    overflow: auto;

    .preview-contents {
        display: flex;
        flex-direction: column;
        gap: ${props => (!props.isMobile ? '16px' : '10px')};
        margin: 12px 0;

        > * {
            margin: 0;
        }
    }

    .preview-input-box {
        input {
            color: #a1a2a5;
            font-family: Pretendard;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 60px; /* 375% */
            letter-spacing: -0.32px;
        }
    }

    .input-box {
        > * {
            margin: 0;
        }
    }

    .title_wrapper {
        display: flex;
        gap: 12px;
        justify-content: ${props => !props.isMobile ? 'flex-start' : 'center'};
        align-items: center;

        > * {
            margin: 0;
        }
    }

    ${props => props.isAccountLogin
    ? `
        box-sizing: border-box;

        .preview-input-box {
            position: relative;
            .guid {
                color: #FFF;
                font-family: Pretendard;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                letter-spacing: -0.32px;
            }
        }

        .input-box {
            display: flex;
            gap: ${props.isMobile ? '8px' : '16px'};

            .preview-input { 
                display: flex;
                overflow: hidden;
                position: relative;

                .bottom_label {
                    position: absolute;
                    justify-content: center;
                    right: 0;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    .click_button { display: flex; }
                }
            }

           
            .preview-item {
                display: inline-flex;
                align-items: center;
                gap: 18px;
                border-radius: ${props.isMobile ? '5px' : '16px'};
                color: #FFF;

                &.skyblue {
                    background: #6ACDF8;
                    ${!props.isMobile &&
        `box-shadow: 6px 6px 12px 0px #C8EBFA;`}
                }
        
                &.purple_blue {
                    background: #8BACFF;
                    ${!props.isMobile &&
        `box-shadow: 6px 6px 12px 0px #D1DEFF;`}
                }
        
                &.purple {
                    background: #A491EE;
                    ${!props.isMobile &&
        `box-shadow: 6px 6px 12px 0px #D0C9EB;`}

                    .enter-icon {
                        right: 0;
                    }
                }

                > * {
                    margin: 0;
                }
            }
    
            .title {
                color: #FFF;
                font-family: suite;
                font-size: 28px;
                font-weight: bold;
                letter-spacing: -0.56px;
            }

            > * {
                margin: 0;
            }
        }        
    `
    : `
        box-sizing: border-box;
        max-width: 510px;
        width: 100%;
        flex-grow: 0;
        ${props.isMobile ? 'padding: 38px 25px' : 'padding: 50px'};
        border-radius: ${props.isMobile ? '18px' : '28px'};
        -webkit-backdrop-filter: blur(7px);
        backdrop-filter: blur(7px);
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.15);
        border: solid 3px rgba(255, 255, 255, 0.25);
        background-color: rgba(255, 255, 255, 0.9);
        

        .enter-icon { 
            width: 100%;
            height: ${props.isMobile ? '50px' : '64px'};
            flex-shrink: 0;  
            border-radius: 10px;
            background: #29AAE2;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            ${props.isMobile && `margin-top: 20px;`};

            span {
                color: #FFF;
                text-align: center;
                font-family: Pretendard;
                font-size: ${props.isMobile ? '12px' : '20px'};
                font-style: normal;
                font-weight: 700;
                letter-spacing: -0.4px;
            }
        }

        .logo {
            text-align: center;
            flex-grow: 0;
        }
        .preview-item {
            display: flex;
            flex-direction: column;

            .preview-label {
                ${props.isMobile
        ? `
                    font-size: 12px;
                `
        : `
                    width: 251px;
                    height: 20px;
                    font-size: 18px;
                    line-height: 60px;
                `}
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-shrink: 0;
                color: #2B3342;
                font-family: Pretendard;
                font-style: normal;
                font-weight: 400;
                letter-spacing: -0.36px;
                margin: 0;
            }

            .title {                
                flex-grow: 0;
                font-family: Pretendard;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: -0.76px;
                text-align: left;
                color: #0e1728;
            }

            .preview-input-box {
                position: relative;
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                gap: 10px;

                .preview-input-icon {
                    left: 28px;
                }

                > * {
                    margin: 0;
                }
            }         
        }
    `}

    ${props => props.isMobile
    ? `
        margin: 0;
        width: 100%;
        ${props.isAccountLogin && `height: 84px;`}
        display: flex;
        justify-content: center;
        align-items: center;

        .preview-inner {
            margin: 0;
            width: 100%;
        }
        
        .preview-input {
            input { 
                font-size: 12px;
                padding: 0 12px 0 12px!important;
                height: 50px; 
            }

            &.preview-input-icon {
                input { 
                    padding: 0 12px 0 50px!important;
                }
            }

            span {
                line-height: 28px;
            }
            .label {
                font-size: 12px;
            }
        }

        .preview-input-box {
            flex: 1;
            ${props.isAccountLogin && `padding: 17px 10px;`}

            &.login {
                .preview-input {
                    input { padding: 0 12px 0 50px!important; }
                }   
            }
        }

        .preview-item {
            flex: 1;
            justify-content: center;
            box-sizing: border-box;

            .title {
                margin: 0;
                font-size: ${props.isAccountLogin ? '14px' : '20px'};
            }
        }

        .input-box {
            .title_wrapper {
                display: flex;
                flex-direction: row-reverse;
                gap: 8px;
                box-sizing: border-box;

                svg {
                    min-width: 28px;
                }
            }
        }
    `
    : `
        max-height: 100%;

        .preview-item {
            .title {
                height: 38px;
                font-size: 2.5rem;
            }
        }

        .preview-input {
            span {
                line-height: 3;
            }
            .label {
                font-size: 1.2em;
            }
        }

        .preview-inner {
            min-width: 350px;
            margin-bottom: 20px;
        }

        .preview-input-box {
            width: 100%;            
            &.login {
                .preview-input {
                    input { padding: 6px 24px 6px 50px; }
                }   
            }
        }

        .input-box {
            .title {
                gap: 12px;
            }
        }

        ${!props.isAccountLogin
        ? `
            width: 100%;

            .preview-input-box {
                input {
                    padding: 0 12px 0 40px;
                }
            }

            .logo {
                margin-bottom: 53.3px;
            }            
        `
        : `
            .title_wrapper {
                margin-bottom: 16px;
            }
            .input-box {
                flex-direction: column;
            }

            .preview-input-box {
                input {
                    padding: 0 12px 0 24px;
                    width: 318px!important;
                }
            }
            .preview-item { padding: 38px; } 
        `}
    `}
`;
// @ts-ignore
export const ClassLobbyStyled = styled.div `
    width: 100%;
    height: 100%;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .classmate_logo {
        width: 100%;
        height: 100%;
        flex-grow: 0;
    }
`;
// @ts-ignore
export const LobbyStyled = styled.div `
    width: 100%;
    height: 100%;
    flex-grow: 0;
    display: flex;

    .sub {
        display: flex;
        flex-direction: column;
        gap: ${props => (props.isMobile ? '26px' : '36px')};
    }

    .sub-cover {
        flex-grow: 0;
        font-family: Pretendard;
        font-size: ${props => (props.isMobile ? '20px' : '38px')};
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.58;
        letter-spacing: -0.76px;
        text-align: left;
        color: #0e1728;
        margin: ${props => (props.isMobile ? '0 0 20px 0' : '0 0 12px 0')};
        gap: ${props => (props.isMobile ? '10px' : '16px')};
        display: flex;
        justify-content: flex-start;
        align-items: center;

        > * {
            margin: 0;
        }

        &.login {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    .hr_conatiner {
        margin: ${props => (props.isMobile ? '15px 0' : '24px 0')};
        display: flex;
        gap: 12px;
        justify-content: center;
        align-items: center;

        .hr {
            border-radius: 10px;
            background: #7f8793;
            flex: 1;
            height: 0.5px;
        }

        .or {
            color: #7f8793;
            ${props => props.isMobile && `font-size: 10px;`}
        }
    }

    .sub_accout {
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 10px;
        border: 1px solid #e3e3e3;
        background: #fff;
        width: 100%;
        height: ${props => (props.isMobile ? '50px' : '64px')};

        color: #29aae2;
        text-align: center;
        font-family: Pretendard;
        font-size: ${props => (props.isMobile ? '12px' : '20px')};
        font-style: normal;
        font-weight: 700;
        letter-spacing: -0.4px;
    }

    .signup_sub {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        color: #29aae2;
        text-align: center;
        font-family: Pretendard;
        font-style: normal;
        font-weight: 700;
        letter-spacing: -0.4px;
        box-sizing: border-box;
        border-bottom: ${props => props.isMobile ? '0.5px solid' : '3px solid'};
    }

    .lobby_error {
        white-space: break-spaces;
        text-align: center;
        line-height: 40px;
    }

    .lobby_container {
        display: flex;
        margin: 0;
        width: 80%;
        overflow: auto;
        min-height: 50%;
        height: 100%;
        max-height: 80%;
        gap: 60px;
        justify-content: center;
        align-items: center;
    }

    .welcome_guid {
        color: #161c1f;
        font-family: SUITE;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 68px; /* 141.667% */
        letter-spacing: -0.96px;
        margin-bottom: 46px;

        .name {
            display: flex;
            justify-content: flex-start;

            > * {
                margin: 0;
            }
        }

        .hello {
            display: flex;
            align-items: center;
            gap: 12px;

            > * {
                margin: 0;
            }
        }
    }

    .preview-input {
        width: 100%;
        font-family: Pretendard;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;

        span {
            flex-grow: 0;
            letter-spacing: -0.4px;
            text-align: left;
            color: #7f8793;
        }
        input {
            display: flex;
            align-items: center;
            width: 100%;
            height: ${props => (props.isMobile ? '50px' : '74px')};
            flex-grow: 0;
            border-radius: 10px;
            border: solid 1px #e3e3e3;
            background-color: #fff;
            box-sizing: border-box;

            &:focus {
                border: 2px solid #000;
                outline: none;
                box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
            }
        }
    }

    .logout_wrapper {
        display: flex;
        flex-shrink: 0;
        gap: ${props => (props.isMobile ? '8px' : '10px')};
        justify-content: center;
        align-items: center;

        .name {
            display: flex;
            max-width: 150px;
        }

        .logout {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${props => (props.isMobile ? '4px' : '8px')};
            &::before {
                content: '';
                ${props => props.isMobile
    ? `
                    border-left: 1px solid #ADADAD;
                    padding-left: 1px;
                    padding-top: 16px;
                `
    : `
                    border-left: 2px solid #ADADAD;
                    padding-left: 2px;
                    padding-top: 18px;
                `}

                box-sizing: border-box;
            }
        }

        color: #161c1f;
        font-family: Pretendard;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.36px;
    }

    ${props => props.isMobile
    ? `
        background: #F5F5F5;
        align-items: ${props.isAccountLogin ? 'strech' : 'center'};
        justify-content: ${props.isAccountLogin ? 'strech' : 'center'};
        flex-direction: column;

        .logo {
            margin-bottom: ${props.isAccountLogin ? '0' : '24px'};      
        }

        .logout_wrapper {
            font-size: 13px;            
        }

        .signup_sub {
            font-size: 12px;
            height: 24px;
            lint-height: 24px;
        }
        
        .mobile_content {
            background: #FFF;
            display: flex;
            flex-direction: column;
            justify-content: normal;
            align-items: flex-start;
            width: 100%;
            gap: 0;
            box-sizing: border-box;

            .configuration_body {
                border-radius: 0!important;
                box-shadow: none!important;
            }
        }
        
        .header_box {
            padding: 30px 20px 15px 20px;
            margin-bottom: 15px;
            gap: 10px;

            .header_logo {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                width: 100%;

                > * { margin: 0; }
            }

            > * { margin: 0; }
        }

        .body_box {
            position: relative;
            flex: 1;
            overflow: auto;
            width: 100%;
            padding: 19px 20px 10px 20px;
            box-sizing: border-box;
        }

        .footer_box {
            border-top: 1px solid #EBECF1;
            padding: 13px 20px 15px;
            box-sizing: border-box;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;

            > * { margin: 0; width: 100%; }

        }
    `
    : `
        justify-content: center;
        align-items: center;
        -webkit-backdrop-filter: blur(12px);
        backdrop-filter: blur(12px);
        background-blend-mode: hard-light;
        background-image: linear-gradient(to bottom, rgba(64, 69, 73, 0.6), rgba(64, 69, 73, 0.6));
        
        .main_logo {
            flex-shrink: 0;
            position: absolute;
            left: 72px;
            top: 56px;
        }

        .signup_sub {
            font-size: 20px;
            height: 30px;
            lint-height: 30px;
        }

        .logout_wrapper {
            position: absolute;
            top: 42px;
            right: 72px;
            font-size: 18px;
        }
    `}
`;
