import { APP_WILL_MOUNT, APP_WILL_UNMOUNT } from './actionTypes';
/**
 * Signals that a specific App will mount (in the terms of React).
 *
 * @param {App} app - The App which will mount.
 * @returns {{
 *     type: APP_WILL_MOUNT,
 *     app: App
 * }}
 */
export function appWillMount(app) {
    typeof APP === 'object' && APP.API.init();
    return {
        type: APP_WILL_MOUNT,
        app
    };
}
/**
 * Signals that a specific App will unmount (in the terms of React).
 *
 * @param {App} app - The App which will unmount.
 * @returns {{
 *     type: APP_WILL_UNMOUNT,
 *     app: App
 * }}
 */
export function appWillUnmount() {
    typeof APP === 'object' && APP.management.leave();
    return {
        type: APP_WILL_UNMOUNT,
        app: false
    };
}
