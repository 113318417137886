import { config as MateConfig } from './MateConfig';
import { config as ClassMateConfig } from './ClassMateConfig';
import { config as IsparkConfig } from './IsparkConfig';
import { config as MertiConfig } from './MertiConfig';

export const getConfig = () => {
	const search = location.search;

	if (search.includes('mate')) {
		return MateConfig;
	} else if (search.includes('merti')) {
		return MertiConfig;
	} else if (search.includes('klassmate')) {
		return ClassMateConfig;
	} else if (search.includes('ispark')) {
		return IsparkConfig;
	}

	return MateConfig;
};
