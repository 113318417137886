import styled from "styled-components";
export const CheckStyled = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    .guid {
        margin: 12px auto;
        white-space: pre-line;
        text-align: center;
    }

    .btn {margin: 0 auto;}

    .btn_bundle {
        display: inline-flex;
        flex-direction: rows;
        gap: 8px;
    }
`;
