import React, { useEffect, useRef, useState } from "react";
import { ClickButton } from "../../ui";
import { useTranslation } from "react-i18next";
const VolumeButton = React.memo(({ initVolume, handlerSetVolume }) => {
    const { t } = useTranslation();
    const inputRef = useRef(null);
    const [volume, setVolume] = useState(1);
    const handlerUpdateVolume = (e) => {
        const v = parseFloat(e.target.value);
        changeVolume(v);
    };
    const changeVolume = v => {
        setVolume(v);
        handlerSetVolume(v);
        if (inputRef.current)
            inputRef.current.value = v;
    };
    useEffect(() => {
        if (initVolume !== volume) {
            if (inputRef.current)
                inputRef.current.value = initVolume;
            setVolume(initVolume);
        }
    }, [initVolume]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "volume" },
            React.createElement("div", { className: "volume_wrap" },
                React.createElement(ClickButton, { className: 'room_label_icon', size: 20, icon: volume === 0 ? 'vodVolumeOff' : 'vodVolumeOn', color: volume === 0 ? 'red' : '#fff', onClick: () => changeVolume(volume === 0 ? 1 : 0) }),
                React.createElement("div", { className: "volume_slider" },
                    React.createElement("input", { ref: inputRef, type: "range", min: "0", max: "1", step: "0.1", className: "volume_input", onChange: handlerUpdateVolume })))),
        volume === 0 && React.createElement("div", null, t("vod.clearVolumeGuid"))));
});
export default VolumeButton;
