import React, { useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { isChatOpend, isMemberOpend, toggleChat, toggleMember } from '../../sidebar';
import { ClickButton } from '../../ui';
import { isChatRead } from '../../chat';
import { isCheckPermission } from '../../../room';
import { PERMISSION } from '../../members';
const ToggleSideBar = React.memo(({ className, size, mode, isText, iconStyles, label, enableLable, }) => {
    const dispatch = useDispatch();
    const chatRead = useSelector((state) => (mode === 'chat' ? isChatRead(state) : true));
    const { isChat, isMember } = useSelector((state) => {
        return {
            isChat: isChatOpend(state),
            isMember: isMemberOpend(state),
        };
    }, shallowEqual);
    const isFocus = useMemo(() => (mode === 'chat' ? (isChat ? true : false) : isMember ? true : false), [mode, isChat, isMember]);
    const onClick = useCallback(() => {
        const toggleSide = mode === 'chat' ? toggleChat : toggleMember;
        dispatch(toggleSide());
    }, []);
    const isPermission = useSelector((state) => isCheckPermission(state, PERMISSION.SHOW_MEMBER));
    return (React.createElement(ClickButton, { className: `${className} ${isFocus ? 'isFocus' : ''}`, isText: isText, icon: mode, size: size || enableLable ? 20 : 24, color: isFocus ? APP.config.style.focusColor : chatRead ? '#fff' : '#e91e63', label: label, onClick: onClick, styles: iconStyles }));
});
export default ToggleSideBar;
