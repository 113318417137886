import React, { useMemo } from "react";
import { ClickButton } from "../ui";
import { SURVEY_MENU_TYPE } from "./constant";
const SurveyItemContainer = React.memo(({ index, replied, localId, deadline, title, uuid, isPollPersmission }) => {
    const isVoted = useMemo(() => replied.includes(localId), [replied, localId]);
    const isFinished = useMemo(() => deadline && Math.floor(new Date(deadline).getTime() / 1000) < Math.floor(new Date().getTime() / 1000), [deadline]);
    const goToResult = () => {
        if (isPollPersmission) {
            APP.eventManager.publish('UPDATE_SURVER_MENU', { uuid, type: SURVEY_MENU_TYPE.EDIT });
        }
        else {
            APP.eventManager.publish('UPDATE_SURVER_MENU', { uuid, type: SURVEY_MENU_TYPE.VOTE });
        }
    };
    return (React.createElement("div", { className: "survey_item" },
        React.createElement("div", { className: "index" }, index + 1),
        React.createElement("div", { className: "title overText" }, title),
        React.createElement("div", { className: "index" }, deadline),
        React.createElement(ClickButton, { className: !isPollPersmission && (isVoted || isFinished) ? 'no-pointer no-hover' : '', isText: true, label: isPollPersmission ? "결과" : isVoted ? "설문 조사 완료" : isFinished ? "설문 기간 만료" : "설문 조사 참여", onClick: !isPollPersmission && (isVoted || isFinished) ? null : goToResult })));
});
export default SurveyItemContainer;
