import React from 'react';
import VideoTrack from '../../base/media/components/web/VideoTrack';
import { Button } from '../../base/common';
const VideoWrap = React.memo(({ track }) => {
    return (React.createElement("div", { className: `video_wrap ${!track ? 'gray' : ''}` }, track ? (React.createElement(VideoTrack, { className: "video-input-preview-display flipVideoX", videoTrack: { track }, _noAutoPlayVideo: false, style: {} })) : (React.createElement(Button, { className: "no-hover no-pointer center video-center", styles: {
            buttonWrap: {
                width: 40,
                height: 40,
            },
        }, option: {
            buttonAfterIcon: {
                show: true,
                name: 'camera_off',
                size: 40,
                color: '#B6CDEA',
            },
        } }))));
});
export default VideoWrap;
