import styled, { css } from "styled-components";

export const GroupDualContainerStyled = styled.div`
    width: 100%;
    height: 100%;

    .random_input {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin: 0;

        > * {
            margin: 0;
        }
    }

    .focusGroup {
        margin: 0;
        width: 100%;
        height: 100%;

        .focusHead {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            gap: 24px;
            max-width: 100%;


            h2 {
                flex: 1;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: keep-all;
            }

            > * {
                margin: 0;
            }
        }
    }

    .no_group {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        flex-direction: column;
        gap: 12px;
    }

    .body {
        gap: 12px;
        .no_group  {
            background: #f2f2f2;
        }
    }

    .btn {
        display: flex;
        gap: 4px;
        justify-content: center;

        > * {
            margin: 0!important;
        }
    }

    .remote_btn {
        background: white!important;
        color: red!important;
        border: 2px solid red;
    }
    
    .add_btn {
        display: flex;
        margin: 12px 0;
        gap: 4px;
        align-items: center;

        input {
            padding: 4px;
            box-sizing: border-box;
        }

        > * { margin: 0; }
    } 

    .group {
        display: flex;
        padding: 8px 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;

        .left_start {
            width: 350px;
        }

        .left_prev {
            
            width: 200px;
        }
        
        .left {
            margin: 0;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            min-width: 200px;

            .menu {
                display: flex;
                flex-direction: column;
                width: 200px;
                margin: 0;
                gap: 4px;
                border-bottom: 2px solid;
                padding-bottom: 12px;

                .text-button {
                    margin: 0;
                }
            }
        }
        
        .body {
            flex: 1;
            padding: 4px 8px;
            box-sizing: border-box;
            overflow: hidden;
            display: flex;
            flex-direction: column;
        }
    }
`;

export const GroupListStyled = styled.div`
    margin: 0;
    display: flex;
    gap: 4px;
    overflow: auto; 
    max-height: 100%;   

    .group_item {
        svg {
            fill: #545454;
        }

        .name_wrapper {
            flex: 1;
            max-width: calc(100% - 56px);
            margin: 0;
            cursor: pointer;
        }

        .click_button:hover {
            svg {
                fill: ${props => APP.config.style.focusColor};
            }
        }

        &.isFocus {
            background: ${props => APP.config.style.focusColor};
            color: #fff;

            svg {
                fill: #fff;
            }

            .click_button:hover {
                svg {
                    fill: #545454;
                }
            }    
        }        
    }

    > * {
        margin: 0!important;
    }

    ${props => props.isRow ? css`
        .group_item {
            flex-direction: column;
            display: flex;
            padding: 12px;
            gap: 12px;
            box-sizing: border-box;
            background: #f2f2f2;
            border-radius: 0;

            .title { margin: 0; }
        }

        ${ props.isStart ? css`
            height: 100%;
            gap: 8px;            
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            // grid-template-rows: repeat(auto-fill, 262px);

            .group_item {
                min-width: 250px;

                .btn_wrapper {
                    margin: 0;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                }
            }
        ` : css`
            flex-wrap: wrap;

            .group_item {
                width: 100%;
                display: flex;
                gap: 4px;
    
                .name {
                    flex: 1;
                }

                .title {
                    display: flex;
                    gap: 12px;
                    align-items: baseline;

                    h2 {
                        margin: 0;
                        flex: 1;
                    }

                    .more {
                        margin: 0 0 0 auto;
                        cursor: pointer;
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        gap: 12px;
                    }
                }
                > * {
                    margin: 0!important;
                }
                
            }
        `}
    ` : `
        width: 100%;
        height: 100%;
        flex-direction: column;
       
        .group_item {
            display: flex;
            gap: 12px;
            padding:  8px;
            box-sizing: border-box;
            background: #f2f2f2;
            align-items: center;

            
            .name {
                flex: 1;
            }
        }
        
    ` }
`;

export const GroupTableStyled = styled.div`
    margin: 0;
    display: flex;
    flex-direction: column;
    ${props => props.isFull ? `
        width: 100%;
        height: 100%;

        .table_td {
            border-bottom: 1px solid;
        }
    ` : `
        .table_td {
            background: #fff;
        }
    `}

    > * {
        margin: 0;
    }

    .table_th {
        display: flex;
        gap: 4px;
        > * {
            border-radius: 2px;
        }
    }

    .table_body {
        flex: 1;
        overflow: auto;
    }

    .table_td > .th, .table_th > * {
        background: #545454;
        flex: 1;
        color: #fff;
        text-align: center;
        padding: 4px;
        box-sizing: border-box;
    }

    .start_group {
        > * {
            padding: 8px!important; 
        }

        &.underline {
            border-bottom: 1px solid #fff;
        }
    }

    select {
        width: 80%;
        border: none;
        padding: 4px;
        box-sizing: border-box;
        font-size: 10px;
        text-align: center;
    }

    .table_td {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        > * {
            flex: 1;
            text-align: center;
            padding: 4px 0;
            box-sizing: border-box;
        }
    }
    
    .search {
        display: inline-flex;
        margin: 4px 0 4px auto;

        > * {
            padding: 2px;
            box-sizing: border-box;
        }
        input {
            border: 1px solid;
        }

        .bottom_label {
            background: #545454;
        }
    }
`;

export const GroupMonitoringStyled = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    .group_select {
        margin: 0 12px 8px;
        select {
            width: 100%;
            padding: 8px;
            box-sizing: border-box;
        }
    }
`;
