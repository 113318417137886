import { PERMISSION } from '../base/members';
import { isCheckPermission } from '../room';
import { SET_DUAL_MONITOR, SET_EXPAND, SET_LAYOUT_MEMBERS, SET_LAYOUT_MODE, SET_LAYOUT_STYLE, SET_LAYOUT_TYPE, SET_MAX_PAGE, SET_PAGE } from './actionTypes';
import { SHARE_MODE_LIST, SHARE_PERMISSION_MODE, VIDEO_MODE_LIST, VISIBLE_LIST, videoLayoutMode } from './constants';
import { getDualMonitor } from './functions';
/**
 * 모바일 or 태블릿 or PC 인가 (현재 레이아웃 상태 체크)
 * @param {string} type
 * @returns {{
 *      type: SET_LAYOUT_TYPE,
 *      type: string
 * }}
 */
export function setLayoutType(layoutType) {
    return {
        type: SET_LAYOUT_TYPE,
        layoutType
    };
}
/**
 * 현재 비디오 모드 변경
 * @param {string} mode
 * @param {boolean} isRemote
 * @param {object} visibleList
 * @returns
 */
let layoutMode = videoLayoutMode.grid;
let shareMode = videoLayoutMode.document;
export function setLayoutMode(mode, isRemote = false, isCompulsion = false) {
    return async (dispatch, getState) => {
        const visibleList = {
            [videoLayoutMode.screen]: false,
            [videoLayoutMode.vod]: false,
            [videoLayoutMode.white]: false,
            [videoLayoutMode.document]: false,
            [videoLayoutMode.note]: false,
        };
        if (VISIBLE_LIST.includes(mode))
            visibleList[mode] = true;
        if (!mode)
            mode = layoutMode;
        if (!isRemote) {
            const permission = SHARE_PERMISSION_MODE.includes(mode) ?
                isCheckPermission(getState, PERMISSION.SHARE) :
                isCheckPermission(getState, PERMISSION.LAYOUT);
            if (permission || isCompulsion) {
                const response = await APP.management.changeLayout(mode, visibleList);
                if (response.status !== 200) {
                    console.log("error");
                    return;
                }
            }
            else {
                return;
            }
        }
        if (VIDEO_MODE_LIST.includes(mode))
            layoutMode = mode;
        else if (SHARE_MODE_LIST.includes(mode))
            shareMode = mode;
        dispatch({
            type: SET_LAYOUT_MODE,
            mode,
            visibleList,
            isRemote
        });
    };
}
/**
 * 레이아웃 스타일 값 변경
 * @param {number} width
 * @param {number} height
 * @returns
 */
export function setLayoutContentStyle(width, height) {
    return {
        type: SET_LAYOUT_STYLE,
        width, height
    };
}
/**
 *  화면 보이는 사용자 리스트 세팅
 * {
 *     type: SET_LAYOUT_MEMBERS,
 *     members: {
 *          remote: new Set<string>,
 *          pin: new Set<string>,
 *          voice: new Set<string>
 *          seminar: new Set<string>
*      }
 * }
 */
export function setLayoutMembers({ remote, pin, voice, seminar, screen }) {
    return {
        type: SET_LAYOUT_MEMBERS,
        remote: remote ? [...remote] : [],
        pin: pin ? [...pin] : [],
        voice: voice ? [...voice] : [],
        seminar: seminar ? [...seminar] : [],
        screen: screen ? [...screen] : [],
    };
}
/**
 * 듀얼 모니터 설정 업데이트
 * @param { boolean } isOpen
 * @returns
 */
export function toggleDualMonitor(isOpen) {
    return async (dispatch, getState) => {
        const current_dual = getDualMonitor(getState);
        const isDualMonitor = isOpen !== undefined ? !current_dual : isOpen || false;
        if (current_dual === isDualMonitor)
            return;
        dispatch({ type: SET_DUAL_MONITOR, isDualMonitor });
    };
}
/**
 * 화면페이지
 * @param {number} page
 * @returns
 */
export function setPage(page) {
    return {
        type: SET_PAGE,
        page
    };
}
/**
 * 화면 페이징
 * @param {number} page
 * @returns
 */
export function setMaxPage(maxPage) {
    return {
        type: SET_MAX_PAGE,
        maxPage
    };
}
/**
 *
 * @param {boolean} expand
 * @returns
 */
export function setExpand(expand) {
    return {
        type: SET_EXPAND,
        expand
    };
}
