import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Select } from "../../base/ui";
import { getGroupList } from "../functions";
const ChangeGroupSelect = React.memo(({ className, group_id, show_start = false, changeGroup }) => {
    const group_list = useSelector((state) => getGroupList(state), shallowEqual);
    const [selectedOption, setSelectedOption] = useState();
    useEffect(() => {
        setSelectedOption(group_id);
    }, [group_id]);
    const onHandlerSet = (value) => {
        setSelectedOption(value);
        changeGroup(value);
    };
    const options = useMemo(() => {
        let list = [];
        let gList = group_list;
        if (show_start)
            gList = gList.filter(g => g.status === 'opened');
        list.push({ name: 'no', uuid: '' });
        list = [...list, ...Array.from(gList.values(), (group) => ({ name: group.name, uuid: group.uuid }))];
        return list;
    }, [group_list, selectedOption]);
    useEffect(() => {
        if (selectedOption && !options.find(i => i.uuid === selectedOption))
            onHandlerSet('');
    }, [options, selectedOption]);
    return (React.createElement(Select, { className: className, options: options, selectedOption: selectedOption, setSelectedOption: onHandlerSet }));
});
export default ChangeGroupSelect;
