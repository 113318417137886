import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFilmStyle, getLayoutMemberByType } from '../functions';
import { MemberContainer } from './layout';
import MemberItemContainer from './MemberItemContainer';
import PageButton from './PageButton';
const MembersLayout = React.memo(({ className, type, noPaging = false, maxStyle, outerSize, vertical = false, noShowMember = false, }) => {
    const members = useSelector((state) => getLayoutMemberByType(state, type));
    const [styles, setStyles] = useState({ width: 0, height: 0 });
    const [prev, setPrev] = useState(false);
    const [next, setNext] = useState(false);
    const { showMembers, memberCnt } = useMemo(() => {
        const memberCnt = members && members.length ? members.length : 0;
        return {
            showMembers: memberCnt > 0 ? true : false,
            memberCnt,
        };
    }, [members]);
    useEffect(() => {
        if (maxStyle) {
            setStyles(maxStyle);
        }
        else {
            if (outerSize) {
                let style = Object.assign(outerSize);
                if (!noPaging && prev)
                    style.width = style.width - 30;
                if (!noPaging && next)
                    style.width = style.width - 30;
                const newStyle = getFilmStyle(APP.store.getState, showMembers ? memberCnt : 1, style);
                setStyles(newStyle);
            }
        }
    }, [showMembers, memberCnt, maxStyle, outerSize, prev, next]);
    const membersLayout = useMemo(() => (React.createElement(React.Fragment, null, Array.isArray(members) &&
        members.map(m => {
            if (!m)
                return null;
            return (React.createElement(MemberItemContainer, { key: m, user_uuid: m, styles: styles, isMaxSize: Boolean(maxStyle), vertical: vertical }));
        }))), [members, styles, maxStyle, vertical]);
    const noMemberLayout = useMemo(() => {
        return (React.createElement(MemberContainer, { className: `${className} full`, styles: styles },
            "no ",
            type));
    }, [className, styles, type]);
    const membersLayoutContainer = useMemo(() => (React.createElement(React.Fragment, null,
        !noPaging && (React.createElement(PageButton, { show: prev, type: "prev", setPrev: setPrev, setNext: setNext, vertical: vertical, maxStyle: maxStyle })),
        React.createElement(MemberContainer, { className: className }, membersLayout),
        !noPaging && (React.createElement(PageButton, { show: next, type: "next", setPrev: setPrev, setNext: setNext, vertical: vertical, maxStyle: maxStyle })))), [className, vertical, membersLayout, noPaging, prev, next]);
    const renderMembers = useMemo(() => {
        if (showMembers) {
            return membersLayoutContainer;
        }
        else {
            if (noShowMember)
                return null;
            return noMemberLayout;
        }
    }, [showMembers, noMemberLayout, membersLayoutContainer]);
    return renderMembers;
});
export default MembersLayout;
// function MembersLayout({
//     dispatch,
//     className, mode, type = 'remote', members, noShowMember, noPaging = false,
//     maxStyle, outerSize, vertical, isMobile = false, children
// }) {
//     const [styles, setStyles] = useState({ width: 0, height: 0 });
//     const [prev, setPrev] = useState(false);
//     const [next, setNext] = useState(false);
//     useEffect(() => {
//         const count = members && members.length || 1;
//         if (maxStyle) {
//             setStyles(maxStyle);
//         } else {
//             if (outerSize) {
//                 let style = _.cloneDeep(outerSize);
//                 if (!noPaging && prev) style.width = style.width - 30;
//                 if (!noPaging && next) style.width = style.width - 30;
//                 const newStyle = getFilmStyle(APP.store.getState, count, style);
//                 setStyles(newStyle);
//             }
//         }
//     }, [members, maxStyle, outerSize, prev, next, noPaging], {
//         deepEquls: true
//     });
//     useEffect(() => {
//         if (noPaging) {
//             setPrev(false);
//             setNext(false);
//         }
//     }, [noPaging]);
//     const renderMember = useMemo(() => {
//         return (
//             members && members.map(m => {
//                 if (!m) return null;
//                 return <MemberItemContainer key={m}
//                     user_uuid={m} styles={styles}
//                     isMaxSize={Boolean(maxStyle)} vertical={vertical} />
//             })
//         )
//     }, [members, maxStyle, vertical, styles]);
//     if (members && members.length > 0) {
//         return (
//             <>
//                 { !noPaging && <PageButton show={prev} type='prev' setPrev={setPrev} setNext={setNext} vertical={vertical} maxStyle={maxStyle} /> }
//                     <MemberContainer className={className}>
//                         { renderMember }
//                     </MemberContainer>
//                 { !noPaging && <PageButton show={next} type='next' setPrev={setPrev} setNext={setNext} vertical={vertical} maxStyle={maxStyle} /> }
//             </>
//         )
//     } else {
//         if (noShowMember) return null;
//         return (
//             <MemberContainer className={className} styles={styles}>
//                 no { type }
//             </MemberContainer>
//         )
//     }
// }
// function _mapStateToProps(state, ownProps) {
//     const members = getLayoutMemberByType(state, ownProps.type);
//     return {
//         members
//     }
// }
// export default connect(_mapStateToProps)(MembersLayout);
