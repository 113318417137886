// @ts-ignore
import { storage } from '../util';
import { CLASS_MATE_LOGIN, LOGOUT, SET_USER_INFO } from './actionTypes';
/**
 * (Class Mate 사용됨. 이외에 사용 X)
 */
export function classMateLogin(data) {
    return {
        type: CLASS_MATE_LOGIN,
        data,
    };
}
/**
 * 로그 아웃
 */
export function logout() {
    storage.remove('token');
    APP.API.setAccessToken(null);
    return {
        type: LOGOUT,
    };
}
/**
 * 사용자 정보 저장
 * @param {ILocalState['token']} token
 * @param {ILocalState['user']} user
 * @returns
 */
export function setUserInformation(token, user) {
    storage.save('token', token);
    APP.API.setAccessToken(token?.access_token);
    return {
        type: SET_USER_INFO,
        token,
        user,
    };
}
