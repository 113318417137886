import { ExtrnalAPI } from './extrnal';

const success = data => ({
	complete: true,
	data,
});

const error = error => ({
	complete: false,
	data: error || 'api.defaultError',
});

class API {
	// 초기화
	init() {
		this.access_token = null;
		this.meeting_uuid = null;
		this.api = ExtrnalAPI;
	}

	setAccessToken(access_token) {
		this.access_token = access_token;
	}

	setMeetingUuid(meeting_uuid) {
		this.meeting_uuid = meeting_uuid;
	}

	async _sendEvent(events = {}, data) {
		try {
			const response = await events(data);
			return response;
		} catch (e) {
			return e.response;
		}
	}

	/**
	 * Accoun
	 */
	// 회원 가입
	async signup(data) {
		// email_challenge_uuid: data.email_challenge_uuid || ''
		const response = await this._sendEvent(this.api.uSignupUser, {
			data: data.data,
			user_id: data.user_id,
			access_token: data.access_token,
		});

		const status = response.status;
		if (status === 200) {
			// data: { access_ token, refresh_token }
			return success(response.data);
		} else {
			if (status === 500) return error('api.errorExistId');
			return error(response.data?.message?.format);
		}
	}

	/**
	 * (ACCOUNT)
	 * ID, PWD로 로그인
	 * @param {string} id
	 * @param {string} password
	 * @param {string} enc_type
	 */
	async login(id, password, enc_type) {
		const response = await this._sendEvent(this.api.uLoginUser, { id, password, enc_type });

		const status = response.status;
		if (status === 200) {
			return success(response.data);
		} else {
			if (status === 400 || status === 404) return error('api.errorCheckIdAndPassword');
			else if (status === 403) return error('api.errorCertExpired');
			else return error(response.data?.message?.format);
		}
	}

	/**
	 * (ACCOUNT)
	 * ACCESS_TOKEN으로 사용자 로그인
	 * @param {string} access_token
	 * @param {string} refresh_token
	 * @returns
	 */
	async getUserInformation(access_token, refresh_token) {
		const response = await this._sendEvent(this.api.uGetUser, { access_token });

		const status = response.status;
		if (status === 200) {
			return success(response.data);
		} else {
			if (status === 400) return error('api.errorCertTokenExpired');
			else return error(response.data?.message?.format);
		}
	}

	/**
	 * (ACCOUNT)
	 * 임시 사용자 로그인을 위한 객체 생성.
	 * @returns
	 */
	async createTempUser() {
		const response = await this._sendEvent(this.api.uCreateTempUser);

		const status = response.status;
		if (status === 201) {
			// data: { access_ token, refresh_token, uuid }
			return success(response.data);
		} else return error(response.data?.message?.format);
	}

	/**
	 * (ACCONT)
	 * 중복 로그인 확인
	 * @param {string} user_id
	 * @returns
	 */
	async checkOccupiedUser(user_id) {
		const meeting_uuid = this.meeting_uuid;

		if (meeting_uuid) {
			const response = await this._sendEvent(this.api.uCheckOccupied, { meeting_uuid, user_id });

			const status = response.status;
			if (status === 200) {
				// data: { total_count, founcd_count, items }
				const { total_count, found_count, items } = response.data;
				if (found_count > 0) {
					/**
					 * 사용자 중복 확인됨.
					 */
					return error('dup');
				} else {
					return success();
				}
			} else {
				return error(response.data?.message?.format);
			}
		} else {
			return error('api.roomInformation');
		}
	}
	// 중복 사용자 강퇴
	async kickOccupiedUser(user_id) {
		const meeting_uuid = this.meeting_uuid;
		const aToken = this.access_token;

		if (!meeting_uuid) return error('api.roomInformation');
		if (!aToken) return error('api.userInformation');

		const response = await this._sendEvent(this.api.uKickOccupied, { meeting_uuid, aToken, user_id });

		const status = response.status;
		if (status === 200) {
			return success();
		} else {
			return error(response.data?.message?.format);
		}
	}

	// 중복 사용자 강퇴
	// kickOccupiedUser

	async getRoom(data) {
		let aToken = this.access_token;
		const response = await this._sendEvent(this.api.rSvcSearchRoom, { data, aToken });

		const status = response.status;
		if (status === 200) {
			return success(response.data);
		} else {
			return error(response.data?.message?.format);
		}
	}
	/**
	 * Room
	 */
	/**
	 * (ROOM)
	 * 방 조회
	 * @param {object: { code?, uuid?, name? }} data
	 * @param {boolean} isReservationList
	 * @returns
	 */
	async searchRoom(data, isReservationList = false, isTemp = false) {
		let aToken = this.access_token;
		let tempLogin = false;

		const response = await this._sendEvent(this.api.rSvcSearchRoom, { data, aToken, isTemp });

		const status = response.status;
		if (status === 200) {
			// data: { total_count, founcd_count, items }
			const { total_count, found_count, items } = response.data;

			if (isReservationList) {
				// 예약된 회의에서 사용
				return success({ items, total_count });
			}

			if (found_count > 0) {
				// 하나의 회의실 검색
				// items: [{ code, creation_time, entry_option, invitations: [],
				// media_server_info, members: [{ uuid, user_uuid}, name, owner_uuid, password,
				// password_checking, prev_entering_duration, progress_duration, roles, starut_time, status, ui, uuid ]}]
				const { uuid, code, name, password_checking, media_server_info, start_time, shared_vod_list, status } =
					items[0];
				if (status === 'closed') {
					//
					const data = { message: 'close' };
					window.parent.postMessage(data, '*');
					return error('api.closedRoom');
				}

				// 임시 사용자 로그인
				return success({
					room: {
						option: { uuid, code, name },
						password_checking,
						media_server_info,
					},
					account: {
						tempLogin: tempLogin,
						data: null,
					},
					start_time,
					shared_vod_list,
				});
			} else {
				return error('api.errorNoRoom');
			}
		} else {
			// error message
			if (status === 403) return error('api.errorNoPermission');
			else return error(response.data?.message?.format);
		}
	}

	/**
	 * (ROOM)
	 * 방 객체 생성
	 * createobjectRoom: (string) => void;
	 */
	async createobjectRoom(aToken) {
		const response = await this._sendEvent(this.api.rCreateRoom, aToken);

		const status = response.status;
		if (status === 201) {
			return success(response.data.uuid);
		} else {
			if (status === 403) return error('api.errorNoPermissionCreateRoom');
			return error(response.data?.message?.format);
		}
	}

	/**
	 * (ROOM)
	 * 방 생성
	 */
	async createRoom(name) {
		const aToken = this.access_token;
		if (aToken) {
			const result = await this.createobjectRoom(aToken);
			if (result.complete) {
				const response = await this._sendEvent(this.api.rHoldRoom, { aToken, name, meeting_uuid: result.data });

				const status = response.status;
				if (status === 200) {
					return success(result.data);
				} else {
					if (status === 403) return error('api.errorNoPermissionCreateRoom');
					return error(response.data?.message?.format);
				}
			}
			return result;
		}

		return error('api.requireLogin');
	}

	/**
	 * (ROOM) 방 강제 종료
	 * @returns
	 */
	async forceEndRoom() {
		const aToken = this.access_token;
		const meeting_uuid = this.meeting_uuid;

		if (!aToken) return error('api.requireLogin');
		if (!meeting_uuid) return error('api.roomInformation');

		const response = await this._sendEvent(this.api.rforceEndRoom, { aToken, meeting_uuid });

		if (response.status === 200) {
			return success();
		}

		return error(response.data?.message?.format);
	}

	// 방 예약
	async reservationRoom(data) {
		const aToken = this.access_token;
		if (aToken) {
			const result = await this.createobjectRoom(aToken);

			if (result.complete) {
				const response = await this._sendEvent(this.api.rReserveRoom, {
					aToken,
					data,
					meeting_uuid: result.data,
				});

				const status = response.status;
				if (status === 200) {
					return success(result.data);
				} else {
					if (status === 400) return error('api.errorTime');
					if (status === 500) return error('api.errorLongRoomName');
					return error(response.data?.message?.format);
				}
			}

			return result;
		} else {
			return error('api.requireLogin');
		}
	}

	/**
	 * 방 속성 변경
	 */
	async udpateReservationRoom(data, meeting_uuid = this.meeting_uuid) {
		const aToken = this.access_token;
		if (aToken) {
			const response = await this._sendEvent(this.api.rUpdateRoomProperty, { aToken, meeting_uuid, data });

			const status = response.status;
			if (status === 200) {
				return success(response.data);
			} else {
				if (status === 400) return error('api.errorTime');
				if (status === 500) return error('api.errorLongRoomName');
				return error(response.data?.message?.format);
			}
		} else {
			return error('api.requireLogin');
		}
	}

	/**
	 * 예약된 회의 삭제
	 */
	async deleteReservationRoom(meeting_uuid) {
		const aToken = this.access_token;
		if (aToken) {
			const response = await this._sendEvent(this.api.rDeleteReservationRoom, { aToken, meeting_uuid });
			const status = response.status;
			if (status === 200) {
				return success(meeting_uuid);
			} else {
				if (status === 404) return error('api.errorNoReservationRoom');
				return error(response.data?.message?.format);
			}
		} else {
			return error('api.requireLogin');
		}
	}

	/**
	 * 방 속성 변경
	 */
	async udpateconvertShareDocument(data, meeting_uuid = this.meeting_uuid) {
		const aToken = this.access_token;
		if (aToken) {
			const response = await this._sendEvent(this.api.rUpdateRoomProperty, { aToken, meeting_uuid, data });

			const status = response.status;
			if (status === 200) {
				return success(response.data);
			} else {
				if (status === 400) return error('api.errorTime');
				if (status === 500) return error('api.errorLongRoomName');
				return error(response.data?.message?.format);
			}
		} else {
			return error('api.requireLogin');
		}
	}

	/**
	 * 예약된 회의 삭제
	 */
	async deleteReservationRoom(meeting_uuid) {
		const aToken = this.access_token;
		if (aToken) {
			const response = await this._sendEvent(this.api.rDeleteReservationRoom, { aToken, meeting_uuid });
			const status = response.status;
			if (status === 200) {
				return success(meeting_uuid);
			} else {
				if (status === 404) return error('api.errorNoReservationRoom');
				return error(response.data?.message?.format);
			}
		} else {
			return error('api.requireLogin');
		}
	}

	/**
	 * Document File
	 * 문서 파일
	 * convertShareDocument: (object, string) => void;
	 */
	async convertShareDocument(file, uuid) {
		const meeting_uuid = this.meeting_uuid;

		if (meeting_uuid) {
			const formData = new FormData();
			formData.append('file', file);

			const response = await this._sendEvent(this.api.dConvertDocument, { file: formData, meeting_uuid });
			const status = response.status;
			if (status === 202) {
				return success(response.data);
			} else {
				if (status === 503) return error({ message: 'api.errorNoApplyFile', uuid });
				if (status === 413) return error({ message: 'api.errorBigVolume', uuid });
				return error({ message: response.data?.message?.format, uuid });
			}
		} else {
			return error({ message: 'api.errorNoRoom', uuid });
		}
	}

	/**
	 * chat(채팅)
	 */
	async convertChatFile(file) {
		const formData = new FormData();
		formData.append('file', file);

		const response = await this._sendEvent(this.api.cConvertFile, { file: formData });
		const status = response.status;

		if (status === 200) {
			return success(response.data);
		} else {
			return error(response.data?.message?.format);
		}
	}

	/**
	 * 초대 (이메일 초대)
	 * -> 회의 초대 ( 예약된 회의에 등록 )
	 * -> 회의 알림 ( 이메일 전송 )
	 */
	async inviteReservationRoom(meeting_uuid, data) {
		const aToken = this.access_token;
		if (aToken) {
			// 예약된 회의실에 초대 기록 등록
			const result = await this._sendEvent(this.api.rInviteReservationRoom, { aToken, meeting_uuid, data });

			if (result.status === 200) {
				// 이메일 전송
				const response = await this._sendEvent(this.api.rSendInviteRoom, { aToken, meeting_uuid });

				if (response.status === 200) return success(response.data);
				else {
					if (response.status === 500) return error('api.errorInvite');
					return error(response.data?.message?.format);
				}
			} else {
				return error(result.data?.message?.format);
			}
		} else {
			return error('api.requireLogin');
		}
	}

	/**
	 * 서버 녹화 객체 생성
	 */
	async createRecordResoure(data) {
		const meeting_uuid = this.meeting_uuid;
		const aToken = this.access_token;

		if (!meeting_uuid) return error('api.roomInformation');
		if (!aToken) return error('api.userInformation');

		const response = await this._sendEvent(this.api.createRecordResoure, { aToken, meeting_uuid, ...data });

		if (response.status === 201) {
			return success(response.data.result);
		} else {
			if (response.status === 403) return error('api.errorNoAuth');
			return error(response.data?.message?.format);
		}
	}

	/**
	 * (REC) 녹화 데이터 PUSH
	 */
	async appendRecordStreaming(data) {
		const aToken = this.access_token;
		if (!aToken) return error('api.userInformation');

		// /api/recording/v1/recordings/{recording_id}/resources/{resource_id}/append
		const response = await this._sendEvent(this.api.appendRecordStreaming, { ...data, aToken });

		if (response.status === 201) {
			return success(response.data);
		} else {
			if (response.status === 403) return error('api.errorNoAuth');
			return error(response.data?.message?.format);
		}
	}

	/**
	 * Group
	 */
	async getRemoteUserInformation(data) {
		const meeting_uuid = this.meeting_uuid;
		const aToken = this.access_token;

		if (!meeting_uuid) return error('api.roomInformation');
		if (!aToken) return error('api.userInformation');
		const response = await this._sendEvent(this.api.uGetMember, {
			aToken,
			data: { ...data, meeting_id: meeting_uuid },
		});

		if (response?.status === 200) {
			const items = response.data?.result?.items;
			return success(items);
		} else {
			if (response.status === 403) return error('api.errorNoAuth');
			return error(response.data?.message?.format);
		}
	}
	/**
	 * (GROUP) 그룹 리스트 조회
	 * @param {object} data {name} - 수정할 데이터
	 * @returns
	 */
	async getGroupList(data) {
		const meeting_uuid = this.meeting_uuid;
		const aToken = this.access_token;

		if (!meeting_uuid) return error('api.roomInformation');
		if (!aToken) return error('api.userInformation');

		const response = await this._sendEvent(this.api.gGroupList, {
			aToken,
			data: { ...data, meeting_id: meeting_uuid },
		});
		if (response.status === 200 && response?.data?.result?.items?.length > 0)
			return success(response.data.result.items[0].groups);
		else return error(response.data?.message?.format);
	}

	/**
	 * (GROUP) 그룹 생성
	 * @param {string} name
	 * @returns
	 */
	async createGroup(name) {
		const meeting_uuid = this.meeting_uuid;
		const aToken = this.access_token;

		if (!meeting_uuid) return error('api.roomInformation');
		if (!aToken) return error('api.userInformation');

		const response = await this._sendEvent(this.api.gCreateGroup, { aToken, meeting_uuid, name });

		if (response.status === 201) return success(response.data.result);
		else return error(response.data?.message?.format);
	}

	/**
	 * (GROUP) 그룹 수정
	 * @param {object} data {name} - 수정할 데이터
	 * @param {string} group_id: 그룹 아이디
	 */
	async modifyGroup(data, group_id) {
		const meeting_uuid = this.meeting_uuid;
		const aToken = this.access_token;

		if (!meeting_uuid) return error('api.roomInformation');
		if (!aToken) return error('api.userInformation');

		const response = await this._sendEvent(this.api.gModifyGroup, {
			aToken,
			meeting_uuid,
			group_id,
			data,
		});

		if (response.status === 200) return success(response.data);
		else {
			if (response.status === 400) return error('api.errorNoPermission');
			else if (response.status === 409) return error('api.errorDupHost');
			return error(response.data?.message?.format);
		}
	}

	/**
	 * (GROUP) 그룹 전체 시작
	 * @param {object} data {name} - 수정할 데이터
	 * @param {string} group_id: 그룹 아이디
	 */
	async updateAllGroup(data) {
		const meeting_uuid = this.meeting_uuid;
		const aToken = this.access_token;

		if (!meeting_uuid) return error('api.roomInformation');
		if (!aToken) return error('api.userInformation');

		const response = await this._sendEvent(this.api.gModifyAllGroup, {
			aToken,
			meeting_uuid,
			data,
		});

		if (response.status === 200) return success(response.data);
		else {
			if (response.status === 400) return error('api.errorNoPermission');
			return error(response.data?.message?.format);
		}
	}

	/**
	 * (GROUP) 그룹 삭제
	 * @param {string} group_id: 그룹 아이디
	 */
	async deleteGroup(group_id) {
		const meeting_uuid = this.meeting_uuid;
		const aToken = this.access_token;

		if (!meeting_uuid) return error('api.roomInformation');
		if (!aToken) return error('api.userInformation');

		const response = await this._sendEvent(this.api.gDeleteGroup, {
			aToken,
			meeting_uuid,
			group_id,
		});

		if (response.status === 200) return success(response.data);
		else {
			if (response.status === 400) return error('api.errorNoPermission');
			return error(response.data?.message?.format);
		}
	}

	/**
	 * 그룹 랜덤 생성
	 */
	async randomizeGroup(data) {
		const meeting_uuid = this.meeting_uuid;
		const aToken = this.access_token;

		if (!meeting_uuid) return error('api.roomInformation');
		if (!aToken) return error('api.userInformation');

		const response = await this._sendEvent(this.api.gRandomizeGroup, { aToken, meeting_uuid, data });

		if (response.status === 200) return success(response.data);
		else {
			if (response.status === 400) return error('api.errorNoPermission');
			return error(response.data?.message?.format);
		}
	}

	/**
	 * (GROUP) 그룹 할당
	 * @param {object} data
	 * @param {string} group_id
	 */
	async assignGroup(data, group_id) {
		const meeting_uuid = this.meeting_uuid;
		const aToken = this.access_token;

		if (!meeting_uuid) return error('api.roomInformation');
		if (!aToken) return error('api.userInformation');

		const response = await this._sendEvent(this.api.gAssignGroup, { aToken, meeting_uuid, data, group_id });
		if (response.status === 200) return success(response.data);
		else {
			if (response.status === 400) return error('api.errorNoPermission');
			return error(response.data?.message?.format);
		}
	}

	async partnerLogin({ token, user_id }) {
		const response = await this._sendEvent(this.api.pPartnerLogin, { token, user_id });

		if (response.status === 200) return success(response.data.result);
		else {
			return error(response.data?.message?.format);
		}
	}

	/**
	 * (VOD) 리스트 조회하기
	 */
	async getVodList(data) {
		const aToken = this.access_token;
		if (!aToken) return error('api.userInformation');

		const response = await this._sendEvent(this.api.vGetVodList, { aToken, data });
		if (response.status === 200) return success(response.data.result);
		else {
			return error(response.data?.message?.format);
		}
	}

	/**
	 * (VOD) 등록하기
	 * @param {FormData} file
	 * @param {string} name
	 * @param {string} owner_id
	 * @returns
	 */
	async registVod(data, name, owner_id) {
		const aToken = this.access_token;
		if (!aToken) return error('api.userInformation');

		const response = await this._sendEvent(this.api.vRegistVod, { aToken, name, owner_id, data });
		if (response.status === 201) return success(response.data.result);
		else {
			if (response.status === 500) return error('api.errorNoApplyFile');
			return error(response.data?.message?.format);
		}
	}

	/**
	 * (VOD) 삭제하기
	 * @param {string} album_id
	 * @param {string} owner_id
	 */
	async deleteVod(album_id, owner_id) {
		const aToken = this.access_token;
		if (!aToken) return error('api.userInformation');

		const response = await this._sendEvent(this.api.vDeleteVod, { aToken, album_id, owner_id });
		if (response.status === 200) return success(response.data.result);
		else {
			return error(response.data?.message?.format);
		}
	}

	/**
	 * (VOD) 회의실 내 VOD 리스트 틍록
	 * @param {object[]} shared_vods
	 * @param {string} group_id
	 * @returns
	 */
	async changeVodList(shared_vods, meeting_uuid, method = 'replace', group_id = '') {
		const aToken = this.access_token;

		if (!meeting_uuid) return error('api.roomInformation');
		if (!aToken) return error('api.userInformation');

		const response = await this._sendEvent(this.api.vChangeVodList, {
			aToken,
			method,
			meeting_uuid,
			group_id,
			shared_vods,
		});
		if (response.status === 200) return success(response.data);
		else return error(response.data?.message?.format);
	}

	/**
	 * (VOD) vod 스트리밍 저장
	 */
	async registVodStreaming(album_id, owner_id) {
		const aToken = this.access_token;

		if (!aToken) return error('api.userInformation');

		const response = await this._sendEvent(this.api.vRegistVodStreaming, { aToken, album_id, owner_id });
		if (response.status === 201) return success(response.data.result);
		else return error(response.data?.message?.format);
	}

	/**
	 * (VOD) vod 인증 토큰 발급
	 * @param {string} member_id
	 * @param {string} streaming_id
	 * @returns
	 */
	async generateStreamingAuth(member_id, streaming_id) {
		const meeting_uuid = this.meeting_uuid;
		const aToken = this.access_token;

		if (!meeting_uuid) return error('api.roomInformation');
		if (!aToken) return error('api.userInformation');

		const response = await this._sendEvent(this.api.vGenerateStreamingAuth, { aToken, meeting_uuid, streaming_id });
		if (response.status === 200) return success(response.data.result);
		else {
			if (response.status === 400) return error('api.errorNoPermission');
			return error(response.data?.message?.format);
		}
	}

	/**
	 * (ATTENDANCE) 출석체크 가져오기
	 */
	async getAttendancesCheck() {
		const meeting_uuid = this.meeting_uuid;
		const aToken = this.access_token;

		if (!meeting_uuid) return error('api.roomInformation');
		if (!aToken) return error('api.userInformation');

		const response = await this._sendEvent(this.api.aGetAttendancesCheck, { aToken, meeting_uuid });
		if (response.status === 200) return success(response.data);
		else {
			return error(response.data?.message?.format);
		}
	}

	/**
	 * (ATTENDANCE) 출석체크 생성하기
	 * @param {*} data
	 * @returns
	 */
	async createAttendancesCheck(data) {
		const meeting_uuid = this.meeting_uuid;
		const aToken = this.access_token;

		if (!meeting_uuid) return error('api.roomInformation');
		if (!aToken) return error('api.userInformation');

		const response = await this._sendEvent(this.api.aCreateAttendances, { aToken, meeting_uuid, ...data });
		if (response.status === 201) return success(response.data);
		else {
			return error(response.data?.message?.format);
		}
	}

	/**
	 * (ATTENDANCE) 출석체크 생성하기
	 * @param {*} data
	 * @returns
	 */
	async checkAttendances(attendance_id) {
		const aToken = this.access_token;
		if (!aToken) return error('api.userInformation');

		const response = await this._sendEvent(this.api.aCheckAttendances, { aToken, attendance_id });
		if (response.status === 200) return success(response.data);
		else {
			return error(response.data?.message?.format);
		}
	}

	/**
	 * (Chat) 채팅 저장
	 */
	async saveChat() {
		const meeting_uuid = this.meeting_uuid;

		if (!meeting_uuid) return error('api.roomInformation');
		const response = await this._sendEvent(this.api.cSaveChat, { meeting_uuid });
		if (response.status === 200) return success(response.data);
		else {
			return error(response.data?.message?.format);
		}
	}

	/**
	 * (SURVEY) 설문 조사 가져오기
	 */
	async getSurveyList(survey_uuid) {
		const meeting_uuid = this.meeting_uuid;
		if (!meeting_uuid) return error('api.roomInformation');

		const response = await this._sendEvent(this.api.sGetSurveyList, { meeting_uuid, survey_uuid });
		if (!response) return error();
		if (response.status === 200) return success(response.data.result);
		else {
			return error(response.data?.message?.format);
		}
	}

	/**
	 * (KLASS MATE - DOCUMENT)
	 * 문서 가져오기
	 */
	async getKlassDocument() {
		const meeting_uuid = this.meeting_uuid;
		if (!meeting_uuid) return error('api.roomInformation');

		const response = await this._sendEvent(this.api.kGetKlassDocument, { meeting_uuid });
		if (!response) return error();
		if (response.status === 200) return success(response.data.items);
		else {
			return error(response.data?.message?.format);
		}
	}

	async getMemberByMeetingId(data) {
		const response = await this._sendEvent(this.api.getMemberByMeetingId, data);

		if (response.status === 200) return success(response.data);
		else {
			return error(response.data?.message?.format);
		}
	}

	/**
	 * 공개 설정 가져오기
	 */
	async getPublicSetting() {
		const response = await this._sendEvent(this.api.getPublicSetting);

		if (response.status === 200) return success(response.data.result);
		else {
			return error(response.data?.message?.format);
		}
	}

	/**
	 * 공개 설정 수정하기
	 */
	async setPublicSetting(data) {
		const response = await this._sendEvent(this.api.getPublicSetting, data);

		if (response.status === 200) return success(response.data.result);
		else {
			return error(response.data?.message?.format);
		}
	}
}

export default new API();
