import React, { memo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ClickButton, Input } from "../../base/ui";
import { setGroupAllList } from "../action";
import { isHaveGroupByName } from "../functions";
const AddGroupButton = React.memo(({ handlerClose }) => {
    const dispatch = useDispatch();
    const nameRef = useRef(null);
    const [text, setText] = useState();
    const onKeyPress = e => {
        if (e.key === 'Space' || e.key === 'Enter' || e.key === 'NumpadEnter') {
            e.preventDefault();
            addGroup();
        }
    };
    const addGroup = async () => {
        try {
            let message;
            const name = text;
            if (!name)
                message = "group.requiredGroup";
            else {
                const isHave = isHaveGroupByName(name);
                if (isHave)
                    message = "group.alreadyGroup";
                else {
                    /**
                     * 그룹 생성
                     */
                    const response = await APP.API.createGroup(name);
                    if (!response.complete) {
                        message = response.message;
                    }
                    else {
                        dispatch(setGroupAllList());
                        if (nameRef && nameRef.current)
                            nameRef.current.value = "";
                        APP.eventManager.publish('UPDATE_OPEN_POPUP');
                    }
                }
            }
            APP.eventManager.publish('ALRET_GROUP_MESSAGE', { message });
        }
        catch (err) {
            console.log("에러 처리 필요 ", err);
            /**
             * (FIX ME) 에러 처리 필요
             */
        }
    };
    const SaveIcon = memo(() => {
        return (React.createElement(ClickButton, { icon: "plus", size: 18, color: "#545454", onClick: addGroup }));
    });
    return (React.createElement(Input, { ref: nameRef, className: 'add_btn', bottomLabel: React.createElement(SaveIcon, null), onKeyPress: onKeyPress, onChange: setText }));
});
export default AddGroupButton;
