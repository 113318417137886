import React, { useEffect, useMemo, useRef } from "react";
import { Rect } from "react-konva";
const PADDING = 10;
const ScrollCanvas = React.memo(({ uuid, index, scale, stageWidth, stageHeight, horizontalWidth, verticalHeight, canvasPos, setCanvasPos }) => {
    const verticalBarRef = useRef(null);
    const horizontalBarRef = useRef(null);
    useEffect(() => {
        if (horizontalBarRef && horizontalBarRef.current) {
            const horizontalBar = horizontalBarRef.current;
            horizontalBar.x(0);
        }
        if (verticalBarRef && verticalBarRef.current) {
            const verticalBar = verticalBarRef.current;
            verticalBar.y(0);
        }
    }, [uuid, index]);
    const renderVerticalBar = useMemo(() => {
        if (verticalHeight) {
            return (React.createElement(Rect, { key: "vertical", ref: verticalBarRef, className: "scroll", width: PADDING, height: verticalHeight, x: stageWidth - PADDING, y: Math.max(Math.min(-(canvasPos.y / scale), stageHeight - verticalHeight - PADDING), 0), fill: 'gray', opacity: 0.5, draggable: true, dragBoundFunc: (pos) => {
                    console.log(pos, stageHeight - verticalHeight);
                    pos.x = stageWidth - PADDING;
                    pos.y = Math.max(Math.min(pos.y, stageHeight - verticalHeight - PADDING), 0);
                    console.log(pos.y);
                    setCanvasPos({ ...canvasPos, y: -(pos.y * scale) });
                    return pos;
                } }));
        }
    }, [stageWidth, stageHeight, verticalHeight, canvasPos.y, scale]);
    const renderHorizontal = useMemo(() => {
        if (horizontalWidth) {
            return (React.createElement(Rect, { key: "horizontal", ref: horizontalBarRef, className: "scroll", width: horizontalWidth, height: PADDING, x: Math.max(Math.min(-(canvasPos.x / scale), stageWidth - horizontalWidth - PADDING), 0), y: stageHeight - PADDING, fill: 'gray', opacity: 0.5, draggable: true, dragBoundFunc: (pos) => {
                    pos.x = Math.max(Math.min(pos.x, stageWidth - horizontalWidth - PADDING), 0);
                    pos.y = stageHeight - (PADDING);
                    setCanvasPos({ ...canvasPos, x: -(pos.x * scale) });
                    return pos;
                } }));
        }
    }, [stageWidth, stageHeight, horizontalWidth, canvasPos.x, scale]);
    const content = [
        renderVerticalBar,
        renderHorizontal
    ].filter(Boolean);
    return (React.createElement(React.Fragment, null, content));
});
export default ScrollCanvas;
