import styled from "styled-components";
export const SettingStyled = styled.div `
    display: flex;
    width: 100%;
    margin-top: 12px;
    gap: 24px;
    flex-direction: ${props => props.isMobile ? 'column' : 'row'};

    .title  {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        font-size: 18px;
        font-weight: 500;

        > * { margin: 0; }
    }

    input[type="number"] {
        border: 1px solid #d5d5d5;
        border-radius: 8px;
        padding: 8px;
        box-sizing: border-box;
        text-align: center;
        width: 80%;
        margin-right: 12px;
    }

    .mobile_tab {
        .click_button {
            margin-bottom: 4px;
        }
    }

    .guid {
        white-space: pre-wrap;
        word-break: keep-all;
        text-align: center;
        line-height: 25px;
        margin: 4px 0 20px;
    }

    .grid_count_input {
        margin: 0;
    }

    > * {
        margin: 0;
    }

    .tab {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .content {
        flex: 1;
        min-height: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 12px;

        .device-permission-btn {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 16px;
            max-width: 350px;
        }

        .switch {
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            overflow: hidden;

            input + .slider:before {
                left: 8px;
                right: auto;
            }
            input:checked + .slider:before {
                left: auto;
                right: 8px;
            }

        
            .slider {
                position: relative;
                padding: 8px 40px;

                .text {
                    min-width: 75px;
                }
            }

            .slider:before {
                width: 20px;
                height: 20px;
                border: 1px solid #d5d5d5;
                box-sizing: border-box;
               
            }

            .text {
                text-align: center;
            }
        }

        > *:not(.switch):not(.guid):not(h4){
            margin: 0;
        }
    }  

    .count_container {
        width: 100%;
    }

    .screen_table {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;

        &.top {
            border-top: 2px solid #d5d5d5;
        }

        .screen_item {
            display: flex;
            margin:  0;
            padding: 8px;
            box-sizing: border-box;
            justify-content: flex-start;
            align-items: center;

            .screen_title {
                font-weight: bold;
            }
            
            > * {
                flex: 1;
                justify-content: flex-start;
            }
        }

        .screen_radio {
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: flex-start;

            > * { margin: 0;}

            label {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                cursor: pointer;
            }
        }

        .screen_frame {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 12px;

            > * {
                margin: 0;
            }

            .text-button {
                padding: 4px 8px;
                height: fit-content;
                margin: 0;

                &.disable {
                    background: #d5d5d5;
                    color: #000;
                    font-weight: bold;
                }
            }
        }
    }

    .radio {
        display: flex;
        gap: 12px;
        justify-content: center;

        > * { margin: 0; }

        label {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
        }
    }

    .label_option  {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .military_time {
        border-top: 1px solid #d5d5d5;
        margin-top: 12px!important;
        padding: 8px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    
    .content {
        .text-button { width: fit-content!important; }
    }
`;
export const LabelStyled = styled.div `
    display: flex;
    flex-direction: column;
    flex: 1;

    .label_container {
        display: flex;
        margin: 0;
        
        .item {
            margin: 0;
            flex: 1;
        }

        input {
            display: none;
        }

        label > .click_button {
            display: flex;
            flex-direction: column;
        }
    }
`;
