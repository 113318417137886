import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClickButton from './ClickButton.web';
import Input from './Input.web';
import TextElement from './TextElement.web';
import { throttle } from 'lodash';
const PreviewInputBox = React.memo(({ className, isMobile = false, isShowInput = false, isShowTitle = false, isShowLable = false, guid, name, handler, icon }) => {
    const { t } = useTranslation();
    const [state, setState] = useState();
    const _handlerRoom = throttle(() => {
        handler && handler(state);
    }, 1000, { leading: true, trailing: false });
    const _onKeyPress = e => {
        if (e.key === 'Space' || e.key === 'Enter' || e.key === 'NumpadEnter') {
            e.preventDefault();
            _handlerRoom();
        }
    };
    const renderIcon = useMemo(() => (React.createElement(ClickButton, { className: `enter-icon ${guid ? 'center' : ''}`, label: isShowLable ? "START" : undefined, icon: isShowLable ? undefined : 'enter', color: guid ? "#fff" : "#29AAE2", size: 28, onClick: _handlerRoom })), [guid, isShowLable, _handlerRoom]);
    return (React.createElement("div", { className: `preview-item ${className}`, key: name, onClick: isMobile ? handler : undefined },
        React.createElement("div", { className: 'preview-input-box' },
            isShowTitle && React.createElement("div", { className: 'title_wrapper' },
                React.createElement(TextElement, { className: 'title', text: `lobby.${name}` }),
                icon),
            isShowInput && React.createElement(Input, { className: 'preview-input', type: "text", bottomLabel: isShowLable ? undefined : renderIcon, label: isShowLable ? t(`lobby.${name}Label`) : undefined, placeholder: t(`lobby.${name}Placeholder`), onKeyPress: _onKeyPress, onChange: setState }),
            (isShowLable || guid) && renderIcon,
            guid && React.createElement("p", { className: 'guid' }, t(`lobby.${guid}`)))));
});
export default PreviewInputBox;
