import React, { useEffect, useMemo, useRef } from "react";
import { Group, Text, Transformer } from "react-konva";
import { useTranslation } from "react-i18next";
const TextCanvas = React.memo(({ selected, canvasTool, points, ratio, handlerUpdateLine }) => {
    const { t } = useTranslation();
    const shapeRef = useRef(null);
    const trRef = useRef(null);
    const hitStrokeWidth = useMemo(() => canvasTool.thickness + 10, [canvasTool.thickness]);
    const { figureWidth, figureHeight } = useMemo(() => {
        const width = canvasTool.figureWidth || 0;
        const height = canvasTool.figureHeight || 0;
        return {
            figureWidth: width,
            figureHeight: height,
        };
    }, [canvasTool.figureWidth, canvasTool.figureHeight]);
    const { x, y } = useMemo(() => {
        return {
            x: points[0],
            y: points[1]
        };
    }, [points]);
    const text = useMemo(() => canvasTool?.text || t("document.dblInput"), [canvasTool.text]);
    const changeTransformer = () => {
        const node = shapeRef.current;
        if (node) {
            handlerUpdateLine({
                canvasTool: {
                    ...canvasTool,
                    figureWidth: node.width() * node.scaleX(),
                    figureHeight: node.height() * node.scaleY()
                },
                points: [node.x(), node.y()]
            });
            node.scaleX(1);
            node.scaleY(1);
        }
    };
    const onDblClick = () => {
        const textNode = shapeRef.current;
        const tr = trRef.current;
        if (!textNode || !tr)
            return;
        textNode.hide();
        tr.hide();
        var textPosition = textNode.absolutePosition();
        var areaPosition = {
            x: textPosition.x,
            y: textPosition.y
        };
        var rotation;
        var textarea = document.createElement('textarea');
        const canvasElement = document.getElementById("canvas_wrapper");
        if (!canvasElement)
            return;
        canvasElement.appendChild(textarea);
        textarea.value = canvasTool?.text || '';
        textarea.style.position = 'absolute';
        textarea.style.top = areaPosition.y + 'px';
        textarea.style.left = areaPosition.x + 'px';
        textarea.style.width = figureWidth + 'px';
        textarea.style.height = figureHeight + 'px';
        textarea.style.fontSize = textNode.fontSize() + 'px';
        textarea.style.fontFamily = textNode.fontFamily();
        textarea.style.textAlign = textNode.align();
        textarea.style.color = textNode.fill();
        rotation = textNode.rotation();
        var transform = '';
        if (rotation) {
            transform += 'rotateZ(' + rotation + 'deg)';
        }
        var px = 0;
        var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isFirefox) {
            px += 2 + Math.round(textNode.fontSize() / 20);
        }
        transform += 'translateY(-' + px + 'px)';
        textarea.style.transform = transform;
        textarea.focus();
        function removeTextarea() {
            if (textarea.parentNode) {
                textarea.parentNode.removeChild(textarea);
            }
            window.removeEventListener('click', handleOutsideClick);
            if (!textNode || !tr)
                return;
            textNode.show();
            tr.show();
        }
        function handleOutsideClick(e) {
            if (e.target !== textarea) {
                if (!textNode)
                    return;
                textNode.text(textarea.value);
                handlerUpdateLine({
                    canvasTool: {
                        ...canvasTool,
                        text: textarea.value,
                        figureWidth: textarea.clientWidth,
                        figureHeight: textarea.clientHeight
                    },
                    points: [x, y]
                });
                removeTextarea();
            }
        }
        setTimeout(() => {
            window.addEventListener('click', handleOutsideClick);
        });
    };
    useEffect(() => {
        if (selected) {
            if (trRef && trRef.current && shapeRef && shapeRef.current) {
                trRef.current.nodes([shapeRef.current]);
                // @ts-ignore
                if (trRef.current.getLayer() !== null)
                    trRef.current.getLayer().batchDraw();
            }
        }
    }, [selected]);
    return (React.createElement(Group, { scaleX: ratio, scaleY: ratio, width: figureWidth, height: figureHeight, onDblClick: onDblClick, onDblTap: onDblClick },
        React.createElement(Text, { ref: shapeRef, name: "text", className: canvasTool.key, width: figureWidth, height: figureHeight, fontSize: hitStrokeWidth, x: x, y: y, draggable: selected, onDragEnd: changeTransformer, text: text }),
        selected && React.createElement(Transformer, { ref: trRef, keepRatio: false, rotateEnabled: false, centeredScaling: true, onTransformEnd: changeTransformer, enabledAnchors: ['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right'], boundBoxFunc: (oldBox, newBox) => {
                if (newBox.width < 5 || newBox.height < 5) {
                    return oldBox;
                }
                return newBox;
            } })));
});
export default TextCanvas;
