import { getAccessToken } from "../local";
import { showNotification } from "../notification/actions";

export class ServerTest {
    constructor(startRecord, stopRecord) {
        this.startRecord = startRecord;
        this.stopRecord = stopRecord;
        this.recordingData = [];
        this.userID;

        this.option;

        this.websocket;
    }

    start() {
        this.startRecord();

        // this._createWebSocket()
        //     .then(async () => {
        //         const access_token = getAccessToken(APP.store.getState);
                
        //         const response = await this._request("SetAuth", { access_token }, null, 5000);
        //         if (response.status === 200) {
        //             // this.userID = response.payload.result.user.user_id;

        //             this.startRecord();
        //         } 
        //     }).catch((err) => {
        //         console.log(err);
        //         this.error("notify.createError - 1");
        //     });
               
    }

    /**
     * 
     * @param {object} option  
     * { recording_id: string, resource_id: string, secret_key: string }
     */
    async useResource(option) { 
        this.option = option;   
        // const response = await this._request("UseResource", option, null, 5000);

        // if (response.status === 200) {
        //     
        // } else {
        //     this.error("notify.createError - 2");
        // }         
    }    

    async save(data) {  
        APP.API.appendRecordStreaming({ data, ...this.option })
            .then(response => {
                if (response.complete) {

                } else {
                    this.error("notify.appendRecordError", { error: response.data });
                }
            });

        // if (this.option) {
        //     const response = await this._request("AppendResource", this.option, data, 5000);
        //     if (response.status === 200) {
        //         this.recordingData.push(data);
        //     } else {
        //         this.error("notify.createError - 3");
        //     }
        // }        
    }

    error(message, props) {
        // message && APP.UI.alertMessage(message);
        APP.store.dispatch(showNotification({
            appearance: "error",
            titleKey: "notify.recordError",
            description: message,
            descriptionProps: props
        }));
        
        this.stop();
    }

    _cleanup() {

    }

    stop() {
        this.stopRecord();     
    }

    _createWebSocket() {
        if (!!this.websocket) {
            throw new Error("이미 접속중입니다.");
        }

        this.nextId = 1;
        this.onDisconnected = [];
        this.onMessage = [];
        this.userID = null;
        return new Promise((resolve, reject) => {
            const ws = new WebSocket(`wss://${location.host}/api/recording/v1/websocket`);
                        
            ws.onopen = () => {
                ws.onclose = () => {
                    this.onDisconnected.forEach((handle) => {
                        handle();
                    });
                    
                    this._cleanup();
                };
                
                ws.onmessage = (msg) => {
                    this.onMessage.forEach((handle) => {
                        handle(msg.data);
                    });
                };
                
                this.websocket = ws;
                return resolve();
            };
            
            ws.onerror = (err) => {
                console.log(err)
                this._cleanup();
                return reject(err);
            };
        });
    }

    _subscribe(handle) {
        this.onMessage.push(handle);
        
        return () => {
            const index = this.onMessage.findIndex((element) => {
                return element == handle;
            });
            
            if (index >= 0) {
                this.onMessage.splice(index, 1);
            }
        };
    }
    
    _subscribeDisconnection(handle) {
        this.onDisconnected.push(handle);
        
        return () => {
            const index = this.onDisconnected.findIndex((element) => {
                return element == handle;
            });
            
            if (index >= 0) {
                this.onDisconnected.splice(index, 1);
            }
        };
    }

    async _request(clazz, payload, binary, timeout) {
        if (!this.websocket || this.websocket.readyState !== WebSocket.OPEN) {
            this.props.setRecordParticipant(false, RECORDING_TYPE.SERVER);
            // throw new Error("요청할 수 있는 상태가 아닙니다.");
            return;
        }
        
        const ws = this.websocket;
        const requestID = `${Date.now()}-${++this.nextId}`;
        if (binary) {
            ws.send (
                new Blob([ new TextEncoder().encode(
                    JSON.stringify({ class: clazz, id: requestID, payload: payload })
                ), new Uint8Array([0]), binary ])
            );
        } else {
            ws.send( 
                JSON.stringify({ class: clazz, id: requestID, payload: payload }) 
            );
        }

        return this._waitFor((data) => JSON.parse(data), (res) => res.id === requestID, timeout);
    }

    async _waitFor(hook, check, timeout) {
        return new Promise((resolve, reject) => {
            let timerID;
            let unsubcribe;
            
            const cleanup = () => {
                clearTimeout(timerID);
                unsubcribe();
            };

            unsubcribe = this._subscribe((data) => {
                try {
                    const result = hook(data);
                    
                    if (!check(result)) return;
              
                    cleanup();
                    return resolve(result);
                } catch (err) {
                    cleanup();
                    return reject(err);
                }
            });
            
            timerID = setTimeout(() => {
                cleanup();
                return reject();
            }, timeout);
        });
    } 
}