import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getRoomOption } from "../../room";
import { getLocalIDMember } from "../members";
import { deleteCookie, setCookie } from "../util/web/cookie";
import { SURVEY_MENU_TYPE } from "./constant";
const SurveyIFrame = React.memo(({ type, setSurveyType, surveyItem }) => {
    const surveyframe = useRef(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const roomId = useSelector((state) => getRoomOption(state)?.uuid);
    const localId = useSelector((state) => getLocalIDMember(state));
    const iFrameUrl = useMemo(() => {
        switch (type) {
            case SURVEY_MENU_TYPE.ADD:
                return `/survey?roomID=${roomId}&memberID=${localId}`;
            case SURVEY_MENU_TYPE.EDIT:
                if (!surveyItem)
                    return;
                setCookie("survAuth", surveyItem, null, '/');
                return `/survey/${roomId}/${surveyItem}/chart?memberID=${localId}`;
            case SURVEY_MENU_TYPE.VOTE:
                if (!surveyItem)
                    return;
                deleteCookie("survAuth");
                return `/survey/${roomId}/${surveyItem}?memberID=${localId}`;
        }
        return null;
    }, [type, surveyItem, roomId, localId]);
    const handlerRefresh = () => {
        setRefreshKey((prevKey) => prevKey + 1);
    };
    useEffect(() => {
        const getIFrameMessage = async (receiveMessage) => {
            const type = receiveMessage?.data?.type;
            const uuid = receiveMessage?.data?.uuid;
            if (!type)
                return;
            if (type === "SHARE_SURVEY") {
                // send websocket 저장 했으니 설문조사 보세요.라고 알림
                const response = await APP.management.notifySurvey(uuid);
                if (response.status !== 200) {
                    console.log("SHARE_SURVEY - error");
                    return;
                }
            }
            else if (type === "REPLY_SURVEY") {
                // send websocket 응답 했으니 설문조사 보세요.라고 알림
                const response = await APP.management.replySurvey(uuid);
                if (response.status !== 200) {
                    console.log("SHARE_SURVEY - error");
                    return;
                }
            }
            setSurveyType(SURVEY_MENU_TYPE.LIST);
        };
        window.addEventListener("message", getIFrameMessage, false);
        APP.eventManager.subscribe('REFRESH_LIST', handlerRefresh);
        return () => {
            window.removeEventListener("message", getIFrameMessage, false);
            APP.eventManager.unsubscribe('REFRESH_LIST', handlerRefresh);
        };
    }, []);
    return (iFrameUrl && React.createElement("iframe", { ref: surveyframe, src: `${iFrameUrl}?key=${refreshKey}`, style: { width: "100%", height: "100%" } }));
});
export default SurveyIFrame;
