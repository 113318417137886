import { ReducerRegistry } from "../base/redux";
import { BACKGROUND_ENABLED, SAVE_VIRTUAL_IMG, SET_VIRTUAL_BACKGROUND } from "./actionTypes";
const DEFAULT_STATE = {
    backgroundEffectEnabled: false,
    backgroundType: undefined,
    blurValue: undefined,
    selectedThumbnail: undefined,
    virtualSource: undefined,
    imageList: [],
    selectImage: undefined
};
ReducerRegistry.register('features/virtual-background', (state = DEFAULT_STATE, action) => {
    const { virtualSource, backgroundEffectEnabled, blurValue, backgroundType, selectedThumbnail } = action;
    switch (action.type) {
        case SET_VIRTUAL_BACKGROUND: {
            return {
                ...state,
                virtualSource,
                blurValue,
                backgroundType,
                selectedThumbnail,
                selectImage: selectedThumbnail
            };
        }
        case BACKGROUND_ENABLED: {
            return {
                ...state,
                backgroundEffectEnabled
            };
        }
        case SAVE_VIRTUAL_IMG: {
            return {
                ...state,
                imageList: [...state.imageList, action.image]
            };
        }
    }
    return state;
});
