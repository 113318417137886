import { toState } from "../redux";
export function getRoute(stateful) {
    const state = toState(stateful);
    return state['features/base/settings'].route;
}
/**
 * 설정 정보 가져오기
 * @param {IStore['getState']} stateful
 * @param {String} propertyName
 * @param {Object} sources
 * @returns
 */
export function getPropertyValue(stateful, propertyName, sources) {
    const state = toState(stateful);
    sources = {
        config: true,
        settings: true,
        urlParams: true,
        ...sources
    };
    if (sources.settings) {
        const value = state['features/base/settings'][propertyName];
        if (typeof value !== 'undefined') {
            return value;
        }
    }
    return undefined;
}
// 닉네임 정보 가져오기 
export function getDisplayName(stateful) {
    const state = toState(stateful);
    return state['features/base/settings'].displayName || '';
}
/**
 * 알려진 장치에서 일치하는 deviceId를 검색하고 일치하는 것으로 대체합니다.
 * 일치하는 항목이 없으면 저장된 기본 카메라 장치 ID를 반환합니다.
 *
 * @param {Object|Function} stateful - The redux state object or
 * {@code getState} function.
 * @returns {string}
 */
export function getUserSelectedCameraDeviceId(stateful) {
    const state = toState(stateful);
    const { userSelectedCameraDeviceId, } = state['features/base/settings'];
    const { videoInput } = state['features/base/devices'].availableDevices;
    return _getUserSelectedDeviceId({
        availableDevices: videoInput,
        // Operating systems may append " #{number}" somewhere in the label so
        // find and strip that bit.
        matchRegex: /\s#\d*(?!.*\s#\d*)/,
        userSelectedDeviceId: userSelectedCameraDeviceId,
        replacement: ''
    });
}
/**
 * 알려진 장치에서 일치하는 deviceId를 검색하고 일치하는 것으로 대체합니다.
 * 라벨. 일치하는 항목이 없으면 저장된 기본 카메라 장치 ID를 반환합니다.
 *
 * @param {Object|Function} stateful - The redux state object or
 * {@code getState} function.
 * @returns {string}
 */
export function getUserSelectedMicDeviceId(stateful) {
    const state = toState(stateful);
    const { userSelectedMicDeviceId } = state['features/base/settings'];
    const { audioInput } = state['features/base/devices'].availableDevices;
    return _getUserSelectedDeviceId({
        availableDevices: audioInput,
        // Operating systems may append " ({number}-" somewhere in the label so
        // find and strip that bit.
        matchRegex: /\s\(\d*-\s(?!.*\s\(\d*-\s)/,
        userSelectedDeviceId: userSelectedMicDeviceId,
        replacement: ' ('
    });
}
/**
 * 알려진 장치에서 일치하는 deviceId를 검색하고 일치하는 것으로 대체합니다.
 * 라벨. 일치하는 항목이 없으면 저장된 기본 카메라 장치 ID를 반환합니다.
 *
 * @param {Object|Function} stateful - The redux state object or
 * {@code getState} function.
 * @returns {string}
 */
export function getUserSelectedOutputDeviceId(stateful) {
    const state = toState(stateful);
    const { userSelectedAudioOutputDeviceId, } = state['features/base/settings'];
    const { audioOutput } = state['features/base/devices'].availableDevices;
    return _getUserSelectedDeviceId({
        availableDevices: audioOutput,
        matchRegex: undefined,
        userSelectedDeviceId: userSelectedAudioOutputDeviceId,
        replacement: undefined
    });
}
/**
 * 사용자가 선택한 장치 가져오기
 *
 * @param {*} options
 * @returns
 */
function _getUserSelectedDeviceId(options) {
    const { availableDevices, matchRegex, userSelectedDeviceId, userSelectedDeviceLabel, replacement } = options;
    // If there is no label at all, there is no need to fall back to checking
    // the label for a fuzzy match.
    if (!userSelectedDeviceLabel || !userSelectedDeviceId) {
        return userSelectedDeviceId;
    }
    const foundMatchingBasedonDeviceId = availableDevices.find(candidate => candidate.deviceId === userSelectedDeviceId);
    // Prioritize matching the deviceId
    if (foundMatchingBasedonDeviceId) {
        return userSelectedDeviceId;
    }
    const strippedDeviceLabel = matchRegex ? userSelectedDeviceLabel.replace(matchRegex, replacement)
        : userSelectedDeviceLabel;
    const foundMatchBasedOnLabel = availableDevices.find(candidate => {
        const { label } = candidate;
        if (!label) {
            return false;
        }
        else if (strippedDeviceLabel === label) {
            return true;
        }
        const strippedCandidateLabel = label.replace(matchRegex, replacement);
        return strippedDeviceLabel === strippedCandidateLabel;
    });
    return foundMatchBasedOnLabel
        ? foundMatchBasedOnLabel.deviceId : userSelectedDeviceId;
}
/**
 * 정책 가져오기
 * @param {IStore['getState']} stateful
 * @param {String} type
 * @returns
 */
export function getPolicy(stateful, type) {
    const state = toState(stateful);
    const policy = state['features/base/settings'].policy;
    return policy[type];
}
