import React, { useRef, useState } from "react";
import { DivElement, TextElement } from "../ui";
export const Test = React.memo(() => {
    const textareaRef = useRef(null);
    const [edit, setEdit] = useState();
    const renderItem = (item) => {
        return (React.createElement(DivElement, { key: item, className: 'flex row', style: { gap: 12, alignItems: 'center' } },
            React.createElement(TextElement, { className: 'txt headline1', text: `test.${item}` }),
            edit === item ?
                React.createElement("textarea", { ref: textareaRef, className: "test_textarea", rows: 10, defaultValue: JSON.stringify(APP.config[item], null, 4) })
                : React.createElement("pre", null, JSON.stringify(APP.config[item], null, 4))));
    };
    const array_items = ['name', 'title', 'lobby', 'nickname', 'ui', 'maxMember', 'optionList', 'option', 'style'];
    return (React.createElement(DivElement, { className: 'flex column test_item', style: { width: '100%', padding: 12, gap: 12 } }, array_items.map(item => renderItem(item))));
});
