export default {
    save: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    },
    remove: (key) => {
        localStorage.removeItem(key);
    },
    clear: () => {
        localStorage.clear();
    },
    get: (key) => {
        return JSON.parse(localStorage.getItem(key));
    },
};
