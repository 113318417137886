import React from "react";
import { useTranslation } from "react-i18next";
function PreviewTab({ text, children, renderTitle }) {
    const { t } = useTranslation();
    return (React.createElement("div", { className: "preview-inner" },
        renderTitle,
        text && React.createElement("div", { className: 'sub-cover', dangerouslySetInnerHTML: { __html: t(text) } }),
        React.createElement("div", { className: "preview-contents" },
            React.createElement("div", { className: "input-box" }, children))));
}
;
export default React.memo(PreviewTab);
