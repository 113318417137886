import React from "react";

export default function Sidebar({
    chat, member, isChat, isMember, renderChat, renderMember
}) {
    return (
        <div className="sidebar">
            { isMember && <div className="item" key="member" style={{ maxHeight: member ? chat ? "30%" : "100%" : "35px" }}>
                { renderMember }
            </div> }
            { isChat && <div className="item" key="chat" style={{ maxHeight: chat ? member ? "70%" : "100%" : "35px" }}>  
                { renderChat }
            </div>  }        
        </div>
    )
}