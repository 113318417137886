import { tempEnterRoom } from '../../room';
import { SET_DUAL_MONITOR } from '../../video-layout/actionTypes';
import { APP_WILL_MOUNT } from '../app';
import { MiddlewareRegistry } from '../redux';
import { toggleChat, toggleMember } from '../sidebar';
import { updateSettings } from './actions';
import './middleware.any';
MiddlewareRegistry.register(store => next => async (action) => {
    switch (action.type) {
        case APP_WILL_MOUNT:
            if (location.pathname.includes('room')) {
                const location_array = location.pathname.split('/');
                if (location_array && location_array.length > 2) {
                    const room_uuid = location_array[2];
                    const response = await tempEnterRoom({ uuid: room_uuid });
                    if (!response.complete) {
                        APP.UI.alertMessage(response.data);
                        location.href = '/';
                    }
                }
            }
            break;
        case SET_DUAL_MONITOR:
            if (action.isDualMonitor) {
                store.dispatch(toggleChat(true));
                store.dispatch(toggleMember(true));
                store.dispatch(updateSettings({ dualSideOpen: true }));
            }
            else {
                store.dispatch(updateSettings({ dualSideOpen: false }));
            }
            break;
    }
    return next(action);
});
