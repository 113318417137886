export const PART_ERASER = { name: 'part_eraser', children: false };
export const LINE_ERASER = { name: 'line_eraser', children: false };
export const RECT = { name: 'rectangle', children: false };
export const F_RECT = { name: 'fill_rectangle', children: false };
export const CIRCLE = { name: 'circle', children: false };
export const F_CIRCLE = { name: 'fill_circle', children: false };
export const TRIANGLE = { name: 'triangle', children: false };
export const F_TRIANGLE = { name: 'fill_triangle', children: false };
export const HANDLER = { name: 'handler', children: false };
export const CHOICE = { name: 'choice', children: false };
export const PEN = { name: 'pen', children: false };
export const THICKNESS = { name: 'thickness', children: true };
export const OPACITY = { name: 'opacity', children: true };
export const COLOR = { name: 'color', children: true };
export const ERASER = { name: 'eraser', children: [PART_ERASER, LINE_ERASER] };
export const ALL_ERASER = { name: 'all_eraser', children: false };
export const FIGURE = { name: 'figure', children: [RECT, F_RECT, CIRCLE, F_CIRCLE, TRIANGLE, F_TRIANGLE] };
export const TEXT = { name: 'text', children: false };
export const ZOOM = { name: 'zoom', children: true };
export const CLOSE = { name: 'close', children: false };
export const canvas_tool_list = [HANDLER, CHOICE, PEN, THICKNESS, OPACITY, COLOR, ERASER, ALL_ERASER, FIGURE, TEXT, ZOOM];
export const canvas_tool_mobile_list = [{
        name: `${HANDLER.name}`,
        children: [HANDLER, CHOICE]
    }, {
        name: `${PEN.name}`,
        children: [PEN, THICKNESS, OPACITY, COLOR, FIGURE]
    }, ERASER, ALL_ERASER, TEXT, ZOOM];
export const pen_list = [
    THICKNESS.name, OPACITY.name, COLOR.name, ZOOM.name
];
export const FIGURE_LIST = [
    RECT.name, F_RECT.name, CIRCLE.name, F_CIRCLE.name, TRIANGLE.name, F_TRIANGLE.name
];
/**
 * MATE
 */
/**
 * 보여질 캔버스 도구 리스트
 */
export const MATE_CANVAS_TOOL = [HANDLER, CHOICE, PEN, THICKNESS, OPACITY, COLOR, ERASER, ALL_ERASER, FIGURE, TEXT, ZOOM];
/**
 * 보여질 캔버스 모바일 도루 리스트
 */
export const MATE_CANVAS_TOOL_MOBILE = [{
        name: `${HANDLER.name}`,
        children: [HANDLER, CHOICE]
    }, {
        name: `${PEN.name}`,
        children: [PEN, THICKNESS, OPACITY, COLOR, FIGURE]
    }, ERASER, TEXT, ZOOM];
/**
 * CLASS MATE
 */
export const CLASS_CANVAS_TOOL = [PEN, COLOR, LINE_ERASER, TEXT];
