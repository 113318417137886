import { toState } from "../base/redux";
import { isCheckPermission } from "../room";
const name = 'features/canvas';
/**
 * ID로 그린 데이터 가져오기
 * @param stateful
 * @param id
 * @returns
 */
export function getPDFDrawByID(stateful, id) {
    const state = toState(stateful);
    try {
        return state[name].draw.get(id);
    }
    catch {
        return null;
    }
}
/**
 * ID와 인덱스 데이터로 가져오기
 */
export function getPDFDrawByIDAndIndex(stateful, id, index) {
    const line_list = getPDFDrawByID(stateful, id) || new Map();
    const item_list = line_list?.get(index) || new Map();
    return { line: line_list, item: item_list };
}
/**
 * ID와 인덱스 데이터로 array로 변환
 */
export function getDrawLineByIDAndIndex(stateful, id, index) {
    const { item } = getPDFDrawByIDAndIndex(stateful, id, index);
    return [...item.values()];
}
/**
 * 이미지 크기 가져오기
 * @param canvasStyle
 * @param pdfLength
 * @param isWidthFit\
 * @returns
 */
export function getFitSize(canvasStyle, pdfLength, isWidthFit) {
    let fitStyle = getScreenFitStyle(canvasStyle.width, canvasStyle.height, pdfLength.width, pdfLength.height, isWidthFit);
    if (isWidthFit === undefined) {
        if (canvasStyle.height < fitStyle.height || canvasStyle.width < fitStyle.width) {
            fitStyle = getScreenFitStyle(canvasStyle.width, canvasStyle.height, pdfLength.width, pdfLength.height, true);
            isWidthFit = true;
        }
    }
    return {
        width: Math.floor(fitStyle.width) < 0 ? pdfLength.width : Math.floor(fitStyle.width),
        height: Math.floor(fitStyle.height) < 0 ? pdfLength.height : Math.floor(fitStyle.height),
        fitWCanvas: isWidthFit
    };
}
export function getScreenFitStyle(width, height, imageWidth, imageHeight, isWidthFit) {
    if (isWidthFit) {
        // 폭맞춤
        return { width, height: imageHeight * (width / imageWidth) || height };
    }
    else {
        // 쪽맞춤
        return { width: imageWidth * (height / imageHeight) || width, height };
    }
}
export function getItemByUUIDAndIndex(stateful, uuid, index) {
    const state = toState(stateful);
    try {
        const canvas = state['features/canvas'].canvas;
        const item = canvas.get(uuid);
        return item;
    }
    catch {
        return null;
    }
}
export function getPdfDrawByUUID(stateful, uuid) {
    const state = toState(stateful);
    const draw = state['features/canvas'].draw;
    return draw.get(uuid) || new Map();
}
export function getPdfDrawByUUIDAndIndex(stateful, uuid, index) {
    const draw = getPdfDrawByUUID(stateful, uuid);
    let list = draw.get(index);
    return list ? [...list.keys()] : [];
}
export function getPdfDrawByUUIDAndIndexAndKey(stateful, uuid, index, key) {
    const draw = getPdfDrawByUUID(stateful, uuid);
    let list = draw.get(index);
    return list.get(key);
}
export function readBlobAsDataURL(blob, callback) {
    var a = new FileReader();
    a.onload = function (e) { callback(e.target.result); };
    a.readAsDataURL(blob);
}
export function dataURLtoBlob(dataurl) {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}
/**
 * 방 설정 권한 확인 (캔버스 에서만  사용)
 * @param {Function} stateful
 * @param {String} type
 * @returns Boolean
 */
export function checkCanvasPermission(stateful, type) {
    return isCheckPermission(stateful, type);
}
