export function getCookieValue(name) {
    var value = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    return value ? value[2] : null;
}

export function deleteCookie(name) {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie =
            escape(name) + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

export function setCookie(
    cookieName,
    cookieValue,
    cookieExpire,
    cookiePath,
    cookieDomain,
    cookieSecure,
) {
    var cookieText = escape(cookieName) + "=" + escape(cookieValue);
    cookieText += cookieExpire ? "; EXPIRES=" + cookieExpire.toGMTString() : "";
    cookieText += cookiePath ? "; PATH=" + cookiePath : "";
    cookieText += cookieDomain ? "; DOMAIN=" + cookieDomain : "";
    cookieText += cookieSecure ? "; SECURE" : "";
    document.cookie = cookieText;
}
