import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getScreenShareTrackId } from "../../screen-share";
import { getTracksByTrackIdAndMediaType } from "../../base/tracks";
import { MEDIA_TYPE } from "../../base/media";
import { MemberVideo } from "./layout";
const ScreenVideo = React.memo(() => {
    const screenTrack = useSelector((state) => {
        const screenJitsiId = getScreenShareTrackId(state);
        return getTracksByTrackIdAndMediaType(state, screenJitsiId, MEDIA_TYPE.SCREENSHARE);
    }, shallowEqual);
    return (React.createElement(MemberVideo, { videoTrack: screenTrack, nickname: "screen" }));
});
export default ScreenVideo;
