import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getPermissions } from '../../room';
import { MEDIA_TYPE, setAudioMuted, setVideoMuted } from '../media';
import { ClickButton } from '../ui';
import { requestPresentation, setHandlerMember, setMemberPinned, setMemberRole } from './actions';
import { GroupName, MemberItem, NoAttend } from './components';
import { CHANGE_ROLE_LIST, MEMBER_STATUS, PERMISSION, ROLE } from './constans';
import { checkRoleLevel, getLocalMemberRole, getMemberTrackByUserID } from './functions';
import { createSelector } from '@reduxjs/toolkit';
const SHOW_ICON_LIST = ['mic', 'video', 'pin'];
/**
 * Icon name
 */
const ITEM_ICONS = {
    presenter: 'presenter',
    handler: 'raise_hand',
    mic: 'mic',
    video: 'camera',
    pin: 'pinned',
    etc: 'etc',
};
const ITEM_COLORS = {
    true: '#8bacff',
    false: '#545454',
};
const MemberItemContainer = ({ user_id, search, local_group_id, isMonitoring, }) => {
    const localRole = useSelector((state) => getLocalMemberRole(state));
    const member = useSelector((state) => getMemberTrackByUserID(state, user_id, true), shallowEqual);
    if (!member)
        return null;
    if (search && !member.nickname?.includes(search) && !member.local)
        return null;
    return (React.createElement(MemberItemRender, { member: member, local_group_id: local_group_id, localRole: localRole || ROLE.PARTICIPANT, isMonitoring: isMonitoring }));
};
export default MemberItemContainer;
// 상태에서 policy 선택
const selectPolicy = state => state['features/base/settings'].policy;
// 필요한 데이터를 추출하는 선택자 생성
const selectInitialMicAndCamera = createSelector([selectPolicy], policy => ({
    initial_mic: policy.initial_mic,
    initial_camera: policy.initial_camera,
}));
export const MemberItemRender = React.memo(({ member, local_group_id, localRole, isMonitoring = false, }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const permissions = useSelector((state) => getPermissions(state));
    const isDualWindow = useSelector((state) => state['features/video-layout'].isDualMonitor && state['features/base/settings'].dualSideOpen);
    const { initial_mic, initial_camera } = useSelector(selectInitialMicAndCamera);
    const { user_uuid, member_uuid, nickname, pinned, hands_up, isLocal, role_name, status, show_icon, group_name, audioMuted, videoMuted, } = useMemo(() => {
        const role_name = member.active_group !== '' ? member.group_role : member.role;
        return {
            role_name,
            user_uuid: member.user_uuid,
            member_uuid: member.member_uuid,
            pinned: member.pinned,
            nickname: member.nickname,
            hands_up: member.hands_up,
            isLocal: member.local,
            status: member.status === MEMBER_STATUS.OCCUPIDE ? true : false,
            group_name: member.group_name,
            show_icon: member.active_group === local_group_id ? true : false,
            audioMuted: !member.audioTrack || member.audioTrack.muted ? true : false,
            videoMuted: !member.videoTrack || member.videoTrack.muted ? true : false,
        };
    }, [member, local_group_id]);
    const [changeNick, setChangeNick] = useState(false);
    const [control, setControl] = useState(false);
    const [showList, setShowList] = useState(SHOW_ICON_LIST);
    const [memberPermission, setMemberPermission] = useState({
        mic: true,
        video: true,
        presenter: true,
        pin: true,
    });
    useEffect(() => {
        const handlerChangenick = () => setChangeNick(!changeNick);
        isLocal && APP.eventManager.subscribe('CHANGE_NICKNAME', handlerChangenick);
        return () => {
            isLocal && APP.eventManager.subscribe('CHANGE_NICKNAME', handlerChangenick);
        };
    }, [isLocal, changeNick]);
    const Item = ({ name }) => {
        const item = {
            icon: undefined,
            color: undefined,
            permission: false,
            onClick: () => null,
        };
        const itemIcon = ITEM_ICONS[name];
        // const itemColor = ITEM_COLORS[`${item.permission}`];
        switch (name) {
            case 'presenter':
                item.icon = itemIcon;
                item.color = ITEM_COLORS[`${ROLE.PRESENTER === role_name}`];
                item.permission = memberPermission[name];
                item.onClick = () => {
                    if (isLocal) {
                        if (localRole === ROLE.PRESENTER)
                            return;
                        dispatch(requestPresentation(true, member_uuid));
                    }
                    else {
                        const change_role = role_name === ROLE.PRESENTER ? ROLE.PARTICIPANT : ROLE.PRESENTER;
                        dispatch(setMemberRole(user_uuid, change_role));
                    }
                };
                break;
            case 'handler':
                item.icon = itemIcon;
                item.color = ITEM_COLORS[`${hands_up}`];
                item.permission = isLocal || memberPermission['presenter'] || hands_up || false;
                item.onClick = () => {
                    if (isLocal || memberPermission['presenter']) {
                        dispatch(setHandlerMember(user_uuid, !hands_up, false));
                    }
                };
                break;
            case 'mic':
                item.icon = `${itemIcon}_${audioMuted ? 'off' : 'on'}`;
                item.color = ITEM_COLORS[`${!audioMuted}`];
                item.permission = memberPermission[name];
                item.onClick = async () => {
                    if (isLocal) {
                        if (APP.config.option.controlDevice &&
                            initial_mic === 'off' &&
                            !checkRoleLevel(localRole, ROLE.PRESENTER)) {
                            alert('현재 권한이 없습니다.');
                            return;
                        }
                        dispatch(setAudioMuted(!audioMuted, false));
                    }
                    else {
                        if (memberPermission[itemIcon]) {
                            await APP.management.setAudio(user_uuid, !audioMuted);
                        }
                    }
                };
                break;
            case 'video':
                item.icon = `${itemIcon}_${videoMuted ? 'off' : 'on'}`;
                item.color = ITEM_COLORS[`${!videoMuted}`];
                item.permission = memberPermission[name];
                item.onClick = async () => {
                    if (isLocal) {
                        if (APP.config.option.controlDevice &&
                            initial_camera === 'off' &&
                            !checkRoleLevel(localRole, ROLE.PRESENTER)) {
                            alert('현재 권한이 없습니다.');
                            return;
                        }
                        dispatch(setVideoMuted(!videoMuted, MEDIA_TYPE.VIDEO));
                    }
                    else {
                        if (memberPermission[name]) {
                            await APP.management.setVideo(user_uuid, !videoMuted);
                        }
                    }
                };
                break;
            case 'pin':
                item.icon = itemIcon;
                item.color = ITEM_COLORS[`${pinned}`];
                item.permission = memberPermission[name];
                item.onClick = async () => {
                    dispatch(setMemberPinned(user_uuid, !pinned));
                    setControl(false);
                };
                break;
            case 'etc':
                if (isMonitoring) {
                    break;
                }
                item.icon = itemIcon;
                item.color = ITEM_COLORS[`${control}`];
                item.permission = true;
                item.onClick = async () => {
                    setControl(!control);
                };
                break;
            default:
                return null;
        }
        if (!item.icon) {
            return null;
        }
        return (React.createElement(ClickButton, { key: item, className: `nonSelect ${!item.permission || isMonitoring ? 'no-hover' : ''}`, icon: item.icon, size: "15", color: item.color || '#545454', 
            // styles={item.styles}
            onClick: item.permission && !isMonitoring ? item.onClick : null }));
    };
    const renderOption = () => {
        if (status) {
            if (show_icon) {
                return showList.map((item, index) => React.createElement(Item, { key: index, name: item }));
            }
            else {
                return React.createElement(GroupName, { group_id: group_name });
            }
        }
        else {
            return React.createElement(NoAttend, null);
        }
    };
    useEffect(() => {
        let list = [];
        let managePermission = {
            mic: true,
            video: true,
            presenter: true,
            pin: true,
        };
        if (!localRole)
            return;
        const presenterPermission = (isLocal && !checkRoleLevel(localRole, ROLE.PRESENTER)) ||
            (!isLocal && checkRoleLevel(localRole, ROLE.MANAGER));
        const kick = permissions.includes(PERMISSION.KICK);
        const rolePermission = permissions.includes(PERMISSION.MEMBER_ROLE) && checkRoleLevel(localRole, role_name);
        list = [
            presenterPermission ? 'presenter' : '',
            !isLocal || localRole !== ROLE.HOST ? 'handler' : '',
            ...SHOW_ICON_LIST.filter(item => item !== 'presenter'),
            !isLocal && (kick || rolePermission) ? 'etc' : '',
        ];
        managePermission.presenter = presenterPermission;
        managePermission.video = isLocal || permissions.includes(PERMISSION.MEMBER_CAMERA);
        managePermission.mic = isLocal || permissions.includes(PERMISSION.MEMBER_MIC);
        managePermission.pin = permissions.includes(PERMISSION.PIN);
        setShowList(list);
        setMemberPermission(managePermission);
    }, [permissions, role_name, localRole]);
    /**
     * 사용자 권한 변경
     * @param {Object|undefined} styles
     * @returns
     */
    const renderRoleList = styles => {
        return CHANGE_ROLE_LIST.map((item, index) => (React.createElement(ClickButton, { key: item, label: t(`role.${item}`), color: role_name === item ? '#8bacff' : '#545454', styles: styles
                ? {
                    ...styles,
                    wrapper: {
                        ...styles.wrapper,
                        backgroundColor: role_name === item ? '#8bacff' : '#d5d5d5',
                    },
                    text: {
                        ...styles.text,
                        color: `${role_name === item ? '#fff' : '#000'}`,
                    },
                }
                : null, onClick: async () => {
                await dispatch(setMemberRole(user_uuid, item));
                setControl(false);
            } })));
    };
    /**
     * 사용자 강제 퇴장
     * @param {Object|undefined} styles
     * @returns
     */
    const renderKick = styles => {
        return (React.createElement(ClickButton, { label: t('member.kick'), styles: styles, onClick: async () => {
                const guid = t('member.kickGuid', { nickname });
                const kickMember = async () => await APP.management.setMemberKick(user_uuid);
                if (isDualWindow) {
                    APP.eventManager.publish('ALERT_DUAL_LAYOUT', {
                        confirmMessage: guid,
                        confrimSuccess: kickMember,
                    });
                }
                else {
                    if (navigator.product === 'ReactNative') {
                        APP.UI.confirmMessage('member.kick', 'member.kickGuid', null, kickMember, null, {
                            nickname,
                        });
                    }
                    else {
                        if (window.confirm(guid)) {
                            kickMember();
                        }
                    }
                }
                setControl(false);
            } }));
    };
    /**
     * 컨트롤러 닫기 / 열기
     * @param {Object|undefined} styles
     * @returns
     */
    const renderCotrol = styles => {
        return React.createElement(ClickButton, { label: 'X', styles: styles, onClick: () => setControl(!control) });
    };
    return (React.createElement(MemberItem, { isLocal: isLocal, name: nickname, role_name: role_name, control: control, changeNick: changeNick, renderOption: renderOption, renderRoleList: renderRoleList, renderKick: renderKick, renderCotrol: renderCotrol, hideControl: () => setControl(false) }));
});
