import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClickButton, Input } from "../ui";
import { VodUrlStyled } from "./components";
// @ts-ignore
import { Dialog } from "../dialog";
/**
 * VOD URL 등록 팝업
 */
const AddUrlPopup = React.memo(({ isMobile = false, isRoom = false, isDialog = false, handlerAddVod }) => {
    const { t } = useTranslation();
    const [url, setUrl] = useState();
    const [name, setName] = useState();
    /**
     * Url, name 전달 후 VOD 등록
     * @returns
     */
    const onClick = () => handlerAddVod && handlerAddVod(url, name);
    /**
     * (Component) VOD URL 등록 팝업 (Dialog or Component
     */
    const renderComponet = useMemo(() => (React.createElement(VodUrlStyled, { isRoom: isRoom, isMobile: isMobile },
        React.createElement(Input, { className: 'vod_input', label: t('vod.url'), onChange: setUrl }),
        React.createElement(Input, { className: 'vod_input', label: t('vod.title'), onChange: setName }),
        React.createElement(ClickButton, { className: 'vod_add_btn', isText: true, label: 'common.save', onClick: onClick }))), [isMobile, setUrl, setName, onClick]);
    if (isDialog) {
        return (React.createElement(Dialog, { className: 'vod_dialog', titleKey: t('vod.urlAdd'), width: 450 }, renderComponet));
    }
    return renderComponet;
});
export default AddUrlPopup;
