import React, { Fragment, useMemo, useRef } from 'react';
import DivElement from './DivElement.web';
import styled from 'styled-components';
import Icon from './Icon';
import TextElement from './TextElement.web';
// @ts-ignore
const SelectStyled = styled.div `
    border-radius: 4px;
    border: 1px solid #C6CBD2;
    background: #FFF;
    display: inline-flex;    
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
    flex-direction: column;
    position: relative;
    overflow: visible!important;
    padding: 0 8px;

    &.error {
        border: 1px solid #F36958;
        color: #F36958;
    }

    .option_wrapper {
        position: absolute;
        background: #fff;
        width: 100%;
        overflow: auto;
        z-index: 1;
        max-height: 150px; /* 최대 높이 설정 */
        left: 0;
        border: 1px solid #C6CBD2;
    }

    .options {
        display: none;
        background-color: #fff;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .selected-option {
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: center;   
        gap: 12px;
        overflow: hidden;

        > * { margin: 0; }
        
        .select-value { flex: 1;}
    }
    
    .options {
        li {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            transition: background-color 0.3s;

            &:last-child {
                border-bottom: none;
            }

            &:hover, &.active {
                background-color: #f0f0f0;
            }
        }
    }
`;
const Select = ({ className, error, options, selectedOption, setSelectedOption, test, isMobile = false }) => {
    const selectRef = useRef(null);
    const optionRef = useRef(null);
    const toggleSelect = () => {
        const element = optionRef.current;
        if (element) {
            element.style.display = element.style.display === 'none' || element.style.display === '' ? 'block' : 'none';
        }
    };
    const handleClickOutside = (event) => {
        const selectBox = selectRef.current;
        const element = optionRef.current;
        if (event.target.tagName === 'LI' && selectBox && element) {
            event.stopPropagation();
            const selectId = event.target.getAttribute('value');
            setSelectedOption(selectId);
            element.style.display = 'none';
        }
    };
    const value = useMemo(() => error || options.find(o => o.uuid === selectedOption)?.name || 'common.default', [error, options, selectedOption]);
    return (React.createElement(SelectStyled, { className: `select-box ${className} ${error && 'error'}`, onClick: toggleSelect },
        React.createElement(Fragment, null,
            React.createElement(DivElement, { id: "select-option", className: 'selected-option', ref: selectRef },
                React.createElement(Fragment, null,
                    test,
                    React.createElement(TextElement, { className: 'select-value overText', text: value }),
                    options && options.length > 0 && React.createElement(Icon, { icon: 'down', size: isMobile ? 16 : 24, color: '#C6CBD2' }))),
            options && options.length > 0 && React.createElement("ul", { className: 'options', ref: optionRef, onClick: handleClickOutside },
                React.createElement(DivElement, { className: 'option_wrapper', style: { position: "absolute" } },
                    React.createElement(React.Fragment, null, options && options.map((option) => (React.createElement("li", { className: `overText option ${option.uuid === selectedOption && 'active'}`, key: option.uuid, value: option.uuid }, option.name)))))))));
};
export default React.memo(Select);
