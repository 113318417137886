import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getMemberTrackByUserID } from '../../base/members';
import { ConnectionIndicatorContainer } from '../../connection-indicator';
import LayoutTogglePin from './LayoutTogglePin';
import MemberAudio from './MemberAudio';
import { MemberItem, MemberVideo } from './layout';
const MemberItemRenderer = React.memo(({ member, user_uuid, styles, isMaxSize, vertical }) => {
    const { local, nickname, pinned, jitsi_id, videoTrack, audioTrack } = useMemo(() => member, [member]);
    const renderVideo = useMemo(() => React.createElement(MemberVideo, { styles: styles, videoTrack: videoTrack, nickname: nickname }), [styles, videoTrack, nickname]);
    const renderPin = useMemo(() => React.createElement(LayoutTogglePin, { user_uuid: user_uuid, pinned: pinned }), [user_uuid, pinned]);
    const renderConnection = useMemo(() => React.createElement(ConnectionIndicatorContainer, { icon: "fill_circle", jitsi_id: jitsi_id }), [jitsi_id]);
    const renderAudioMuted = useMemo(() => React.createElement(MemberAudio, { audioTrack: audioTrack }), [audioTrack]);
    return (React.createElement(MemberItem, { isMaxSize: isMaxSize, local: local, nickname: nickname, styles: styles, vertical: vertical, renderVideo: renderVideo, renderPin: renderPin, renderConnection: renderConnection, renderAudioMuted: renderAudioMuted }));
});
const MemberItemContainer = React.memo((props) => {
    const member = useSelector((state) => getMemberTrackByUserID(state, props.user_uuid, true), shallowEqual);
    if (!member)
        return null;
    else
        return React.createElement(MemberItemRenderer, { ...props, member: member });
});
export default MemberItemContainer;
