/**
 * 현재 채팅 관리자 열기, 닫기
 * @param {boolean} isOpen
 * @returns
 */
export const TOGGLE_OPEN_GROUP = 'TOGGLE_OPEN_GROUP';
/**
 * 그룹 전체 가져오기
 * @returns
 */
export const SET_ALL_GROUP_LIST = 'SET_ALL_GROUP_LIST';
/**
 * 그룹 세팅
 * @param {GroupList} group
 * @returns
 */
export const SET_GROUP_ITEM = 'SET_GROUP_ITEM';
/**
 * 그룹 삭제
 * @param {string} uuid
 * @param {boolean} isRemote
 * @returns
 */
export const DELETE_GROUP = 'DELETE_GROUP';
/**
 * 그룹 수정
 * @param {string} uuid: 그룹 아이디
 * @param {string} name: 수정할 이름
 * @param {boolean} isRemote: 자의 or 타의 (자의에 의한 액션일 경우 API업데이트 / 타의에 의한 액션일 경우 그냥 업데이트 )
 * @returns
 */
export const MODIFY_GROUP = 'MODIFY_GROUP';
/**
 * 그룹 할당
 * @param {string} uuid: 그룹 아이디
 * @param {string} user_uuid: 할당할 user_id
 * @param {string} assgin_type: 할당 유형 (unset: 할당, reset: 할당 해제)
 */
export const ASSIGN_GROUP = 'ASSIGN_GROUP';
/**
 * 그룹 상태 원격 조정
 * @param {boolean} isStart: 시작 여부
 * @param {string} group_id: 그룹 아이디
 * @returns
 */
export const REMOTE_GROUP = 'REMOTE_GROUP';
/**
 * 전체 그룹 업데이트
 * @param {boolean} isStart: 시작 여부
 * @returns
 */
export const UPDATE_GROUP_STATUS = 'UPDATE_GROUP_STATUS';
/**
 * 그룹에 전체 알림
 * @param {string} message: 전달할 메시지
 * @param {string} group_id: 그룹 아이디
 */
export const NOTIFY_GROUP_MESSAGE = 'NOTIFY_GROUP_MESSAGE';
/**
 * 에러 메시지
 * @param {string} error
 * @returns
 */
export const UPDATE_ERROR_IN_GROUP = 'UPDATE_ERROR_IN_GROUP';
/**
 * (GROUP) 그룹 초기화
 */
export const INIT_GROUP = 'INIT_GROUP';
/**
 * (GROUP) 전체 시작 및 종료
 */
export const REMOTE_ALL_GROUP = 'REMOTE_ALL_GROUP';
