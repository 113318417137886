import { toggleLoad } from '../../../features/base/loading';
import { getAccessToken } from '../../../features/base/local';
import { memberRoleChanged } from '../../../features/base/members';
import { getDisplayName } from '../../../features/base/settings';
import { getWebsocketURLBase } from '../../../features/base/ui/utils';
import { getCookieValue } from '../../../features/base/util/web/cookie';
import { getRoomOption, setActiveGroupId } from '../../../features/room';
import { VOD_MODE } from '../../../features/share-layout/constants';
import { videoLayoutMode } from '../../../features/video-layout';
import { createTaskQueue } from '../../util/helpers';
import { command } from './command';
import { default as Events } from './events';

const _replaceEventQueue = createTaskQueue();

class MateManagement {
	constructor(group_id) {
		this.groupId = group_id;
		this.nextId = 0;
		this.connection = null;
		this.onMessage = new Array();
	}

	clear() {
		this.groupId = '';
		this.nextId = 0;
		this.connection = null;
		this.onMessage = new Array();
	}

	setGroupJoin(joined) {
		Events.setGroupJoin(joined);
	}

	async resultOnlyJoin() {
		try {
			const password = APP.store.getState()['features/room-lock'].password || '';
			const nickname = getDisplayName(APP.store.getState);
			const joinResponse = await this._request(
				command['join-room'],
				{
					password,
					nickname,
					type: '', // 'hidden' or '',
					result_only: true,
				},
				5000,
			);

			if (joinResponse?.status != 200) {
				APP.UI.alertMessage(joinResponse?.payload.message.message.format);
			} else {
				this.setGroupJoin(false);
				APP.store.dispatch(setActiveGroupId(''));
				APP.store.dispatch(toggleLoad(false));
				// room 초기 세팅 - 이전 기록 불러옴.
				await Events.initialEntrySetting(joinResponse.payload, true);
			}
		} catch (err) {
			console.log(err);
		}
	}

	/**
	 * MATE 연결 및 입장
	 */
	connectAndJoin(
		{ password = '', role_name = null, group_id = '', joinType = '', access_token },
		eventManager,
		isTemp,
	) {
		const { getState, dispatch } = APP.store;

		const connection = new WebSocket(`${getWebsocketURLBase()}/svc/room/ws?${this.groupId}`);

		return new Promise((resolve, reject) => {
			this.connection = connection;

			connection.onopen = async () => {
				// 연결 끊김.
				connection.onclose = () => {
					this.connection = null;

					// this.disconnect();
				};

				// 메시지
				connection.onmessage = message => {
					// console.log(isTemp)
					if (!isTemp) {
						eventManager
							? eventManager.handleMessage(JSON.parse(message.data))
							: _replaceEventQueue.enqueue(async onFinish => {
									await Events.handleMessage(JSON.parse(message.data), this.groupId);
									onFinish();
								});
					}

					this.onMessage.forEach(handle => {
						handle(message.data);
					});
				};

				const token = access_token || getAccessToken(getState);
				const response = await this._request(command['auth-setting'], { access_token: token }, 5000);

				if (response?.status != 200) {
					reject('서버 오류 - set Auth');
				}

				const nickname = getDisplayName(getState) || 'test';
				const data = {
					password: password || '',
					nickname,
					type: joinType, // 'hidden' or ''
					result_only: false,
				};

				if (role_name || getCookieValue('meeting_role')) {
					data.role_name_forced = role_name || getCookieValue('meeting_role');
				}
				try {
					const joinResponse = await this._request(command['join-room'], data, 50000);

					if (joinResponse.status === 200) {
						if (isTemp) return resolve();
						if (joinType === 'hidden') return resolve();
						// room 초기 세팅 - 이전 기록 불러옴.
						_replaceEventQueue.enqueue(async onFinish => {
							const result = await Events.initialEntrySetting(joinResponse.payload);
							if (this.groupId) this.setGroupJoin(true);
							resolve(result);
							onFinish();
						});
					} else {
						if (joinResponse && joinResponse.payload) reject(joinResponse.payload.message.message.format);
					}
				} catch (err) {
					reject('api.defaultError');
				}
			};

			connection.onerror = err => {
				this.connection = null;
				reject(err);
			};

			connection.onclose = err => {
				this.connection = null;

				resolve(true);
			};
		});
	}

	/**
	 * MATE 연결 해제
	 */
	disconnect() {
		return new Promise(async (resolve, reject) => {
			// console.error('dsfasdf');
			const connect = this.connection;
			this.connection = null;

			if (connect) {
				this.onMessage = new Array();

				try {
					connect.send(
						JSON.stringify({
							class: command['left-room'],
							id: 'leave',
							payload: {
								group_id: this.groupId,
								meeting_id: getRoomOption(APP.store.getState).uuid,
							},
						}),
					);

					connect.close();
					this.nextId = 0;
					this.clear();

					resolve();
				} catch (err) {
					console.log(err);
					reject('err - mate 연결 해제 실패');
				}
			} else {
				resolve();
			}
		});
	}

	/**
	 * (TRACK) jitsi conference user_id를 mate에 업데이트 해준다.
	 * @param {String} bridge_id
	 */
	setBridgeId(bridge) {
		return this._request(command['set-jitsi-id'], { bridge_sessions: [bridge] });
	}

	/**
	 * (LAYOUT) 레이아웃 변경
	 * @param {String} mode
	 * @returns
	 */
	setLayoutMode(mode) {
		return this._request(command[mode], null);
	}

	/**
	 * 그리드 모드 옵션 변경
	 * @param {Number} count
	 */
	setGridCount(count) {
		return this._request(command['set-grid-count'], {
			grid_count: Number(count),
		});
	}

	/**
	 * (SCREEN SHARE)
	 * 현재 화면 공유 중이거나 화면 공유 종료를 알림.
	 * @param {String} jitsi_id
	 * @param {String} member_uuid
	 * @returns
	 */
	setScreenOption(jitsi_id = null, member_uuid = null) {
		return this._request(command[videoLayoutMode.screen], {
			member_uuid,
			jitsi_id,
		});
	}

	/**
	 * 화면 가시성 레이아웃 모드 변경 ( screen, document, note, white, vad )
	 * @param {Object} viewVisible
	 */
	setVisibleLayoutMode(viewVisible) {
		return this._request(command['set-layout-visiblity'], viewVisible);
	}

	/**
	 * 문서
	 */
	/**
	 * (DOCUMENT) 문서 공유 저장 & 공유
	 * @param {Object} data
	 * @returns
	 */
	setShareItem(data) {
		return this._request(command['set-document'], data);
	}
	/**
	 * (DOCUMENT) 문서 삭제
	 * @param {Object} data
	 * @returns
	 */
	deleteShareItem(data) {
		return this._request(command['del-document'], data);
	}
	/**
	 * (DOCUMENT) 현재 문서 Foucs
	 * @param {String} uuid
	 * @param {Number} index
	 * @param {String} mode
	 * @returns
	 */
	setFocusShareItem(uuid, index, mode) {
		if (mode === VOD_MODE) {
			return this._request(command['focus-vod-item'], {
				video_uuid: uuid,
				index,
			});
		} else {
			return this._request(command[mode], { uuid, index });
		}
	}

	/**
	 * (CANVAS) 문서 포인트 전송
	 * @param {String} uuid
	 * @param {Number} index
	 * @param {String} key
	 * @param {Object} property
	 * @returns
	 */
	sendSharePoints(uuid, index, key, property) {
		return this._request(command['set-canvas'], {
			uuid,
			index: index.toString(),
			key,
			value: property,
		});
	}

	/**
	 * (CANVAS) 문서 포인트 삭제 (라인 한줄 )
	 * @param {String} uuid
	 * @param {Number} index
	 * @param {String|null} key
	 * @param {Boolean} isAll
	 * @returns
	 */
	deleteShareLine(uuid, index, key, isAll) {
		return this._request(command['del-canvas'], {
			uuid,
			index: index.toString(),
			key,
			isAll,
		});
	}

	/**
	 * (CANVAS) 문서 판서 삭제 ( 한페이지 )
	 * @param {String} uuid
	 * @param {Number} index
	 * @returns
	 */
	deleteShareCanvasIndex(uuid, index) {
		return this._request(command['del-canvas'], {
			uuid,
			index: index.toString(),
			key: null,
			isAll: true,
		});
	}

	/**
	 * 채팅
	 */
	/**
	 * (CHAT) 채팅 전송
	 * @param {Object} data
	 */
	sendMessage(data) {
		const privateRecipient = data.privateMessage;
		if (privateRecipient && privateRecipient.length > 0) {
			let promise = [];
			promise.push(
				privateRecipient.map(message => {
					return this._request(command['send-private-text'], {
						message: JSON.stringify(data),
						user_uuid: message.user_uuid,
					});
				}),
			);

			return Promise.all(promise)
				.then(response => {
					return { status: 200 };
				})
				.catch(err => {
					return { status: 400 };
				});
		} else {
			return this._request(command['send-text'], {
				message: JSON.stringify(data),
			});
		}
	}
	/**
	 * (CHAT) 파일 전송
	 * @param {Objcet} data
	 */
	sendFile(data) {
		return this._request(command['send-file'], { message: data });
	}

	/**
	 * USer
	 */
	/**
	 * (TRACK) 오디오 ON / OFF
	 * @param {String} user_uuid
	 * @param {Boolean} muted
	 * @returns
	 */
	setAllAudio(muted) {
		return this._request(command[`set-all-audio-${muted}`]);
	}

	/**
	 * (TRACK) 전체 비디오 ON / OFF
	 * @param {Boolean} muted
	 * @returns
	 */
	setAllVideo(muted) {
		return this._request(command[`set-all-video-${muted}`]);
	}

	/**
	 * (TRACK) 오디오 ON / OFF
	 * @param {String} user_uuid
	 * @param {Boolean} muted
	 * @returns
	 */
	setAudio(user_uuid, muted) {
		return this._request(command[`set-audio-${muted}`], { user_uuid });
	}

	/**
	 * (TRACK) 비디오 ON / OFF
	 * @param {String} user_uuid
	 * @param {Boolean} muted
	 * @returns
	 */
	setVideo(user_uuid, muted) {
		return this._request(command[`set-video-${muted}`], { user_uuid });
	}

	/**
	 * (MEMBER) 발표자 권한 요청
	 * @param {String} member_uuid
	 * @returns
	 */
	requestPresentation(member_uuid) {
		return this._request(command['request-presentation'], {
			id: member_uuid,
		});
	}

	/**
	 * (MEMBER) 발표자 권한 응답
	 * @param {String} user_uuid
	 * @param {Boolean} accepted
	 * @returns
	 */
	responsePresentation(user_uuid, accepted) {
		return this._request(command['response-presentation'], {
			user_uuid,
			accepted,
		});
	}

	/**
	 * (MEMBER) 사용자 권한 변경
	 * @param {String} user_uuid
	 * @param {String} role
	 * @param {String} group_id
	 * @returns
	 */
	setMemberRole(user_uuid, role, group_id = '') {
		return this._request(command['set-role'], {
			user_uuid,
			role,
			group_id,
		});
	}

	/**
	 * (MEMBER) 녹화 중을 알림.
	 * @param {string} user_uuid
	 * @param {boolean} recording
	 * @returns
	 */
	setRecording(user_uuid, recording) {
		return this._request(command['set-member-record'], {
			recording,
			user_uuid,
		});
	}

	/**
	 * (MEMBER) 사용자 PIN 변경
	 * @param {String} user_uuid
	 * @param {Boolean} pinned
	 * @returns
	 */
	setMemberPinned(user_uuid, pinned) {
		const event = pinned ? command['member-add-pin'] : command['member-del-pin'];

		return this._request(event, { user_uuid });
	}
	/**
	 * (MEMBER) 사용자 강제 퇴장
	 * @param {String} user_uuid
	 * @returns
	 */
	setMemberKick(user_uuid) {
		return this._request(command['member-kick'], { user_uuid });
	}
	/**
	 * (MEMBER) 사용자 손들기
	 * @param {String} user_uuid
	 * @param {Boolean} handler
	 * @returns
	 */
	raiseHand(user_uuid, handler) {
		const event = handler ? command['set-handler-up'] : command['set-handler-down'];

		return this._request(event, { user_uuid });
	}

	// 강제 퇴장
	kickMember(user_uuid) {
		this._request(command['member-kick'], { user_uuid });
	}

	/**
	 * (POLICY)
	 * 현재 권한
	 * @param {object} policy
	 * @returns
	 */
	updateRoomPolicy(policy) {
		return this._request(command['set-policy'], policy);
	}

	/**
	 * 전체 상태 업데이트
	 * @param {boolean} running
	 * @returns
	 */
	updateGroupStatus(running) {
		return this._request(command['group-status'], { running });
	}

	/**
	 * 그룹에 알림 메시지 전달
	 * @param {string} message: 전달할 메시지
	 * @param {string} group_id: 그룹 아이디
	 */
	notifyGroupMessage(message, group_id) {
		const data = JSON.stringify({ message, group_id });

		return this._request(command['send-notification'], { message: data });
	}

	/**
	 * (SURVEY) 설문조사 알림
	 * @param { string } survey_id
	 * @returns
	 */
	notifySurvey(survey_id) {
		return this._request(command['share-survey'], { survey_id });
	}
	/**
	 * (SURVEY) 설문조사 응답
	 * @param { string } survey_id
	 * @returns
	 */
	replySurvey(survey_id) {
		return this._request(command['reply-survey'], { survey_id });
	}

	updateNickname(nickname) {
		return this._request(command['set-nickname'], { nickname });
	}

	_request(clazz, payload, timeout = 5000) {
		if (!this.connection || this.connection.readyState !== WebSocket.OPEN) {
			console.log('웹소켓 연결 안됨.', payload);
			return Promise.reject('api.refreshError');
		}
		const requestID = `${Date.now()}-${++this.nextId}`;

		this.connection.send(
			JSON.stringify({
				class: clazz,
				id: requestID,
				payload: {
					...payload,
					group_id: payload?.group_id ? payload.group_id : this.groupId,
					meeting_id: getRoomOption(APP.store.getState).uuid,
				},
			}),
		);

		return this._waitFor(
			data => JSON.parse(data),
			res => res?.payload?.id === requestID || res?.id === requestID,
			timeout,
			clazz,
		);
	}

	_subscribe(handle) {
		this.onMessage.push(handle);

		return () => {
			const index = this.onMessage.findIndex(element => {
				return element == handle;
			});

			if (index >= 0) {
				this.onMessage.splice(index, 1);
			}
		};
	}

	async _waitFor(hook, check, timeout, clazz) {
		return new Promise((resolve, reject) => {
			let timerID;
			let unsubcribe;

			const cleanup = () => {
				clearTimeout(timerID);
				unsubcribe();
			};

			unsubcribe = this._subscribe(data => {
				try {
					const result = hook(data);

					if (!check(result)) return;

					cleanup();
					resolve(result);
				} catch (err) {
					cleanup();
					// reject(err);
				}
			});

			timerID = setTimeout(() => {
				cleanup();
				// reject(clazz);
			}, timeout);
		});
	}

	/**
	 * 그룹 사용자 권한 변경
	 */
	compulsionChangeRole(user_id, role_name) {
		APP.store.dispatch(memberRoleChanged(user_id, role_name, false, true));
	}
}

export default MateManagement;
