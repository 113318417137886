// @flow
import _ from 'lodash';
/**
 * Contains all the callbacks to be notified when stats are updated.
 *
 * {
 *     userId: Function[]
 * }.
 */
const subscribers = {};
/**
 * A singleton that acts as a pub/sub service for connection stat updates.
 */
const statsEmitter = {
    /**
     * Have {@code statsEmitter} subscribe to stat updates from a given
     * conference.
     *
     * @param {JitsiConference} conference - The conference for which
     * {@code statsEmitter} should subscribe for stat updates.
     * @returns {void}
     */
    startListeningForStats(conference) {
        const updateLocalStats = stats => this._onStatsUpdated(conference.myUserId(), stats);
        APP.management.registerUpdateLocalStats(updateLocalStats);
        const updateRemotStats = (id, stats) => this._emitStatsUpdate(id, stats);
        APP.management.registerUpdateRemoteStats(updateRemotStats);
    },
    /**
     * Add a subscriber to be notified when stats are updated for a specified
     * user id.
     *
     * @param {string} id - The user id whose stats updates are of interest.
     * @param {Function} callback - The function to invoke when stats for the
     * user have been updated.
     * @returns {void}
     */
    subscribeToClientStats(id, callback) {
        if (!id) {
            return;
        }
        if (!subscribers[id]) {
            subscribers[id] = [];
        }
        subscribers[id].push(callback);
    },
    /**
     * Remove a subscriber that is listening for stats updates for a specified
     * user id.
     *
     * @param {string} id - The user id whose stats updates are no longer of
     * interest.
     * @param {Function} callback - The function that is currently subscribed to
     * stat updates for the specified user id.
     * @returns {void}
     */
    unsubscribeToClientStats(id, callback) {
        if (!subscribers[id]) {
            return;
        }
        const filteredSubscribers = subscribers[id].filter(subscriber => subscriber !== callback);
        if (filteredSubscribers.length) {
            subscribers[id] = filteredSubscribers;
        }
        else {
            delete subscribers[id];
        }
    },
    /**
     * Emit a stat update to all those listening for a specific user's
     * connection stats.
     *
     * @param {string} id - The user id the stats are associated with.
     * @param {Object} stats - New connection stats for the user.
     * @returns {void}
     */
    _emitStatsUpdate(id, stats = {}) {
        const callbacks = subscribers[id] || [];
        callbacks.forEach(callback => {
            callback(stats);
        });
    },
    /**
     * 로컬 통계 업데이트를 수신 대기중인 모든 사용자에게 통계 업데이트를 전송합니다.
     * 또한, 원격 사용자 통계에 대한 변화와
     * 관련된 변경 사항이 있는 경우 해당 변경 사항에 대한 리스너도 업데이트합니다.
     *
     * @param {string} localUserId - The user id for the local user.
     * @param {Object} stats - Connection stats for the local user as provided
     * by the library.
     * @returns {void}
     */
    _onStatsUpdated(localUserId, stats) {
        const allUserFramerates = stats.framerate || {};
        const allUserResolutions = stats.resolution || {};
        const allUserCodecs = stats.codec || {};
        const modifiedLocalStats = Object.assign({}, stats, {
            framerate: allUserFramerates[localUserId],
            resolution: allUserResolutions[localUserId],
            codec: allUserCodecs[localUserId]
        });
        this._emitStatsUpdate(localUserId, modifiedLocalStats);
        // Get all the unique user ids from the framerate and resolution stats
        // and update remote user stats as needed.
        const framerateUserIds = Object.keys(allUserFramerates);
        const resolutionUserIds = Object.keys(allUserResolutions);
        const codecUserIds = Object.keys(allUserCodecs);
        _.union(framerateUserIds, resolutionUserIds, codecUserIds)
            .filter(id => id !== localUserId)
            .forEach(id => {
            const remoteUserStats = {};
            const framerate = allUserFramerates[id];
            if (framerate) {
                remoteUserStats.framerate = framerate;
            }
            const resolution = allUserResolutions[id];
            if (resolution) {
                remoteUserStats.resolution = resolution;
            }
            const codec = allUserCodecs[id];
            if (codec) {
                remoteUserStats.codec = codec;
            }
            this._emitStatsUpdate(id, remoteUserStats);
        });
    }
};
export default statsEmitter;
