import React, { useEffect, useState } from 'react';
import { DivElement } from '../base/ui';
import { JitsiTrackEvents } from '../base/lib-jitsi-meet';
const AudioInputPreview = React.memo(({ track }) => {
    const [audioLevel, setAudioLevel] = useState(0);
    useEffect(() => {
        if (track) {
            track.on(JitsiTrackEvents.TRACK_AUDIO_LEVEL_CHANGED, setAudioLevel);
        }
        return () => {
            track && track.off(JitsiTrackEvents.TRACK_AUDIO_LEVEL_CHANGED, setAudioLevel);
        };
    }, [track]);
    return (React.createElement(DivElement, { className: "audio-input-preview" },
        React.createElement(DivElement, { className: "audio-input-preview-level", style: { width: `${Math.floor(audioLevel * 100)}%` } })));
});
export default AudioInputPreview;
