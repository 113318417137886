import React from "react";
import { CheckStyled } from "./components/styles.web";
import { ClickButton } from "../base/ui";
import { useTranslation } from "react-i18next";
import { hideNotification } from "../base/notification/actions";
import { useDispatch } from "react-redux";
const PromptAlertAttendances = React.memo(({ uuid, name }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const onClick = () => {
        APP.API.checkAttendances(uuid)
            .then(response => {
            if (response.complete) {
            }
            else {
                APP.UI.alertMessage(response.data);
            }
            dispatch(hideNotification(uuid));
        });
    };
    return (React.createElement(CheckStyled, null,
        React.createElement("span", { className: "guid" }, t("attendances.requestGuid", { name: t(`attendances.${name}`) })),
        React.createElement(ClickButton, { isText: true, label: t("attendances.okay"), onClick: onClick })));
});
export default PromptAlertAttendances;
