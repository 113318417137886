const VOD_LIST = 'vod_list';
const VOD_ROOM_CONFIG = 'vod_room_config';
const VOD_ROOM_RESERVATION = 'vod_room_reservation';
const VOD_ATTEND_LIST = 'vod_attend_list';
const VOD_RECORD_LIST = 'vod_record_list';
export const VOD_PAGE_TYPE = {
    VOD_LIST,
    VOD_ROOM_CONFIG,
    VOD_ROOM_RESERVATION,
    VOD_ATTEND_LIST,
    VOD_RECORD_LIST
};
