/**
 * 방 정보 세팅 ( 옵션, 비밀번호, 미디어서버, 회의실 오픈 시간 )
 * @param {RoomData} data
 * @returns
 */
export const SET_ROOM_OPTION = 'SET_ROOM_OPTION';
/**
 * 회의실 퇴장
 * @returns
 */
export const LEAVE_ROOM = 'LEAVE_ROOM';
/**
 * 현재 룸에 대한 권한,
 * @param {string[]} permissions
 * @returns
 */
export const SET_ROOM_PERMISSIONS = 'SET_ROOM_PERMISSIONS';
/**
 * 회의실 입장 여부
 * @param {boolean} joined
 * @returns
 */
export const SET_ROOM_JOINED = 'SET_ROOM_JOINED';
/**
 * 현재 입장되어있는 그룹 아이디
 * @param {string} group
 * @returns
 */
export const SET_ACTIVE_GROUP_ID = 'SET_ACTIVE_GROUP_ID';
