/**
 * 자동 사라지는 알림이 사라져야 하는 표준 시간입니다.
 */
export const NOTIFICATION_TIMEOUT = {
    SHORT: 2500,
    MEDIUM: 2500,
    LONG: 10000,
    STICKY: false
};
/**
 * 알림 시간 초과 유형입니다.
 */
export const NOTIFICATION_TIMEOUT_TYPE = {
    SHORT: 'short',
    MEDIUM: 'medium',
    LONG: 'long',
    STICKY: 'sticky'
};
export const NOTIFICATION_TYPE = {
    ERROR: 'error',
    INFO: 'info',
    NORMAL: 'normal',
    SUCCESS: 'success',
    WARNING: 'warning'
};
