import React from "react";
import { CheckStyled } from "./styles.web";
import { useTranslation } from "react-i18next";
import { ClickButton } from "../../base/ui";
const NextPage = React.memo(({ name, onSave, handlerClick }) => {
    const { t } = useTranslation();
    return (React.createElement(CheckStyled, null,
        React.createElement("div", { className: "guid" }, t("attendances.nextAttachments", { name: t(`attendances.${name}`) })),
        React.createElement("div", { className: "btn_bundle" },
            React.createElement(ClickButton, { onClick: () => handlerClick('init'), label: t("attendances.previous"), isText: true }),
            React.createElement(ClickButton, { onClick: onSave, label: t("attendances.okay"), isText: true }))));
});
export default NextPage;
