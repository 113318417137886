import styled from "styled-components";

export const NotificationStyled = styled.div`
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);


    .n_item {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 8px;
        padding: 8px 12px;
        box-sizing: border-box;
        background: #fff;
        color: #000;
        border-radius: 10px;
        margin-bottom: 8px;

        > * {
            margin: 0;
        }

        &.error {
            background: #D00000;
            color: #fff;
        }


        .title {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;

        }
    }

    @media (max-width: 600px) {
        bottom: 50px;
    }
`;