import { useEffect, useState } from "react";
import { getLocalizedDurationFormatter } from "../../i18n";
import { connect } from "../../redux";
let _interval = null;
function RoomTimer({ timestamp }) {
    const [timeValue, setTimeValue] = useState(0);
    useEffect(() => {
        _interval = null;
        return () => {
            _stopTimer();
        };
    }, []);
    useEffect(() => {
        _startTimer();
        _interval = null;
        return () => {
            _stopTimer();
        };
    }, [timestamp]);
    const _setStateFromUTC = (refValueUTC, currentValueUTC) => {
        if (!refValueUTC || !currentValueUTC) {
            return;
        }
        if (currentValueUTC < refValueUTC) {
            return;
        }
        const timerMsValue = currentValueUTC - refValueUTC;
        const localizedTime = getLocalizedDurationFormatter(timerMsValue);
        setTimeValue(localizedTime);
    };
    const _startTimer = () => {
        if (!_interval) {
            _setStateFromUTC(timestamp, new Date().getTime());
            _interval = setInterval(() => {
                _setStateFromUTC(timestamp, new Date().getTime());
            }, 1000);
        }
    };
    const _stopTimer = () => {
        if (_interval) {
            clearInterval(_interval);
            _interval = null;
        }
        setTimeValue(getLocalizedDurationFormatter(0));
    };
    return timeValue;
}
function _mapStateToProps(state, ownProps) {
    const timestamp = ownProps.start_time ? ownProps.start_time : state["features/room"].start_time;
    return {
        timestamp
    };
}
export default connect(_mapStateToProps)(RoomTimer);
