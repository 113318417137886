import React from "react";
import { useTranslation } from "react-i18next";
import { ScreenOptionStyled } from "./styles.web";

function ScreenOption({
    renderLayoutType, renderLayout, renderFrameBtn
}) {
    const { t } = useTranslation();
    return (
        <ScreenOptionStyled>
            <div className="guid">
                {t("dialog.screenGuid")}
            </div>
            {/* <ToggleIconButton guid={t("dialog.screenGuid")} /> */}
            
            <div className="screen_table">
                <div className="screen_item">
                    <div className="screen_title">{t("dialog.allLayoutSync")}</div>
                    <div className="screen_frame">
                        { renderLayoutType() }
                    </div>
                </div>
                <div className="screen_item">
                    <div className="screen_title">{t("dialog.showLayout")}</div>
                    { renderLayout() }
                </div>
            </div>
           
            <div className="screen_table" style={{ borderTop: "1px solid #d5d5d5"}}>
                <div className="screen_item">
                    <div className="screen_title">{t("dialog.setFrame")}</div>
                    
                    <div className="screen_frame">
                        { renderFrameBtn() }
                    </div>
                </div>
            </div>
        </ScreenOptionStyled>
    )
}

export default React.memo(ScreenOption);

