import { useRef } from "react";
export default function useIntersectionObserver(callback) {
    const observer = useRef(new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting)
                callback();
        });
    }, { threshold: 0.8 }));
    const observe = (element) => {
        if (!element)
            return;
        observer.current && observer.current.observe(element);
    };
    const unobserve = (element) => {
        if (!element)
            return;
        observer.current && observer.current.unobserve(element);
    };
    return [observe, unobserve];
}
