import React from "react";
import AddGroupButton from "../AddGroupButton";
const GroupContainer = React.memo(({ renderMenuList, renderGroupList, renderContent }) => {
    return (React.createElement("div", { className: "group" },
        React.createElement("div", { className: "left left_prev" },
            React.createElement("div", { className: "menu" }, renderMenuList),
            React.createElement(AddGroupButton, null),
            renderGroupList),
        React.createElement("div", { className: "body" }, renderContent)));
});
export default GroupContainer;
