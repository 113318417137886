import { addDocumentFile, addDocumentItem, removeShareItem, setFocusShareItem, updateShareItem } from './actions';
import { preventHWP } from "./functions";
export default {
    addDoumentFile: async (dispatch, file) => {
        return new Promise(async (resolve, reject) => {
            if (!file)
                return reject({ message: 'noFile', uuid: null });
            const extArr = file.name.split("\.");
            const ext = extArr[extArr.length - 1];
            if (preventHWP(ext))
                return reject({ message: 'hwpfile', uuid: null });
            // 문서 저장 
            const response = await dispatch(addDocumentFile(file));
            if (response === true) {
                resolve(response);
            }
            else {
                reject(response.message);
            }
        });
    },
    addDocoumetItem: (dispatch, option) => {
        dispatch(addDocumentItem(option));
    },
    deleteShareItem: (dispatch, uuid) => {
        dispatch(removeShareItem(uuid));
    },
    focusShareItem: (dispatch, uuid, index) => {
        dispatch(setFocusShareItem(uuid, index));
    },
    currentShareItem: (dispatch, uuid, isWhiteBoard) => {
        dispatch(updateShareItem(uuid, { uuid, shared: true, isWhiteBoard }));
    }
};
