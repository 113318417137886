// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./SUITE-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./SUITE-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./SUITE-Heavy.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @font-face {
	font-family: 'SUITE';
	font-weight: 300;
	src: url('./font/SUITE-Light.woff2') format('woff2');
} */
/* @font-face {
	font-family: 'SUITE';
	font-weight: 400;
	src: url('./SUITE-Regular.woff2') format('woff2');
} */
@font-face {
	font-family: 'SUITE';
	font-weight: 500;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2');
}
/* @font-face {
	font-family: 'SUITE';
	font-weight: 600;
	src: url('./font/SUITE-SemiBold.woff2') format('woff2');
} */
@font-face {
	font-family: 'SUITE';
	font-weight: 700;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff2');
}
/* @font-face {
	font-family: 'SUITE';
	font-weight: 800;
	src: url('./font/SUITE-ExtraBold.woff2') format('woff2');
} */
@font-face {
	font-family: 'SUITE';
	font-weight: 900;
	src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2');
}`, "",{"version":3,"sources":["webpack://./src/css/font/SUITE.css"],"names":[],"mappings":"AAAA;;;;GAIG;AACH;;;;GAIG;AACH;CACC,oBAAoB;CACpB,gBAAgB;CAChB,4DAAgD;AACjD;AACA;;;;GAIG;AACH;CACC,oBAAoB;CACpB,gBAAgB;CAChB,4DAA8C;AAC/C;AACA;;;;GAIG;AACH;CACC,oBAAoB;CACpB,gBAAgB;CAChB,4DAA+C;AAChD","sourcesContent":["/* @font-face {\n\tfont-family: 'SUITE';\n\tfont-weight: 300;\n\tsrc: url('./font/SUITE-Light.woff2') format('woff2');\n} */\n/* @font-face {\n\tfont-family: 'SUITE';\n\tfont-weight: 400;\n\tsrc: url('./SUITE-Regular.woff2') format('woff2');\n} */\n@font-face {\n\tfont-family: 'SUITE';\n\tfont-weight: 500;\n\tsrc: url('./SUITE-Medium.woff2') format('woff2');\n}\n/* @font-face {\n\tfont-family: 'SUITE';\n\tfont-weight: 600;\n\tsrc: url('./font/SUITE-SemiBold.woff2') format('woff2');\n} */\n@font-face {\n\tfont-family: 'SUITE';\n\tfont-weight: 700;\n\tsrc: url('./SUITE-Bold.woff2') format('woff2');\n}\n/* @font-face {\n\tfont-family: 'SUITE';\n\tfont-weight: 800;\n\tsrc: url('./font/SUITE-ExtraBold.woff2') format('woff2');\n} */\n@font-face {\n\tfont-family: 'SUITE';\n\tfont-weight: 900;\n\tsrc: url('./SUITE-Heavy.woff2') format('woff2');\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
