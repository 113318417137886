import React, { useMemo, useRef } from "react";
import { Group, Line, Transformer } from "react-konva";
import { PEN } from "../../constants";
function deflat(n, arr, y = []) {
    return arr.length === 0
        ? y
        : deflat(n, arr.slice(n), y.concat([arr.slice(0, n)]));
}
const LineCanvas = React.memo(({ selected, canvasTool, points, ratio, handlerUpdateLine }) => {
    const shapeRef = useRef(null);
    const trRef = useRef(null);
    const { hitStrokeWidth, strokeWidth } = useMemo(() => {
        let nThickness = canvasTool.tool === PEN.name ? canvasTool.thickness : canvasTool.thickness + 20;
        const hitStrokeWidth = (nThickness + 5) / ratio;
        const strokeWidth = (nThickness) / window.devicePixelRatio / ratio;
        return {
            hitStrokeWidth, strokeWidth
        };
    }, [canvasTool.tool, canvasTool.thickness, window.devicePixelRatio, ratio]);
    // useEffect(() => {
    //     if (selected) {
    //         if (trRef && trRef.current && shapeRef && shapeRef.current) {
    //             trRef.current.nodes([shapeRef.current]);
    //             // @ts-ignore
    //             if (trRef.current.getLayer() !== null) trRef.current.getLayer().batchDraw();
    //         }
    //     }       
    // }, [selected]);
    return (React.createElement(Group, { scaleX: ratio, scaleY: ratio },
        React.createElement(Line, { ref: shapeRef, className: canvasTool.key, points: points, strokeWidth: strokeWidth === Infinity ? 0 : strokeWidth, hitStrokeWidth: hitStrokeWidth === Infinity ? 0 : hitStrokeWidth, opacity: canvasTool.tool === PEN.name ? canvasTool.opacity / 10 : 1, lineJoin: 'round', lineCap: 'round', globalCompositeOperation: canvasTool.tool === PEN.name ? 'source-over' : 'destination-out', bezier: true, stroke: canvasTool.color, draggable: selected, onDragEnd: (e) => {
                if (!selected)
                    return;
                const node = shapeRef.current;
                if (node) {
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();
                    const flattenedPoints = deflat(2, node.points());
                    let newPoints = [];
                    flattenedPoints.map((point) => {
                        //@ts-ignore
                        newPoints.push(point[0] * scaleX);
                        //@ts-ignore
                        newPoints.push(point[1] * scaleY);
                    });
                    handlerUpdateLine({
                        canvasTool,
                        points: newPoints
                    });
                    node.scaleX(1);
                    node.scaleY(1);
                }
            } }),
        selected && React.createElement(Transformer, { ref: trRef, keepRatio: false, rotateEnabled: false, centeredScaling: true, onTransformEnd: (e) => {
                if (!selected)
                    return;
                const node = shapeRef.current;
                if (node) {
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();
                    const flattenedPoints = deflat(2, node.points());
                    let newPoints = [];
                    flattenedPoints.map((point) => {
                        //@ts-ignore
                        newPoints.push(point[0] * scaleX);
                        //@ts-ignore
                        newPoints.push(point[1] * scaleY);
                    });
                    handlerUpdateLine({
                        canvasTool,
                        points: newPoints
                    });
                    node.scaleX(1);
                    node.scaleY(1);
                }
            }, enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'], boundBoxFunc: (oldBox, newBox) => {
                if (newBox.width < 5 || newBox.height < 5) {
                    return oldBox;
                }
                return newBox;
            } })));
});
export default LineCanvas;
