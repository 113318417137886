import styled, { css } from 'styled-components';
export const LayoutContainerStyled = styled.div `
	width: 100%;
	height: 100%;
	margin: 0;
	display: flex;
	color: #fff;
	overflow: hidden;

	position: relative;
	display: inline-flex;
	width: ${props => (props.isHide ? 0 : '100%')};
	height: 100%;
	overflow: hidden;
	flex-direction: ${props => (props.horizontal ? 'column' : 'row')};
	justify-content: ${props => (props.isGrid ? 'center' : 'flex-start')};
	align-items: ${props => (props.isGrid ? 'center' : 'flex-start')};
	background: ${props => APP.config.style.main};
	.voice {
		display: inline-flex;
	}

	.gridSlide {
		display: grid;
		grid-auto-flow: row;
		width: 100%;
		height: 100%;
		grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
		overflow: hidden;
		${props => props.name === 'class' &&
    css `
				justify-items: center;
				gap: 15px;

				.video_label {
					width: 60%;
					left: 50%;
					transform: translate(-50%, -14px);
					/* height: 5%; */
					border-radius: 28px;
					padding: 12px;
					box-sizing: border-box;
				}
			`}
		.item {
			${props => props.name === 'class'
    ? css `
							width: 80% !important;
							height: 100% !important;
							border-radius: 30px;
						`
    : css `
							width: 100% !important;
							height: 100% !important;
						`}
		}
	}

	.horizontal_item {
		display: contents;
		margin: 0;
	}

	.mainFlex {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;

		&.full {
			width: 100%;
			height: 100%;
			background: #000;
		}
	}

	.gridFlex {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		width: auto !important;
		height: auto !important;
	}

	.horizontal {
		width: 100%;
		max-height: 98px;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;

		&.layout_center {
			justify-content: center;
		}

		&.non_expand {
			position: absolute;
		}
	}

	.vertical_grid {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		${props => props.name === 'class'
    ? css `
						height: inherit;
					`
    : css `
						height: 100%;
					`}
	}

	.item {
		box-sizing: border-box;
		position: relative;
		background-color: ${props => APP.config.style.main};
		margin: 0;
		border: 1px solid ${props => APP.config.style.main};
		overflow: hidden;

		&.mirror {
			video {
				transform: scale(-1, 1);
				-moz-transform: scale(-1, 1);
				-webkit-transform: scale(-1, 1);
				-o-transform: scale(-1, 1);

				object-fit: contain;
			}

			.video_label {
				border: 1.5px solid orange;
			}
		}
	}

	.expand_button {
		position: absolute;
		left: 147px;
		top: 40px;

		&.non {
			transform: rotate(180deg);
		}
	}

	.video_pin_icon {
		position: absolute;
		top: 8px;
		right: 8px;

		.hover {
			cursor: pointer;
		}
	}

	.video_label {
		position: absolute;
		box-sizing: border-box;
		bottom: 0px;
		overflow: hidden;
		padding: 0px 10px;
		font-size: 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;
		min-height: 25px;
		max-height: 62px;
		box-sizing: border-box;
		max-width: 360px;
		width: 80%;
		left: 50%;
		transform: translate(-50%, -14px);
		border-radius: 28px;
		padding: 4px 12px;
		background: rgba(0, 0, 0, 0.5);
		.nickname {
			flex: 5;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;

			span {
				line-height: 16px;
			}
		}
	}

	.click_button {
		&:not(.no-pointer) {
			cursor: pointer;
		}

		&:hover:not(.no-hover):not(.text-button) {
			@include hoverColor;

			> * {
				@include hoverColor;
			}

			svg {
				@include fillIconColor;
			}
		}
	}

	.circleText {
		font-weight: bold;
		font-size: 23px;
		width: 200px;
		height: 200px;
		border-radius: 50%;
		overflow: hidden;
		background: #8bacff;
		padding: 12px;
		max-width: 80%;
		max-height: 80%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.videoWrap {
		overflow: hidden;
		width: 100%;
		height: 100%;
		position: relative;
		background-color: ${props => APP.config.style.video_wrap};

		video {
			width: 100%;
			height: 100%;
		}

		.default {
			width: 100%;
			height: 100%;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;

			${props => APP.config.name === 'class' && `background: #123;`}
		}
	}

	.horizontal_item {
		.item {
			.video_label {
				height: fit-content;
				bottom: -11px;
			}
		}
	}
`;
export const PagingDesktopStyled = styled.div `
	position: relative;
	right: ${props => (props.type === 'next' ? '0' : 'auto')};
	left: ${props => (props.type === 'prev' ? '0' : 'auto')};
	width: ${props => (props.vertical ? '100%' : '30px')};
	min-height: ${props => (props.vertical ? '30px' : '100%')};
	background: #000;
	display: flex;
	justify-content: space-around;
	align-items: center;
	cursor: pointer;
	margin: 0;

	&:hover {
		svg {
			color: #76acfb !important;
		}
	}
`;
export const ScreenShareStyled = styled.div `
	display: flex;
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;

	.screen_layout {
		background: #000;
	}

	> * {
		margin: 0;
	}

	${props => props.screenOnly
    ? css `
					.screen_layout {
						width: 100%;
						height: 100%;
					}

					.member_container {
						position: absolute;

						width: 100%;
						height: 100%;
					}

					.member_layout {
						position: absolute;
						width: 172px;
						height: 98px;
						${props.position === 1
        ? css `
									left: 0;
									top: 0;
								`
        : props.position === 2
            ? css `
										top: 0;
										right: 0;
									`
            : props.position === 3
                ? css `
											right: 0;
											bottom: 0;
										`
                : css `
											left: 0;
											bottom: 0;
										`}
					}

					.btns_wrapper {
						position: absolute;
						width: 172px;
						height: 98px;
						z-index: 2;

						&:hover {
							.btns {
								display: block;
							}
						}

						${props.position === 1
        ? css `
									left: 0;
									top: 0;
								`
        : props.position === 2
            ? css `
										top: 0;
										right: 0;
									`
            : props.position === 3
                ? css `
											right: 0;
											bottom: 0;
										`
                : css `
											left: 0;
											bottom: 0;
										`}

						.btns {
							width: 100%;
							height: 100%;
							margin: 0;
							position: absolute;
							display: none;

							${props.position === 1
        ? css `
										left: 0;
										top: 0;
									`
        : props.position === 2
            ? css `
											top: 0;
											right: 0;
										`
            : props.position === 3
                ? css `
												right: 0;
												bottom: 0;
											`
                : css `
												left: 0;
												bottom: 0;
											`}
						}
					}
					.screen_button {
						position: absolute;

						svg {
							fill: #fff;
							stroke: #000;
						}

						&:hover {
							svg {
								fill: ${APP.config.style.focusColor};
							}
						}
						&.top {
							top: 8px;
							left: 50%;
							transform: translate(-50%, 0) rotate(-90deg);
						}

						&.right {
							right: 8px;
							top: 50%;
							transform: translate(0, -50%);
						}

						&.bottom {
							bottom: 8px;
							left: 50%;
							transform: translate(-50%, 0) rotate(90deg);
						}

						&.left {
							left: 8px;
							top: 50%;
							transform: translate(0, -50%) rotate(180deg);
						}
					}
				`
    : css `
					flex-direction: row-reverse;

					.screen_layout {
						width: calc(100% - 172px);
						height: 100%;
					}

					.member_container {
						display: flex;
						flex-direction: column;
					}
					.member_layout {
						width: 172px;
						flex: 1;
					}
				`}

	video {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;
export const DualStyled = styled.div `
	background: ${props => APP.config.style.main};
	display: flex;
	width: 100%;
	height: 100%;

	.page_btns {
		margin: 0 0 0 auto;
		.text-button {
			margin: 0;
			padding: 0 12px;
			height: 99%;
		}
	}

	.dual_sidebar {
		width: 350px;
		height: 100%;
	}

	.dual_side_header {
		display: flex;
		height: 30px;
		width: 100%;
		gap: 4px;

		.side {
			margin: 0;
			flex: 1;
			border-radius: 0;

			svg,
			span {
				fill: #fff !important;
				color: #fff !important;
			}
		}
	}

	.downBtn {
		background: #123;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 12px;
		margin: 0;

		&.right {
			transform: rotate(270deg);
		}

		&.left {
			transform: rotate(90deg);
		}
	}

	.dual_content {
		display: flex;
		flex-direction: column;
		flex: 1;
		height: 100%;
	}

	.dual_right_content {
		display: flex;
		flex-direction: column;
		flex: 1;
		height: ${props => (props.isScreenMode ? 'calc(100% - 198px)' : 'calc(100% - 30px)')};
	}
`;
