import React, { useMemo } from 'react';
import { ClickButton, DivElement, Select } from '../base/ui';
import { useGlobalState } from '../app/GlobalStateProvider';
const DeviceSelect = React.memo(({ error, type, list, deviceId, handlerUpdateId, children, }) => {
    const { isMobile, isIPhone } = useGlobalState();
    const options = useMemo(() => list
        .map(({ deviceId, label }) => ({
        uuid: deviceId,
        name: label,
    }))
        .filter(item => item.uuid !== ''), [list]);
    const renderSelectIcon = useMemo(() => (React.createElement(ClickButton, { className: "no-hover select_icon", size: isMobile ? 18 : 28, icon: type, color: "#C6CBD2" })), [type, isMobile]);
    return (React.createElement(DivElement, { className: "device_select", style: { width: '100%', alignItems: 'center' } },
        React.createElement(Select, { className: `${type}_select ${isIPhone && 'full'}`, options: options, error: error, selectedOption: deviceId, setSelectedOption: handlerUpdateId, isMobile: isMobile, test: renderSelectIcon }),
        children));
});
export default DeviceSelect;
