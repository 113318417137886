
import { TOGGLE_OPEN_GROUP } from "../../groups/actionTypes";
import { SET_DUAL_MONITOR } from "../../video-layout/actionTypes";
import { TEMP_SERVER_RECORD_START } from "../record/actionTypes";
import { MiddlewareRegistry } from "../redux";
import { hideDialog } from "./actions";

MiddlewareRegistry.register(store => next => action => {
    switch (action.type) {
        case SET_DUAL_MONITOR:
        case TEMP_SERVER_RECORD_START: 
        case TOGGLE_OPEN_GROUP:
            store.dispatch(hideDialog());

            break;
    }

    return next(action);
});
