import { MiddlewareRegistry } from "../base/redux";
import { START_LISTENING_FOR_STATS } from "./actionTypes";
import statsEmitter from "./statsEmitter";

MiddlewareRegistry.register(store => next => action => {
    switch (action.type) {
        case START_LISTENING_FOR_STATS: {
            statsEmitter.startListeningForStats(action.conference);
            break;
        }
    }

    return next(action);
});