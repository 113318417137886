export const Colors = {
    black: '#101010',
    dark_grey: '#4d4d4d',
    grey: '#666',
    light_grey: '#83868A',
    w_grey: '#b6bac0',
    c_grey: '#d5d6da',
    sliver: '#f3f4f8',
    white: '#fff',
    dark_red: '#C33025',
    scarlet: '#E01507',
    m_red: '#E02B1D',
    raspberry: '#FF5A4F',
    rubicund: '#F0857D',
    light_red: '#FFF1F0',
    accent2: '#FF2D55',
    transparent: 'transparent',
    hr: '#297A94',
    accent: '#ED010F',
    pink: '#F46790',
    dark_tur: '#479DA6',
    tur: '#3AB2BF',
    light_tur: '#458c94',
    p_ture: '#2D39AA',
    dark_blue: '#5168AA',
    blue: '#3C5295',
    light_blue: '#1B3E93',
    p_blue: '#D9468A',
    pur: '#A08EDE',
};
