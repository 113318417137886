import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { enterRoom, getRoomOption, isRoomJoined } from '../../room';
import { toggleLoad } from '../loading';
import { getDisplayName } from '../settings';
import { appWillMount, appWillUnmount } from './actions';
import { BaseApp } from './components';
import { PAGE_TYPE } from './constants';
const BaseContainer = () => {
    const dispatch = useDispatch();
    // Selector에서 반환하는 타입을 명시해줍니다.
    const { roomUid, displayname, isJoined, appLoad, } = useSelector((state) => {
        const roomUid = getRoomOption(state)?.uuid;
        const displayname = getDisplayName(state);
        const isJoined = isRoomJoined(state);
        const appLoad = !!state['features/base/app'].app;
        return {
            roomUid,
            displayname,
            isJoined,
            appLoad,
        };
    }, shallowEqual);
    const _mount = () => dispatch(appWillMount(true));
    const _unmount = () => dispatch(appWillUnmount());
    // 두 번째 Selector도 반환하는 타입을 명시합니다.
    const { enableLobby, enableNickname, } = useSelector((state) => state['features/base/settings']);
    const [page, setPage] = useState();
    const handlerPreventMenu = (e) => e.preventDefault();
    const leave = () => APP.management.leave();
    const handlerPage = (props) => {
        if (props.test) {
            setPage(props.page);
        }
        else {
            handlerChangePage(props.page);
        }
    };
    useEffect(() => {
        const pathArray = window.location.pathname.split('/');
        const secondPathValue = pathArray[1];
        if (['test', 'error', 'login', 'signup'].includes(secondPathValue)) {
            handlerChangePage(secondPathValue);
        }
        else {
            handlerChangePage(PAGE_TYPE.LOBBY);
        }
        _mount();
        window.addEventListener('contextmenu', handlerPreventMenu);
        window.addEventListener('beforeunload', leave);
        return () => {
            leave();
            _unmount();
            dispatch(toggleLoad(false));
            window.removeEventListener('beforeunload', leave);
            window.removeEventListener('contextmenu', handlerPreventMenu);
            APP.eventManager.unsubscribe('UPDATE_PAGE', handlerPage);
        };
    }, []);
    useEffect(() => {
        APP.eventManager.subscribe('UPDATE_PAGE', handlerPage);
    }, [appLoad]);
    const handlerChangePage = (page) => {
        if (!appLoad)
            return;
        if (page === PAGE_TYPE.LOBBY) {
            if (!enableLobby)
                setPage(PAGE_TYPE.ERROR);
            else
                setPage(PAGE_TYPE.LOBBY);
        }
        else if (page === PAGE_TYPE.NICKNAME) {
            if (!enableNickname)
                setPage(PAGE_TYPE.DEVICE);
            else
                setPage(PAGE_TYPE.NICKNAME);
        }
        else {
            setPage(page);
        }
    };
    useEffect(() => {
        if (appLoad) {
            if (isJoined)
                handlerChangePage(PAGE_TYPE.MAIN);
            else {
                if (roomUid) {
                    if (navigator.product === 'ReactNative') {
                        if (!displayname)
                            handlerChangePage(PAGE_TYPE.NICKNAME);
                        // @ts-ignore
                        else
                            dispatch(enterRoom());
                    }
                    else {
                        if (!displayname)
                            handlerChangePage(PAGE_TYPE.NICKNAME);
                        else
                            handlerChangePage(PAGE_TYPE.DEVICE);
                    }
                }
                dispatch(toggleLoad(false));
            }
        }
        else {
            dispatch(toggleLoad(true));
        }
    }, [appLoad, isJoined, roomUid, displayname]);
    if (!appLoad)
        return null;
    return (React.createElement(BaseApp, { page: page, roomUid: roomUid, handlerChangePage: handlerChangePage }));
};
export default BaseContainer;
