import React from "react";
import { useSelector } from "react-redux";
import { Dialog } from "../base/dialog";
import { PERMISSION } from "../base/members/constans";
import { getCurrentMode } from "../video-layout/functions";
import DocumentListContainer from "./DocumentListContainer";
import { AddDocumentButton } from "./components";
import { DocumentStyled } from "./components/styles.web";
import { VOD_MODE } from "./constants";
import { checkSharePermission, getFocusItemByMode } from "./functions";
const DocumentListDialog = React.memo(() => {
    const mode = useSelector((state) => getCurrentMode(state));
    const permission = useSelector((state) => checkSharePermission(state, PERMISSION.SHARE));
    const focusItem = useSelector((state) => getFocusItemByMode(state, mode));
    return (React.createElement(Dialog, { titleKey: mode === VOD_MODE ? 'document.shareVod' : 'document.shareDocument', width: 370 },
        React.createElement(AddDocumentButton, { mode: mode, isDialog: true, isIcon: false }),
        React.createElement(DocumentStyled, { name: APP.config.name, ...APP.config.ui.document, expand: false },
            React.createElement("div", { className: "share_list" },
                React.createElement(DocumentListContainer, { mode: mode, permission: permission, focusItem: focusItem })))));
});
export default DocumentListDialog;
