import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { addVodFile } from "../../share-layout/actions";
// @ts-ignore
import { hideDialog, toggleDialog } from "../dialog";
import { ClickButton, DivElement, FileComponent } from "../ui";
import AddUrlPopup from "./AddUrlPopup";
import { addFileVod, addUrlVod } from "./functions";
const AddVodItem = React.memo(({ isMobile = false, isRoom = false, isIcon = false, isText = false, handler }) => {
    const dispatch = useDispatch();
    const handlerAddFile = (file) => {
        if (!file)
            handler.failed('api.noFile');
        else {
            handler.setLoad(true);
            if (isRoom)
                addFileVod(file, file.name, undefined, handler);
            // @ts-ignore
            else
                dispatch(addVodFile({ file }, file.name, undefined, handler));
        }
    };
    const handlerAddUrl = (external_source_url, name) => {
        if (!external_source_url)
            handler.failed('api.noUrl');
        else if (!name)
            handler.failed('api.noName');
        else {
            handler.setLoad(true);
            if (isRoom)
                addUrlVod(external_source_url, name, undefined, handler);
            // @ts-ignore
            else
                dispatch(addVodFile({ external_source_url }, name, undefined, handler));
            dispatch(hideDialog());
        }
    };
    const handlerOpenUrlPopup = () => {
        if (isRoom) {
            APP.eventManager.publish('UPDATE_ROOM_CONFIG_DIALOG', { show: true, props: {
                    component: React.createElement(AddUrlPopup, { isRoom: isRoom, isMobile: isMobile, isDialog: false, handlerAddVod: handlerAddUrl }),
                    title: 'vod.urlAdd',
                    width: 450,
                    isMobile
                } });
        }
        else {
            // @ts-ignore
            dispatch(toggleDialog(AddUrlPopup, { isDialog: true, handlerAddVod: handlerAddUrl }));
        }
    };
    /**
     * 파일 등록 버튼 UI
     */
    const fileLabel = useMemo(() => (React.createElement(ClickButton, { className: isRoom ? 'btn add_room_btn' : 'btn add_btn', icon: isIcon ? isRoom ? 'roomFileAdd' : 'fileAdd' : '', label: isText ? 'vod.fileAdd' : '', size: 22, color: isRoom ? '#29AAE2' : APP.config.style.playerColor })), [isIcon, isText, isRoom]);
    /**
     * Input 파일 xonnection
     */
    const renderFileBtn = useMemo(() => (React.createElement(FileComponent, { id: 'add_vod', label: fileLabel, onChange: handlerAddFile })), [fileLabel, handlerAddFile]);
    // URL 버튼 등록 
    const renderUrlBtn = useMemo(() => (React.createElement(ClickButton, { className: isRoom ? 'btn add_room_btn' : 'btn add_btn', icon: isIcon ? isRoom ? 'urlAdd' : 'urlAdd' : '', label: isText ? 'vod.urlAdd' : '', size: 22, color: isRoom ? '#29AAE2' : APP.config.style.playerColor, onClick: handlerOpenUrlPopup })), [isIcon, isText, isMobile, isRoom, handlerOpenUrlPopup]);
    return (React.createElement(DivElement, { className: 'test' },
        APP.config.option.vodFile && renderFileBtn,
        APP.config.option.vodUrl && renderUrlBtn));
});
export default AddVodItem;
