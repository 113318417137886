import React, { useEffect, useMemo, useRef } from "react";
import { Group, Shape, Transformer } from 'react-konva';
import { CIRCLE, RECT, TRIANGLE } from "../../constants";
const FigureCanvas = React.memo(({ selected, canvasTool, points, ratio, handlerUpdateLine }) => {
    const shapeRef = useRef(null);
    const trRef = useRef(null);
    const { hitStrokeWidth, strokeWidth } = useMemo(() => {
        const hitStrokeWidth = (canvasTool.thickness + 5) / ratio;
        const strokeWidth = (canvasTool.thickness) / ratio;
        return {
            hitStrokeWidth,
            strokeWidth,
            ratio
        };
    }, [canvasTool.thickness, ratio]);
    const { figureWidth, figureHeight } = useMemo(() => {
        return {
            figureWidth: canvasTool.figureWidth || 0,
            figureHeight: canvasTool.figureHeight || 0
        };
    }, [canvasTool.figureWidth, canvasTool.figureHeight]);
    const { x, y } = useMemo(() => {
        return {
            x: points[0],
            y: points[1]
        };
    }, [points]);
    const drawFun = (context, shape) => {
        const { color, tool } = canvasTool;
        context.lineWidth = strokeWidth;
        context.fillStyle = color;
        context.strokeStyle = color;
        context.beginPath();
        if (tool.includes(RECT.name)) {
            context.rect(0, 0, figureWidth, figureHeight);
        }
        else if (tool.includes(CIRCLE.name)) {
            const width = Math.abs(figureWidth);
            const height = Math.abs(figureHeight);
            context.ellipse(width / 2, height / 2, width / 2, height / 2, 0, 0, 2 * Math.PI);
        }
        else if (tool.includes(TRIANGLE.name)) {
            context.moveTo(figureWidth / 2, 0);
            context.lineTo(0, figureHeight);
            context.lineTo(figureWidth, figureHeight);
            context.closePath();
        }
        if (tool.indexOf("fill") !== -1)
            context.fill();
        else
            context.stroke();
        context.closePath();
        context.fillStrokeShape(shape);
    };
    const chnageTransformer = () => {
        const node = shapeRef.current;
        if (node) {
            handlerUpdateLine({
                canvasTool: {
                    ...canvasTool,
                    figureWidth: node.width() * node.scaleX(),
                    figureHeight: node.height() * node.scaleY()
                },
                points: [node.x(), node.y()]
            });
            node.scaleX(1);
            node.scaleY(1);
        }
    };
    useEffect(() => {
        if (selected) {
            if (trRef && trRef.current && shapeRef && shapeRef.current) {
                trRef.current.nodes([shapeRef.current]);
                // @ts-ignore
                if (trRef.current.getLayer() !== null)
                    trRef.current.getLayer().batchDraw();
            }
        }
    }, [selected]);
    return (React.createElement(Group, { scaleX: ratio, scaleY: ratio, width: figureWidth, height: figureHeight },
        React.createElement(Shape, { ref: shapeRef, className: canvasTool.key, strokeWidth: strokeWidth, hitStrokeWidth: hitStrokeWidth, opacity: canvasTool.opacity / 10, width: figureWidth, height: figureHeight, x: x, y: y, sceneFunc: drawFun, hitFunc: drawFun, draggable: selected, onDragEnd: chnageTransformer }),
        selected && React.createElement(Transformer, { ref: trRef, keepRatio: false, rotateEnabled: false, centeredScaling: true, onTransformEnd: chnageTransformer, enabledAnchors: ['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right'], boundBoxFunc: (oldBox, newBox) => {
                if (newBox.width < 5 || newBox.height < 5) {
                    return oldBox;
                }
                return newBox;
            } })));
});
export default FigureCanvas;
// export default function FigureCanvas({
//     canvasTool, points, ratio, 
//     selected, handlerSelectedLine, handlerChangeCanvasPoint, handlerDeleteLine
// }) {
//     const shapeRef = useRef();
//     const trRef = useRef();
//     const [shapeProps, setShapeProps] = useState(initShapeProps);
//     const { thickness, color, opacity, tool } = canvasTool;
//     useEffect(() => {
//         if (selected) {
//             if (trRef && trRef.current && shapeRef && shapeRef.current) {
//                 trRef.current.nodes([shapeRef.current]);
//                 trRef.current.getLayer().batchDraw();
//             }
//         }       
//     }, [selected]);
//     useEffect(() => {
//         let [x, y] = points;
//         setShapeProps({
//             width: canvasTool.figureWidth,
//             height: canvasTool.figureHeight,
//             x,
//             y
//         });
//     }, [points, canvasTool], shallowEqual);
//     const handlerUpdatePoint = (x, y, width, height) => {
//         handlerChangeCanvasPoint([ x, y ], {
//             ...canvasTool,
//             figureWidth: width, 
//             figureHeight: height
//         });
//     }
//     const drawFun = (context, shape) => {
//         context.lineWidth = thickness;
//         context.fillStyle = color;
//         context.strokeStyle = color;
//         const { tool } = canvasTool;
//         let { width, height, x, y } = shapeProps;
//         context.beginPath();
//         if (tool.includes(RECT.name)) {
//             context.rect(0, 0, width, height);
//         } else if (tool.includes(CIRCLE.name)) {            
//             width = Math.abs(width);
//             height = Math.abs(height);
//             context.ellipse(width / 2, height / 2, width / 2, height / 2, 0, 0, 2 * Math.PI);
//         } else if (tool.includes(TRIANGLE.name)) {
//             // const newWidth = width; // canvasTool?.flipX ? width * -1 : width;
//             // const newHeight =  height;  //canvasTool?.flipY ? height * -1 : height;
//             context.moveTo(width / 2 , 0);
//             context.lineTo(0, height); 
//             context.lineTo(width, height);
//             // context.scale(canvasTool?.flipX ? -1 : 1, canvasTool?.flipY ? -1 : 1);
//             context.closePath();
//         }
//         if (tool.indexOf("fill") !== -1) context.fill();
//         else context.stroke();
//         context.closePath();
//         context.fillStrokeShape(shape);
//     }
//     const hitStrokeWidth = (canvasTool.thickness + 5) / ratio;
//     const strokeWidth = (canvasTool.thickness) / ratio;
//     return (
//         <Group
//             scaleX={ratio} scaleY={ratio}  
//             hitStrokeWidth={hitStrokeWidth}
//             onClick={handlerSelectedLine} 
//             onTap={handlerSelectedLine}
//             onMouseOver={handlerDeleteLine}
//             onMouseMove={handlerDeleteLine}
//             onTouchMove={handlerDeleteLine}    
//             width={shapeProps.width}
//             height={shapeProps.height}
//         >
//             <Shape
//                 ref={shapeRef}
//                 strokeWidth={strokeWidth}
//                 hitStrokeWidth={hitStrokeWidth}
//                 opacity={canvasTool.opacity / 10}
//                 width={shapeProps.width}
//                 height={shapeProps.height}
//                 x={shapeProps.x}
//                 y={shapeProps.y}
//                 draggable={selected}                
//                 onDragEnd={(e) => {
//                     const data = {
//                         ...shapeProps,
//                         x: e.target.x(),
//                         y: e.target.y()
//                     };
//                     setShapeProps(data);
//                     handlerUpdatePoint(data.x, data.y, data.width, data.height);
//                 }}
//                 onTransformEnd={(e) => {
//                     if (!selected) return;
//                     const node = shapeRef.current;
//                     const scaleX = node.scaleX();
//                     const scaleY = node.scaleY();
//                     node.scaleX(1);
//                     node.scaleY(1);
//                     const data = {
//                         ...shapeProps,
//                         x: node.x(),
//                         y: node.y(),
//                         // set minimal value
//                         width: Math.max(5, node.width() * scaleX),
//                         height: Math.max(node.height() * scaleY),
//                     };
//                     setShapeProps(data);
//                     handlerUpdatePoint(data.x, data.y, data.width, data.height);
//                 }}
//                 sceneFunc={drawFun}
//                 hitFunc={drawFun}
//             />
//             { selected && <Transformer ref={trRef} 
//                 keepRatio={false}
//                 rotateEnabled={false}
//                 centeredScaling={true}
//                 enabledAnchors={['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right']}
//                 boundBoxFunc={(oldBox, newBox) => {
//                     if (newBox.width < 5 || newBox.height < 5) {
//                         return oldBox;
//                     }
//                     return newBox;
//                 }}
//             /> }
//         </Group>
//     )
// }
