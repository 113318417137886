/**
 * 로컬 오디오의 가용성을 조정하기 위한 (redux) 작업 유형입니다.
 *
 * {
 *     type: SET_AUDIO_AVAILABLE,
 *     muted: boolean
 * }
 */
export const SET_AUDIO_AVAILABLE = 'SET_AUDIO_AVAILABLE';
/**
 * 로컬 오디오의 음소거 상태를 설정하는 (redux) 작업 유형입니다.
 *
 * {
 *     type: SET_AUDIO_MUTED,
 *     muted: boolean
 * }
 */
export const SET_AUDIO_MUTED = 'SET_AUDIO_MUTED';
/**
 * 오디오 음소거 아이콘을 활성화/비활성화하는 (redux) 작업 유형입니다.
 *
 * {
 *     type: SET_AUDIO_UNMUTE_PERMISSIONS,
 *     blocked: boolean
 * }
 */
export const SET_AUDIO_UNMUTE_PERMISSIONS = 'SET_AUDIO_UNMUTE_PERMISSIONS';
/**
 * 로컬 화면 공유의 음소거 상태를 설정하는 (redux) 작업 유형입니다.
 *
 * {
 *     type: SET_SCREENSHARE_MUTED,
 *     muted: boolean
 * }
 */
export const SET_SCREENSHARE_MUTED = 'SET_SCREENSHARE_MUTED';
/**
 * 로컬 비디오의 가용성을 조정하기 위한 (redux) 작업 유형입니다.
 *
 * {
 *     type: SET_VIDEO_AVAILABLE,
 *     available: boolean
 * }
 */
export const SET_VIDEO_AVAILABLE = 'SET_VIDEO_AVAILABLE';
/**
 * 로컬 비디오의 음소거 상태를 설정하는 (redux) 작업 유형입니다.
 *
 * {
 *     type: SET_VIDEO_MUTED,
 *     muted: boolean
 * }
 */
export const SET_VIDEO_MUTED = 'SET_VIDEO_MUTED';
/**
 * 스트림에 적용된 마지막 동영상 {@link Transform}을 저장하기 위한 (redux) 작업 유형입니다.
 *
 * {
 *     type: STORE_VIDEO_TRANSFORM,
 *     streamId: string,
 *     transform: Transform
 * }
 */
export const STORE_VIDEO_TRANSFORM = 'STORE_VIDEO_TRANSFORM';
/**
 * 비디오 음소거 아이콘을 활성화/비활성화하는 (redux) 작업 유형입니다.
 *
 * {
 *     type: SET_VIDEO_UNMUTE_PERMISSIONS,
 *     blocked: boolean
 * }
 */
export const SET_VIDEO_UNMUTE_PERMISSIONS = 'SET_VIDEO_UNMUTE_PERMISSIONS';
/**
 *  * 로컬 비디오 카메라 대면 모드를 전환하는 (redux) 작업 유형입니다.
 * SET_CAMERA_FACING_MODE와 달리 현재/유효한 카메라 대면 모드의 별도 읽기 및 쓰기 오버헤드 없이 토글을 최적으로 및/또는 기본적으로 구현할 수 있습니다.
 *
 * {
 *     type: TOGGLE_CAMERA_FACING_MODE
 * }
 */
export const TOGGLE_CAMERA_FACING_MODE = 'TOGGLE_CAMERA_FACING_MODE';
/**
 * 트랙이 소스 이벤트에서 데이터를 트리거하지 않았을 때 발송되는 redux 작업 유형입니다.
 *
 * {
 *     type: TRACK_NO_DATA_FROM_SOURCE,
 *     track: Track
 * }
 */
export const TRACK_NO_DATA_FROM_SOURCE = 'TRACK_NO_DATA_FROM_SOURCE';
