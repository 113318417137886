/**
 * jisti connection 연결 중
 */
export const WILL_JITSI_CONNECTION = 'WILL_JITSI_CONNECTION';
/**
 * jitsi connection success
 */
export const SUCCESS_JITSI_CONNECTION = 'SUCCESS_JITSI_CONNECTION';
/**
 * jitsi connection failed
 */
export const FAILED_JITSI_CONNECTION = 'FAILED_JITSI_CONNECTION';
