import React from "react";
import { useSelector } from "react-redux";
import { getRoomOption } from "../../room";
import { DocumentStyled } from "../../share-layout/components/styles.web";
import { translate } from "../i18n";
import { getMediaServerInfo } from "../../room";

function CommonNoteLayout({
    t, isMobile, mode, expand, isLeft, setIsLeft, renderShareLeft, style
}){
    const mediaInfo = useSelector(state => getMediaServerInfo(state));
    const roomCode = useSelector(state => getRoomOption(state)?.code);
    const groupId = ""; //useSelector(state => getGroupId(state) || "");

    let serverPath = '/ep/p';
    if (mediaInfo.type === 'jitsi' && typeof mediaInfo.url === 'string') {
        const shardName = mediaInfo.url.replaceAll('/xmpp-websocket', '');
        serverPath = `${shardName}/ep/p`;
    }

    return (
        <DocumentStyled name={APP.config.name} {...APP.config.ui.document} expand={expand}>
            { isLeft && <div className={`share_list ${!expand && !isMobile && 'non_expand'}`}>
                <div className="share_header">
                    <p>{ t(`share.${mode}`) } </p>
                </div>
            </div> }
            
            { renderShareLeft() }
            <div style={{flex: "1", position: "relative", background: "#f7f7f7"}} >
                <iframe src={`${window.location.origin}${serverPath}/${roomCode}_${groupId}`} style={style}/>               
            </div>
        </DocumentStyled>
    );
}

export default translate(CommonNoteLayout);