export const PAGE_TYPE = {
    LOBBY: "lobby",
    NICKNAME: "nickname",
    DEVICE: "device",
    MAIN: "main",
    CANVAS: "canvas",
    ERROR: "error",
    LOGIN: "login",
    SIGNUP: "signup",
};
