import i18next from 'i18next';
import { createTaskQueue } from '../../../modules/util/helpers';
import { SET_ROOM_JOINED, getActiveGroupId, getRoomOption } from '../../room';
import { getLocalID } from '../local';
import { getMemberByUserID } from '../members';
import { SHOW_NOTIFICATION } from '../notification/actionTypes';
import { MiddlewareRegistry } from '../redux';
import { isChatOpend } from '../sidebar';
import { OPEN_CHAT } from '../sidebar/actionTypes';
import { addShareMessage, setChatMessageList, setIsChatRead } from './action';
import { ADD_MESSAGE } from './actionTypes';
import { checkChatPermission } from './functions';
const taskQueue = createTaskQueue();
const renderChatList = (store, timestamp, isLt, isFirst) => {
    const local = getLocalID(store.getState());
    store.dispatch(setIsChatRead(true));
    const uuid = getRoomOption(store.getState()).uuid;
    let apiUrl = `/api/meeting/v1/meetings/${uuid}/chat-logs?limit=20&orderby=${encodeURIComponent('creation_time DESC')}`;
    apiUrl &&
        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
            if (data.result) {
                const { count_in_meeting_group, items } = data.result;
                let messages = new Map();
                if (items) {
                    items.forEach((item) => {
                        const timestamp = new Date(item.creation_time).getTime();
                        const sendMember = item.to_user_id === '' ? [] : [getMemberByUserID(store.getState(), item.to_user_id)];
                        messages.set(timestamp.toString() + item.from_user_id, 
                        // @ts-ignore
                        {
                            uuid: timestamp.toString() + item.from_user_id,
                            type: item.type,
                            from: item.from_member_id,
                            isMe: local === item.from_user_id ? true : false,
                            fromNickname: item.from_nicknams,
                            fromRole: item.from_role,
                            message: item.message,
                            privateMessage: sendMember,
                            timestamp,
                            group_id: '',
                        });
                    });
                }
                APP.store.dispatch(setChatMessageList(messages));
            }
        });
};
MiddlewareRegistry.register(store => next => async (action) => {
    switch (action.type) {
        case SET_ROOM_JOINED:
            if (action.joined)
                renderChatList(store, null, true, true);
            break;
        case SHOW_NOTIFICATION:
            if (action.props?.save) {
                store.dispatch(addShareMessage(
                // @ts-ignore
                {
                    uuid: action.uid + '_sysytem',
                    isMe: true,
                    type: 'system',
                    from: 'system',
                    fromNickname: 'system',
                    message: i18next.t(action.props.description, action.props.descriptionArguments),
                    timestamp: Number(action.uid),
                    group_id: getActiveGroupId(store.getState) || '',
                }, true));
            }
            break;
        case OPEN_CHAT:
            store.dispatch(setIsChatRead(true));
            break;
        case ADD_MESSAGE:
            if (!isChatOpend(store.getState))
                store.dispatch(setIsChatRead(false));
            taskQueue.enqueue(async (onFinish) => {
                if (!action.isRemote) {
                    if (checkChatPermission(store.getState, 'chat')) {
                        const response = await APP.management.sendMessage(action.data);
                        if (response.status !== 200) {
                            /**
                             * (fix me)
                             */
                            console.log('addShareFile - error');
                            return;
                        }
                        action.data.group_id = getActiveGroupId(store.getState);
                        onFinish();
                    }
                    else {
                        onFinish();
                        return;
                    }
                }
                else {
                    const fromID = getLocalID(store.getState);
                    action.data.isMe = action.data.from === fromID;
                    onFinish();
                }
            });
            break;
    }
    return next(action);
});
