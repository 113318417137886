import React, { useMemo } from 'react';
import { CheckBoxElement } from './CheckBox';
// RenderBody 컴포넌트 정의
const RenderBody = ({ tableList, styles, showCheckBox, checkedList, handlerCheck, showIndex, page, showCount, isInifinite, renderItem, renderMoreItem, }) => {
    return (React.createElement(React.Fragment, null, tableList.map((item, index) => (React.createElement(TableItem, { key: index, item: item, index: index, styles: styles, showCheckBox: showCheckBox, checkedList: checkedList, handlerCheck: handlerCheck, showIndex: showIndex, page: page, showCount: showCount, isInifinite: isInifinite, renderItem: renderItem, renderMoreItem: renderMoreItem })))));
};
// TableItem 컴포넌트 정의
const TableItem = ({ item, index, styles, showCheckBox, checkedList, handlerCheck, showIndex, page, showCount, isInifinite, renderItem, renderMoreItem, }) => {
    const [showDetailList, setShowDetail] = React.useState([]);
    const onClick = (id) => {
        if (showDetailList.includes(id)) {
            // 클릭된 항목이 이미 표시되어 있는 경우 해당 항목을 숨김
            setShowDetail(showDetailList.filter(item => item !== id));
        }
        else {
            // 클릭된 항목이 표시되어 있지 않은 경우 해당 항목을 표시
            setShowDetail(prevList => [...prevList, id]);
        }
    };
    const renderComponent = useMemo(() => {
        return (React.createElement("div", { className: "item", style: {
                ...styles?.bodyWrapper,
                cursor: `${renderMoreItem ? 'pointer' : 'default'}`,
            }, onClick: renderMoreItem ? () => onClick(item.id) : undefined },
            showCheckBox && (React.createElement("div", { className: "body-item", style: { ...styles?.bodyItem, minWidth: 30 } },
                React.createElement(CheckBoxElement, { id: item.id, iconSize: 22, checked: item?.id ? checkedList.includes(item.id) : false, handlerCheck: handlerCheck }))),
            showIndex && (React.createElement("div", { className: "body-item", style: { ...styles?.bodyItem, minWidth: 30 } },
                React.createElement("p", { style: styles?.bodyText }, isInifinite ? index + 1 : page * showCount + index + 1))),
            renderItem(item, styles, index)));
    }, [
        item,
        showDetailList,
        checkedList,
        handlerCheck,
        showCheckBox,
        showIndex,
        page,
        showCount,
        renderItem,
        styles,
    ]);
    if (renderMoreItem) {
        return (React.createElement("div", null,
            renderComponent,
            showDetailList.includes(item.id) && renderMoreItem(item, styles, index)));
    }
    else {
        return renderComponent;
    }
};
export default RenderBody;
