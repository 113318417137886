import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactNewWindowStyles } from "react-new-window-styles";
import { useDispatch, useSelector } from "react-redux";
import { toggleOpenGroups } from "../action";
import { getGroupError, getStartGroup } from "../functions";
import { GroupDualContainerStyled } from "./styles";
import PrevGroupContainer from "./PrevGroupContainer";
import StartGroupContainer from "./StartGroupContainer";
const GroupDualContainer = React.memo(() => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const start_group = useSelector((state) => getStartGroup(state));
    const error = useSelector((state) => getGroupError(state));
    const newWindowNode = useRef();
    const [popup, setPopup] = useState();
    const handlerPopup = (data) => {
        const popupComponents = data?.componet ? React.createElement(data?.componet, data?.props) : null;
        setPopup(popupComponents);
    };
    const alertMessage = (data) => {
        if (newWindowNode.current) {
            const elementWindow = newWindowNode.current.state.externalWindow;
            if (elementWindow) {
                if (data?.message)
                    elementWindow.alert(t(data.message));
                if (data?.confirmMessage)
                    return elementWindow.confirm(t(data.confirmMessage));
            }
        }
    };
    useEffect(() => {
        APP.eventManager.subscribe('UPDATE_OPEN_POPUP', handlerPopup);
        APP.eventManager.subscribe('ALRET_GROUP_MESSAGE', alertMessage);
        return () => {
            APP.eventManager.unsubscribe("UPDATE_OPEN_POPUP", handlerPopup);
            APP.eventManager.unsubscribe('ALRET_GROUP_MESSAGE', alertMessage);
        };
    }, []);
    useEffect(() => {
        if (error)
            alertMessage({ message: error });
    }, [error]);
    const unload = () => dispatch(toggleOpenGroups(false));
    return (React.createElement(ReactNewWindowStyles, { ref: newWindowNode, title: "Meemate", windowProps: { width: 960, height: 960 }, onClose: unload, copyStyles: true, autoClose: true },
        React.createElement(GroupDualContainerStyled, null,
            start_group ? React.createElement(StartGroupContainer, null) : React.createElement(PrevGroupContainer, null),
            popup)));
});
export default GroupDualContainer;
