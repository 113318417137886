import { HIDE_CHAT, HIDE_MEMBER, OPEN_CHAT, OPEN_MEMBER } from './actionTypes';
import { isChatOpend, isMemberOpend } from "./functions";
export function toggleChat(isOpen) {
    return async (dispatch, getState) => {
        const open = isOpen !== undefined ? isOpen : !isChatOpend(getState);
        if (open)
            dispatch({ type: OPEN_CHAT });
        else
            dispatch({ type: HIDE_CHAT });
    };
}
export function toggleMember(isOpen) {
    return async (dispatch, getState) => {
        const open = isOpen !== undefined ? isOpen : !isMemberOpend(getState);
        if (open)
            dispatch({ type: OPEN_MEMBER });
        else
            dispatch({ type: HIDE_MEMBER });
    };
}
