import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, useLocation } from 'react-router-dom';
import { Routes, useNavigate } from 'react-router-dom/dist';
import DeviceSelection from '../../../device-selection/DeviceSelection2';
import { isMobileDevice } from '../../../lobby/components';
import { classMateLogin } from '../../local';
import { PAGE_TYPE } from '../constants';
import { InitStyled } from './web/styles';
import { Test } from '../Test';
import Loading from '../../loading/components/web/Loading';
// const LazyDeviceSelection = React.lazy(
//     () => import('../../../device-selection/DeviceSelection'),
// );
const LazyLogin = React.lazy(() => import('../../../account/LoginContainer'));
const LazySignup = React.lazy(() => import('../../../account/SignupContainer'));
const LazyRoom = React.lazy(() => import('../../../room/RoomContainer'));
const LazyNickname = React.lazy(() => import('../../../nickname/NicknameContainer'));
const LazyLobby = React.lazy(() => import('../../../lobby/LobbyContainer'));
const BaseApp = ({ page, roomUid, handlerChangePage, }) => {
    return (React.createElement(BrowserRouter, null,
        React.createElement(Room, { page: page, roomUid: roomUid, handlerChangePage: handlerChangePage })));
};
export default BaseApp;
const Room = ({ page, roomUid, handlerChangePage, }) => {
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
    const leave = () => APP.management.leave();
    const receiveMsgFromParent = async (e) => {
        if (e.origin.includes('mate') && e.data.type === 'login') {
            dispatch(classMateLogin(e.data));
        }
    };
    const popStateEvent = (e) => {
        if (location.pathname.includes('room')) {
            if (location.hash.includes(PAGE_TYPE.NICKNAME))
                handlerChangePage(PAGE_TYPE.NICKNAME);
            else if (location.hash.includes(PAGE_TYPE.DEVICE))
                handlerChangePage(PAGE_TYPE.DEVICE);
            else if (!location.hash)
                handlerChangePage(PAGE_TYPE.MAIN);
        }
        else if (location.pathname.includes(PAGE_TYPE.ERROR)) {
            handlerChangePage(PAGE_TYPE.ERROR);
        }
        else {
            navigator(`${location.search}`);
        }
    };
    const setLayout = () => {
        const isMobile = isMobileDevice();
        setIsMobile(isMobile);
    };
    useEffect(() => {
        changePage();
        setLayout();
        window.addEventListener('resize', setLayout);
        window.addEventListener('popstate', popStateEvent);
        window.addEventListener('message', receiveMsgFromParent);
        window.addEventListener('unload', leave);
        return () => {
            window.removeEventListener('popstate', popStateEvent);
            window.removeEventListener('message', receiveMsgFromParent);
            window.removeEventListener('unload', leave);
        };
    }, []);
    useEffect(() => {
        changePage();
    }, [page]);
    const changePage = () => {
        if (!page)
            return;
        let path = '';
        let state = PAGE_TYPE.LOBBY;
        switch (page) {
            case 'test':
                path = `/test`;
                state = 'test';
                break;
            case PAGE_TYPE.MAIN:
                path = `/room/${roomUid}${location.search}`;
                state = PAGE_TYPE.MAIN;
                break;
            case PAGE_TYPE.NICKNAME:
                path = `/room/${roomUid}${location.search}#${PAGE_TYPE.NICKNAME}`;
                state = PAGE_TYPE.NICKNAME;
                break;
            case PAGE_TYPE.DEVICE:
                path = `/room/${roomUid}${location.search}#${PAGE_TYPE.DEVICE}`;
                state = PAGE_TYPE.DEVICE;
                break;
            case PAGE_TYPE.ERROR:
                path = `/error${location.search}`;
                state = PAGE_TYPE.ERROR;
                break;
            case PAGE_TYPE.LOGIN:
                path = `/login${location.search}`;
                state = PAGE_TYPE.LOGIN;
                break;
            case PAGE_TYPE.SIGNUP:
                path = `/signup${location.search}`;
                state = PAGE_TYPE.SIGNUP;
                break;
            default:
                path = `/${location.search}`;
                break;
        }
        navigator(path, { state });
    };
    return (React.createElement(Suspense, { fallback: React.createElement(Loading, { isLoad: true }) },
        React.createElement(InitStyled, null),
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/test", element: React.createElement(Test, null) }),
            React.createElement(Route, { path: "/error", element: React.createElement(LazyLobby, { isErrorPage: true, isMobile: isMobile }) }),
            React.createElement(Route, { path: `/room/*`, element: React.createElement(React.Fragment, null, page === PAGE_TYPE.NICKNAME ? (React.createElement(LazyNickname, { isMobile: isMobile })) : page === PAGE_TYPE.DEVICE ? (React.createElement(DeviceSelection, { isDialog: false })) : page === PAGE_TYPE.MAIN ? (React.createElement(LazyRoom, { isMobile: isMobile })) : null) }),
            React.createElement(Route, { path: "/login", element: React.createElement(LazyLogin, { isDialog: false, isMobile: isMobile }) }),
            React.createElement(Route, { path: "/signup", element: React.createElement(LazySignup, { isDialog: false, isMobile: isMobile }) }),
            React.createElement(Route, { path: "/*", element: React.createElement(LazyLobby, { isErrorPage: false, isMobile: isMobile }) }))));
};
