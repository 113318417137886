import React from 'react';
import { useTranslation } from 'react-i18next';
export const FormField = React.memo(({ className, gap, children, style, }) => {
    return (React.createElement("div", { className: `form ${className}`, style: { gap, ...style } }, children));
});
export const FormFieldItemWrap = React.memo(({ className, style, width, children, }) => {
    return (React.createElement("div", { className: `task ${className}`, style: { width: `${width}`, ...style } }, children));
});
export const FormFieldItem = React.memo(({ className, style, label, labelChild, width = '100%', labelWidth = 65, children, }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: `task ${className ? className : ''}`, style: { width: `${width}`, ...style } },
        React.createElement("div", { className: "task-label-wrap" },
            React.createElement("p", { style: { minWidth: labelWidth }, className: "task-label" }, label && t(label)),
            labelChild),
        children));
});
