import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import ChatBodyContainer from "./ChatBodyContainer";
import ChatChoiceMember from "./ChatChoiceMember";
import ChatInputConatainer from "./ChatInputContainer";
import ChoiceChatMember from "./ChoiceChatMember";
import ChoiceMember from "./ChoiceSendType";
import EmojiContainer from "./EmojiContainer";
import { AddFile } from "./components";
import { ChatStyled } from "./styles.web";
import ChatNotificationContainer from "./ChatNotificationContainer";
const Chat = React.memo(({ isChoice, isMonitoring, groupId }) => {
    const { t } = useTranslation();
    const chatRef = useRef(null);
    const handlerScroll = () => {
        if (chatRef && chatRef.current) {
            const { scrollHeight, clientHeight } = chatRef.current;
            chatRef.current.scrollTop = scrollHeight - clientHeight;
        }
    };
    return (React.createElement(ChatStyled, null,
        React.createElement(ChatNotificationContainer, null),
        React.createElement("div", { className: "chat_content", ref: chatRef },
            React.createElement(ChatBodyContainer, { groupId: groupId, handlerScroll: handlerScroll })),
        !isMonitoring && React.createElement("div", { className: "chat_input_wrapper" },
            isChoice && React.createElement("div", { className: "choice_member" },
                " ",
                React.createElement(ChoiceChatMember, null),
                " "),
            React.createElement("div", { className: "chat_head" },
                React.createElement("span", { className: "title" }, t("chat.inputLabel")),
                React.createElement("div", { className: "btn_wrapper" },
                    React.createElement(ChoiceMember, { isChoice: isChoice })),
                React.createElement("div", { className: "btn_left_wrapper" },
                    React.createElement(EmojiContainer, null),
                    React.createElement(AddFile, null))),
            React.createElement("div", { className: "choice_list" },
                React.createElement(ChatChoiceMember, null)),
            React.createElement(ChatInputConatainer, null))));
});
export default Chat;
