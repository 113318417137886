import EmojiPicker from 'emoji-picker-react';
import React, { useEffect, useRef, useState } from "react";
import { ClickButton } from "../ui";
const EmojiContainer = React.memo(() => {
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const buttonRef = useRef(null);
    useEffect(() => {
        const handleWindowClick = (event) => {
            if (showEmojiPicker &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)) {
                setShowEmojiPicker(false);
            }
        };
        window.addEventListener("click", handleWindowClick);
        return () => {
            window.removeEventListener("click", handleWindowClick);
        };
    }, [showEmojiPicker]);
    const onEmojiClick = (emojiObject) => {
        APP.eventManager.publish('CHAT_ADD_EMOJI', { emoji: emojiObject.emoji });
    };
    const toggleEmojiPicker = () => {
        setShowEmojiPicker((prev) => !prev);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ClickButton, { icon: "emoji", size: 25, color: showEmojiPicker ? APP.config.style.focusColor : "transparent", onClick: toggleEmojiPicker, ref: buttonRef }),
        showEmojiPicker && (React.createElement("div", { style: {
                position: "absolute",
                bottom: "112px",
                left: 0
            } },
            React.createElement(EmojiPicker, { onEmojiClick: onEmojiClick })))));
});
export default EmojiContainer;
