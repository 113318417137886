import { WILL_JITSI_CONNECTION, SUCCESS_JITSI_CONNECTION, FAILED_JITSI_CONNECTION  } from "./actionTypes";
/**
 * jisti connection 연결 중 
 */
export function setWillJitsiConnection(conference) {
    return {
        type: WILL_JITSI_CONNECTION,
        conference
    }
}

/**
 * jitsi connection success
 */
export function setSuccessJitsiConnection(conference) {
    return {
        type: SUCCESS_JITSI_CONNECTION,
        conference
    }
}

/**
 * jitsi connection failed
 */
export function setFailedJitsiConnection(conference) {
    return {
        type: FAILED_JITSI_CONNECTION,
        conference
    }
}