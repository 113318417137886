import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ClickButton } from "../../base/ui";
import { remoteAllGroup } from "../action";
import { getGroupIds } from "../functions";
const RemoteGroupButton = React.memo(({ isStart }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const group_ids = useSelector((state) => getGroupIds(state));
    const handlerStartGroup = () => {
        const result = group_ids.reduce((obj, groupId) => {
            obj[groupId] = { switch: isStart ? 'open' : 'close' };
            return obj;
        }, {});
        dispatch(remoteAllGroup(result, isStart));
    };
    return (React.createElement(ClickButton, { className: "remote_btn", isText: true, label: t(`group.${isStart ? "start" : "close"}`), onClick: handlerStartGroup }));
});
export default RemoteGroupButton;
