import i18next from 'i18next';
import { throttle } from 'lodash';
import { addShareFile, addShareMessage } from '../../../features/base/chat/action';
import { openDialog } from '../../../features/base/dialog';
import { isDialogOpen } from '../../../features/base/dialog/functions';
import { toggleLoad } from '../../../features/base/loading';
import { getLocalID } from '../../../features/base/local';
import { setAudioMuted, setVideoMuted } from '../../../features/base/media';
import {
	MEMBER_STATUS,
	PERMISSION,
	ROLE,
	addLocalMember,
	addMember,
	addMembers,
	getLoaclMemberTrack,
	getLocalIDMember,
	getLocalMember,
	getMemberByUserID,
	getUserIDByMemberId,
	removeMember,
	requestPresentation,
	responsePresentation,
	setBridgeId,
	setHandlerMember,
	setMemberNick,
	setMemberPinned,
	setMemberRecord,
	setMemberRole,
	updateMember,
} from '../../../features/base/members';
import { saveNotification, showNotification } from '../../../features/base/notification/actions';
import { updateGridOption, updateRoomPolicy } from '../../../features/base/settings';
import SurveyContainer from '../../../features/base/survey/SurveyContainer';
import { SURVEY_MENU_TYPE } from '../../../features/base/survey/constant';
import { convertVodList } from '../../../features/base/vod/functions';
import { deleteCanvasIndex, deleteCanvaskey, setCanvasPoint } from '../../../features/canvas/actions';
import { getGroupByUid, getStartGroup } from '../../../features/groups';
import {
	assignGroup,
	handlerGroupUpdate,
	modifyGroup,
	setGroupAllList,
	updateGroupStaus,
} from '../../../features/groups/action';
import { getActiveGroupId, getPermissions, getRoomOption, setRoomPermissions } from '../../../features/room';
import { showStartAttendance } from '../../../features/room-attendance/actions';
import { getStartScreenShare, setShareScreenUserId, stopScreen } from '../../../features/screen-share';
import {
	addDocumentList,
	addShareItem,
	getDocumentItemByUUID,
	removeShareItem,
	setFocusShareItem,
	updateShareItem,
} from '../../../features/share-layout';
import { DEFAULT_INDEX, DOCUMENT_MODE, VOD_MODE } from '../../../features/share-layout/constants';
import { VIDEO_MODE_LIST, getCurrentMode, setLayoutMode, videoLayoutMode } from '../../../features/video-layout';
import { command } from './command';
import { PAGE_TYPE } from '../../../features/base/app/constants';

const isRemote = true;

class MateMessage {
	constructor() {
		this.groupJoin = false;

		this.initialEntrySetting = this.initialEntrySetting.bind(this);
		this.setGroupJoin = this.setGroupJoin.bind(this);
	}

	setGroupJoin(joined) {
		this.groupJoin = joined;
	}

	async handleMessage(data, group_id) {
		if (group_id === '') {
			switch (data.class) {
				case 'Meeting.Meeting.Closed':
				case 'Room.Member.Leave':
					await APP.management.leave();
					self.close();

					APP.eventManager.publish('UPDATE_PAGE', {
						page: PAGE_TYPE.ERROR,
					});
					break;
				case `Event.${command['set-nickname']}`:
					this.handlerChangeNick(data.payload);
					break;

				// set bridge session
				case `Event.${command['set-jitsi-id']}`:
					this.handlerSetBridgeSession(data.payload);
					break;

				// 문서 상태 변경
				case 'Meeting.Document.StatusChanged':
					this.handlerDocumentStatus(data.payload);
					break;

				/**
				 * 그룹
				 */
				case `${command['group-crated']}`:
				case `${command['group-remove']}`:
				case `${command['group-name-changed']}`:
				case `${command['group-assined']}`:
				case `${command['group-changed']}`:
				case `${command['group-member-left']}`:
				case `Event.${command['group-status']}`:
					APP.store.dispatch(setGroupAllList());
					break;
				case `${command['group-raised']}`:
					APP.store.dispatch(setGroupAllList());
					break;

				case `Event.${command['recive-notification']}`:
					this.handlerGroupNotification(data.payload);
					break;
			}
		}

		switch (data.class) {
			// 사용자 입장
			case `Event.${command['join-room']}ed`:
				this.handlerJoined(data.payload.message, data.payload.group_id);
				break;
			// 사용자 퇴장 Event.Room.Member.Left
			case `Event.${command['left-member-room']}`:
				this.handlerLefted(data.payload.user_id, data.payload.group_id);
				break;
		}

		if (this.groupJoin && group_id === '') return;
		switch (data.class) {
			case `${command['start-attendance']}`:
				this.handlerStartAttendances(data.payload);
				break;

			case `Event.${command['member-kick']}ed`:
				this.handlerKickMember(data.payload);
				break;

			// 레이아웃 모드 변경 (grid, pin, voice, screen)
			case `Event.${command[videoLayoutMode.screen]}`:
				this.handlerScreenOption(data.payload);
				break;

			case `Event.${command[videoLayoutMode.grid]}`:
				this.handlerUpdateLayoutMode(videoLayoutMode.grid);
				break;

			case `Event.${command[videoLayoutMode.seminar]}`:
				this.handlerUpdateLayoutMode(videoLayoutMode.seminar);
				break;

			case `Event.${command[videoLayoutMode.pin]}`:
				this.handlerUpdateLayoutMode(videoLayoutMode.pin);
				break;

			case `Event.${command[videoLayoutMode.voice]}`:
				this.handlerUpdateLayoutMode(videoLayoutMode.voice);
				break;

			// 화면 가시성 레이아웃 모드 변경 ( screen, document, note, white, vad )
			case `Event.${command['set-layout-visiblity']}`:
				this.handlerUpdateViewVisible(data.payload);
				break;

			/**
			 * share (document)
			 */
			// 문서 focus
			case `Event.${command['set-vod-file']}`:
				break;

			case `Event.${command['del-vod-file']}`:
				break;

			case `Event.${command['set-document']}`:
				this.handlerUpdateDocument(data.payload);
				break;

			case `Event.${command['del-document']}`:
				this.handlerDelDocument(data.payload);
				break;

			case `Event.${command[videoLayoutMode.white]}`:
				this.handlerFocusItem(videoLayoutMode.white, data.payload);
				break;

			case `Event.${command[videoLayoutMode.document]}`:
				this.handlerFocusItem(videoLayoutMode.document, data.payload);
				break;

			case `Event.${command['set-canvas']}`:
				this.handlerSetShareCanvas(data.payload);
				break;

			case `Event.${command['del-canvas']}`:
				this.handlerDelShareCanvas(data.payload);
				break;
			case `Event.${command['focus-vod-item']}`:
				this.handlerFocusItem(videoLayoutMode.vod, data.payload);
				break;
			/**
			 * 채팅
			 */
			case `Event.${command['send-text']}`:
			case `Event.${command['send-private-text']}`:
				this.handlerSetMessage(data.payload);
				break;

			case `Event.${command['send-file']}`:
				this.handlerSetFile(data.payload);
				break;
			/**
			 * Member
			 */
			// 녹화 - recording
			case `Event.${command['set-member-record']}`:
				this.handlerRecording(data.payload);
				break;

			case `Event.${command[`set-audio-false`]}`:
			case `Event.${command[`set-all-audio-false`]}`:
				this.handlerUpdateAudio(false);
				break;

			case `Event.${command[`set-audio-true`]}`:
			case `Event.${command[`set-all-audio-true`]}`:
				this.handlerUpdateAudio(true);
				break;

			case `Event.${command[`set-video-false`]}`:
			case `Event.${command[`set-all-video-false`]}`:
				this.handlerUpdateVideo(false);
				break;

			case `Event.${command[`set-video-true`]}`:
			case `Event.${command[`set-all-video-true`]}`:
				this.handlerUpdateVideo(true);
				break;

			case `Event.${command['set-role']}`:
				this.handlerUpdateRole(data.payload.message, data.payload.group_id);
				break;

			/**
			 * 발표자 요청
			 */
			case `Event.${command['request-presentation']}`:
				this.handlerRequestPresentation(data.payload);
				break;
			case `Event.${command['response-presentation']}`:
				this.handlerResponsePresentation(data.payload);
				break;

			case `Event.${command['member-add-pin']}ed`:
				this.handlerUpdatePin(data.payload, true);
				break;

			case `Event.${command['member-del-pin']}d`:
				this.handlerUpdatePin(data.payload, false);
				break;

			case `Event.${command['set-handler-up']}`:
				this.handlerRaiseHand(data.payload, true);
				break;
			case `Event.${command['set-handler-down']}`:
				this.handlerRaiseHand(data.payload, false);
				break;

			/**
			 * survey 설문좃사
			 */
			case `Event.${command['share-survey']}`:
				this.notifySurvey(data.payload);
				break;
			case `Event.${command['reply-survey']}`:
				this.replySurvey(data.payload);
				break;
			/**
			 * policy
			 */
			case `Event.Room.Grid.Count.Set`:
				this.setGridOption(data.payload);
				break;

			case `Event.${command['set-policy']}d`:
				this.updatePolicy(data.payload);
				break;

			case 'Meeting.Meeting.Signaled':
				this.handlerMimac(data.payload);
				break;
		}
	}

	handlerMimac(data) {
		const { class: classes } = data.payload;

		switch (classes) {
			case 'MIMAC.Test.Submitted':
				APP.eventManager.publish('UPDATE_SUBMITTED', { test_id: data.payload.test_id });

				break;
			case 'MIMAC.Test.Timer.Started':
			case 'MIMAC.Test.Timer.Finished':
				APP.eventManager.publish('UPDATE_ASSIGNMENT');

				break;
		}
	}
	// 회의실 입장 초기 세팅
	initialEntrySetting(data, result_only = false) {
		return new Promise(async (resolve, reject) => {
			let startGroup = undefined;
			const { dispatch, getState } = APP.store;
			// 그룹 리스트 세팅
			await dispatch(setGroupAllList());

			const meeting_uuid = getRoomOption(getState).uuid;

			const {
				member_uuid,
				layout,
				view_visible,
				role,
				permissions,
				pinned,
				members,
				document_list,
				video_list,
				group_id,
				last_notification,
			} = data;

			const user_uuid = getLocalID(getState);

			if (group_id !== '') {
				const local_member = getLocalMember(getState);
				await dispatch(
					updateMember({
						...local_member,
						member_uuid,
						group_role: role,
						pinned,
						hidden: false,
						active_group: group_id,
					}),
				);

				members.map(m => {
					const member = getMemberByUserID(getState, m.user_uuid);
					if (m.type === 'hidden') return;
					dispatch(
						updateMember({
							...member,
							...m,
							active_group: group_id,
						}),
					);
				});
			} else {
				// 로컬 사용자 입장
				await dispatch(
					addLocalMember({
						user_uuid,
						member_uuid,
						role,
						permissions,
						hidden: false,
						pinned,
						active_group: group_id,
					}),
				);

				// remote 사용자 입장
				await dispatch(addMembers(members, member_uuid));
			}

			// dispatch(updateGroupStaus(running_group));
			dispatch(setRoomPermissions(permissions));

			if (last_notification) {
				if (last_notification === '') {
					dispatch(saveNotification('', isRemote));
				} else {
					const { message, group_id: last_group } = JSON.parse(last_notification);
					if (last_group === '') {
						dispatch(saveNotification(message, isRemote));
					} else {
						dispatch(
							showNotification({
								titleKey: 'notify.notifyMessage',
								description: message,
							}),
						);
					}
				}
			}

			/**
			 * klassmate에서 이미 등록된 문서 불러와서 등록하기
			 */
			let list = [];
			if (APP.config.name === 'class') {
				const response = await APP.API.getKlassDocument();
				if (response.complete) {
					response.data &&
						response.data.map(item => {
							if (document_list.find(dItem => dItem.uuid === item.uuid)) return;
							const data = {
								uuid: item.uuid,
								user_uuid,
								file: {
									name: item.filename,
								},
								isWhiteBoard: false,
								shared: true,
								page: item.resource_id,
								list: item.completed_pages,
								count: item.completed_pages.length,
								complete: true,
								config: DOCUMENT_MODE,
							};

							dispatch(addShareItem(data, false));
						});
				}
			}

			await fetch(
				`/svc/meeting/attendances?meeting_uuid=${meeting_uuid}&orderby=creation_time&limit=1&status=started`,
			)
				.then(res => {
					return res.json();
				})
				.then(data => {
					if (!data) return;

					if (data.items && data.items.length > 0) {
						const item = data.items[0];

						if (item && item.status === 'started') {
							dispatch(
								showStartAttendance({
									name: item.name,
									uuid: item.uuid,
									check_duration: item.duration,
								}),
							);
						}
					}
					// console.log(data);
				});

			/**
			 * (SCREEN MODE) 화면 공유
			 */
			const screen_track_id = data.screen_shared_jitsi_id;
			if (screen_track_id) {
				const screen_member_id = data.screen_shared_member_uuid;
				const screen_user_id = getMemberByUserID(getState, screen_member_id);

				dispatch(setShareScreenUserId(screen_track_id, screen_user_id || screen_member_id, isRemote));
			}

			/**
			 * video-layout
			 */
			// 레이아웃 저장
			const mode = getCurrentMode(getState, { mode: layout, viewVisible: view_visible });
			dispatch(setLayoutMode(mode, isRemote));

			/**
			 * Share
			 */
			const isSharedPermission = permissions.includes(PERMISSION.SHARE);

			const values = Object.entries(video_list).reduce((acc, [v, itemList]) => acc.concat(itemList), []);
			const vod_list = await convertVodList(values, document_list);
			const filteredVodConfigs = vod_list.filter(itemA => {
				return document_list.some(itemB => itemB.uuid === itemA.uuid);
			});

			const otherConfigs = document_list.filter(itemA => itemA.config !== VOD_MODE);
			// Document list 저장
			dispatch(
				addDocumentList(
					[...list, ...vod_list, ...filteredVodConfigs, ...otherConfigs],
					member_uuid,
					isSharedPermission,
				),
			);

			// share document
			if (APP.config.option.workbook) {
				if (data.document_focus_item) {
					const { uuid, index } = data.document_focus_item;
					const focusUId = uuid === '' ? data.whiteboard_focus_item.uuid : uuid;
					const focusIndex = uuid === '' ? data.whiteboard_focus_item.index : index;

					dispatch(setFocusShareItem(focusUId, focusIndex, videoLayoutMode.document, isRemote));
				}
			} else {
				if (data.document_focus_item) {
					const { uuid, index } = data.document_focus_item;

					dispatch(setFocusShareItem(uuid, index, videoLayoutMode.document, isRemote));
				}

				if (data.whiteboard_focus_item) {
					const { uuid, index } = data.whiteboard_focus_item;
					dispatch(setFocusShareItem(uuid, index, videoLayoutMode.white, isRemote));
				}
			}

			if (data.vod_shared_video_uuid) {
				dispatch(setFocusShareItem(data.vod_shared_video_uuid, DEFAULT_INDEX, videoLayoutMode.vod, isRemote));
			}
			/**
			 * Policy
			 */
			dispatch(updateRoomPolicy(data.policy));

			/**
			 * 그룹 시작 여부
			 */
			if (group_id === '') {
				if (!result_only) {
					const local = getLoaclMemberTrack(getState, user_uuid);

					if (local && local.group_status === 'opened') {
						if (role !== ROLE.HOST) {
							startGroup = {
								group_id: local.group_id,
								group_name: local.group_name,
								group_role: local.role_name,
							};
						} else {
							if (local.group_id)
								await dispatch(
									assignGroup(
										local.group_id,
										[{ user_id: user_uuid, role_name: ROLE.ADMIN, type: '' }],
										'reset',
										false,
									),
								);
						}
					}
				}
			}

			return resolve(startGroup);
		});
	}

	/**
	 * 사용자 입장
	 */
	handlerJoined(message, group_id) {
		if (message.type === 'hidden') return;
		const { getState, dispatch } = APP.store;
		// 현재 내가 속한 그룹
		const active_group = getActiveGroupId(getState);

		// 내가
		const member = getMemberByUserID(getState, message.user_uuid);

		if (!member) {
			const remote = {
				...message,
				status: MEMBER_STATUS.OCCUPIDE,
				existed: false,
				active_group: group_id,
			};

			dispatch(addMember(remote));
		} else {
			if (active_group === group_id) {
				dispatch(
					addMember({
						...member,
						...message,
						status: MEMBER_STATUS.OCCUPIDE,
						active_group: group_id,
					}),
				);
			} else {
				dispatch(
					updateMember({
						...member,
						status: MEMBER_STATUS.OCCUPIDE,
						active_group: group_id,
					}),
				);
			}
		}
	}

	handlerLefted(user_id, group_id) {
		const { getState, dispatch } = APP.store;
		const member = getMemberByUserID(getState, user_id);

		if (member && !member?.local) {
			const active_group = getActiveGroupId(getState);

			if (group_id === active_group) {
				APP.store.dispatch(removeMember(member));
			} else {
				dispatch(
					updateMember({
						...member,
						active_group: '',
					}),
				);
			}
		}
	}

	handlerSetBridgeSession({ message }) {
		// from -> member_uuid
		const bridge_sessions = message.bridge_sessions;

		const user_uuid = message.user_id;

		if (bridge_sessions && bridge_sessions.length > 0)
			APP.store.dispatch(setBridgeId(user_uuid, bridge_sessions[0]));
	}

	/**
	 * (MEMBER) 사용자 녹화 알림.
	 * @param {object} data
	 */
	handlerRecording(data) {
		const { getState, dispatch } = APP.store;
		const { to, recording } = data;

		const user_uuid = getUserIDByMemberId(getState, to);

		if (user_uuid) {
			dispatch(setMemberRecord(user_uuid, recording, isRemote));
		}
	}

	/**
	 * (LAYOUT)
	 * 레이아웃 모드 변경
	 * @param {string} mode
	 */
	handlerUpdateLayoutMode(mode) {
		APP.store.dispatch(setLayoutMode(mode, isRemote));
	}

	/**
	 * (LAYOUT)
	 * 화면 가시성으로 레이아웃 모드 변경
	 * @param {object} {view_visible}
	 */
	handlerUpdateViewVisible({ view_visible }) {
		let mode = null;
		Object.keys(view_visible).map(v => {
			if (view_visible[v]) mode = v;
		});

		if (!mode) {
			const currentMode = getCurrentMode(APP.store.getState);

			if (VIDEO_MODE_LIST.includes(currentMode)) mode = currentMode;
		}

		mode && this.handlerUpdateLayoutMode(mode);
	}

	/**
	 * (TRACK)
	 * 사용자 오디오 ON / OFF
	 * @param {Boolean} muted
	 */
	handlerUpdateAudio(muted) {
		APP.store.dispatch(setAudioMuted(muted));
	}

	/**
	 * (TRACK)
	 * 사용자 비디오 ON / OFF
	 * @param {Boolean} muted
	 */
	handlerUpdateVideo(muted) {
		APP.store.dispatch(setVideoMuted(muted));
	}

	/**
	 * (MEMBER)
	 * 사용자 권한 업데이트
	 * @param {object} data
	 */
	handlerUpdateRole(data, group_id) {
		const { target, role, permissions } = data;

		APP.store.dispatch(setMemberRole(target, role, isRemote, group_id !== '' ? true : false));

		if (target === getLocalID(APP.store.getState)) {
			// 회의실 내 권한 세팅
			APP.store.dispatch(setRoomPermissions(permissions));
		}
	}

	/**
	 * (MEMBER)  발표자 권한 요청
	 * @param {object} data
	 * @returns
	 */
	handlerRequestPresentation(data) {
		const { from } = data;

		APP.store.dispatch(requestPresentation(false, from));
	}

	/**
	 * (MEMBER) 사용자 발표 응담
	 * @param {object} data
	 */
	handlerResponsePresentation(data) {
		const { to, accepted } = data;
		const user_uuid = getUserIDByMemberId(APP.store.getState, to);

		APP.store.dispatch(responsePresentation(user_uuid, accepted, isRemote));
	}

	/**
	 * (MEMBER) 사용자 PIN 변경
	 * @param {object} data
	 * @param {Boolean} pinned
	 */
	handlerUpdatePin(data, pinned) {
		APP.store.dispatch(setMemberPinned(data.message.target, pinned, isRemote));
	}

	/**
	 * (MEMBER) 사용자 강제 퇴장
	 * @param {object} data
	 */
	/**
	 * (MEMBER) 사용자 강제 퇴장
	 * @param {object} data
	 */
	async handlerKickMember(data) {
		const { getState, dispatch } = APP.store;
		const { to, from, type } = data;

		if (type === 'close') {
			await APP.management.leave();
		} else {
			const toUserId = getUserIDByMemberId(getState, to);
			const fromUserId = getUserIDByMemberId(getState, from);
			const localID = getLocalID(getState);

			if (localID === toUserId) {
				if (fromUserId !== toUserId) {
					const fromMember = getMemberByUserID(getState, fromUserId);
					const displayName = fromMember?.nickname;

					const text = i18next.t('notify.kickMember', { displayName });

					await APP.management.leave(text);
				} else {
					await APP.management.leave(i18next.t('notify.dupLogin'));
				}
			}
		}
	}

	/**
	 * (MEMBER) 사용자 닉네임 수정
	 * @param {*} data
	 */
	handlerChangeNick(data) {
		const user_id = getUserIDByMemberId(APP.store.getState, data.from);

		if (user_id) {
			APP.store.dispatch(setMemberNick(user_id, data.message.nickname, isRemote));
		}
	}

	/**
	 * (MEMBER) 손들기
	 * @param {object} data
	 * @param {Boolean} handler
	 */
	handlerRaiseHand(data, handler) {
		const { getState, dispatch } = APP.store;

		const { to } = data;
		const user_uuid = getUserIDByMemberId(getState, to);

		user_uuid && dispatch(setHandlerMember(user_uuid, handler, isRemote));
	}

	/**
	 * (SCREEN SHARE)
	 * 화면 공유 옵션 업데이트
	 * @param {object} data
	 */
	handlerScreenOption(data) {
		const { target, jitsi_id } = data;
		const changeJitsi = !jitsi_id || jitsi_id === '' ? null : jitsi_id;
		const changeTarge = !target || target === '' ? null : target;

		if (!changeJitsi && getStartScreenShare(APP.store.getState)) {
			APP.store.dispatch(stopScreen());
			return;
		}

		APP.store.dispatch(setShareScreenUserId(changeJitsi, changeTarge, isRemote));
	}

	/**
	 * 문서
	 */

	/**
	 * 현재 문서 공유시 공유 업데이트
	 */
	handlerUpdateDocument(data) {
		const { dispatch, getState } = APP.store;
		const { document } = data;

		const user_uuid = getMemberByUserID(getState, document.member_uuid);
		const localId = getLocalIDMember(getState);

		if (document.shared || user_uuid === localId) {
			dispatch(
				updateShareItem(
					document.uuid,
					{
						...document,
						user_uuid,
					},
					isRemote,
				),
			);
		}
	}

	/**
	 * 문서 삭제
	 * @param {*} data
	 */
	handlerDelDocument(data) {
		APP.store.dispatch(removeShareItem(data.uuid, isRemote));
	}

	/**
	 * 문서 변환 완료 시 상태 변경
	 * @param {object} {data}
	 */
	handlerDocumentStatus(data) {
		const { uuid, resource_id, status: documentStatus, completed_pages, complete } = data;

		if (documentStatus === 'failed') {
			APP.UI.alertMessage('share.no_converting');
			APP.store.dispatch(removeShareItem(uuid, isRemote));
			return;
		}

		const document = getDocumentItemByUUID(APP.store.getState, uuid);

		if (document) {
			if (completed_pages.length >= 1) {
				APP.store.dispatch(
					updateShareItem(uuid, {
						uuid,
						page: resource_id,
						count: completed_pages.length,
						list: completed_pages,
						complete: true,
						isWhiteBoard: false,
					}),
				);
			}
		}
	}

	/**
	 * 문서 포커스
	 * @param {string} mode
	 * @param {object<{uuid, index}>} data
	 */
	handlerFocusItem(mode, { video_uuid, uuid, index }) {
		if (mode === videoLayoutMode.vod) {
			APP.store.dispatch(setFocusShareItem(video_uuid, DEFAULT_INDEX, mode, isRemote));
		} else {
			APP.store.dispatch(setFocusShareItem(uuid, index, mode, isRemote));
		}
	}

	/**
	 * 문서 캔버스
	 * @param {object<{ uuid, index, key, value }}} data
	 */
	handlerSetShareCanvas(data) {
		const { uuid, index, key, value } = data;

		APP.store.dispatch(setCanvasPoint({ uuid, index: Number(index), key, ...value }, isRemote));
	}
	/**
	 * 문서 캔버스 지우기
	 * @param {*} data
	 */
	handlerDelShareCanvas(data) {
		const { uuid, index, key, isAll } = data;

		if (!isAll) APP.store.dispatch(deleteCanvaskey(uuid, Number(index), key, isRemote));
		else APP.store.dispatch(deleteCanvasIndex(uuid, Number(index), isRemote));
	}

	/**
	 * 채팅
	 */
	handlerSetMessage({ message, time }) {
		const data = JSON.parse(message);

		APP.store.dispatch(addShareMessage({ ...data, timestamp: new Date(time).getTime() }, isRemote));
	}

	handlerSetFile({ message }) {
		APP.store.dispatch(addShareFile(message, isRemote));
	}

	/**
	 * (Survey)
	 * 설문조사 요청
	 */
	async notifySurvey({ survey_id }) {
		const permissions = getPermissions(APP.store.getState);

		const isOpenSurvey = isDialogOpen(APP.store.getState, SurveyContainer);

		if (isOpenSurvey) {
			const isPollPermission = permissions.indexOf(PERMISSION.POLL) !== -1 ? true : false;
			APP.eventManager.publish('UPDATE_SURVER_MENU', {
				uuid: survey_id,
				type: isPollPermission ? SURVEY_MENU_TYPE.EDIT : SURVEY_MENU_TYPE.VOTE,
			});
		} else {
			await APP.store.dispatch(openDialog(SurveyContainer, { permissions, survey_id }));

			APP.eventManager.publish('REFRESH_LIST');
		}
	}

	/**
	 * (Survey)
	 * 설문조사 응답
	 */
	replySurvey({ survey_id }) {
		APP.eventManager.publish('REFRESH_LIST');
	}

	/**
	 * (POLICY)
	 * 현재 방 설정
	 * @param {object} policy
	 * @returns
	 */
	updatePolicy({ policy }) {
		APP.store.dispatch(updateRoomPolicy(policy, isRemote));
	}

	/**
	 * (POLICY)
	 * 그리드 옵션 설정
	 */
	setGridOption({ grid_count }) {
		APP.store.dispatch(updateGridOption(null, grid_count, isRemote));
	}

	/**
	 * (GROUP) 그룹 전체 업데이트
	 */
	handlerGroupUpdate() {
		APP.store.dispatch(handlerGroupUpdate());
	}

	/**
	 * (GROUP) 그룹 이름 수정
	 * @param {object} data
	 */
	handlerGroupNameUpdate(data) {
		const { group_id, name } = data;

		APP.store.dispatch(modifyGroup(group_id, { name }, isRemote));
	}

	/**
	 * (GROUP) 그룹 전체 상태 업데이트
	 * @param {object} data
	 */
	handlerGroupStatus(data) {
		const { dispatch, getState } = APP.store;
		const { running } = data;

		APP.store.dispatch(updateGroupStaus(running));

		const local = getLocalMember(getState);
		if (local.group_id !== '') {
			const group = getGroupByUid(getState, local.group_id);
			if (running === true) {
				dispatch(toggleLoad(true, i18next.t('group.assginGroup', { name: group.name })));
			} else {
				dispatch(toggleLoad(true, i18next.t('group.unsetGroup', { name: group.name })));
			}
		}
	}

	/**
	 * (GROUP) 그룹에서 알림메시지 받음.
	 * @param {string} message: 전달할 메시지
	 * @param {string} group_id: 그룹 아이디
	 */
	handlerGroupNotification(data) {
		const result = JSON.parse(data.message);
		if (result.group_id === '') {
			APP.store.dispatch(saveNotification(result.message, isRemote));
			return;
		}

		if (getActiveGroupId(APP.store.getState) === result.group_id) {
			APP.store.dispatch(
				showNotification({
					titleKey: 'notify.notifyMessage',
					description: result.message,
				}),
			);
		}
	}

	handlerStartAttendances(data) {
		const { name, uuid, check_duration } = data;
		APP.store.dispatch(showStartAttendance({ name, uuid, check_duration }));
	}
}

export default new MateMessage();

/**
 * (GROUP) 그룹을 할당/해제 받았을때 대기를 위한 함수.
 * @param {object} data
 */
const handlerUpdateStatus = async (data, isAssign) => {
	const { getState, dispatch } = APP.store;
	const start_group = getStartGroup(getState);
	if (!start_group) return;

	const local = getLocalMember(getState);
	const activieGroup = getActiveGroupId(getState);
	const change_group = data?.group_id || local.group_id;

	if (local.role === ROLE.HOST) {
		if (!isAssign) {
			await dispatch(
				assignGroup(
					change_group,
					[{ user_id: local.user_uuid, role_name: ROLE.ADMIN, type: '' }],
					'unset',
					false,
				),
			);
			APP.management.changeManagement(activieGroup, { group_id: '' });
		}
		return;
	}

	const group = getGroupByUid(getState, change_group);

	if (local.group_id === change_group && data.status === 'opened') {
		dispatch(toggleLoad(true, i18next.t('group.assginGroup', { name: group.name })));
	} else if (local.group_id === change_group && data.status === 'closed') {
		if (activieGroup === change_group)
			dispatch(toggleLoad(true, i18next.t('group.unsetGroup', { name: group.name })));
	}
};

/**
 * (GROUP) 그룹을 할당/해제 받았을때 대기를 위한 함수.
 * @param {object} data
 */
const handlerWaitingSsignGroup = throttle(
	(data, isAssign) => {
		// const throttleEvent = throttle(() => {
		const { getState, dispatch } = APP.store;
		const start_group = getStartGroup(getState);
		if (!start_group) return;

		const local = getLocalMember(getState);
		if (local.role === ROLE.HOST) return;
		const groupName = getGroupByUid(getState, data.group_id)?.name;

		if (local.user_uuid === data.user_id) {
			const active = getActiveGroupId(APP.store.getState);

			if (isAssign) {
				if (active !== '' && local.group_id === active) return;
				dispatch(toggleLoad(true, i18next.t('group.assginGroup', { name: groupName })));
			} else {
				if (local.group_id === active) {
					APP.store.dispatch(toggleLoad(true, i18next.t('group.unsetGroup', { name: groupName })));
				}
			}
		}
	},
	1000,
	{ leading: false, trailing: true },
);
