import { throttle } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Recorder from '../../modules/server/recorder';
import { useGlobalState } from '../app/GlobalStateProvider';
import { CheckBoxElement } from '../base/common';
import { Dialog, toggleDialog } from '../base/dialog';
import { createLocalTrack, createLocalTracks } from '../base/lib-jitsi-meet/functions.any';
import { toggleLoad } from '../base/loading';
import { getPropertyValue, updateSettings } from '../base/settings';
import { ClickButton, Icon, TextElement } from '../base/ui';
import { isMobileDevice } from '../lobby/components';
import { enterRoom, setRoomJoined } from '../room';
import AudioInputPreview from './AudioInputPreview';
import AudioOutputPreview from './AudioOutputPreview';
import AudioVolume from './AudioVolume';
import DeviceSelect from './DeviceSelect';
import { PreviewTab } from './components';
import VideoWrap from './components/VideoWrap';
import AudioInputTest from './components/web/AudioInputTest';
import { getDeviceSelectionProps } from './functions';
import RoomLockDialog from '../room-lock/RoomLockDialog';
import { setAudioInputDeviceAndUpdateSettings, setVideoInputDeviceAndUpdateSettings, } from '../base/devices';
function isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}
function isMacOS() {
    return /Macintosh|MacIntel|MacPPC|Mac68K/.test(navigator.userAgent) && !isIOS();
}
const DeviceSelection = React.memo(({ isDialog }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isMobile, isTablet, isDesktop, isIPhone } = useGlobalState();
    const [possibleRecord, setPossibleRecord] = useState(false);
    const enableNickname = useSelector((state) => getPropertyValue(state, 'enableNickname'), shallowEqual);
    const { hasAudioPermission, hasVideoPermission, availableDevices, selectedAudioInputId, selectedVideoInputId, selectedAudioOutputId, selectedVideoMuted, selectedAudioMuted, initVolume, } = useSelector((state) => getDeviceSelectionProps(state, true), shallowEqual);
    const [initLoad, setInitLoad] = useState(true);
    const [videoId, setVideoId] = useState(undefined);
    const [micId, setMicId] = useState(undefined);
    const [speakerId, setSpeakerId] = useState(selectedAudioOutputId || 'default');
    const [videoErr, setVideoErr] = useState(undefined);
    const [audioErr, setAudioErr] = useState(undefined);
    const [videoMuted, setVideoMuted] = useState(false);
    const [audioMuted, setAudioMuted] = useState(false);
    const [speakerVolume, setSpeakerVolume] = useState(initVolume);
    const [oneClick, setOneClick] = useState(false);
    const [videoTrack, setVideoTrack] = useState(null);
    const [audioTrack, setAudioTrack] = useState(null);
    const [taskQueue, setTaskQueue] = useState(new Set(['getVideoDevice', 'getMicDevice']));
    const toggleLoading = (load) => {
        dispatch(toggleLoad(load));
    };
    const onEnterRoom = throttle(async (e) => {
        if (oneClick)
            return;
        setOneClick(true);
        e?.preventDefault();
        try {
            toggleLoading(true);
            if (isDialog) {
                try {
                    await dispatch(updateSettings({
                        // userSelectedCameraDeviceId: videoId,
                        // userSelectedMicDeviceId: micId,
                        userSelectedAudioOutputDeviceId: speakerId,
                        userSelectedCameraMuted: videoMuted,
                        userSelectedAudioMuted: audioMuted,
                        speakerVolume: speakerVolume,
                    }));
                    dispatch(setVideoInputDeviceAndUpdateSettings(videoId));
                    dispatch(setAudioInputDeviceAndUpdateSettings(micId));
                    // @ts-ignore
                    dispatch(toggleDialog());
                    APP.management.setAudioOutputDevice(speakerId);
                }
                catch { }
            }
            else {
                dispatch(updateSettings({
                    userSelectedCameraDeviceId: videoId,
                    userSelectedMicDeviceId: micId,
                    userSelectedAudioOutputDeviceId: speakerId,
                    userSelectedCameraMuted: videoMuted,
                    userSelectedAudioMuted: audioMuted,
                    speakerVolume: speakerVolume,
                }));
                const response = await enterRoom();
                if (response.complete) {
                    dispatch(setRoomJoined(true));
                }
                else {
                    if (response.message === 'room.passwordChecking') {
                        dispatch(toggleDialog(RoomLockDialog));
                        return;
                    }
                    response.data && APP.UI.alertMessage(response.data, null, true);
                    response.message && APP.UI.alertMessage(response.message, null, true);
                    self.close();
                }
            }
        }
        catch (error) {
            console.error('Error during enterRoom:', error);
        }
        finally {
            dispatch(toggleLoad(false)); // Stop loading
            setOneClick(false);
        }
    }, 1000, { leading: true, trailing: false });
    useEffect(() => {
        toggleLoading(true);
        setOneClick(false);
        APP.management.setLeaveStatus(true);
        const recorder = new Recorder(null, true);
        setPossibleRecord(recorder.isSupportedBrowser());
    }, []);
    useEffect(() => {
        return () => {
            audioTrack && audioTrack.dispose();
        };
    }, [audioTrack]);
    useEffect(() => {
        return () => {
            videoTrack && videoTrack.dispose();
        };
    }, [videoTrack]);
    useEffect(() => {
        if (initLoad)
            return;
        if (availableDevices.audioInput.length === 0 && availableDevices.videoInput.length === 1) {
            setTaskQueue(new Set([]));
        }
    }, [initLoad]);
    useEffect(() => {
        if (initLoad)
            return;
        toggleLoading(true);
        getMicDevice();
    }, [hasAudioPermission]);
    useEffect(() => {
        if (initLoad)
            return;
        toggleLoading(true);
        getVideoDevice();
    }, [hasVideoPermission]);
    useEffect(() => {
        if (!initLoad)
            return;
        const test = throttle(() => {
            if (!initLoad)
                return;
            createLocalTracks(['audio', 'video'], 5000, {})
                .then(([audioTrack, videoTrack]) => {
                if (audioTrack)
                    setMicId(audioTrack.deviceId);
                if (videoTrack)
                    setVideoId(videoTrack.deviceId);
            })
                .catch(err => {
                console.log(err);
                setMicId('default');
                setVideoId('default');
                setSpeakerId('default');
            });
            setTimeout(() => toggleLoad(false), 5000);
        }, 1000, { leading: true, trailing: false });
        test();
    }, [initLoad]);
    const getVideoDevice = () => {
        const task = 'getVideoDevice';
        setTaskQueue(prevQueue => new Set(prevQueue).add(task));
        if (videoTrack)
            videoTrack.dispose();
        createLocalTrack('video', videoId, 5000)
            .then(localTrack => {
            setVideoTrack(localTrack);
            setVideoId(localTrack.deviceId);
            setVideoErr(undefined);
        })
            .catch(err => {
            setVideoTrack(undefined);
            setVideoId(undefined);
            setVideoErr(err.name);
        })
            .finally(() => {
            setTaskQueue(prevQueue => {
                const newQueue = new Set(prevQueue);
                newQueue.delete(task);
                return newQueue;
            });
        });
    };
    useEffect(() => {
        if (videoTrack?.deviceId !== videoId && videoId)
            getVideoDevice();
    }, [videoId, videoTrack]);
    const getMicDevice = () => {
        const task = 'getMicDevice';
        setTaskQueue(prevQueue => new Set(prevQueue).add(task));
        if (audioTrack)
            audioTrack.dispose();
        createLocalTrack('audio', micId, 5000)
            .then(localTrack => {
            setAudioTrack(localTrack);
            setMicId(localTrack.deviceId);
            setAudioErr(undefined);
        })
            .catch(err => {
            setAudioTrack(undefined);
            setMicId(undefined);
            setAudioErr(err.name);
        })
            .finally(() => {
            setTaskQueue(prevQueue => {
                const newQueue = new Set(prevQueue);
                newQueue.delete(task);
                return newQueue;
            });
        });
    };
    useEffect(() => {
        if (audioTrack?.deviceId !== micId && micId)
            getMicDevice();
    }, [micId]);
    useEffect(() => {
        APP.management.setAudioOutputDevice(speakerId);
    }, [speakerId]);
    useEffect(() => {
        if (taskQueue.size === 0) {
            toggleLoading(false);
            setInitLoad(false);
            // locationonEnterRoom();
        }
    }, [taskQueue]);
    const renderPreviewIcon = useMemo(() => {
        return (React.createElement(React.Fragment, null,
            React.createElement(Icon, { icon: "device_connect", size: isDesktop ? 68 : 24, color: "#B9D1EF" }),
            React.createElement("p", { className: "device_title" }, t('deviceSelection.title')),
            !isDialog && isDesktop && React.createElement("p", { className: "device_guid overText" }, t('deviceSelection.guid'))));
    }, [isDesktop, isDialog]);
    const renderEnterMeeting = useMemo(() => {
        return (React.createElement(ClickButton, { className: "device_btn", label: isDialog ? '확인' : 'deviceSelection.enter', icon: isDialog ? undefined : 'nickname_enter', size: isDesktop ? 24 : 12, color: "#FFF", onClick: onEnterRoom }));
    }, [isDesktop, isDialog, onEnterRoom]);
    const renderVideoSelect = useMemo(() => {
        return (React.createElement("div", { className: "device_item", style: { flex: 1 } },
            React.createElement(VideoWrap, { track: videoTrack }),
            React.createElement(DeviceSelect, { error: videoErr, type: "video", list: availableDevices.videoInput, deviceId: videoId, handlerUpdateId: setVideoId }),
            !isDialog && (React.createElement("div", { className: "deivce_check" },
                React.createElement(CheckBoxElement, { id: "video", title: "deviceSelection.videoInputMuted", checked: videoMuted || false, handlerCheck: (id, checked) => setVideoMuted(checked) })))));
    }, [availableDevices.videoInput, videoTrack, videoId, videoMuted, setVideoId, setVideoMuted]);
    const renderMicSelect = useMemo(() => {
        return (React.createElement("div", { className: "device_item" },
            React.createElement(DeviceSelect, { error: audioErr, type: "mic", list: availableDevices.audioInput, deviceId: micId, handlerUpdateId: setMicId },
                React.createElement(AudioInputTest, { track: audioTrack, isIPhone: isIPhone })),
            React.createElement("div", { className: "device_input", style: { width: '100%', alignItems: 'center' } },
                React.createElement(React.Fragment, null,
                    !isDialog && (React.createElement("div", { className: "deivce_check" },
                        React.createElement(CheckBoxElement, { id: "mic", title: "deviceSelection.audioInputMuted", checked: audioMuted || false, handlerCheck: (id, checked) => setAudioMuted(checked) }))),
                    possibleRecord && !isIPhone && React.createElement(AudioInputPreview, { track: audioTrack }))),
            (!possibleRecord || isIPhone) && (React.createElement("span", { className: "sub_guid error" }, t(`${isIPhone ? 'deviceSelection.audioInputIosError' : 'deviceSelection.audioInputError'}`))),
            !isIPhone && React.createElement("span", { className: "sub_guid" }, t('deviceSelection.audioInputGuid'))));
    }, [
        availableDevices.audioInput,
        audioErr,
        audioTrack,
        isIPhone,
        micId,
        setMicId,
        audioMuted,
        setAudioMuted,
        possibleRecord,
    ]);
    const renderSpeakerSelect = useMemo(() => {
        return (React.createElement("div", { className: "device_item" },
            isMobileDevice() ? (React.createElement("div", { className: "mobile_speaker_test" },
                React.createElement("p", null, " \uC2A4\uD53C\uCEE4 \uC74C\uB7C9 \uD14C\uC2A4\uD2B8 "),
                React.createElement(AudioOutputPreview, { deviceId: speakerId, handlerVolume: speakerVolume }))) : (React.createElement(DeviceSelect, { error: audioErr, type: "speaker", list: availableDevices.audioOutput, deviceId: speakerId, handlerUpdateId: deviceId => {
                    setSpeakerId(deviceId);
                    try {
                        APP.management.setAudioOutputDevice(deviceId).catch(err => { });
                    }
                    catch (e) { }
                } },
                React.createElement(AudioOutputPreview, { deviceId: speakerId, handlerVolume: speakerVolume }))),
            React.createElement("div", { className: "device_input", style: { width: '100%', alignItems: 'center' } }, isIOS() || isMacOS() ? (React.createElement(TextElement, { className: "sub_guid error", text: "deviceSelection.audioOutputIosError" })) : (React.createElement(React.Fragment, null,
                !isDialog && (React.createElement("div", { className: "deivce_check" },
                    React.createElement(CheckBoxElement, { id: "speaker", title: "deviceSelection.audioOutputMuted", checked: speakerVolume === 0 ? true : false, handlerCheck: (id, checked) => setSpeakerVolume(checked ? 0 : 0.5) }))),
                !isMobile && (React.createElement(AudioVolume, { defaultVolume: speakerVolume * 100, setSpeakerVolume: setSpeakerVolume })))))));
    }, [audioErr, speakerId, setSpeakerId, speakerVolume, availableDevices.audioOutput]);
    const renderCompontent = () => {
        if (isMobile || isTablet || isDialog) {
            return (React.createElement("div", { className: `device-container ${isDialog && 'isDialog'} mobile` },
                React.createElement("div", { className: "device_wrapper" },
                    React.createElement("div", { className: "device_title_container" },
                        React.createElement("div", { className: "device_container" },
                            !isDialog && enableNickname && React.createElement(PreviewTab, null),
                            renderPreviewIcon)),
                    React.createElement("div", { className: "device_list" },
                        renderVideoSelect,
                        renderMicSelect,
                        renderSpeakerSelect)),
                renderEnterMeeting));
        }
        return (React.createElement("div", { className: `device-container ${isDialog && 'isDialog'}` },
            React.createElement("div", { className: `device_wrapper` },
                !isDialog && enableNickname && React.createElement(PreviewTab, null),
                React.createElement("div", { className: "device_title_container" },
                    React.createElement("div", { className: "device_container" },
                        renderPreviewIcon,
                        renderEnterMeeting)),
                React.createElement("div", { className: "device_list" },
                    renderVideoSelect,
                    renderMicSelect,
                    renderSpeakerSelect))));
    };
    if (isDialog) {
        return (React.createElement(Dialog, { titleKey: "dialog.settingDevice", width: 600, height: 800 }, renderCompontent()));
    }
    else {
        return renderCompontent();
    }
});
export default DeviceSelection;
