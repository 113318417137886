import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteCanvasIndex, setCanvasOption, setCanvasTool } from '../../actions';
import { ALL_ERASER, CLASS_CANVAS_TOOL, MATE_CANVAS_TOOL, MATE_CANVAS_TOOL_MOBILE, PEN, pen_list } from '../../constants';
import ToolButton from './ToolButton';
const CanvasTool = React.memo(({ isMobile, isVod, tool, option, uuid, index, focusTool, setFocusTool }) => {
    const dispatch = useDispatch();
    const [list, setList] = useState(MATE_CANVAS_TOOL);
    useEffect(() => {
        updateList();
    }, []);
    useEffect(() => {
        updateList();
    }, [isMobile, isVod]);
    const updateList = () => {
        let show_list = MATE_CANVAS_TOOL;
        switch (APP.config.name) {
            case 'class':
                show_list = CLASS_CANVAS_TOOL;
                break;
            default:
                if (isMobile)
                    show_list = MATE_CANVAS_TOOL_MOBILE;
                break;
        }
        setList(show_list);
    };
    /**
     * (ACTION) 캔버스 툴 업데이트
     */
    const handlerUpdateCanvasTool = (tool) => {
        if (tool === ALL_ERASER.name) {
            uuid && dispatch(deleteCanvasIndex(uuid, index));
            return;
        }
        dispatch(setCanvasTool(tool));
    };
    /**
     * (ACTION) 캔버스 옵션 업데이트
     */
    const handlerChangeOption = (name, value) => {
        if (pen_list.includes(name)) {
            handlerUpdateCanvasTool(PEN.name);
        }
        dispatch(setCanvasOption({ [name]: value }));
    };
    return (React.createElement(React.Fragment, null, list.map(item => {
        const showChecked = () => {
            const name = typeof item.children !== 'boolean'
                && item.children.find(i => i.name === focusTool.name || i.name === tool)?.name || item.name;
            return tool === name ? true : false;
        };
        const getShowDialog = () => { return focusTool && focusTool.name === item.name && focusTool.children ? true : false; };
        return (React.createElement(ToolButton, { key: item.name, isMobile: isMobile, item: item, option: option, checked: showChecked(), showDialog: getShowDialog(), focusTool: focusTool, setFocusTool: setFocusTool, handlerUpdateCanvasTool: handlerUpdateCanvasTool, handlerChangeOption: handlerChangeOption }));
    })));
});
export default CanvasTool;
