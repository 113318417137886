import { toState } from '../redux';
import { VIDEO_MUTISM_AUTHORITY } from './constants';
// XX 구성/기본 설정/설정 startWithAudioMuted 및 startWithVideoMuted는 회의/회의용으로 도입되었습니다. 
// 따라서 회의/미팅(예: WelcomePage) 외부에서 고려하지 않는 것이 좋습니다. 
// 그러나 나중에 우리는 특정 구성/기본 설정/설정이 약간 배타적인 논리를 사용하도록 
// 회의/회의 외부에서 startAudioOnly를 활용하는 WelcomePage에 "비디오 <-> 음성" 토글을 도입했습니다.
const START_WITH_AUDIO_VIDEO_MUTED_SOURCES = {
    // We have startWithAudioMuted and startWithVideoMuted here:
    config: true,
    settings: true,
    // XXX 이미 base/config를 url Params로 덮어썼습니다. 
    // 그러나 설정은 서버 측 구성보다 더 중요합니다. 따라서 어쨌든 url Params에서 읽어야 합니다.
    urlParams: true,
    // We don't have startWithAudioMuted and startWithVideoMuted here:
    jwt: false
};
/**
 * 오디오가 현재 음소거되어 있는지 여부를 결정합니다.
 *
 * @param {Function|Object} stateful - The redux store, state, or
 * {@code getState} function.
 * @returns {boolean}
 */
export function isAudioMuted(stateful) {
    return Boolean(toState(stateful)['features/base/media'].audio.muted);
}
/**
 * 비디오가 현재 오디오 전용 권한에 의해 음소거되었는지 여부를 결정합니다.
 *
 * @param {Function|Object} stateful - The redux store, state, or
 * {@code getState} function.
 * @returns {boolean}
 */
export function isVideoMutedByAudioOnly(stateful) {
    return (_isVideoMutedByAuthority(stateful, VIDEO_MUTISM_AUTHORITY.AUDIO_ONLY));
}
/**
 * 비디오가 현재 특정 장치에 의해 음소거되었는지 여부를 결정합니다.
 * {@code VIDEO_MUTISM_AUTHORITY}.
 *
 * @param {Function|Object} stateful - The redux store, state, or
 * {@code getState} function.
 * @param {number} videoMutismAuthority - The {@code VIDEO_MUTISM_AUTHORITY}
 * 음소거 된 비디오가 있는지 확인해야합니다.
 * @returns {boolean} 동영상이 현재 지정된 {@code videoMutismAuthority}에 의해 음소거된 경우 {@code true}입니다. 그렇지 않으면 {@code false}.
 */
function _isVideoMutedByAuthority(stateful, videoMutismAuthority) {
    const { muted } = toState(stateful)['features/base/media'].video;
    // eslint-disable-next-line no-bitwise
    return Boolean(muted & videoMutismAuthority);
}
// /**
//  * 구성, URL 및 설정에서 값을 검색하여 startWithAudioMuted를 계산합니다.
//  *
//  * @param {Object|Function} stateful - The redux state object or {@code getState} function.
//  * @returns {boolean} - The computed startWithAudioMuted value that will be used.
//  */
// export function getStartWithAudioMuted(stateful: Object | Function) {
//     return Boolean(getPropertyValue(stateful, 'startWithAudioMuted', START_WITH_AUDIO_VIDEO_MUTED_SOURCES));
// }
// /**
//  * 구성, URL 및 설정에서 값을 검색하여 startWithVideoMuted를 계산합니다.
//  *
//  * @param {Object|Function} stateful - The redux state object or {@code getState} function.
//  * @returns {boolean} - 사용될 계산된 startWithVideoMuted 값입니다.
//  */
// export function getStartWithVideoMuted(stateful: Object | Function) {
//     return Boolean(getPropertyValue(stateful, 'startWithVideoMuted', START_WITH_AUDIO_VIDEO_MUTED_SOURCES));
// }
/**
 * 비디오가 현재 음소거 상태인지 여부를 결정합니다.
 *
 * @param {Function|Object} stateful - The redux store, state, or {@code getState} function.
 * @returns {boolean}
 */
export function isVideoMuted(stateful) {
    return Boolean(toState(stateful)['features/base/media'].video.muted);
}
/**
 * 비디오가 현재 사용자 권한에 의해 음소거되었는지 여부를 결정합니다.
 *
 * @param {Function|Object} stateful - The redux store, state, or
 * {@code getState} function.
 * @returns {boolean}
 */
export function isVideoMutedByUser(stateful) {
    return _isVideoMutedByAuthority(stateful, VIDEO_MUTISM_AUTHORITY.USER);
}
/**
 * 특정 videoTrack을 렌더링해야 하는지 여부를 결정합니다.
 *
 * @param {Track} videoTrack - The video track which is to be rendered.
 * @param {boolean} waitForVideoStarted - 지정된 videoTrack이 연결된 비디오가 시작된 후에만 렌더링되어야 하는 경우 True이고, 그렇지 않으면 False
 * @returns {boolean} 지정된 videoTrack을 렌더링해야 하는 경우 True이고, 그렇지 않으면 False.
 */
export function shouldRenderVideoTrack(videoTrack, waitForVideoStarted) {
    return (videoTrack
        && !videoTrack.muted
        && (!waitForVideoStarted || videoTrack.videoStarted));
}
