import React from 'react';
import { Button } from '../../base/common';
import { useGlobalState } from '../../app/GlobalStateProvider';
import { PAGE_TYPE } from '../../base/app/constants';
export const PreviewTab = React.memo(() => {
    const { isMobile } = useGlobalState();
    const handlerEnterNick = () => {
        APP.eventManager.publish('UPDATE_PAGE', { page: PAGE_TYPE.NICKNAME });
    };
    return (React.createElement(Button, { className: "page_previous", option: {
            buttonAfterIcon: { show: true, name: 'page_previous', size: isMobile ? 10 : 25, color: '#E4E4E5' },
        }, onClick: handlerEnterNick }));
});
