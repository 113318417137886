import React, { useEffect, useRef, useState } from "react";
const FileComponent = React.memo(({ id, label, onChange }) => {
    const inputRef = useRef(null);
    const [file, setFile] = useState();
    useEffect(() => {
        if (file) {
            onChange(file);
            setFile(undefined);
            if (inputRef.current && inputRef.current.value) {
                inputRef.current.value = '';
            }
        }
    }, [file]);
    const handlerChange = (e) => {
        const files = e.target.files;
        if (files && files.length > 0)
            setFile(files[0]);
    };
    return (React.createElement("label", { htmlFor: id },
        React.createElement("input", { ref: inputRef, type: 'file', style: { display: "none" }, id: id, onChange: handlerChange }),
        label && label));
});
export default FileComponent;
