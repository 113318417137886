/**
 * 모바일 or 태블릿 or PC 인가 (현재 레이아웃 상태 체크)
 * @param {string} type
 * @returns {{
  *      type: SET_LAYOUT_TYPE,
  *      type: string
  * }}
  */
export const SET_LAYOUT_TYPE = 'SET_LAYOUT_TYPE';
/**
 * 레이아웃 변경
 *
 * {
 *     type: SET_LAYOUT_MODE,
 *     mode: string,
 *     page: number
 * }
 */
export const SET_LAYOUT_MODE = 'SET_LAYOUT_MODE';
/**
 * 레이아웃 스타일 값 변경
 * @param {number} width
 * @param {number} height
 * @returns
 */
export const SET_LAYOUT_STYLE = 'SET_LAYOUT_STYLE';
/**
 *  화면 보이는 사용자 리스트 세팅
 * {
 *     type: SET_LAYOUT_MEMBERS,
 *     members: {
 *          remote: new Set<string>,
 *          pin: new Set<string>,
 *          voice: new Set<string>
 *          seminar: new Set<string>
*      }
 * }
 */
export const SET_LAYOUT_MEMBERS = 'SET_LAYOUT_MEMBERS';
/**
 * 화면페이지
 * @param {number} page
 * @returns
 */
export const SET_PAGE = 'SET_PAGE';
/**
 * 화면 페이징
 * @param {number} maxPage
 * @returns
 */
export const SET_MAX_PAGE = 'SET_MAX_PAGE';
/**
 *
 * @param {Boolean} expand
 * @returns
 */
export const SET_EXPAND = 'SET_EXPAND';
/**
 * 듀얼 모니터
 */
export const SET_DUAL_MONITOR = 'SET_DUAL_MONITOR';
