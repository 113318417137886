import { SETTINGS_UPDATED, SET_DISPLAYNAME, SET_FULL_SCREEN, UPDATE_ROOM_POLICY } from './actionTypes';
import { IStore } from '../../app/types';
import { getPropertyValue } from './functions';
/**
 * Create an action for when the settings are updated.
 *
 * @param {Object} settings - The new (partial) settings properties.
 * @returns {{
 *     type: SETTINGS_UPDATED,
 *     settings: {
 *          route: stirng|boolean
 *     }
 * }}
 */
export function updateSettings(settings) {
    return {
        type: SETTINGS_UPDATED,
        settings
    };
}

/**
 * 닉네임 저장 
 * @param {Strig} displayName,
 * @returns({
 *     type: SET_DISPLAYNAME,
 *     displayName: String
 * })
 */
export function setDisplayName(displayName) {
    return {
        type: SET_DISPLAYNAME,
        displayName
    }
}

/**
 * 전체 화면 보기 
 * {
 *     type: SET_FULL_SCREEN,
 *     full: Boolean
 * }
 */
export function setFullScreen(full) {
    return {
        type: SET_FULL_SCREEN,
        full
    }
}

/**
 * 룸 설정 값 업데이트 
 * @param {Object} policy 
 * @param {Boolean} isRemote
 */
export function updateRoomPolicy(policy, isRemote = true) {
    return async (dispatch, getState) => {
        const prevPolicy = getPropertyValue(getState, 'policy');
        const newPolicy = { ...prevPolicy, ...policy };

        if (isRemote === false) {
            const response =  await APP.management.updateRoomPolicy(newPolicy);

            if (response.status !== 200) {
                /**
                 * (Fix me)
                 */
                return;
            }
        }

        dispatch({
            type: UPDATE_ROOM_POLICY,
            policy: newPolicy
        });
    }
}

export function updateGridOption(sync, count, isRemote = true) {
    return async (dispatch, getState) => {
        sync && await dispatch(updateRoomPolicy({ grid_count_option: sync }, isRemote));

        if (isRemote === false) {
            const response =  await APP.management.setGridCount(count);

            if (response.status !== 200) {
                /**
                 * (Fix me)
                 */
                return;
            }
        }

        dispatch(updateSettings({ grid_count: count }));
    }
}