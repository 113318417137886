import React, { useEffect, useRef, useState } from "react";
import { ClickButton } from "../../ui";
import ChatFilePopup from "../ChatFilePopup";
import { isTransferableFile } from "../functions";
function AddFile() {
    const [file, setFile] = useState();
    const inputRef = useRef(null);
    const handlerAddFile = e => {
        if (inputRef && inputRef.current && inputRef.current.files) {
            const file = inputRef.current.files[0];
            setFile(file);
        }
    };
    useEffect(() => {
        if (!file)
            return;
        const result = isTransferableFile(file);
        APP.eventManager.publish('UPDATE_OPEN_CHAT_POPUP', { componet: ChatFilePopup, props: { status: result, data: file } });
        // file input reset 
        if (inputRef.current)
            inputRef.current.value = '';
    }, [file]);
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { ref: inputRef, type: "file", id: "file", style: { display: "none" }, onChange: handlerAddFile }),
        React.createElement("label", { htmlFor: "file" },
            React.createElement(ClickButton, { icon: "file", size: 20, color: "#545454" }))));
}
export default React.memo(AddFile);
