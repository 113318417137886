import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getLocalIDMember } from "../../base/members";
import { ClickButton, Input } from "../../base/ui";
import { PopupContainer } from "./common";
const GroupRandomPopup = React.memo(() => {
    const { t } = useTranslation();
    const [count, setCount] = useState('0');
    const localID = useSelector((state) => getLocalIDMember(state));
    const handlerMakeRandom = async () => {
        const group_number = parseInt(count);
        if (group_number > 0) {
            await APP.API.randomizeGroup({ group_number, exception_ids: [localID] });
            APP.eventManager.publish('UPDATE_OPEN_POPUP');
        }
    };
    return (React.createElement(PopupContainer, { title: t("group.random"), guid: t("group.randomGuid") },
        React.createElement(Input, { className: 'random_input', label: t("group.count"), placeholder: t("group.placeholderCount"), onChange: setCount }),
        React.createElement(ClickButton, { type: "number", label: t("group.makeRandomGroup"), isText: true, onClick: handlerMakeRandom })));
});
export default GroupRandomPopup;
