import { openDialog } from "../base/dialog";
import { getLocalID } from "../base/local";
import { PERMISSION } from "../base/members";
import { MiddlewareRegistry } from "../base/redux";
import { DELETE_CANVAS_INDEX } from "../canvas";
import { checkCanvasPermission } from "../canvas/functions";
import DocumentListDialog from "./DocumentListDialog";
import { ADD_SHARE_ITEM, DELETE_SHARE_ITEM, OPEN_DOCUMENT_LIST, SET_FOCUS_SHARE_ITEM, UPDATE_SHARE_ITEM } from "./actionTypes";
import { addShareItem, setFocusShareItem } from "./actions";
import { DEFAULT_DOCUMENT, DEFAULT_INDEX, VOD_MODE } from "./constants";
import { checkSharePermission, getDocumentItemByUUID, getFocusItemByMode } from "./functions";
MiddlewareRegistry.register(store => next => async (action) => {
    const { dispatch, getState } = store;
    switch (action.type) {
        // (DOCUMENT - FOCUS_DOCUMENT) 문서 포커스 
        case SET_FOCUS_SHARE_ITEM:
            if (!action.isRemote) {
                if (checkSharePermission(getState, PERMISSION.SHARE)) {
                    // const changeMode = APP.config.option.workbook && WORKBOOK_LIST.includes(action.mode) ? DOCUMENT_MODE : action.mode;
                    const response = await APP.management.focusShareItem(action.uuid, action.index, action.mode);
                    if (response.status !== 200) {
                        /**
                         * (fix) 여기서의 에러 처리 필요
                         */
                        console.log(" 문서 포커스 오류 - ", action);
                        return;
                    }
                }
                else
                    return;
            }
            break;
        // (DOCUMENT - REMOVE DOCUMENT) 문서 삭제
        case DELETE_SHARE_ITEM:
            if (!action.isRemote) {
                if (checkSharePermission(getState, PERMISSION.SHARE)) {
                    const response = await APP.management.deleteShareItem({ uuid: action.uuid });
                    if (response.status !== 200) {
                        /**
                         * (fix) 여기서의 에러 처리 필요
                         */
                        console.log(" 문서 포커스 오류 - ", action);
                        return;
                    }
                    else {
                        const document = getDocumentItemByUUID(getState, action.uuid);
                        if (document) {
                            const focusItem = getFocusItemByMode(getState, document.config);
                            if (action.uuid === focusItem.uuid) {
                                dispatch(setFocusShareItem(DEFAULT_DOCUMENT, DEFAULT_INDEX, document.config));
                            }
                        }
                        // Focus 된 상태인 경우 -> Focus out 
                    }
                }
                else
                    return;
            }
            break;
        case UPDATE_SHARE_ITEM:
            const prevDocument = getDocumentItemByUUID(getState, action.uuid);
            const newDocument = prevDocument ? Object.assign(prevDocument, action.data) : action.data;
            store.dispatch(addShareItem(newDocument, action.isRemote));
            break;
        case ADD_SHARE_ITEM:
            if (!action.isRemote) {
                if (checkSharePermission(getState, PERMISSION.SHARE)) {
                    const data = action.data.config === VOD_MODE
                        ? { ...action.data, user_uuid: getLocalID(getState) }
                        : action.data;
                    const response = await APP.management.setShareItem(data);
                    if (response.status !== 200) {
                        /**
                         * (fix) 여기서의 에러 처리 필요
                         */
                        console.log(" 레이아웃 모드 오류 - ", action.mode);
                        return;
                    }
                    if (action.data.isWhiteBoard)
                        dispatch(setFocusShareItem(action.data.uuid, DEFAULT_INDEX, action.data.config, action.isRemote));
                }
                else
                    return;
            }
            else {
            }
            break;
        case DELETE_CANVAS_INDEX:
            if (!action.isRemote) {
                if (checkCanvasPermission(getState, 'share')) {
                    APP.management.deleteShareCanvasIndex(action.uuid, action.index)
                        .catch(err => {
                        console.log(err);
                    });
                }
                else
                    return;
            }
            break;
        case OPEN_DOCUMENT_LIST:
            store.dispatch(openDialog(DocumentListDialog));
            break;
    }
    return next(action);
});
