import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../Colors';
import InputElement from './Input';
import { Icon } from '../../ui';
// CheckBoxElement 컴포넌트를 메모화하여 동일한 속성으로 렌더링 재사용
export const CheckBoxElement = React.memo(({ style, id, className, iconSize = 25, isRadioButton = false, title, checked, option, handlerCheck, isDisable, }) => {
    // 언어 번역을 위한 훅 사용
    const { t } = useTranslation();
    // 체크 상태 토글 함수
    const toggleChecked = () => {
        handlerCheck(id, !checked);
    };
    // 컴포넌트 렌더링
    return (React.createElement("label", { className: `checkbox-item ${className}`, style: style },
        React.createElement("input", { type: "checkbox", checked: checked, onChange: toggleChecked }),
        React.createElement(Icon, { icon: checked
                ? isRadioButton
                    ? 'radio_checked'
                    : 'checkbox_checked'
                : isRadioButton
                    ? 'radio_unchecked'
                    : 'checkbox_unchecked', size: iconSize, fill: Colors.transparent }),
        !option?.input?.show && title && React.createElement("span", null,
            t(title),
            " "),
        option?.input?.show && (React.createElement(InputElement, { className: option.input?.className, type: "text", placeholder: option?.input?.placeholder, defaultValue: title, onChange: option?.input?.onChange ? value => option?.input?.onChange(id, value) : undefined, isDisable: isDisable }))));
});
export const CheckBox = React.memo(({ className, isRadioButton = false, iconSize = 25, list, option, handlerCheck, }) => {
    const [selectedId, setSelectedId] = useState(null);
    const handleCheck = (id, checked) => {
        if (checked) {
            setSelectedId(id);
            handlerCheck(id, checked);
        }
        else {
            if (isRadioButton) {
                const checkedItem = list.find(item => item.id === id);
                if (checkedItem.checked)
                    return;
            }
            setSelectedId(null);
            handlerCheck(id, checked);
        }
    };
    React.useEffect(() => {
        if (isRadioButton) {
            const checkedItem = list.find(item => item.checked);
            if (checkedItem) {
                setSelectedId(checkedItem.id);
            }
        }
    }, [isRadioButton]);
    return (React.createElement("div", { className: `checkbox ${className}` }, list.map((item) => (React.createElement(CheckBoxElement, { key: item.id, iconSize: iconSize, option: option, isRadioButton: isRadioButton, ...item, checked: isRadioButton ? selectedId === item.id : item.checked, handlerCheck: isRadioButton ? handleCheck : handlerCheck })))));
});
