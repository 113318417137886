import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getActiveGroupId } from '../../room';
import ChatItemContainer from './ChatItemContainer';
import { getMessages } from './functions';
const ChatBodyContainer = React.memo(({ groupId, handlerScroll }) => {
    const active_group = useSelector((state) => getActiveGroupId(state), shallowEqual);
    const messages = useSelector((state) => getMessages(state, groupId ? groupId : active_group), shallowEqual);
    useEffect(() => {
        handlerScroll();
    }, [messages]);
    return messages.map((uuid, index) => React.createElement(ChatItemContainer, { key: index, uuid: uuid }));
});
export default ChatBodyContainer;
