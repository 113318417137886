/**
 * 현재 화면 공유 중이거나 화면 공유 종료를 알림.
 * @param {String} jitsi_id
 * @param {String} member_uuid
 * @param {Boolean} isRemote
 * @returns
 */
export const SET_SHARE_SCREEN_ID = 'SET_SHARE_SCREEN_ID';
/**
 * 사용자 UI 옵션 업데이트
 * @param {String|null} ui
 * @returns
 */
export const SET_SHARE_SCREEN_OPTION = 'SET_SHARE_SCREEN_OPTION';
