import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
const DialogContainer = React.memo(() => {
    const { component, componentProps } = useSelector((state) => state['features/base/dialog'], shallowEqual);
    const renderDialogContent = useMemo(() => {
        return component ? React.createElement(component, componentProps) : null;
    }, [component, componentProps]);
    return renderDialogContent;
});
export default DialogContainer;
