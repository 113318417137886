//@ts-ignore
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DropDownOption } from '../option';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../ui';
export const DropDown = React.memo(({ className, value, list, styles, option, isDisable = false, setDropValue }) => {
    const { t } = useTranslation();
    const dropDownRef = useRef(null);
    const dropDownChildRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [dropItem, setDropItem] = useState(value);
    useEffect(() => {
        if (dropItem.id !== value.id)
            setDropItem(value);
    }, [value]);
    const dropOption = useMemo(() => _.merge({}, DropDownOption, option), [option]);
    const handlerDropDownClick = () => {
        const dropdownContent = dropDownRef.current;
        if (dropdownContent) {
            const dropChild = dropDownChildRef.current;
            const parentRect = dropdownContent.getBoundingClientRect();
            if (dropChild) {
                dropChild.style.width = `${parentRect.width}px`;
                // dropChild.style.top = `${parentRect.y + parentRect.height + 10}px`; // 부모 요소의 아래에 위치하도록 top 값 설정
                // dropChild.style.left = `${parentRect.x}px`; // 좌측 정렬
            }
        }
        setOpen(!open);
    };
    const handleClickOutside = (event) => {
        const dropdownContent = dropDownRef.current;
        if (dropdownContent && !dropdownContent.contains(event.target)) {
            setOpen(false);
        }
    };
    const handleResize = () => setOpen(false);
    useEffect(() => {
        APP.eventManager.subscribe('scroll', handleResize);
        window.addEventListener('click', handleClickOutside);
        return () => {
            APP.eventManager.unsubscribe('scroll', handleResize);
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);
    const handlerItemClick = (item) => {
        setDropValue(item);
        setDropItem(item);
        setOpen(false);
    };
    /**
     * (Component) Select Title
     */
    const renderTitle = useMemo(() => {
        return (React.createElement(React.Fragment, null,
            dropOption.titleIcon.show && (React.createElement(Icon, { icon: dropOption.titleIcon.name, size: dropOption.titleIcon.size, fill: dropOption.titleIcon.color })),
            dropOption.titleText.show && (React.createElement("p", { className: "placeholder overText", style: { flex: 1 } }, t(`${dropItem.title}`)))));
    }, [dropItem, dropOption.titleIcon]);
    const renderItem = (item, index) => {
        const selected = dropItem.id === item.id;
        return (React.createElement("div", { key: `${item.id}_${index}`, className: `${selected ? 'dropdown-label2' : 'dropdown-label'} non-hover`, style: styles?.bodyItem, onClick: () => handlerItemClick(item) },
            dropOption.itemIcon.show && (React.createElement(Icon, { icon: dropOption.itemIcon.name, size: dropOption.itemIcon.size, fill: selected ? dropOption.itemIcon.focus : dropOption.itemIcon.color })),
            React.createElement("p", { className: "placeholder2 overText", style: { flex: 1, ...styles?.itemText } }, t(`${item.title}`))));
    };
    return (React.createElement("div", { ref: dropDownRef, className: `dropdown ${isDisable ? 'disabled' : ''} ${className}`, style: styles?.dropWrap, onMouseDown: open ? handleClickOutside : undefined },
        React.createElement("div", { className: "dropdown-field", style: styles?.titleWrap, onClick: isDisable ? undefined : handlerDropDownClick },
            renderTitle,
            dropOption.downIcon.show && (React.createElement(Icon, { icon: dropOption.downIcon.name, className: open ? 'arrow-up' : 'arrow-down', size: dropOption.downIcon.size, stroke: dropOption.downIcon.color }))),
        React.createElement("div", { ref: dropDownChildRef, className: "dropdown-list", style: {
                display: open ? 'flex' : 'none',
                ...styles?.itemWrap,
            } }, list.map((item, index) => renderItem(item, index)))));
});
